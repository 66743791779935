/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
	Form,
	Formik,
	FormikHelpers,
	FormikProps,
	FormikProvider,
	FormikValues,
	useFormik,
} from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';

// icons
import { ReactComponent as Profile } from 'design_system/src/static/icons/stars-profile.svg';

import { ReactComponent as Close } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as User } from 'design_system/src/static/icons/add-profile.svg';
import { ReactComponent as Phone } from 'design_system/src/static/icons/phone.svg';
import { ReactComponent as Mail } from 'design_system/src/static/icons/mail.svg';
import { ReactComponent as Email } from 'design_system/src/static/icons/email.svg';
import { ReactComponent as Map } from 'design_system/src/static/icons/map.svg';
import { ReactComponent as ID } from 'design_system/src/static/icons/user-card-2.svg';
import { ReactComponent as Calendar } from 'design_system/src/static/icons/date.svg';
import { ReactComponent as Check } from 'design_system/src/static/icons/checkmark.svg';
import { ReactComponent as Moto } from 'design_system/src/static/icons/motorcycle.svg';
import { ReactComponent as Baby } from 'design_system/src/static/icons/baby.svg';
import { ReactComponent as Working } from 'design_system/src/static/icons/working-bag-3.svg';
// import { ReactComponent as Save } from "design_system/src/static/icons/save.svg";
import { ReactComponent as Suspension } from 'design_system/src/static/icons/suspension.svg';
import { ReactComponent as Ruler } from 'design_system/src/static/icons/ruler-pencil.svg';
import { ReactComponent as Love } from 'design_system/src/static/icons/couple-love.svg';
import { ReactComponent as Files } from 'design_system/src/static/icons/documents.svg';
import { ReactComponent as GPS } from 'design_system/src/static/icons/gps.svg';
import { ReactComponent as Up } from 'design_system/src/static/icons/success-plan.svg';
import { ReactComponent as Down } from 'design_system/src/static/icons/failed-plan.svg';
import { ReactComponent as Percentage } from 'design_system/src/static/icons/percentage.svg';
import { ReactComponent as Bookmark } from 'design_system/src/static/icons/bookmark.svg';
import { ReactComponent as Color } from 'design_system/src/static/icons/color.svg';
// import { ReactComponent as Art } from "design_system/src/static/icons/new-artboard.svg";
import { ReactComponent as Coin } from 'design_system/src/static/icons/coin.svg';
import { ReactComponent as Money } from 'design_system/src/static/icons/money.svg';
import { ReactComponent as Archive } from 'design_system/src/static/icons/archive.svg';
import { ReactComponent as Speedometer } from 'design_system/src/static/icons/speedometer.svg';
import { ReactComponent as CD } from 'design_system/src/static/icons/cd.svg';
import { ReactComponent as List } from 'design_system/src/static/icons/numbered-list.svg';
import { ReactComponent as Card } from 'design_system/src/static/icons/credit-card-2.svg';
import { ReactComponent as Wheel } from 'design_system/src/static/icons/car-wheel-2.svg';
import { ReactComponent as Image } from 'design_system/src/static/icons/image.svg';
import { ReactComponent as Save } from 'design_system/src/static/icons/save.svg';
import { ReactComponent as Store } from 'design_system/src/static/icons/store.svg';
import { ReactComponent as Helmet } from 'design_system/src/static/icons/helmet-race.svg';
import { ReactComponent as Copy } from 'design_system/src/static/icons/copy.svg';
import { ReactComponent as Wrench } from 'design_system/src/static/icons/thermometer-hot.svg';
import { ReactComponent as Book } from 'design_system/src/static/icons/book.svg';

import Divider from '@mui/material/Divider';
import { Button, Input, SideModal, Typography } from 'design_system/src';

// css
import './ozocio.scss';
import { OfferVehicle, TOzocio } from 'models/ozocio.interface';
import Inventory from 'views/inventory/Inventory';
import {
	createOffer,
	createOzocio,
	editOffer,
	editOzocio,
	getBrandsOzocios,
	getModelsOzocios,
} from 'models/ozocios';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { toasterOptions } from '../../models/shared/enums/toaster.enum';

const ozocioVS = yup.object({
	name: yup.string().required('Campo requerido'),
	phone: yup.number().required('Campo requerido'),
	email: yup.string().email('Debe ser email').required('Campo requerido'),
});
const offerVS = yup.object({
	km: yup.number().required('Campo requerido'),
	brand: yup.string().required('Campo requerido'),
	model: yup.string().required('Campo requerido'),
	cylinderCapacity: yup.string().required('Campo requerido'),
	year: yup.number().required('Campo requerido'),
	amountOffer: yup.number().required('Campo requerido'),
});
const ozocioD = {
	name: '',
	phone: '',
	email: '',
};

const offerD = {
	km: '',
	brand: '',
	model: '',
	cylinderCapacity: '',
	year: '',
	amountOffer: '',
};

interface EditProps {
	open: boolean;
	setOpen: Function;
	setResult: Function;
	ozocio?: TOzocio | null;
	offer?: OfferVehicle | null;
	label?: string;
}

const years = [
	{
		label: '2024',
		value: 2024,
	},
	{
		label: '2023',
		value: 2023,
	},
	{
		label: '2022',
		value: 2022,
	},
	{
		label: '2021',
		value: 2021,
	},
	{
		label: '2020',
		value: 2020,
	},
];

const formatNumber = (number: string) => {
	if (!number.startsWith('521')) {
		return `521${number}`;
	}
	return number;
};
function EditOzocio({
	open,
	setOpen,
	ozocio,
	offer,
	setResult,
	label = 'Crear nuevo Ozocio',
}: EditProps) {
	const [ozocioI, setOzocioI] = useState<any>(ozocioD);
	const [offerI, setOfferI] = useState<any>(offerD);
	const [brands, setBrands] = useState<string[]>([]);
	const [models, setModels] = useState<string[]>([]);
	const [cylinders, setCylinders] = useState<{ [key: string]: string[] }>({});

	const [loading, setLoading] = useState<boolean>(false);
	const [showErrors, setShowErrors] = useState<boolean>(false);

	const ozocioF = useFormik({
		initialValues: ozocioI,
		validateOnMount: true,
		enableReinitialize: true,
		validationSchema: ozocioVS,
		onSubmit: () => {},
	});
	const offerF = useFormik({
		initialValues: offerI,
		validateOnMount: true,
		enableReinitialize: true,
		validationSchema: offerVS,
		onSubmit: () => {},
	});

	useEffect(() => {
		if (ozocio) {
			const { name, phone, email } = ozocio;
			setOzocioI({ name, phone, email });
		}
		if (offer) {
			const { km, brand, model, cylinderCapacity, year, amountOffer } = offer;

			setOfferI({
				km,
				brand,
				model,
				cylinderCapacity,
				year,
				amountOffer,
			});
		}
	}, [ozocio, offer]);

	const clearForm = () => {
		setOpen(false);
		setOzocioI(ozocioD);
		setOfferI(offerD);
		ozocioF.resetForm();
		offerF.resetForm();
		setLoading(false);
	};

	const onSubmit = () => {
		setLoading(true);
		if (ozocioF.isValid && offerF.isValid) {
			const ozocioData = {
				...ozocioF.values,
				phone: formatNumber(`${ozocioF.values.phone}`),
			};
			const formData: any = { ...offerF.values };

			const p = [];
			if (!ozocio?._id) {
				p.push(
					createOzocio(ozocioData).then((result) => {
						return createOffer(result._id, formData);
					}),
				);
			} else {
				p.push(editOzocio(ozocio._id, ozocioData));
				if (offer?._id) {
					p.push(editOffer(ozocio._id, offer._id, formData));
				} else {
					p.push(createOffer(ozocio._id, formData));
				}
			}
			Promise.all(p)
				.then(() => {
					setResult();
					clearForm();
					toasterNotification({
						msg: 'Se actualizó el Ozocio Correctamente',
						toasterType: toasterOptions.success,
						style: 'light',
					});
				})
				.catch((err) => {
					toasterNotification({
						// eslint-disable-next-line no-nested-ternary
						msg: err ? (Array.isArray(err) ? err[0] : err) : 'Ocurrio un error',
						toasterType: toasterOptions.error,
						style: 'light',
					});
					setLoading(false);
				});
		} else {
			setLoading(false);
			setShowErrors(true);
		}
	};

	const handleBrandChange = (brand: any) => {
		setModels([]);
		offerF.setFieldValue('model', '');
		getModelsOzocios(brand).then((model: any[]) => {
			const cylinder: any = {};
			setModels(
				model.map((m) => {
					cylinder[m._id] = m.cylinder_capacities.map((c: any) => c.value);
					return m._id;
				}),
			);
			setCylinders(cylinder);
		});
	};

	const handleModelChange = (e: any) => {
		offerF.setFieldValue('cylinderCapacity', '');
	};

	useEffect(() => {
		getBrandsOzocios().then((brand: any[]) => {
			setBrands(brand.map((b) => b.name));
		});
	}, []);

	useEffect(() => {
		if (offerI.brand) {
			getModelsOzocios(offerI.brand).then((model: any[]) => {
				const cylinder: any = {};
				setModels(
					model.map((m) => {
						cylinder[m._id] = m.cylinder_capacities.map((c: any) => c.value);
						return m._id;
					}),
				);
				setCylinders(cylinder);
			});
		}
	}, [offerI]);

	return (
		<SideModal
			open={open}
			setOpen={(e: any) => {
				clearForm();
				setOpen(e);
			}}
			size="normal"
		>
			<div className="flex_content_col">
				<div className="flex_header">
					<Typography
						scale="heading4"
						weight="600"
						textColor="neutral_900"
						className="m_b_lg m_t_md"
					>
						{label}
					</Typography>
					<Divider />
				</div>
				<div className="flex_body p_y_lg p_x_sm overflow_x_hidden">
					<form>
						<Typography
							scale="heading4"
							weight="600"
							textColor="neutral_700"
							className="m_b_xl"
						>
							Información personal
						</Typography>
						<Grid
							container
							direction="row"
							rowSpacing={0}
							columnSpacing={6}
							justifyContent="center"
							className=""
						>
							<Grid item xs={12}>
								<Input
									title="Nombre*"
									placeholder="Escribe el nombre aquí"
									name="name"
									type="text"
									icon={<Profile />}
									className="m_b_xs w_100_per"
									value={ozocioF.values.name}
									onChange={ozocioF.handleChange}
									onBlur={ozocioF.handleBlur}
									error={
										!!ozocioF.errors['name'] &&
										(ozocioF.getFieldMeta('name').touched || showErrors)
									}
									subtitle={
										ozocioF.getFieldMeta('name').touched || showErrors
											? (ozocioF.errors['name'] as string)
											: undefined
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									onBlur={ozocioF.handleBlur}
									error={
										!!ozocioF.errors['email'] &&
										(ozocioF.getFieldMeta('email').touched || showErrors)
									}
									subtitle={
										ozocioF.getFieldMeta('email').touched || showErrors
											? (ozocioF.errors['email'] as string)
											: undefined
									}
									title="Correo*"
									placeholder="Escribe el correo aquí"
									name="email"
									type="email"
									icon={<Email />}
									className="m_b_xs w_100_per"
									value={ozocioF.values.email}
									onChange={ozocioF.handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									onBlur={ozocioF.handleBlur}
									error={
										!!ozocioF.errors['phone'] &&
										(ozocioF.getFieldMeta('phone').touched || showErrors)
									}
									subtitle={
										ozocioF.getFieldMeta('phone').touched || showErrors
											? (ozocioF.errors['phone'] as string)
											: undefined
									}
									title="Teléfono*"
									placeholder="Escribe el teléfono aquí"
									name="phone"
									type="number"
									icon={<Phone />}
									className="m_b_xs w_100_per"
									value={ozocioF.values.phone}
									onChange={ozocioF.handleChange}
								/>
							</Grid>
						</Grid>

						<Divider variant="middle" />
						<Typography
							scale="heading4"
							weight="600"
							textColor="neutral_700"
							className="m_y_xl"
						>
							Oferta de vehiculo
						</Typography>
						<Grid
							container
							direction="row"
							rowSpacing={0}
							columnSpacing={6}
							justifyContent="center"
							className=""
						>
							<Grid item xs={12}>
								<Input
									onBlur={(e) => {
										offerF.handleBlur(e);
									}}
									error={
										!!offerF.errors.km &&
										(offerF.getFieldMeta('km').touched || showErrors)
									}
									subtitle={
										offerF.getFieldMeta('km').touched || showErrors
											? (offerF.errors.km as string)
											: undefined
									}
									title="Kilometraje*"
									name="km"
									type="number"
									className="m_b_xs w_100_per"
									icon={<Speedometer />}
									placeholder="Escribe el Kilometraje"
									value={offerF.values.km}
									onChange={(e: any) => {
										offerF.handleChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									onBlur={offerF.handleBlur}
									error={
										!!offerF.errors.brand &&
										(offerF.getFieldMeta('brand').touched || showErrors)
									}
									subtitle={
										offerF.getFieldMeta('brand').touched || showErrors
											? (offerF.errors.brand as string)
											: undefined
									}
									title="Marca*"
									name="brand"
									icon={<Moto />}
									placeholder="Selecciona la marca"
									type="select"
									options={brands}
									className="m_b_xs w_100_per"
									value={offerF.values.brand}
									onChange={(e: any) => {
										offerF.handleChange(e);
										handleBrandChange(e.target.value);
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									onBlur={offerF.handleBlur}
									error={
										!!offerF.errors.model &&
										(offerF.getFieldMeta('model').touched || showErrors)
									}
									subtitle={
										offerF.getFieldMeta('model').touched || showErrors
											? (offerF.errors.model as string)
											: undefined
									}
									title="Modelo*"
									name="model"
									icon={<GPS />}
									placeholder="Selecciona el modelo"
									type="select"
									options={models}
									className="m_b_xs w_100_per"
									value={offerF.values.model}
									onChange={(e: any) => {
										offerF.handleChange(e);
										handleModelChange(e.target.value);
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									onBlur={offerF.handleBlur}
									error={
										!!offerF.errors.cylinderCapacity &&
										(offerF.getFieldMeta('cylinderCapacity').touched ||
											showErrors)
									}
									subtitle={
										offerF.getFieldMeta('cylinderCapacity').touched ||
										showErrors
											? (offerF.errors.cylinderCapacity as string)
											: undefined
									}
									title="Cilindraje*"
									name="cylinderCapacity"
									icon={<Suspension />}
									placeholder="Selecciona el cilindraje"
									type="select"
									options={cylinders[offerF.values.model] ?? []}
									className="m_b_xs w_100_per"
									value={offerF.values.cylinderCapacity}
									onChange={(e: any) => {
										offerF.handleChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									onBlur={offerF.handleBlur}
									error={
										!!offerF.errors.year &&
										(offerF.getFieldMeta('year').touched || showErrors)
									}
									subtitle={
										offerF.getFieldMeta('year').touched || showErrors
											? (offerF.errors.year as string)
											: undefined
									}
									title="Año*"
									name="year"
									icon={<Calendar />}
									placeholder="Selecciona el año"
									type="select"
									options={years}
									className="m_b_xs w_100_per"
									value={offerF.values.year}
									onChange={(e: any) => {
										offerF.handleChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									onBlur={offerF.handleBlur}
									error={
										!!offerF.errors.amountOffer &&
										(offerF.getFieldMeta('amountOffer').touched || showErrors)
									}
									subtitle={
										offerF.getFieldMeta('amountOffer').touched || showErrors
											? (offerF.errors.amountOffer as string)
											: undefined
									}
									title="Oferta*"
									name="amountOffer"
									icon={<Money />}
									placeholder="Selecciona el cilindraje"
									type="number"
									className="m_b_xs w_100_per"
									value={offerF.values.amountOffer}
									onChange={(e: any) => {
										offerF.handleChange(e);
									}}
								/>
							</Grid>
						</Grid>
					</form>
				</div>

				<div className="flex_footer">
					<Button
						scale="large"
						orientation="right"
						className="w_100_per"
						type="submit"
						onClick={() => {
							setShowErrors(true);
							onSubmit();
						}}
						disabled={loading || (!ozocioF.dirty && !offerF.dirty)}
					>
						Guardar
					</Button>
				</div>
			</div>
		</SideModal>
	);
}

export default EditOzocio;
