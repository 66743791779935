// eslint-disable-next-line no-shadow
export enum VehicleStatus {
	AVAILABLE = 'available',
	DELIVERED = 'delivered',
	ASIDE = 'aside',
	LOST = 'lost',
	IN_RECOVERY = 'inRecovery',
	RECOVERED = 'recovered',
	DRAFT = 'draft',
}

// eslint-disable-next-line no-shadow
export enum VehicleColor {
	PINK = 'pink',
	PURPLE = 'purple',
	BLUE = 'blue',
	ORANGE = 'orange',
	GREEN = 'green',
	WHITE = 'white',
	GRAY = 'gray',
	RED = 'red',
	BLACK = 'black',
	YELLOW = 'yellow',
	GRAPHITE = 'graphite',
}

// eslint-disable-next-line no-shadow
export enum VisualStatus {
	GOOD = 'good',
	REGULAR = 'regular',
	BAD = 'bad',
	BONE = 'bone',
}

// eslint-disable-next-line no-shadow
export enum DocumentalStatus {
	DIGITAL = 'digital',
	COMPLETE = 'complete',
	INCOMPLETE = 'incomplete',
}

// eslint-disable-next-line no-shadow
export enum BrakeType {
	DISC = 'disc',
	DRUM = 'drum',
}

// eslint-disable-next-line no-shadow
export enum MaintenanceColor {
	GREEN = 'green',
	YELLOW = 'yellow',
	BLUE = 'blue',
	RED = 'red',
	BLACK = 'black',
}

// eslint-disable-next-line no-shadow
export enum SalePriceWeek {
	CASH = 1,
	LEGACY = 52,
	OPPORTUNITY = 78,
	COMFORT = 104,
	SECURITY = 156,
}

// eslint-disable-next-line no-shadow
export enum DiscountType {
	VALUE = 'value',
	PERCENTAGE = 'percentage',
}

// eslint-disable-next-line no-shadow
export enum DiscountStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
}

export const MaintenanceColorValues: {
	[key in MaintenanceColor]: { label: string; days?: number; color: string };
} = {
	[MaintenanceColor.GREEN]: { label: 'Verde', days: 0, color: 'green_300' },
	[MaintenanceColor.YELLOW]: {
		label: 'Amarillo',
		days: 2,
		color: 'yellow_100',
	},
	[MaintenanceColor.BLUE]: { label: 'Azul', days: 3, color: 'yellow_100' },
	[MaintenanceColor.RED]: { label: 'Rojo', days: 7, color: 'red_300' },
	[MaintenanceColor.BLACK]: { label: 'Negro', color: 'neutral_1000' },
};

export const VisualStatusValues: { [key in VisualStatus]: { label: string } } =
	{
		[VisualStatus.GOOD]: { label: 'Bueno' },
		[VisualStatus.REGULAR]: { label: 'Regular' },
		[VisualStatus.BAD]: { label: 'Malo' },
		[VisualStatus.BONE]: { label: 'Hueso' },
	};

export const DocumentalStatusValues: {
	[key in DocumentalStatus]: { label: string };
} = {
	[DocumentalStatus.DIGITAL]: { label: 'Completa Digital' },
	[DocumentalStatus.COMPLETE]: { label: 'Completa' },
	[DocumentalStatus.INCOMPLETE]: { label: 'Incompleta' },
};

export const VehicleStatusValues = {
	[VehicleStatus.DRAFT]: {
		title: 'Draft',
		color: 'primary_200',
		availableStatus: [VehicleStatus.AVAILABLE],
	},
	[VehicleStatus.AVAILABLE]: {
		title: 'Disponible',
		color: 'primary_300',
		availableStatus: [],
	},
	[VehicleStatus.DELIVERED]: {
		title: 'Entregada',
		color: 'green_300',
		availableStatus: [VehicleStatus.LOST, VehicleStatus.IN_RECOVERY],
	},
	[VehicleStatus.ASIDE]: {
		title: 'Apartada',
		color: 'secondary_500',
		availableStatus: [],
	},
	[VehicleStatus.IN_RECOVERY]: {
		title: 'En recuperacion',
		color: 'red_300',
		availableStatus: [VehicleStatus.RECOVERED],
	},
	[VehicleStatus.RECOVERED]: {
		title: 'Reingreso',
		color: 'yellow_100',
		availableStatus: [VehicleStatus.LOST],
	},
	[VehicleStatus.LOST]: {
		title: 'Perdidas',
		color: 'neutral_900',
		availableStatus: [VehicleStatus.RECOVERED],
	},
};

export const vehicleFields = {
	salePrices: 'Precios de venta',
	hub: 'Hub',
	city: 'Ciudad',
	country: 'País',
	internalId: 'ID interno',
	images: 'Imágenes',
	vehicleSN: 'Número de serie del vehículo',
	registrationCard: 'Tarjeta de registro',
	engineSN: 'Número de serie del motor',
	brakeType: 'Tipo de frenos',
	salePlans: 'Planes de venta',
	plate: 'Matrícula',
	purchaseCost: 'Costo de compra',
	color: 'Color',
	secondaryColor: 'Color secundario',
	suffix: 'Sufijo',
	purchaseDate: 'Fecha de compra',
	cylindersCapacity: 'Capacidad de los cilindros',
	model: 'Modelo',
	brand: 'Marca',
	documents: 'Documentos',
	discounts: 'Descuentos',
	maintenanceDays: 'Dias de mantenimiento',
	status: 'Estado',
	year: 'Año',
	mileage: 'Kilometraje',
	shortDescription: 'Descripción corta',
	description: 'Descripción',
	isPresale: 'Es preventa',
	isNewVehicle: 'Es vehículo nuevo',
	isInternalFraud: 'Es fraude interno',
	isCorralon: 'Es corralon',
	marketPrice: 'Precio del mercado',
	extraCosts: 'Costos adicionales',
	visualStatus: 'Estado visual',
	documentalStatus: 'Estado documental',
	hasTileDevice: 'Tiene un GPS Tile instalado',
	hasGpsTracker: 'Tiene GPS Tracker instalado',
};

// eslint-disable-next-line no-shadow
export enum VehicleSortType {
	promotion = 'promotion',
	higherPrice = 'higherPrice',
	lowerPrice = 'lowerPrice',
	higherCC = 'higherCC',
	lowerCC = 'lowerCC',
	higherKM = 'higherKM',
	lowerKM = 'lowerKM',
}
