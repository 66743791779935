export const formatValuesIfNotMaxLength = (
	value: string[],
	maxLenght: number,
) => {
	if (value?.length && value?.length !== maxLenght) return value.join(',');
	return undefined;
};

export function IsStrongPassword() {
	return (value: string) => {
		const regex = /(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
		if (!regex.test(value)) {
			return {
				message:
					'The password must have an uppercase letter, a lowercase letter, and a number',
			};
		}
		return true;
	};
}

export const buildPathWithQueryParams = (
	base: string,
	params: Record<string, any>,
) => {
	const queryString = Object.entries(params)
		.filter(([_, value]) => value !== undefined && value !== null)
		.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
		.join('&');
	return `${base}?${queryString}`;
};

export const TransformStringBoolean = (value: any) =>
	typeof value === 'string' ? JSON.parse(value) : value;

export const sanitizeName = (input: string) => {
	let sanitized = input
		.replace(/[^a-zA-ZáéíóúÁÉÍÓÚüÜñÑ ]/g, '')
		.replace(/\s+/g, ' ')
		.trim();

	sanitized = sanitized
		.split(' ')
		.map((word) => word.charAt(0).toLocaleUpperCase() + word.slice(1))
		.join(' ');
	return sanitized;
};

export const filterObjectByKeys = <T>(
	obj: Record<string, any>,
	keys: Array<keyof T>,
): Partial<T> => {
	const result: Partial<T> = {};
	Object.keys(obj).forEach((key) => {
		if (keys.includes(key as keyof T)) {
			result[key as keyof T] = obj[key];
		}
	});
	return result;
};
