/* eslint-disable no-shadow */

export enum LoanStatus {
	current = 'current',
	writeOff = 'writeOff',
	completed = 'completed',
	devolution = 'devolution',
	repossession = 'repossession',
}

export enum WarrantyReason {
	documentation = 'documentation',
	mechanical = 'mechanical',
}

export enum LoanClassification {
	AAA = 'AAA',
	AA = 'AA',
	A = 'A',
	B = 'B',
	C = 'C',
	D = 'D',
}

export enum LoanDevolutionReasons {
	paymentCapacity = 'paymentCapacity',
	mechanicalFailure = 'mechanicalFailure',
	falseMechanicalFailure = 'falseMechanicalFailure',
	documents = 'documents',
	satisfaction = 'satisfaction',
	warranty = 'warranty',
}

export enum LoanStatusFlags {
	isFraud = 'isFraud',
	isStolen = 'isStolen',
	isCorralon = 'isCorralon',
	isJudicialCollection = 'isJudicialCollection',
}

export enum LoanEvidenceDocumentType {
	repossession_evidence = 'repossession_evidence',
	devolution_evidence = 'devolution_evidence',
}

export const LoanStatusValues: {
	[key in LoanStatus]: {
		title: string;
		color: string;
		tooltip: string;
	};
} = {
	[LoanStatus.current]: {
		title: 'Al dia',
		color: 'secondary_500',
		tooltip: 'Crédito al dia con sus pagos',
	},
	[LoanStatus.writeOff]: {
		title: 'Write Off',
		color: 'neutral_900',
		tooltip: 'Crédito en cartera castigada',
	},
	[LoanStatus.devolution]: {
		title: 'Devolucion',
		color: 'yellow_100',
		tooltip: 'Crédito en el que la moto fue devuelta voluntariamente',
	},
	[LoanStatus.repossession]: {
		title: 'Recuperada',
		color: 'yellow_100',
		tooltip: 'Crédito recuperado por mora',
	},
	[LoanStatus.completed]: {
		title: 'Liquidado',
		color: 'green_300',
		tooltip: 'Crédito liquidado satisfactoriamente',
	},
};

export const LoanStatusFlagsValues: {
	[key in LoanStatusFlags]: {
		title: string;
		color: string;
		tooltip: string;
	};
} = {
	[LoanStatusFlags.isCorralon]: {
		title: 'Corralon',
		color: 'neutral_600',
		tooltip: 'Crédito en que la moto entro en corralon',
	},
	[LoanStatusFlags.isStolen]: {
		title: 'Robo al ozoner',
		color: 'neutral_600',
		tooltip: 'Crédito en que al ozoner le robaron la moto',
	},
	[LoanStatusFlags.isFraud]: {
		title: 'Fraude',
		color: 'neutral_900',
		tooltip: 'Crédito en el que no se pagaron las primeras cuotas',
	},
	[LoanStatusFlags.isJudicialCollection]: {
		title: 'Cobranza Judicial',
		color: 'neutral_900',
		tooltip: 'Crédito en proceso de cobranza judicial',
	},
};
