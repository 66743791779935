/* eslint-disable no-shadow */
import { Ozoner } from 'models/ozoner/interfaces/ozoner.interface';
import * as yup from 'yup';
import { GenericFinancialForm } from 'models/financial-form/interfaces/financial-form.interfaces';

const curpRegex =
	/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

export enum validationSections {
	financial = 'financial',
	info = 'info',
	personal = 'personal',
	references = 'references',
}

export const validationSectionsValues = {
	[validationSections.financial]: {
		title: 'Datos Financieros',
	},
	[validationSections.info]: {
		title: 'Datos basicos',
	},
	[validationSections.personal]: {
		title: 'Datos personales',
	},
	[validationSections.references]: {
		title: 'Ref. personales',
	},
};

const ozonerVS = yup.object({
	name: yup.string().required('Falta el nombre del ozoner'),
	lastName: yup.string().required('Falta el apellido del ozoner'),
	email: yup
		.string()
		.email('El email del ozoner tiene el formato incorrecto')
		.required('Falta el email del ozoner'),
	curp: yup
		.string()
		.required('Falta el CURP del ozoner')
		.matches(curpRegex, 'El CURP es inválido'),
});
const personalVS = yup.object({
	phone: yup.number().required('Falta el numero del ozoner'),
	dependantsCount: yup.number().required('Falta el numero de dependientes'),
	civilStatus: yup.string().required('Falta el estado civil'),
	educationalLevel: yup.string().required('Falta el nivel de estudios'),
	childrenCount: yup.number().required('Falta el numero de hijos'),
	livesWith: yup.array().min(1, 'Falta con quien vive'),
	fullAddress: yup.object({
		zipCode: yup
			.string()
			.required('Falta el codigo postal')
			.matches(/^\d{5}$/, 'El codigo postal debe ser un número de 5 dígitos.'),
		state: yup.string().required('Falta el estado en la direccion'),
		delegation: yup.string().required('Falta la delegacion en la direccion'),
		neighborhood: yup.string().required('Falta la colonia en la direccion'),
		street: yup.string().required('Falta la calle en la direccion'),
		extNumber: yup
			.string()
			.required('Falta el numero exterior en la direccion'),
		lat: yup.string().required('No se ha asignado ubicacion exacta'),
	}),
});
const referencesVS = yup.object({
	personalReference1: yup.object({
		name: yup.string().required('Falta el nombre de la referencia 1'),
		phone: yup.number().required('Falta el numero de la referencia 1'),
		relationship: yup.string().required('Falta la relación de la referencia 1'),
		other: yup.string().when('relationship', {
			is: (value: string) => value === 'Otro',
			then: yup.string().required('Falta la relación de la referencia 1'),
			otherwise: yup.string().optional(),
		}),
	}),
	personalReference2: yup.object({
		name: yup.string().required('Falta el nombre de la referencia 2'),
		phone: yup.number().required('Falta el numero de la referencia 2'),
		relationship: yup.string().required('Falta la relación de la referencia 2'),
		other: yup.string().when('relationship', {
			is: (value: string) => value === 'Otro',
			then: yup.string().required('Falta la relación de la referencia 2'),
			otherwise: yup.string().optional(),
		}),
	}),
});
const financialVS = yup.object({
	monthlyIncome: yup.number().required('Falta el ingreso mensual'),
	monthlyOutcome: yup.number().required('Falta el egreso emnsual'),
	companyName: yup.string().nullable(),
	economicActivity: yup.object({
		value: yup.string().required('Falta la actividad economica'),
	}),
});

export const validateModel = async (
	ozoner?: Ozoner,
	financialForm?: GenericFinancialForm,
) => {
	const validation = {
		[validationSections.financial]: {
			valid: true,
			errors: [],
		},
		[validationSections.info]: {
			valid: true,
			errors: [],
		},
		[validationSections.personal]: {
			valid: true,
			errors: [],
		},
		[validationSections.references]: {
			valid: true,
			errors: [],
		},
		isValid: true,
	};
	await ozonerVS
		.validate(ozoner, { abortEarly: false })
		.then(() => {})
		.catch((err) => {
			const errors = err.inner.reduce((obj: any, e: any) => {
				// eslint-disable-next-line no-param-reassign
				if (!(e.path in obj)) obj[e.path] = [];
				// eslint-disable-next-line no-param-reassign
				obj[e.path] = obj[e.path].concat(e.errors);

				return [...obj, ...e.errors];
			}, []);
			validation[validationSections.info].valid = false;
			validation[validationSections.info].errors = errors;
			validation.isValid = false;
		});
	await referencesVS
		.validate(financialForm?.personalInformation?.personalReferences ?? {}, {
			abortEarly: false,
		})
		.then(() => {})
		.catch((err) => {
			validation[validationSections.references].valid = false;
			validation.isValid = false;
			if (err.inner) {
				const errors = err.inner.reduce((obj: any, e: any) => {
					// eslint-disable-next-line no-param-reassign
					if (!(e.path in obj)) obj[e.path] = [];
					// eslint-disable-next-line no-param-reassign
					obj[e.path] = obj[e.path].concat(e.errors);

					return [...obj, ...e.errors];
				}, []);
				validation[validationSections.references].errors = errors;
			} else {
				throw err;
			}
		});
	await personalVS
		.validate(financialForm?.personalInformation ?? {}, {
			abortEarly: false,
		})
		.then(() => {})
		.catch((err) => {
			validation[validationSections.personal].valid = false;
			validation.isValid = false;
			if (err.inner) {
				const errors = err.inner.reduce((obj: any, e: any) => {
					// eslint-disable-next-line no-param-reassign
					if (!(e.path in obj)) obj[e.path] = [];
					// eslint-disable-next-line no-param-reassign
					obj[e.path] = obj[e.path].concat(e.errors);

					return [...obj, ...e.errors];
				}, []);
				validation[validationSections.personal].errors = errors;
			}
		});
	await financialVS
		.validate(financialForm?.financialInformation ?? {}, {
			abortEarly: false,
		})
		.then(() => {})
		.catch((err) => {
			const errors = err.inner.reduce((obj: any, e: any) => {
				// eslint-disable-next-line no-param-reassign
				if (!(e.path in obj)) obj[e.path] = [];
				// eslint-disable-next-line no-param-reassign
				obj[e.path] = obj[e.path].concat(e.errors);

				return [...obj, ...e.errors];
			}, []);
			validation[validationSections.financial].valid = false;
			validation[validationSections.financial].errors = errors;
			validation.isValid = false;
		});
	console.log(validation);
	return validation;
};

export const partnershipSegmentationOptions = ['Sin Alianza', 'Didi', 'Uber'];
export const loanSegmentationOptions = [
	'Clientes generales',
	'Go Nuts',
	'Walmart',
];
export const collectionSegmentationOptions = [
	'Clientes generales',
	'Nuevas Compras',
	'WO 2023',
	'Reestructuracion',
	'Reestructurado',
	'Clientes 15 intereses',
	'Clientes 30 Intereses',
];
