import { Input } from 'design_system/src';
import React from 'react';

export interface BaseGenericFormInputProps {
	title: string;
	placeholder: string;
	name: string;
	options?: any;
	icon?: React.ReactElement<any, any> | null;
	className?: string;
	subtitle?: string;
	type?:
		| 'number'
		| 'textarea'
		| 'time'
		| 'text'
		| 'email'
		| 'month'
		| 'week'
		| 'color'
		| 'tel'
		| 'url'
		| 'date'
		| 'short-date'
		| 'datetime-local'
		| 'file'
		| 'select'
		| 'password';
	variant?: 'button' | 'checkbox' | 'default';
	size: number;
}

export interface GenericFormInputProps extends BaseGenericFormInputProps {
	error?: string;
	forceDisplayError?: boolean;
	form: any;
	onChange?: (e: any) => void;
	onBlur?: (e: any) => void;
}

export const GenericFormInput = ({
	params,
}: {
	params: GenericFormInputProps;
}) => (
	<Input
		title={params.title}
		placeholder={params.placeholder}
		name={params.name}
		type={params.type ?? 'text'}
		icon={params.icon}
		className={params.className}
		options={params.options}
		value={params.form.values[params.name]}
		variant={params.variant}
		onChange={(e) => {
			params.form.handleChange(e);
			params.onChange?.(e);
		}}
		onBlur={(e) => {
			params.form.handleBlur(e);
			params.onBlur?.(e);
		}}
		error={
			!!params.form.errors[params.name] &&
			(params.form.getFieldMeta(params.name).touched ||
				params.error ||
				params.forceDisplayError)
		}
		subtitle={
			params.form.getFieldMeta(params.name).touched ||
			params.forceDisplayError ||
			params.error
				? params.error || (params.form.errors[params.name] as string)
				: params.subtitle
		}
	/>
);
