import ReactGA from 'react-ga4';

export const handleEventGoogle = (
	name: string,
	category: string,
	label: string,
) => {
	ReactGA.event(name, {
		category,
		label,
	});
};
