/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import {
	MaintenanceColorValues,
	VehicleStatusValues,
	VehicleStatus,
} from 'models/inventory/enums/vehicle.enum';
import {
	Discount,
	SalePrices,
	VehiclePopulated,
} from 'models/inventory/interfaces/vehicle.interface';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/extensions
import SwiperClass from 'swiper/types/swiper-class';
// eslint-disable-next-line import/extensions
import { Swiper, SwiperSlide } from 'swiper/react';
import Swipercore, { Navigation, Pagination, Autoplay } from 'swiper';

// swiper bundle styles
import 'swiper/swiper-bundle.min.css';

// swiper core styles
import 'swiper/swiper.min.css';

// modules styles
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

// css
import './detailsI.scss';

// imgs
import { ReactComponent as Back } from 'design_system/src/static/icons/back.svg';
import { ReactComponent as UserCircle } from 'design_system/src/static/icons/user-circle.svg';
import { ReactComponent as Moto } from 'design_system/src/static/icons/Motocycle.svg';
import { ReactComponent as Position } from 'design_system/src/static/icons/gps.svg';
// import {ReactComponent as Add} from 'design_system/src/static/icons/add-bracket.svg';
import { ReactComponent as Edit } from 'design_system/src/static/icons/edit-text.svg';
import { ReactComponent as Close } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as Image } from 'design_system/src/static/icons/image.svg';
import { ReactComponent as Refresh } from 'design_system/src/static/icons/refresh.svg';

import { Button, Typography, DataTable } from 'design_system/src';
import {
	addFile,
	deleteFile,
	editVehicle,
	getVehicleByInternalId,
} from 'models/inventory/controllers/vehicle.controller';

// import { useDraggable } from 'libs/index';
import { LightTooltip } from 'components/hocs/tooltip/Tooltip';
import History from 'components/hocs/history/History';
import moment from 'moment';

import {
	updateDevice,
	fetchCurrentDevice,
	fetchDevices,
} from 'models/geozon/controllers/device.controller';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { Device } from 'models/geozon/interfaces/device.interface';
import { Divider } from '@mui/material';
import { Ozoner } from 'models/ozoner/interfaces/ozoner.interface';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { findAllNotifications } from 'models/notification/controllers/notifications.controller';
import { getOzonerByVehicle } from 'models/ozoner/controllers/ozoner.controller';
import { GenericFinancialForm } from 'models/financial-form/interfaces/financial-form.interfaces';
import { Notify } from 'models/notification/interfaces/notifications.interface';
import {
	informativeMessage,
	validationItem,
} from 'components/hocs/messages/messages';
import { findAllFinancialFormsOzoner } from 'models/financial-form/controllers/financial-form.controller';
import { displayName, formatName } from 'models/shared/helpers/file';
import { detailsData } from './constants';
import SelectDeviceModal from './SelectDeviceModal';
import AddEditDiscount from '../addEditDiscount/AddEditDiscount';
import EditVehicle from '../edit/EditVehicle';

function DetailsInventory() {
	const [swiperRef, setSwiperRef] = useState<SwiperClass>();
	Swipercore.use([Navigation, Pagination, Autoplay]);
	const navigate = useNavigate();
	const { internalid } = useParams();
	const [vehicle, setVehicle] = useState<VehiclePopulated | null>();
	const [ozoner, setOzoner] = useState<Ozoner | undefined>();
	const [financialForms, setFinancialForms] = useState<GenericFinancialForm[]>(
		[],
	);
	const [loading, setloading] = useState<boolean>(false);
	const [slider, setSlider] = useState({ current: 0 });
	const [openDetails, setOpenDetails] = useState<boolean>(false);
	const [openAddEditDiscount, setOpenAddEditDiscount] =
		useState<boolean>(false);
	const [activeDiscount, setActiveDiscount] = useState<Discount | null>();

	const [loanTimes, setLoanTimes] = useState<SalePrices[]>();
	const [openHistory, setOpenHistory] = useState<boolean>(false);

	const [alarms, setAlarms] = useState<Notify[]>([]);

	const [openDevices, setOpenDevices] = useState<boolean>(false);

	const [device, setDevice] = useState<Device | null>();

	// eslint-disable-next-line no-unused-vars
	const [photos, setPhotos] = useState<Record<string, any>[]>(() => []);
	const slideTo = (index: number) => {
		if (swiperRef) swiperRef.slideTo(index, 0);
	};

	useEffect(() => {
		if (internalid) {
			findAllNotifications({ page: 1, vehicleInternalId: internalid }).then(
				(info) => {
					setAlarms(info.data);
				},
			);
			fetchCurrentDevice(internalid).then((dev) => {
				if (dev?.device) {
					setDevice(dev.device);
				}
			});
		}
	}, [internalid]);

	const assignDevice = (id: string) => {
		if (vehicle && internalid) {
			updateDevice({ vehicle: vehicle._id }, id).then(() => {
				navigate(`/inventory/${internalid}`);
				fetchCurrentDevice(internalid).then((dev: any) => {
					if (dev?.device) {
						setDevice(dev.device);
					}
				});
			});
		}
	};

	const fetchVehicle = () => {
		getVehicleByInternalId(internalid)
			.then((vehi) => {
				setloading(false);
				setVehicle(vehi);
				if (vehi.images.length > 0) {
					setPhotos(vehi.images);
				}
			})
			.catch(() => {
				setloading(false);
				Swal.fire({
					title: 'Error!',
					text: 'Internal id no valido',
					icon: 'error',
					confirmButtonText: 'Aceptar',
				}).then(() => {
					navigate('/inventory');
				});
			});
	};

	useEffect(() => {
		setPhotos([
			{
				url: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`,
			},
		]);
		setloading(true);
		fetchVehicle();
	}, []);

	useEffect(() => {
		if (vehicle) {
			getOzonerByVehicle(vehicle._id)
				.then((res) => {
					setOzoner(res);
					return findAllFinancialFormsOzoner(res._id);
				})
				.then((forms) => {
					setFinancialForms(forms);
				});

			if (vehicle.activeDiscount) setActiveDiscount(vehicle.activeDiscount);

			setLoanTimes(Object.values(vehicle.salePrices));
		}
	}, [vehicle]);

	const setState = (status: VehicleStatus) => {
		setloading(true);
		if (vehicle) {
			editVehicle({ status }, [], vehicle.internalId)
				.then((vehi) => {
					setVehicle(vehi);
					if (vehi.images.length > 0) {
						setPhotos(vehi.images);
					}
				})
				.catch(() => {
					Swal.fire({
						text: 'No se pudo actualizar el vehículo',
						icon: 'error',
						confirmButtonText: 'Aceptar',
					});
				})
				.finally(() => setloading(false));
		}
	};

	const handleFile = (e: any) => {
		const file = e.target.files[0];
		if (file.size > 10000000) {
			Swal.fire({
				text: 'La imagen no debe ser superior a 10mb',
				icon: 'warning',
				confirmButtonText: 'Aceptar',
			});
		} else if (vehicle && internalid) {
			const blob = file.slice(0, file.size, file.type);
			const newFile = new File([blob], formatName(file.name), {
				type: file.type,
			});
			addFile(internalid, newFile).then((newData) => {
				setVehicle(newData);
			});
		}
	};

	const deleteFiles = (id: string) => {
		if (vehicle && internalid) {
			deleteFile(internalid, id).then((newData) => {
				setVehicle(newData);
			});
		}
	};

	const getDetails = (group: number) => {
		const data = [];
		if (group === 1) {
			data.push({ label: 'Marca', value: vehicle?.brand?.name });
			data.push({ label: 'Modelo', value: vehicle?.model?.name });
			data.push({
				label: 'Cilindraje',
				value: vehicle?.cylindersCapacity,
			});
			data.push({
				label: 'Kilometraje',
				value: vehicle?.mileage ?? 'Por confirmar',
			});
		}
		if (group === 0) {
			data.push({
				label: 'Precio GPS',
				value: priceFormatFinancial(vehicle?.extraCosts.gpsHardwareCost ?? 0),
			});
			data.push({
				label: 'Precio software GPS (Mensual)',
				value: priceFormatFinancial(
					vehicle?.extraCosts.gpsMonthlySoftwareCost ?? 0,
				),
			});
		}
		return [
			...data,
			...detailsData[group].map((field) => ({
				label: field.title,
				value: `${
					field.map
						? field.map[vehicle?.[field.name] as string]
						: vehicle?.[field.name] ?? ''
				}`,
			})),
		];
	};

	const goToOzoner = () => {
		if (ozoner && vehicle && financialForms) {
			const findForm = financialForms.find(
				(form) => form.vehicle?.internalId === vehicle.internalId,
			);
			navigate(
				`/ozoner/${ozoner._id}${findForm ? `?formId=${findForm._id}` : ''}`,
			);
		} else {
			toasterNotification({
				toasterType: toasterOptions.error,
				msg: 'No fue posible redirigir al ozoner',
				style: 'light',
			});
		}
	};

	return (
		<div className=" display_flex detailsView overflow_hidden">
			{!loading && vehicle ? (
				<>
					<div className="flex_basis_0 flex_grow_1 overflow_x_hidden">
						<div className="">
							<div className="">
								<Button
									variant="icon"
									icon={<Back />}
									subvariant="edit"
									scale="small"
									onClick={() => navigate(-1)}
									className="m_b_lg"
								/>
								<div
									className="display_flex flex_col br_l_md"
									style={{ border: '1px solid #ECEEEF' }}
								>
									<div className="display_flex flex_gap_md flex_align_center p_xs">
										<LightTooltip
											title={
												vehicle.maintenanceDays
													? `Mantenimiento de ${vehicle.maintenanceDays} dias`
													: 'No dispone de informacion'
											}
										>
											<Moto
												className={`dim_lg text_${
													MaintenanceColorValues[vehicle.maintenanceColor]
														?.color ?? 'primary_300'
												}`}
											/>
										</LightTooltip>
										<Typography
											scale="heading2"
											weight="600"
											textColor="neutral_900"
										>
											{vehicle.internalId}
										</Typography>
										<div>
											<div className="display_flex flex_gap_xs flex_align_center">
												<Typography
													scale="large"
													weight="600"
													textColor="primary_300"
													component="span"
												>
													{`${vehicle.brand.name} ${vehicle.model.name} ${
														vehicle.suffix ? vehicle.suffix : ''
													} ${vehicle.cylindersCapacity ?? ''}`}
												</Typography>
											</div>
											<div className="display_flex flex_gap_xs flex_align_center">
												<Position className="dim_lg text_neutral_900" />
												<Typography
													scale="large"
													weight="400"
													textColor="neutral_900"
													component="span"
												>
													{`${vehicle.location.city?.name} / ${vehicle.location.hub?.name}`}
												</Typography>
											</div>
										</div>
									</div>
									<div
										className="p_xs bg_neutral_300 display_flex flex_align_center flex_gap_xs"
										style={{
											borderTop: '1px solid #ECEEEF',
											borderBottomLeftRadius: '1rem',
										}}
									>
										<UserCircle className="text_primary_300" />
										<Typography
											scale="medium"
											weight="400"
											className="text_neutral_700"
										>
											{ozoner ? ozoner.name : 'Sin usuario'}
										</Typography>
										{ozoner && (
											<Button scale="small" variant="tint" onClick={goToOzoner}>
												Ver ozoner
											</Button>
										)}
									</div>
								</div>
							</div>
							<div className=" p_r_md">
								<Typography scale="large" weight="600" textColor="neutral_900">
									Descripción del vehículo
								</Typography>
								<hr className="m_y_md bg_neutral_500" />
								<Typography
									scale="medium"
									weight="400"
									textColor="neutral_700"
									className="m_b_md"
								>
									{vehicle.shortDescription}
								</Typography>
								<div className="dso_card bg_neutral_0 br_xs p_md m_b_xxl">
									<Swiper
										navigation
										onSwiper={setSwiperRef}
										className=" w_100_per m_b_md"
										onSlideChange={() => {
											setSlider({
												current: swiperRef ? swiperRef.activeIndex : 0,
											});
										}}
									>
										{photos.map((value, index) => (
											<SwiperSlide
												key={`slide-${index}`}
												className="overflow_hidden br_md flex_center"
											>
												<img
													src={value.url}
													alt="moto"
													className="swiper_img"
												/>
											</SwiperSlide>
										))}
									</Swiper>
									<div className="image-section">
										{[0, 1, 2, 3].map((_, index) => (
											<div
												className={`image-cont ${
													photos[index] ? 'dso_card' : 'empty'
												}  ${slider.current === index ? 'active' : ''}`}
												onClick={() => slideTo(index)}
												role="button"
												onKeyPress={() => slideTo(index)}
												tabIndex={0}
												key={`photo${index}`}
											>
												<div className="image-basis">
													{photos[index] && (
														<>
															<img
																src={photos[index].url}
																alt="moto"
																className="center"
															/>
															{index === 3 && photos.length > 4 && (
																<div className="cover_img flex_center">
																	<Typography
																		scale="heading1"
																		weight="600"
																		textColor="primary_300"
																		className="text_center"
																	>
																		+{photos.length - 3}
																	</Typography>
																</div>
															)}
														</>
													)}
												</div>
											</div>
										))}
									</div>
								</div>
								<div className="display_flex flex_gap_md m_b_md">
									<Button
										variant="outline"
										scale="small"
										icon={<Edit />}
										className="flex_basis_0 flex_grow_1"
										onClick={() => setOpenDetails(true)}
									>
										Editar
									</Button>
									<Button
										variant="outline"
										scale="small"
										icon={<Edit />}
										className="flex_basis_0 flex_grow_1"
										onClick={() => setOpenAddEditDiscount(true)}
									>
										Generar Descuento
									</Button>
								</div>
								<Button
									scale="small"
									variant="tint"
									icon={<Refresh />}
									onClick={() => setOpenHistory(true)}
									className="w_100_per  m_b_xxl"
								>
									Historial de cambios
								</Button>
								<Typography scale="large" weight="600" textColor="neutral_900">
									Especificaciones del vehiculo
								</Typography>
								<hr className="m_y_md bg_neutral_500" />
								<DataTable
									data={getDetails(0)}
									weightLabels={2}
									className="m_b_xl"
								/>
								<DataTable
									data={getDetails(1)}
									weightLabels={2}
									className="m_b_xl"
								/>
								<Typography scale="large" weight="600" textColor="neutral_900">
									Detalles
								</Typography>
								<hr className="m_y_md bg_neutral_500" />
								<div className="border_solid border_neutral_300 border_1 p_lg m_b_xl">
									<Typography
										scale="medium"
										weight="400"
										textColor="neutral_700"
									>
										{vehicle.description}
									</Typography>
								</div>
							</div>
						</div>
					</div>
					<div className="flex_basis_0 flex_grow_1 overflow_x_hidden bg_neutral_300 display_flex flex_col">
						<div className="p_xl static_info_vehicle">
							<div className="display_flex flex_justify_between m_b_md">
								<Typography
									scale="large"
									weight="600"
									textColor="neutral_900"
									className=""
								>
									Información general
								</Typography>
								<Button
									icon={<Position />}
									scale="small"
									variant="outline"
									onClick={() => setOpenDevices(true)}
								>
									{device ? `GPS: ${device.imei}` : 'Asignar GPS'}
								</Button>
							</div>
							<div className="bg_neutral_400 br_sm  m_b_xxl">
								<div className="bg_neutral_0 br_t_sm display_flex p_y_sm p_x_lg flex_align_center flex_justify_between">
									<Typography
										scale="large"
										weight="600"
										textColor="neutral_900"
									>
										Estado del Vehículo
									</Typography>
									<div className="flex_center flex_gap_xs">
										<div
											className={`
                                            dso_chip_large
                                            pos_relative
                                            text_neutral_0
                                            bg_${
																							VehicleStatusValues[
																								vehicle.status as keyof typeof VehicleStatusValues
																							]?.color
																						}_nocontrast`}
										>
											{VehicleStatusValues[
												vehicle.status as keyof typeof VehicleStatusValues
											]?.title ?? vehicle.status}
										</div>
									</div>
								</div>
								<div className="p_x_lg p_t_xxxl p_b_xl display_flex flex_gap_sm flex_align_center">
									<div
										className="display_flex flex_gap_xs overflow_x_auto p_b_md"
										// ref={ref}
										// {...events}
									>
										<div className="dso_card bg_neutral_0 p_md w_fit border_primary_200 border_dotted display_flex flex_col flex_justify_between pos_relative">
											<Typography
												scale="xsmall"
												weight="600"
												className="text_center m_b_md pdf_name"
												textColor="primary_300"
											>
												Añadir Documento
											</Typography>
											<div
												className="dso_card_img m_b_md bg_neutral_200 w_fit center_x display_flex overflow_hidden border_primary_300"
												onClick={() => 1}
												onKeyPress={() => 1}
												role="button"
												tabIndex={0}
											>
												<Image className="dim_xl m_lg text_primary_300 border_transparent" />
											</div>
											<input
												type="file"
												accept="image/png, image/jpeg, application/pdf"
												onChange={handleFile}
												className="input-file"
											/>
										</div>
										{vehicle.documents &&
											vehicle.documents.map((ele: any, i: number) => (
												<div
													key={`photo${i}`}
													className="dso_card bg_neutral_0 p_md w_fit pos_relative display_flex flex_col flex_justify_between"
												>
													<Close
														className="text_red_300 dim_lg delete_photo"
														onClick={() => {
															deleteFiles(ele.name);
														}}
													/>
													<Typography
														scale="xsmall"
														weight="600"
														textColor="neutral_900"
														className="text_center m_b_md pdf_name"
													>
														{displayName(ele.name)}
													</Typography>
													<div
														className="dso_card_img m_b_md bg_neutral_200 w_fit center_x display_flex overflow_hidden cursor_pointer"
														onClick={() => window.open(ele.url, '_blank')}
														onKeyPress={() => window.open(ele.url, '_blank')}
														role="button"
														tabIndex={0}
													>
														<Image className="dim_xl m_lg" />
													</div>
												</div>
											))}
									</div>
								</div>
							</div>
							{vehicle.status === VehicleStatus.DRAFT && (
								<div className="display_flex flex_col flex_gap_md m_b_xxl">
									{internalid &&
										/[a-zA-Z]/.test(internalid[internalid.length - 1]) &&
										new Date().getFullYear() -
											(vehicle?.year ?? new Date().getFullYear()) >
											2 &&
										informativeMessage(
											'Considerada moto quedada',
											'Las motos recuperadas y que tienen mas de 2 años deberian pasar a quedadas',
											'neutral_800',
											'yellow_100',
										)}
									<div className="display_flex flex_gap_xs flex_align_center">
										{validationItem(
											vehicle.isNewVehicle || !!vehicle.mileage,
											'Kilometraje asignado',
											'Kilometraje sin asignar',
										)}
									</div>
									<div className="display_flex flex_gap_xs flex_align_center">
										{validationItem(
											Object.keys(vehicle.salePrices).length > 0 &&
												Object.keys(vehicle.salePrices).reduce(
													(acc: boolean, key: string) =>
														acc && vehicle.salePrices![key].paymentWeek > 50,
													true,
												),
											'Precio asignado',
											'Precio sin asignar',
										)}
									</div>
									<Button
										scale="small"
										disabled={
											!(vehicle.isNewVehicle || !!vehicle.mileage) ||
											!Object.keys(vehicle.salePrices).reduce(
												(acc: boolean, key: string) =>
													acc && vehicle.salePrices![key].paymentWeek > 50,
												true,
											)
										}
										onClick={() => setState(VehicleStatus.AVAILABLE)}
									>
										Pasar moto a disponible
									</Button>
								</div>
							)}
							<Typography
								scale="large"
								weight="600"
								textColor="neutral_900"
								className="m_b_lg"
							>
								Plan de pagos disponibles
							</Typography>
							<div className="display_flex flex_gap_lg flex_justify_center m_b_lg">
								{loanTimes?.map((price: any) => (
									<div
										className="dso_card bg_neutral_0 br_sm"
										key={`price-${price.weeks}`}
									>
										<div className="bg_primary_300 p_lg br_t_sm">
											<Typography
												scale="medium"
												weight="600"
												textColor="neutral_0"
												className="text_center"
											>
												{price.weeks} semanas
											</Typography>
										</div>
										<div className="p_xs p_t_sm">
											<Typography
												scale="small"
												weight="600"
												textColor="neutral_900"
												className="text_center m_b_xxs"
											>
												Pago semanal
											</Typography>
											{price?.paymentWeekBeforeDiscount && (
												<Typography
													scale="large"
													weight="600"
													className="text_center text_red_300 text_decoration_line-through"
												>
													{priceFormatFinancial(
														price?.paymentWeekBeforeDiscount,
														0,
													)}
												</Typography>
											)}
											<Typography
												scale="heading3"
												weight="600"
												textColor="green_300"
												className="text_center m_b_xxs"
											>
												{priceFormatFinancial(price.paymentWeek, 0)}
											</Typography>
											<Typography
												scale="small"
												weight="600"
												textColor="neutral_900"
												className="text_center m_b_xxs"
											>
												Tasa de interés
											</Typography>
											<Typography
												scale="medium"
												weight="600"
												textColor="neutral_900"
												className="text_center m_b_xs"
											>
												{price.percentageIncrease}%
											</Typography>
											<div className="bg_neutral_300 br_b_sm p_x_lg p_y_sm">
												<Typography
													scale="small"
													weight="600"
													textColor="neutral_1000"
													className="text_center"
												>
													Precio de venta
												</Typography>
												{price?.loanAmountBeforeDiscount && (
													<Typography
														scale="small"
														weight="600"
														className="text_center text_red_300 text_decoration_line-through"
													>
														{priceFormatFinancial(
															price?.loanAmountBeforeDiscount,
															0,
														)}
													</Typography>
												)}
												<Typography
													scale="large"
													weight="600"
													textColor="green_300"
													className="text_center"
												>
													{priceFormatFinancial(price.loanAmount, 0)}
												</Typography>
											</div>
										</div>
									</div>
								))}
							</div>

							<div className="bg_neutral_400 br_sm  m_b_xxl">
								<div className="bg_neutral_0 br_t_sm display_flex p_y_sm p_x_lg flex_align_center flex_justify_between">
									<Typography
										scale="large"
										weight="600"
										textColor="neutral_900"
									>
										Alertas del Vehículo
									</Typography>
								</div>
								<div className="p_lg p_r_sm display_flex flex_gap_sm flex_col ">
									<div className="alarms_section p_r_sm">
										{alarms.map((alarm: any) => (
											<div
												className="p_x_lg p_y_sm br_md m_b_xs bg_neutral_0 display_flex flex_gap_lg"
												key={alarm._id}
											>
												{!alarm.read && (
													<div className="dim_sm br_circle bg_red_300" />
												)}
												<div className="flex_grow_1">
													<div className="display_flex flex_align_center m_b_md flex_justify_between ">
														<Typography
															scale="small"
															weight="600"
															textColor="neutral_900"
														>
															{alarm.title}
														</Typography>
														<Typography
															scale="xsmall"
															weight="600"
															textColor="neutral_600"
														>
															{moment(alarm.createdAt)
																.utcOffset(-360)
																.format('DD/MM/YYYY HH:mm')}
														</Typography>
													</div>
													<Typography
														scale="xsmall"
														weight="400"
														textColor="neutral_600"
													>
														{alarm.description}
													</Typography>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<div>Cargando...</div>
			)}
			<EditVehicle
				open={openDetails}
				setOpen={setOpenDetails}
				values={vehicle}
				setResult={(vehi: VehiclePopulated) => {
					setVehicle(vehi);
					if (vehi.images.length > 0) {
						setPhotos(vehi.images);
					}
				}}
			/>
			{openHistory && vehicle?._id && (
				<History
					entityId={vehicle._id}
					open={openHistory}
					setOpen={setOpenHistory}
				/>
			)}
			{loanTimes && loanTimes.length ? (
				<AddEditDiscount
					open={openAddEditDiscount}
					vehicle={vehicle}
					setOpen={setOpenAddEditDiscount}
					setResult={setVehicle}
					initials={activeDiscount}
					loanTimes={loanTimes}
				/>
			) : null}
			<SelectDeviceModal
				open={openDevices}
				setOpen={setOpenDevices}
				assignDevice={assignDevice}
			/>
		</div>
	);
}

export default DetailsInventory;
