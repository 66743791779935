import { useState } from 'react';

interface useDateFiltersReturn {
	datesFilter: {
		initial: Date;
		last: Date;
		option: number;
	};
	hasDatesFilter: boolean;
	setDatesFilter: (dates: {
		initial: Date;
		last: Date;
		option: number;
	}) => void;
	setHasDatesFilter: (hasDatesFilter: boolean) => void;
}

const useDateFilters = (): useDateFiltersReturn => {
	const [datesFilter, setDatesFilter] = useState({
		initial: new Date(),
		last: new Date(),
		option: -1,
	});
	const [hasDatesFilter, setHasDatesFilter] = useState(false);

	return {
		datesFilter,
		hasDatesFilter,
		setDatesFilter,
		setHasDatesFilter,
	};
};

export default useDateFilters;
