/* eslint-disable no-unused-vars */
import { createTheme, PaletteColorOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
	interface CustomPalette {
		anger: PaletteColorOptions;
		customBlack: PaletteColorOptions;
		customYellow: PaletteColorOptions;
	}
	type Palette = CustomPalette;
	type PaletteOptions = CustomPalette;
}

declare module '@mui/material/Button' {
	// eslint-disable-next-line no-shadow
	interface ButtonPropsColorOverrides {
		anger: true;
		customBlack: true;
		customYellow: true;
	}
}

export const index = createTheme({
	palette: {
		primary: {
			main: '#F28E18',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#FFFFFF',
			dark: '#E5E5E5',
			light: '#FF8B00',
		},
	},
	typography: {
		fontFamily: 'Poppins',
		fontSize: 16,
		button: {
			textTransform: 'none',
			whiteSpace: 'nowrap',
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 360,
			md: 600,
			lg: 1450,
			xl: 1800,
		},
	},
});
