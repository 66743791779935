/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import {
	Button,
	Input,
	Pager,
	Select,
	SideModal,
	Typography,
} from 'design_system/src';

import { ReactComponent as Search } from 'design_system/src/static/icons/search.svg';
import { ReactComponent as Position } from 'design_system/src/static/icons/gps.svg';

import { Device } from 'models/geozon/interfaces/device.interface';
import { fetchDevices } from 'models/geozon/controllers/device.controller';
import { hasVehicleOptions } from './constants';

interface SelectDeviceModalProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	assignDevice: (id: string) => void;
}
function SelectDeviceModal({
	open,
	setOpen,
	assignDevice,
}: SelectDeviceModalProps) {
	const [devices, setDevices] = useState<Device[]>([]);
	const [hasVehicleFilter, setHasVehicleFilter] = useState(['false']);

	const [queryDevices, setQueryDevices] = useState('');
	const [loadingDevices, setLoadingDevices] = useState<boolean>(false);

	const [count, setCount] = useState(0);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const closeDevices = () => {
		setCurrentPage(1);
		setDevices([]);
		setQueryDevices('');
		setOpen(false);
	};

	const getDevices = () => {
		setLoadingDevices(true);
		let hasVehicle;

		if (
			hasVehicleFilter.length &&
			hasVehicleFilter.length !== hasVehicleOptions.length
		)
			[hasVehicle] = hasVehicleFilter;
		fetchDevices({
			page: currentPage,
			limit: 30,
			query: queryDevices,
			hasVehicle,
		}).then((info: any) => {
			setLoadingDevices(false);
			setDevices(info.data);
			setTotalPages(info.pages);
			setCount(info.count);

			setCurrentPage(1);
		});
	};

	useEffect(() => getDevices(), [hasVehicleFilter, currentPage]);

	return open ? (
		<SideModal open={open} setOpen={closeDevices} size="normal">
			<div className="flex_content_col">
				<div className="flex_header">
					<Typography
						scale="heading4"
						weight="600"
						textColor="neutral_900"
						className="m_b_md"
					>
						Asignar GPS
					</Typography>
					<Divider />
					<div className="display_flex flex_gap_md w_100_per flex_align_center">
						<Input
							title=""
							placeholder="Búsqueda"
							className="flex_1"
							name="search"
							type="text"
							value={queryDevices}
							onChange={(e: any) => setQueryDevices(e.target.value)}
						/>
						<Button
							type="submit"
							scale="small"
							icon={<Search />}
							className=""
							onClick={getDevices}
							variant="icon"
							subvariant="fill"
						/>
					</div>
				</div>
				<div className="m_b_md">
					<Select
						fixedPlaceholder
						variant="checkbox"
						placeholder="Seleccione estado"
						options={hasVehicleOptions}
						value={hasVehicleFilter}
						onChange={(e: any) => {
							setHasVehicleFilter(e.target.value);
						}}
					/>
				</div>
				<div className="flex_body p_r_xs">
					{loadingDevices ? (
						<Typography scale="medium" weight="400" textColor="neutral_900">
							Cargando Dispositivos
						</Typography>
					) : devices?.length ? (
						<div className="display_flex flex_col flex_gap_xs">
							{devices.map((dev) => (
								<div
									className="br_xs border_1 border_solid border_neutral_400 p_md display_flex flex_gap_md flex_align_center cursor_pointer"
									key={dev._id}
									role="button"
									onClick={() => {
										closeDevices();
										assignDevice(dev._id);
									}}
									onKeyDown={() => {
										closeDevices();
										assignDevice(dev._id);
									}}
									tabIndex={0}
								>
									<Position className="dim_md" />
									<Typography scale="medium" weight="400" className="">
										IMEI: {dev.imei ?? '--'}
									</Typography>
									<Typography
										scale="medium"
										weight="600"
										className="flex_1 text_right"
									>
										{dev.phone ?? '--'}
									</Typography>
								</div>
							))}
						</div>
					) : (
						<Typography scale="medium" weight="400" textColor="neutral_900">
							No se encontraron dispositivos
						</Typography>
					)}
				</div>
				<div className="flex_footer">
					<Pager
						totalPages={totalPages}
						setPage={setCurrentPage}
						page={currentPage}
						count={count}
						className="w_fit"
					/>
				</div>
			</div>
		</SideModal>
	) : null;
}

export default SelectDeviceModal;
