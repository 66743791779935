/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import {
	Button,
	Input,
	Knob,
	ModalConfirmation as Modal,
	ModalConfirmation,
	SideModal,
	Typography,
} from 'design_system/src';

// -----------------DATA IMPORTS------------------- //

import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';

// -----------------STATIC IMPORTS------------------- //
import '../../../ozoner.scss';
import { ReactComponent as Reload } from 'design_system/src/static/icons/reload.svg';
import { ReactComponent as Fail } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as Success } from 'design_system/src/static/icons/tick-circle.svg';
import { RequireRole } from 'components/layouts/main/sidebar/Sidebar';
import { updateFinancialForm } from 'models/financial-form/controllers/financial-form.controller';
import { FormStatus } from 'models/financial-form/enums/financial-form.enums';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { Roles } from 'models/ozonio/enums/ozonio.enum';
import {
	DevolutionAction,
	RejectAction,
	UpdateQuota,
} from './SolicitudActions';
import { ozonerContext } from '../../OzonerContext';

const validationItem = (
	valid: boolean,
	validLabel: string,
	invalidLabel: string,
) => (
	<>
		{valid ? (
			<Success className="dim_lg text_green_300" />
		) : (
			<Fail className="dim_lg text_red_300" />
		)}
		<Typography
			scale="xsmall"
			weight="400"
			textColor={valid ? 'green_300' : 'red_300'}
			className="text_no_break"
		>
			{valid ? validLabel : invalidLabel}
		</Typography>
	</>
);

function ValidationSection() {
	const { financialForm, ozoner, updateFormData } = useContext(ozonerContext);

	const [loadingTruora, setLoadingTruora] = useState(false);
	const [openRevalidateTruora, setOpenRevalidateTruora] = useState(false);

	// TODO:Terminar truora
	// const validateTruora = () => {
	// 	if (ozoner.truoraId) {
	// 		setOpenRevalidateTruora(true);
	// 	} else {
	// 		handleEventGoogle(
	// 			'BTN_Pb_validate_truora',
	// 			'Panda Solicitudes',
	// 			'click in validate truora button',
	// 		);
	// 		setLoadingTruora(true);
	// 		triggerTruora().then(() => {
	// 			setLoadingTruora(false);
	// 		});
	// 	}
	// };

	const approveSolicitud = () => {
		updateFinancialForm(financialForm.form._id, {
			status: FormStatus.approved,
		})
			.then((newForm) => {
				toasterNotification({
					msg: 'Solicitud Exitosa',
					style: 'light',
					toasterType: toasterOptions.success,
				});
				updateFormData(newForm);
			})
			.catch((err) => {
				commonErrorHandler(err);
			});
		handleEventGoogle(
			'BTN_Pb_request_approve',
			'Panda Solicitudes',
			'click in approve request button',
		);
	};

	return (
		<>
			<RequireRole authRoles={[Roles.ADMIN, Roles.CREDIT_ANALYST]}>
				<div
					id="validate-soli-ozoner"
					className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
				>
					<div className="display_flex flex_col flex_gap_xs">
						<div className="display_flex flex_gap_xs flex_align_center overflow_hidden">
							{validationItem(
								!!financialForm?.form?.loanApplication?.loanSegmentation,
								'Segmentación asignada',
								'Segmentación sin asignar',
							)}
						</div>
						<div className="display_flex flex_gap_xs flex_align_center overflow_hidden">
							{validationItem(
								!!financialForm?.form?.loanApplication?.approvedWeeklyPayment,
								'Cuota maxima asignada',
								'Cuota maxima sin asignar',
							)}
						</div>
					</div>

					{/* <Button
							scale="small"
							color="secondary"
							onClick={validateTruora}
							disabled={loadingTruora}
						>
							Validar Truora
						</Button> */}
					<UpdateQuota />
					<Button
						scale="small"
						color="primary"
						onClick={approveSolicitud}
						disabled={
							!financialForm?.form?.loanApplication?.loanSegmentation ||
							!financialForm?.form?.loanApplication?.approvedWeeklyPayment
						}
					>
						Aprobar
					</Button>
					<DevolutionAction />
					<RejectAction />
				</div>
			</RequireRole>

			{/* {openRevalidateTruora && (
				<Modal
					openConfirmation={openRevalidateTruora}
					handleClose={() => setOpenRevalidateTruora(false)}
					widthDesktop={50}
					actions={[
						{
							color: 'secondary',
							label: 'Validar Truora',
							action: () => {
								handleEventGoogle(
									'BTN_Pb_validate_truora_again',
									'Panda Solicitudes',
									'click in validate truora button again',
								);
								setLoadingTruora(true);
								triggerTruora().then(() => {
									setLoadingTruora(false);
								});
								setOpenRevalidateTruora(false);
							},
						},
						{
							color: 'primary',
							label: 'Actualizar Datos',
							action: () => {
								handleEventGoogle(
									'BTN_Pb_update_platforms',
									'Panda Solicitudes',
									'click in update platforms button',
								);
								refreshPlatforms(ozoner, true);
								setOpenRevalidateTruora(false);
							},
						},
					]}
					title={
						<Typography scale="large" weight="600">
							Validar Truora
						</Typography>
					}
					subtitle={
						<>
							<Typography scale="medium" weight="600" className="m_b_md">
								Esta acción va a generar costos adicionales al proceso ¿Estas
								seguro que quieres continuar?
							</Typography>
							<Typography scale="medium" weight="400">
								En algunos casos Truora demora en mostrar los datos te
								recomendamos actualizar datos antes de Validar nuevamente
							</Typography>
						</>
					}
					icon={<Reload />}
				/>
			)} */}
		</>
	);
}
export default ValidationSection;
