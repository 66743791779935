import React from 'react';
import { Typography } from 'design_system/src';

import { ReactComponent as Success } from 'design_system/src/static/icons/tick-circle.svg';
import { ReactComponent as Info } from 'design_system/src/static/icons/info.svg';
import { ReactComponent as Close } from 'design_system/src/static/icons/close-circle.svg';

export const validationItem = (
	valid: boolean,
	validLabel: string,
	invalidLabel: string,
) => (
	<>
		{valid ? (
			<Success className="dim_lg text_green_300 flex_shrink_0" />
		) : (
			<Close className="dim_lg text_red_300 flex_shrink_0" />
		)}
		<Typography
			scale="medium"
			weight="400"
			textColor={valid ? 'green_300' : 'red_300'}
			className="text_no_break "
		>
			{valid ? validLabel : invalidLabel}
		</Typography>
	</>
);

export const informativeMessage = (
	title: string,
	subtitle: string,
	textColor: any,
	color: string,
) => (
	<div className={`p_xs bg_${color} flex_center_col flex_gap_xs m_b_xs`}>
		<div className="flex_center flex_gap_xs ">
			<Info className={`dim_lg text_${textColor}`} />
			<Typography scale="small" weight="600" textColor={textColor}>
				{title}
			</Typography>
		</div>
		<Typography
			scale="xsmall"
			textColor={textColor}
			weight="400"
			className="text_center"
		>
			{subtitle}
		</Typography>
	</div>
);
