/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import './dataTable.scss';
import { Typography } from '..';

interface DataType {
	value: string | React.ReactNode;
	label?: string | React.ReactNode;
	valueColor?: string;
	onClick?: any;
}

interface DataTablePros {
	data: DataType[];
	title?: string | React.ReactNode;
	subtitle?: string | React.ReactNode;
	weightLabels?: number;
	weightValues?: number;
	className?: string;
}
const DataTable: FC<DataTablePros> = ({
	data,
	title,
	subtitle,
	weightLabels = 1,
	weightValues = 1,
	className = '',
}) => (
	<div className={`${className} overflow_hidden br_sm `}>
		{(title || subtitle) && (
			<div className="p_y_sm p_x_lg display_flex flex_gap_lg flex_align_center br_t_sm bg_neutral_200 w_fit">
				{typeof title === 'string' || title instanceof String ? (
					<Typography scale="medium" weight="600" textColor="neutral_1000">
						{title}
					</Typography>
				) : (
					title
				)}
				{typeof subtitle === 'string' || subtitle instanceof String ? (
					<Typography scale="medium" weight="400" textColor="neutral_1000">
						{subtitle}
					</Typography>
				) : (
					subtitle
				)}
			</div>
		)}
		{data.map((field) => (
			<div
				className="display_flex detail_data"
				key={`details-${field.label}-${field.value}`}
			>
				{field.label && (
					<div
						className={`flex_grow_${weightLabels} flex_basis_0 overflow_hidden bg_neutral_200 display_flex flex_align_center p_x_lg p_y_md`}
					>
						{typeof field.label === 'string' ||
						field.label instanceof String ? (
							<Typography
								scale="medium"
								weight="600"
								textColor="neutral_700"
								className="w_100_per break_word"
							>
								{field.label}
							</Typography>
						) : (
							field.label
						)}
					</div>
				)}
				<div
					className={`flex_grow_${weightValues} flex_basis_0 overflow_hidden bg_neutral_0 display_flex flex_align_center p_x_xl p_y_md`}
				>
					{typeof field.value === 'string' || field.value instanceof String ? (
						field.value === undefined ||
						field.value === 'undefined' ||
						field.value === '' ||
						field.value === null ? (
							<Typography
								scale="medium"
								weight="600"
								textColor="red_300"
								className="w_100_per break_word"
							>
								Campo Incompleto
							</Typography>
						) : (
							<Typography
								scale="medium"
								weight="400"
								textColor={field.onClick?undefined:(field.valueColor as any) ?? 'neutral_700'}
								className={`w_100_per break_word ${
									field.onClick ? 'text_link' : ''
								}`}
								onClick={field.onClick}
							>
								{field.value}
							</Typography>
						)
					) : (
						field.value
					)}
				</div>
			</div>
		))}
		<div className="final_detail" />
	</div>
);

export default DataTable;
