import { get, axiosErrorHandler } from 'models/shared/helpers/axiosInstance';
import { Historic } from 'models/historic/interfaces/historic.interface';

import 'moment/locale/es';
import { PaginatedDocuments } from 'models/shared/types/pagination';

export const findAllHistoric = async (
	id: string,
): Promise<PaginatedDocuments<Historic>> => {
	try {
		const res = await get(`v1/historic?objectId=${id}&sort=-createdAt`);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
