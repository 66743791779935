/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { FC } from 'react';
import './styles.scss';

export enum SkeletonVariant {
	ROUND,
	SQUARE,
}

interface Props {
	width?: string | number;
	height?: string | number;
	variant?: SkeletonVariant;
	styles?: React.CSSProperties;
	className?: string;
}

const Skeleton: FC<Props> = ({
	height = '30px',
	width = '100px',
	variant = SkeletonVariant.ROUND,
	className = '',
	styles,
}) => (
	<div
		style={{
			borderRadius: variant === SkeletonVariant.SQUARE ? '0' : '27px',
			width,
			height,
			...styles,
		}}
		className={`skeleton-container waves ${className}`}
	/>
);

export default Skeleton;
