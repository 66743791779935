/* eslint-disable*/
import React from 'react';
import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom';
import Ozon from './Ozon';
import reportWebVitals from './reportWebVitals';
import './theme/global.scss';
import 'design_system/src/styles/_design-system.scss';

require('react-dom');
/* @ts-ignore*/
window.React2 = require('react');
/* @ts-ignore*/
// console.log(window.React1 === window.React2);

ReactGA.initialize(`${process.env.REACT_APP_GA}`);

ReactDOM.render(
	<React.StrictMode>
		<Ozon />
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
