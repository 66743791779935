/* eslint-disable no-shadow */

import { LoginResponse } from 'models/auth/interfaces/auth.interface';

export enum AuthActions {
	login = '[LOGIN] user',
	refresh_token = '[REFRESH] token',
	logout = '[LOGOUT] user',
}

export interface AuthState {
	isLoggedIn: boolean;
	access: {
		accessToken: string;
		refreshToken: string;
	} | null;
}

export interface LoginAction {
	type: AuthActions.login;
	payload: LoginResponse;
}

export interface LogoutAction {
	type: AuthActions.logout;
}
export interface RefreshAction {
	type: AuthActions.refresh_token;
}

export type AuthActionTypes = LoginAction | LogoutAction | RefreshAction;
