/* eslint-disable no-shadow */
export enum NotificationClassification {
	fixedPosition = 'fixedPosition',
	sleepingSiteChange = 'sleepingSiteChange',
	noSignal = 'noSignal',
	leavingGeofence = 'leavingGeofence',
	powerAlarm = 'powerAlarm',
	lowBattery = 'lowBattery',
}

export const NotificationClassificationValues: {
	[key in NotificationClassification]: {
		module: string;
		moduleName: string;
		idField: string;
	};
} = {
	[NotificationClassification.fixedPosition]: {
		module: 'inventory',
		moduleName: 'Vehiculo',
		idField: 'internalId',
	},
	[NotificationClassification.sleepingSiteChange]: {
		module: 'inventory',
		moduleName: 'Vehiculo',
		idField: 'internalId',
	},
	[NotificationClassification.noSignal]: {
		module: 'inventory',
		moduleName: 'Vehiculo',
		idField: 'internalId',
	},
	[NotificationClassification.leavingGeofence]: {
		module: 'inventory',
		moduleName: 'Vehiculo',
		idField: 'internalId',
	},
	[NotificationClassification.powerAlarm]: {
		module: 'inventory',
		moduleName: 'Vehiculo',
		idField: 'internalId',
	},
	[NotificationClassification.lowBattery]: {
		module: 'inventory',
		moduleName: 'Vehiculo',
		idField: 'internalId',
	},
};
