import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Knob, Skeleton, Typography } from 'design_system/src';

import { ReactComponent as Add } from 'design_system/src/static/icons/add-circle.svg';
import { ReactComponent as Close } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as Profile } from 'design_system/src/static/icons/stars-profile.svg';
import { ReactComponent as Groups } from 'design_system/src/static/icons/groups.svg';
import { ReactComponent as Phone } from 'design_system/src/static/icons/phone.svg';
import { ReactComponent as Info } from 'design_system/src/static/icons/user-information.svg';
import { ReactComponent as Copy } from 'design_system/src/static/icons/copy.svg';
import { ReactComponent as Email } from 'design_system/src/static/icons/email.svg';
import { ReactComponent as Signal } from 'design_system/src/static/icons/radio-signal.svg';
import { ReactComponent as NoSignal } from 'design_system/src/static/icons/no-radio-signal.svg';
import { ReactComponent as Send } from 'design_system/src/static/icons/cursor.svg';

import { useNavigate, useParams } from 'react-router-dom';
import profileDefaultOzoner from 'static/images/default_profile_ozoner.png';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { FetchRepossession } from 'models/repossession/controllers/repossession.controller';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState, store } from 'store';
import { addHistoric } from 'store/actions/repossessions';
import { findOneOzoner } from 'models/ozoner/controllers/ozoner.controller';
import {
	InterestPoint,
	Repossession,
} from 'models/repossession/interfaces/repossession.interface';
import { FinancialFormTypeEnum } from 'models/financial-form/enums/financial-form.enums';
import { Ozoner } from 'models/ozoner/interfaces/ozoner.interface';
import {
	FullAddress,
	GenericFinancialForm,
	LoanFinancialFormExtraFields,
	LoanFinancialFormWithExtraFields,
} from 'models/financial-form/interfaces/financial-form.interfaces';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import {
	OzonerStatus,
	OzonerStatusValues,
} from 'models/ozoner/enums/ozoner.enums';
import {
	InterestPointStatus,
	InterestPointTypeValues,
	RepossessionPolicyValues,
} from 'models/repossession/enums/repossession.enums';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { compareRouteRecoveries } from './RecoveryMap';

interface TabsProps {
	tabsTitles: string[];
	children: React.ReactNode;
	className: string;
}

const Tabs: FC<TabsProps> = ({ tabsTitles, children, className }) => {
	const [tab, setTab] = useState(0);

	const contents = React.Children.toArray(children) as React.ReactElement[];
	return (
		<div className={`dso_card bg_neutral_200 overflow_hidden ${className}`}>
			<div className="tab_titles bg_neutral_400 display_flex flex_gap_xl p_x_md p_y_xs">
				{tabsTitles.map((title, i) => (
					<div className="pos_relative" key={title}>
						<Typography
							scale="small"
							weight={tab === i ? '600' : '400'}
							className="cursor_pointer overflow_ellipsis"
							onClick={() => setTab(i)}
						>
							{title}
						</Typography>
						{tab === i ? (
							<div className="title_mark bg_primary_300 w_100_per h_xxs br_t_xxs pos_absolute" />
						) : null}
					</div>
				))}
			</div>
			<div className="tabs_body">{contents[tab]}</div>
		</div>
	);
};

export interface IOzonerReferenceProps {
	ozonerPhone: string;
	ozonerEmail: string;
	ozonerRef1: any;
	ozonerRef2: any;
}

export interface RecoveryDetailsProps {
	detailRepossession?: {
		repossession: Repossession & { gps?: any };
		interestPoint: InterestPoint;
	};
	setDetailRepossession: Function;
	addRoute: Function;
	selecteds: { repossession: Repossession; interestPoint: InterestPoint }[];
	rutaId?: string;
	map: any;
	loadingHotspots: boolean;
}

const RecoveryDetail = ({
	detailRepossession,
	setDetailRepossession,
	addRoute,
	selecteds,
	rutaId,
	map,
	loadingHotspots,
}: RecoveryDetailsProps) => {
	const navigate = useNavigate();

	const contentRef = useRef<HTMLDivElement>(null);

	const [ozoner, setOzoner] = useState<Ozoner | null>();
	const [financialForm, setFinancialForm] =
		useState<LoanFinancialFormWithExtraFields | null>();
	const [reference1, setReference1] = useState<any>();
	const [reference2, setReference2] = useState<any>();
	const [address, setAddress] = useState<FullAddress>();
	const [selfie, setSelfie] = useState<any>();
	const [selected, setSelected] = useState(false);
	const [repossession, setRepossession] = useState<{
		repossession: Repossession & { gps?: any };
		interestPoint: InterestPoint;
	} | null>();

	const { historicRecoveries } = useSelector(
		(state: RootState) => state.repossessionReducer,
	);

	const fetchOzoner = (data: Repossession) => {
		findOneOzoner(data.ozoner._id).then((ozonerResponse) => {
			setOzoner(ozonerResponse);
			const { financialForm: form } = data.loan;
			setFinancialForm(form as LoanFinancialFormWithExtraFields);
			if (
				form.type === FinancialFormTypeEnum.loan &&
				form?.personalInformation?.personalReferences
			) {
				const references = form.personalInformation.personalReferences;
				setReference1(references.personalReference1);
				setReference2(references.personalReference2);
			}
			if (
				form.type === FinancialFormTypeEnum.loan &&
				form?.personalInformation?.fullAddress
			) {
				setAddress(form.personalInformation.fullAddress);
			}
		});
	};

	useEffect(() => {
		setOzoner(null);
		setRepossession(null);
		setReference1(undefined);
		setReference2(undefined);
		setAddress(undefined);
		setSelfie(undefined);
		if (detailRepossession) {
			if (historicRecoveries[detailRepossession.repossession._id]) {
				setRepossession({
					repossession: historicRecoveries[detailRepossession.repossession._id],
					interestPoint: detailRepossession.interestPoint,
				});
				fetchOzoner(historicRecoveries[detailRepossession.repossession._id]);
			} else {
				FetchRepossession(detailRepossession.repossession._id).then(
					(response) => {
						const data = {
							...response,
							gps: detailRepossession.repossession.gps,
						};
						store.dispatch(addHistoric(data) as any);
						setRepossession({
							repossession: data,
							interestPoint: detailRepossession.interestPoint,
						});
						fetchOzoner(data);
					},
				);
			}
		}
	}, [detailRepossession]);

	useEffect(() => {
		const find = selecteds.filter((item) =>
			compareRouteRecoveries(item, repossession),
		);
		setSelected(!!find.length);
	}, [selecteds, repossession]);

	useEffect(() => {
		if (ozoner) {
			if (ozoner.documents?.length) {
				const url = ozoner.documents.filter(
					(obj: any) => obj.typeEvidence === 'selfie',
				);
				if (url.length > 0) {
					setSelfie(url[0].url);
				} else {
					setSelfie(undefined);
				}
			}
		}
	}, [ozoner]);

	const goWA = (number?: string) => {
		if (number)
			window.open(`https://api.whatsapp.com/send?phone=${number}`, '_blank');
	};

	const goToOzoner = () => {
		if (ozoner) {
			window.open(`${window.location.origin}/ozoner/${ozoner._id}`, '_blank');
		}
	};

	const handleCopy = (content?: string) => {
		if (content) {
			navigator.clipboard
				.writeText(content)
				.then(() => {
					toasterNotification({
						toasterType: toasterOptions.success,
						msg: 'Copiado al portapapeles',
						style: 'light',
					});
				})
				.catch((error) => {
					toasterNotification({
						toasterType: toasterOptions.error,
						msg: `Error al copiar el contenido: ${error}`,
						style: 'light',
					});
				});
		}
	};

	const changeInterestPoint = (interestPoint: InterestPoint) => {
		setDetailRepossession({
			...(repossession as any),
			interestPoint,
		});
	};

	return (
		<div className="h_100_per dso_card bg_neutral_0 p_sm flex_content_col w_100_per">
			{repossession && ozoner ? (
				<>
					<div className="flex_center m_b_sm p_x_md flex_justify_between flex_gap_lg flex_header">
						<div className="flex_center flex_gap_lg">
							<div
								className={`dso_card_img br_xs bg_neutral_200 display_flex overflow_hidden border_${
									(OzonerStatusValues as any)[ozoner?.status ?? '']?.color ??
									'primary_300'
								}`}
							>
								<img
									src={ozoner?.image?.url || selfie || profileDefaultOzoner}
									alt=""
									className="recovery_photo flex_shrink_0"
								/>
							</div>
							<div>
								<Typography
									scale="xsmall"
									weight="600"
									textColor={
										(RepossessionPolicyValues as any)[
											repossession.repossession.policy
										].color
									}
								>
									{
										(RepossessionPolicyValues as any)[
											repossession.repossession.policy
										].title
									}
								</Typography>
								<Typography scale="small" weight="600">
									{ozoner?.name}
								</Typography>
							</div>
						</div>
						<div className="display_flex flex_col flex_gap_md flex_justify_between">
							<Button
								scale="small"
								icon={<Phone />}
								onClick={() => goWA(financialForm?.personalInformation.phone)}
							>
								Contactar
							</Button>
							<Button
								scale="small"
								icon={<Info />}
								variant="tint"
								onClick={goToOzoner}
							>
								Ver perfil
							</Button>
						</div>
					</div>
					<div className="m_b_sm bg_neutral_200 display_flex w_100_per br_xs">
						<div className="border_solid border_1 border_neutral_400 flex_basis_0 flex_grow_1 p_y_xs br_l_xs">
							<Typography
								scale="medium"
								weight="600"
								textColor="red_300"
								className="text_no_break text_center"
							>
								{repossession.repossession.loan.lateQuotas} cuotas
							</Typography>
							<Typography
								scale="xsmall"
								weight="400"
								textColor="neutral_900"
								className="text_no_break text_center"
							>
								en mora
							</Typography>
						</div>
						<div className="display_flex flex_gap_xs flex_justify_center flex_align_center border_solid border_1 border_neutral_400 flex_basis_0 flex_grow_1 p_y_xs">
							<Knob
								value={Math.round(
									(repossession.repossession.loan.totalDebt /
										repossession.repossession.loan.totalLoan) *
										100,
								)}
								variant="fixed"
								symbol="%"
								className="dim_xl border_transparent"
							/>
							<div>
								<Typography scale="xsmall" weight="600" textColor="neutral_900">
									Cuotas
								</Typography>
								<Typography scale="xsmall" weight="600" textColor="neutral_900">
									pagadas
								</Typography>
							</div>
						</div>
						<div className="border_solid border_1 border_neutral_400 flex_basis_0 flex_grow_1 p_y_xs br_r_xs">
							<Typography
								scale="medium"
								weight="600"
								textColor="neutral_900"
								className="text_center"
							>
								{priceFormatFinancial(
									repossession.repossession.loan.totalDebt,
									0,
								)}
							</Typography>
							<Typography
								scale="xsmall"
								weight="400"
								textColor="neutral_900"
								className="text_no_break text_center"
							>
								Para regularizar
							</Typography>
						</div>
					</div>
					<div className="flex_body">
						<Tabs
							tabsTitles={[
								'Contacto del ozoner',
								'Referencia 1',
								'Referencia 2',
							]}
							className="m_b_sm"
						>
							<div className="p_x_lg p_y_md">
								<div className="display_flex flex_justify_between m_b_xs">
									<div className="display_flex flex_gap_md flex_align_center">
										<Phone className="dim_lg text_primary_300" />
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_900"
										>
											{financialForm?.personalInformation?.phone ?? '--'}
										</Typography>
									</div>
									<div
										className="display_flex flex_gap_xs flex_align_center cursor_pointer"
										onClick={() =>
											handleCopy(financialForm?.personalInformation?.phone)
										}
										role="button"
										onKeyDown={() =>
											handleCopy(financialForm?.personalInformation?.phone)
										}
										tabIndex={0}
									>
										<Copy className="dim_md text_neutral_700" />
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_700"
										>
											Copiar
										</Typography>
									</div>
								</div>
								<div className="display_flex flex_justify_between">
									<div className="display_flex flex_gap_md flex_align_center">
										<Email className="dim_lg text_primary_300" />
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_900"
										>
											{ozoner?.email ?? '--'}
										</Typography>
									</div>
									<div
										className="display_flex flex_gap_xs flex_align_center cursor_pointer"
										onClick={() => handleCopy(ozoner?.email)}
										role="button"
										onKeyDown={() => handleCopy(ozoner?.email)}
										tabIndex={0}
									>
										<Copy className="dim_md text_neutral_700" />
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_700"
										>
											Copiar
										</Typography>
									</div>
								</div>
							</div>
							<div className="p_x_lg p_y_md">
								<div className="display_flex flex_gap_md flex_align_center m_b_xs">
									<Profile className="dim_lg text_primary_300" />
									<Typography
										scale="small"
										weight="600"
										textColor="neutral_900"
									>
										{reference1?.name ?? '--'}
									</Typography>
								</div>
								<div className="display_flex flex_gap_md flex_align_center m_b_xs">
									<Groups className="dim_lg text_primary_300" />
									<Typography
										scale="small"
										weight="600"
										textColor="neutral_900"
									>
										{reference1?.relationship ?? '--'}
									</Typography>
								</div>

								<div className="display_flex flex_justify_between ">
									<div className="display_flex flex_gap_md flex_align_center">
										<Phone className="dim_lg text_primary_300" />
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_900"
										>
											{reference1?.phone ?? '--'}
										</Typography>
									</div>
									<div
										className="display_flex flex_gap_xs flex_align_center cursor_pointer"
										onClick={() => handleCopy(reference1?.phone)}
										role="button"
										onKeyDown={() => handleCopy(reference1?.phone)}
										tabIndex={0}
									>
										<Copy className="dim_md text_neutral_700" />
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_700"
										>
											Copiar
										</Typography>
									</div>
								</div>
							</div>
							<div className="p_x_lg p_y_md">
								<div className="display_flex flex_gap_md flex_align_center m_b_xs">
									<Profile className="dim_lg text_primary_300" />
									<Typography
										scale="small"
										weight="600"
										textColor="neutral_900"
									>
										{reference2?.name ?? '--'}
									</Typography>
								</div>
								<div className="display_flex flex_gap_md flex_align_center m_b_xs">
									<Groups className="dim_lg text_primary_300" />
									<Typography
										scale="small"
										weight="600"
										textColor="neutral_900"
									>
										{reference2?.ship ?? '--'}
									</Typography>
								</div>

								<div className="display_flex flex_justify_between ">
									<div className="display_flex flex_gap_md flex_align_center">
										<Phone className="dim_lg text_primary_300" />
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_900"
										>
											{reference2?.phone ?? '--'}
										</Typography>
									</div>
									<div
										className="display_flex flex_gap_xs flex_align_center cursor_pointer"
										onClick={() => handleCopy(reference2?.phone)}
										role="button"
										onKeyDown={() => handleCopy(reference2?.phone)}
										tabIndex={0}
									>
										<Copy className="dim_md text_neutral_700" />
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_700"
										>
											Copiar
										</Typography>
									</div>
								</div>
							</div>
						</Tabs>
						<Tabs
							tabsTitles={['Ubicación', 'Motocicleta', 'Puntos de interes']}
							className="m_b_sm"
						>
							<div className="p_x_md p_y_xs">
								{loadingHotspots && (
									<>
										<div className="display_flex flex_align_center flex_gap_sm m_b_xs">
											<Typography
												scale="small"
												weight="400"
												textColor="neutral_600"
												className=""
											>
												Hotspots
											</Typography>
											<div className="border_solid border_1 border_neutral_400 flex_grow_1" />
										</div>
										<Typography
											scale="large"
											weight="400"
											textColor="red_300"
											className="p_y_md text_center"
										>
											Los Hotspots de esta moto se estan cargando...
										</Typography>
									</>
								)}
								<div className="display_flex flex_align_center flex_gap_sm m_b_xs">
									<Typography
										scale="small"
										weight="400"
										textColor="neutral_600"
										className=""
									>
										Ultima ubicacion
									</Typography>
									<div className="border_solid border_1 border_neutral_400 flex_grow_1" />
								</div>
								{/* TODO: Add missing info */}

								{/* {repossession.repossession.gps?.pos ? (
                  <>
                    <div className="display_flex flex_gap_xs flex_align_center m_b_xs">
                      {repossession.repossession.gps.pos.days <= 3 ? (
                        <Signal className="dim_lg text_green_300" />
                      ) : (
                        <NoSignal className="dim_lg text_red_300" />
                      )}
                      <Typography
                        scale="small"
                        weight="600"
                        textColor="neutral_900"
                      >
                        {repossession.repossession.gps.pos.location[2]}
                      </Typography>
                    </div>
                    <div className="display_flex flex_justify_between m_b_xs">
                      <Typography
                        scale="small"
                        weight="600"
                        textColor="neutral_900"
                      >
                        {repossession.repossession.gps.pos.location.join(", ")}
                      </Typography>

                      <div
                        className="display_flex flex_gap_xs flex_align_center cursor_pointer"
                        onClick={() =>
                          handleCopy(
                            repossession.repossession.gps.pos.location.join(
                              ", "
                            )
                          )
                        }
                        role="button"
                        onKeyDown={() =>
                          handleCopy(
                            repossession.repossession.gps.pos.location.join(
                              ", "
                            )
                          )
                        }
                        tabIndex={0}
                      >
                        <Copy className="dim_md text_neutral_700" />
                        <Typography
                          scale="small"
                          weight="600"
                          textColor="neutral_700"
                        >
                          Copiar
                        </Typography>
                      </div>
                    </div>
                    <div className="m_b_sm bg_neutral_0 display_flex w_100_per br_sm">
                      <div className="border_solid border_1 border_neutral_300 flex_basis_0 flex_grow_1 p_y_xs br_l_sm flex_center">
                        <Typography
                          scale="small"
                          weight="400"
                          textColor="neutral_700"
                          className="text_no_break text_center"
                        >
                          {Math.round(
                            repossession.repossession.gps.prms.speed.v / 1000
                          )}{" "}
                          km/h
                        </Typography>
                      </div>
                      <div className="display_flex flex_gap_xs flex_justify_center flex_align_center border_solid border_1 border_neutral_300 flex_basis_0 flex_grow_1 p_y_xs flex_center">
                        <Typography
                          scale="small"
                          weight="400"
                          textColor="neutral_700"
                          className="text_center"
                        >
                          {moment(
                            repossession.repossession.gps.pos.t * 1000
                          ).format("DD/MMM/YYYY hh:mm a")}
                        </Typography>
                      </div>
                      <div className="border_solid border_1 border_neutral_300 flex_basis_0 flex_grow_1 p_y_xs br_r_sm">
                        <Typography
                          scale="small"
                          weight="400"
                          textColor="neutral_700"
                          className="text_center"
                        >
                          {repossession.repossession.gps.pos.y},{" "}
                          {repossession.repossession.gps.pos.x}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <Typography
                    scale="medium"
                    weight="400"
                    textColor="neutral_900"
                    className="p_md text_center"
                  >
                    No se tiene informacion disponible del gps
                  </Typography>
                )} */}
								{repossession.repossession?.gps?.type === 'Point' ? (
									<>
										{repossession.repossession?.gps.isTrackGPS ? (
											<div className="display_flex flex_gap_xs flex_align_center m_b_xs">
												<NoSignal className="dim_lg text_neutral_800" />
												<Typography
													scale="small"
													weight="600"
													textColor="neutral_900"
												>
													Moto con ultimo registro de Tracker GPS
												</Typography>
											</div>
										) : (
											<div className="display_flex flex_gap_xs flex_align_center m_b_xs">
												{Math.round(
													(Date.now() -
														new Date(
															repossession.repossession?.gps?.updatedAt,
														).getTime()) /
														(1000 * 60 * 60 * 24),
												) <= 3 ? (
													<>
														<Signal className="dim_lg text_green_300" />
														<Typography
															scale="small"
															weight="600"
															textColor="neutral_900"
														>
															Moto con señal
														</Typography>
													</>
												) : (
													<>
														<NoSignal className="dim_lg text_red_300" />
														<Typography
															scale="small"
															weight="600"
															textColor="neutral_900"
														>
															Moto sin señal
														</Typography>
													</>
												)}
											</div>
										)}

										<div className="m_b_sm bg_neutral_0 display_flex w_100_per br_sm">
											<div className="border_solid border_1 border_neutral_300 flex_basis_0 flex_grow_1 p_y_xs br_l_sm flex_center">
												<Typography
													scale="small"
													weight="400"
													textColor="neutral_700"
													className="text_no_break text_center"
												>
													Ubicacion
												</Typography>
											</div>
											<div className="table_divider h_xl" />
											<div className="display_flex flex_gap_xs flex_justify_center flex_align_center border_solid border_1 border_neutral_300 flex_basis_0 flex_grow_1 p_y_xs flex_center">
												<Typography
													scale="small"
													weight="400"
													textColor="neutral_700"
													className="text_center"
												>
													{moment(
														repossession.repossession?.gps?.updatedAt,
													).format('DD/MMM/YYYY hh:mm a')}
												</Typography>
											</div>
											{/* <div className="table_divider h_xl" /> */}
											<div className="border_solid border_1 border_neutral_300 flex_basis_0 flex_grow_1 p_y_xs br_r_sm">
												<Typography
													scale="small"
													weight="400"
													textColor="neutral_700"
													className="text_center"
												>
													{repossession.repossession?.gps?.coordinates[0]},{' '}
													{repossession.repossession?.gps?.coordinates[1]}
												</Typography>
											</div>
										</div>
									</>
								) : (
									<Typography
										scale="medium"
										weight="400"
										textColor="neutral_900"
										className="p_md text_center"
									>
										No se tiene informacion disponible del gps
									</Typography>
								)}
								<div className="display_flex flex_align_center flex_gap_sm m_b_xs">
									<Typography
										scale="small"
										weight="400"
										textColor="neutral_600"
										className=""
									>
										Domicilio del ozoner
									</Typography>
									<div className="border_solid border_1 border_neutral_400 flex_grow_1" />
								</div>
								{address ? (
									<div className="display_flex flex_justify_between m_b_xs">
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_900"
										>
											{`${address.intNumber} ${address.extNumber} ${address.street}, ${address.delegation} ${address.neighborhood}, ${address.zipCode}, ${address.state}`}
										</Typography>

										<div
											className="display_flex flex_gap_xs flex_align_center cursor_pointer"
											onClick={() =>
												handleCopy(
													`${address.intNumber} ${address.extNumber} ${address.street}, ${address.delegation} ${address.neighborhood}, ${address.zipCode}, ${address.state}`,
												)
											}
											role="button"
											onKeyDown={() =>
												handleCopy(
													`${address.intNumber} ${address.extNumber} ${address.street}, ${address.delegation} ${address.neighborhood}, ${address.zipCode}, ${address.state}`,
												)
											}
											tabIndex={0}
										>
											<Copy className="dim_md text_neutral_700" />
											<Typography
												scale="small"
												weight="600"
												textColor="neutral_700"
											>
												Copiar
											</Typography>
										</div>
									</div>
								) : (
									<Typography
										scale="medium"
										weight="400"
										textColor="neutral_900"
										className="p_md text_center"
									>
										No se tiene informacion disponible del domicilio del ozoner
									</Typography>
								)}
							</div>
							<div className="p_x_md p_y_xs">
								<div className="img_recovery_cont m_b_md">
									<img
										src={repossession?.repossession?.vehicle?.images?.[0]?.url}
										alt=""
										className="img_cover"
									/>
								</div>
								<div className="display_flex m_b_xs flex_gap_xxs">
									<div className="display_flex flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs">
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_700"
											className=" flex_basis_0 flex_grow_1"
										>
											Marca
										</Typography>
										<Typography
											scale="small"
											weight="400"
											textColor="neutral_1000"
											className=" flex_basis_0 flex_grow_1"
										>
											{repossession?.repossession?.vehicle?.brand?.name}
										</Typography>
									</div>
									<div className="display_flex flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs">
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_700"
											className=" flex_basis_0 flex_grow_1"
										>
											Modelo
										</Typography>
										<Typography
											scale="small"
											weight="400"
											textColor="neutral_1000"
											className=" flex_basis_0 flex_grow_1"
										>
											{repossession?.repossession?.vehicle?.model?.name}
										</Typography>
									</div>
									<div className="flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs">
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_700"
											className="text_center"
										>
											Serie vehicular
										</Typography>
									</div>
								</div>
								<div className="display_flex flex_gap_xxs">
									<div className="display_flex flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs br_bl_xs">
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_700"
											className=" flex_basis_0 flex_grow_1"
										>
											Año
										</Typography>
										<Typography
											scale="small"
											weight="400"
											textColor="neutral_1000"
											className=" flex_basis_0 flex_grow_1"
										>
											{repossession?.repossession?.vehicle?.year}
										</Typography>
									</div>
									<div className="display_flex flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs">
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_700"
											className=" flex_basis_0 flex_grow_1"
										>
											Cilindraje
										</Typography>
										<Typography
											scale="small"
											weight="400"
											textColor="neutral_1000"
											className=" flex_basis_0 flex_grow_1"
										>
											{repossession?.repossession?.vehicle?.cylindersCapacity}
										</Typography>
									</div>
									<div className="flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs br_br_xs">
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_1000"
											className="text_center"
										>
											{repossession?.repossession?.vehicle?.engineSN}
										</Typography>
									</div>
								</div>
							</div>
							<div className="p_x_md p_y_xs">
								{repossession.repossession.interestPoint?.length ? (
									repossession.repossession.interestPoint
										.filter((ip) => ip.status === InterestPointStatus.active)
										.map((interrestPoint) => (
											<div
												className="border_neutral_500 border_b_solid b_b_1 p_y_md"
												key={interrestPoint._id}
											>
												<div className="display_flex flex_gap_md flex_align_center m_b_xs">
													{interrestPoint._id ===
														repossession.interestPoint._id && (
														<div className="dim_sm bg_primary_300 br_circle" />
													)}
													<Typography
														scale="small"
														weight="600"
														textColor={
															interrestPoint._id ===
															repossession.interestPoint._id
																? 'primary_300'
																: 'neutral_900'
														}
													>
														{InterestPointTypeValues[interrestPoint.type]
															?.title ?? ''}
													</Typography>
													<Typography
														scale="small"
														weight="400"
														textColor="neutral_700"
													>
														{moment
															.utc(interrestPoint.createdAt)
															.format('DD/MM/YYYY')}
													</Typography>
												</div>
												<Typography
													scale="small"
													weight="400"
													textColor="neutral_900"
													className="m_b_xs"
												>
													{interrestPoint.description}
												</Typography>
												<div className="display_flex flex_gap_md flex_align_stretch">
													<Typography
														scale="small"
														weight="400"
														textColor="neutral_700"
														className="p_x_md p_y_xs bg_neutral_100 text_center br_xs shadow_hard flex_grow_1 "
													>
														{interrestPoint.location.coordinates[0]},{' '}
														{interrestPoint.location.coordinates[0]}
													</Typography>
													{interrestPoint._id !==
														repossession.interestPoint._id && (
														<div
															className="display_flex p_x_sm flex_align_center cursor_pointer bg_neutral_0 shadow_hard br_xs"
															onClick={() =>
																changeInterestPoint(interrestPoint)
															}
															role="button"
															onKeyDown={() =>
																changeInterestPoint(interrestPoint)
															}
															tabIndex={0}
														>
															<Send className="dim_md text_neutral_800" />
														</div>
													)}
												</div>
											</div>
										))
								) : (
									<Typography
										scale="medium"
										weight="400"
										textColor="neutral_900"
										className="p_md text_center"
									>
										No se tiene informacion disponible de puntos de interes
									</Typography>
								)}
							</div>
						</Tabs>
					</div>
					<div className="flex_footer">
						{!rutaId && (
							<Button
								color={selected ? 'red' : 'primary'}
								icon={selected ? <Close /> : <Add />}
								className="w_100_per"
								scale="small"
								onClick={() => addRoute(repossession)}
							>
								{selected ? 'Eliminar de la ruta' : 'Agregar a la Ruta'}
							</Button>
						)}
					</div>
				</>
			) : (
				<>
					<div className="display_flex flex_gap_xs h_15_per flex_align_stretch m_b_md">
						<Skeleton className=" " height="100%" width={90} />
						<Skeleton className="flex_grow_1" height="100%" width={60} />
						<Skeleton className="m_l_xxxl" height="100%" width="30%" />
					</div>
					{/* <Skeleton className="m_b_lg" height={60} width={60} /> */}
					{/* <Skeleton className="m_b_xl" height={45} width={45} /> */}
					<Skeleton className="w_100_per m_b_sm" height="10%" />
					<Skeleton className="w_100_per m_b_sm" height="15%" />
					<Skeleton className="w_100_per m_b_sm" height="43%" />
					<Skeleton className="w_100_per m_b_sm" height="7%" />
				</>
			)}
		</div>
	);
};

export default RecoveryDetail;
