import moment from 'moment';
import { Repossession } from 'models/repossession/interfaces/repossession.interface';
import { PaginatedDocuments } from 'models/shared/types/pagination';
import { buildPathWithQueryParams } from 'models/shared/helpers/utils';
import {
	get,
	put,
	post,
	deletes,
	axiosErrorHandler,
} from '../../shared/helpers/axiosInstance';
import {
	EstimateWarrantyDto,
	Loan,
	LoanAddDiscountsDto,
	LoanAddFeesDto,
	LoanEstimateReestructureDto,
	LoanEstimateReestructureResponse,
	LoanEstimateWarrantyResponse,
	LoanFilterDto,
	LoanReestructureDto,
	LoanWithExtraFields,
	settleLoanDto,
	UpdateLoanDto,
	WarrantyCompleteLoanDto,
	WarrantyCurrentLoan,
	WarrantyCurrentLoanResponse,
} from '../interfaces/loan.interface';

const baseLoanPath = '/v1/loan';

export const addFeeOrders = async (
	loanId: string,
	dto: LoanAddFeesDto,
): Promise<LoanWithExtraFields> => {
	try {
		const res = await put<LoanWithExtraFields>(
			`${baseLoanPath}/${loanId}/addFeeOrders`,
			dto,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const addDiscountOrders = async (
	loanId: string,
	dto: LoanAddDiscountsDto,
): Promise<LoanWithExtraFields> => {
	try {
		const res = await put<LoanWithExtraFields>(
			`${baseLoanPath}/${loanId}/addDiscountOrders`,
			dto,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const reestructureLoan = async (
	loanId: string,
	dto: LoanReestructureDto,
): Promise<LoanWithExtraFields> => {
	try {
		const res = await put<LoanWithExtraFields>(
			`${baseLoanPath}/${loanId}/restructure`,
			dto,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const estimateRestructureLoan = async (
	loanId: string,
	dto: LoanEstimateReestructureDto,
): Promise<LoanEstimateReestructureResponse> => {
	try {
		const res = await put<LoanEstimateReestructureResponse>(
			`${baseLoanPath}/${loanId}/estimateRestructure`,
			dto,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const findOneLoan = async (id: string): Promise<LoanWithExtraFields> => {
	try {
		const res = await get<LoanWithExtraFields>(`${baseLoanPath}/${id}`);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const findLoanByOzoner = async (
	ozonerId: string,
): Promise<LoanWithExtraFields[]> => {
	try {
		const res = await get<LoanWithExtraFields[]>(
			`${baseLoanPath}/ozoner/${ozonerId}`,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const findAllLoans = async (
	dto: LoanFilterDto,
): Promise<PaginatedDocuments<LoanWithExtraFields>> => {
	// eslint-disable-next-line no-unused-vars,
	try {
		const res = await get<PaginatedDocuments<LoanWithExtraFields>>(
			buildPathWithQueryParams(baseLoanPath, dto),
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const updateLoan = async (
	loanId: string,
	dto: UpdateLoanDto,
): Promise<LoanWithExtraFields> => {
	try {
		const res = await put<LoanWithExtraFields>(
			`${baseLoanPath}/${loanId}`,
			dto,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const uploadFileLoan = async (
	loanId: string,
	file: File,
	type = 'other',
	alternativeName = '',
): Promise<LoanWithExtraFields> => {
	try {
		const data = new FormData();
		data.append('name', alternativeName !== '' ? alternativeName : file.name);
		data.append('file', file);

		const res = await put<LoanWithExtraFields>(
			`${baseLoanPath}/${loanId}/${type}`,
			data,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
			},
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const deleteFileLoan = async (
	loanId: string,
	docId: string,
): Promise<LoanWithExtraFields> => {
	try {
		const res = await deletes<LoanWithExtraFields>(
			`${baseLoanPath}/${loanId}/document/${docId}`,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const settleDebt = async (
	loanId: string,
	dto: settleLoanDto,
): Promise<LoanWithExtraFields> => {
	try {
		const res = await post<LoanWithExtraFields>(
			`${baseLoanPath}/settle-debt/${loanId}`,
			dto,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const warrantyCompleteLoan = async (
	loanId: string,
	dto: WarrantyCompleteLoanDto,
): Promise<LoanWithExtraFields> => {
	try {
		const res = await post<LoanWithExtraFields>(
			`${baseLoanPath}/warranty/${loanId}`,
			dto,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const estimateWarrantyLoan = async (
	loanId: string,
	dto: EstimateWarrantyDto,
): Promise<LoanEstimateWarrantyResponse> => {
	try {
		const res = await post<LoanEstimateWarrantyResponse>(
			`${baseLoanPath}/warranty-current/${loanId}/estimate`,
			dto,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const warrantyCurrentLoan = async (
	loanId: string,
	dto: WarrantyCurrentLoan,
): Promise<WarrantyCurrentLoanResponse> => {
	try {
		const res = await post<WarrantyCurrentLoanResponse>(
			`${baseLoanPath}/warranty/${loanId}`,
			dto,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
