// eslint-disable-next-line no-shadow
export enum DeviceBrands {
	COBAN_4G = 'Coban4G',
	SEEWORLD = 'Seeworld',
}

// eslint-disable-next-line no-shadow
export enum DeviceStatus {
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
	BROKEN = 'Broken',
	REPAIRING = 'Repairing',
}
