/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';

// -----------------LIBRARIES IMPORTS------------------- //
import { Button } from 'design_system/src';

// -----------------DATA IMPORTS------------------- //
import moment from 'moment';

import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';

// -----------------STATIC IMPORTS------------------- //
import '../../../ozoner.scss';
import { LightTooltip } from 'components/hocs/tooltip/Tooltip';
import { informativeMessage } from 'components/hocs/messages/messages';
import { FormStatus } from 'models/financial-form/enums/financial-form.enums';
import { updateFinancialForm } from 'models/financial-form/controllers/financial-form.controller';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { ozonerContext } from '../../OzonerContext';

function ExpiredSection() {
	const { updateFormData, financialForm } = useContext(ozonerContext);

	const reviveSolicitud = () => {
		updateFinancialForm(financialForm.form._id, {
			status: FormStatus.pending,
		})
			.then((newForm) => {
				toasterNotification({
					msg: 'Solicitud Exitosa',
					style: 'light',
					toasterType: toasterOptions.success,
				});
				updateFormData(newForm);
			})
			.catch((e) => {
				toasterNotification({
					msg: e ?? 'Ocurrio un error',
					toasterType: toasterOptions.error,
					style: 'light',
				});
			});
		handleEventGoogle(
			'BTN_Pb_request_revive',
			'Panda Solicitudes',
			'click in revive request button',
		);
	};

	return (
		<>
			{informativeMessage(
				'Solicitud caducada por inactividad:',
				'',
				'neutral_1000',
				'neutral_400',
			)}
			<div
				id="validate-soli-ozoner"
				className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col"
			>
				<LightTooltip
					title={
						financialForm?.form?.updatedAt &&
						!moment(financialForm.form.updatedAt).isBefore(
							moment().subtract(3, 'month'),
						)
							? ''
							: 'La solicitud tiene mas de 3 meses, no es posible restaurarla'
					}
				>
					<div className="w_100_per">
						<Button
							scale="small"
							color="primary"
							onClick={reviveSolicitud}
							className="w_100_per"
							disabled={
								financialForm?.form?.updatedAt
									? moment(financialForm.form.updatedAt).isBefore(
											moment().subtract(3, 'month'),
									  )
									: true
							}
						>
							Restablecer solicitud
						</Button>
					</div>
				</LightTooltip>
			</div>
		</>
	);
}
export default ExpiredSection;
