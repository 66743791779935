/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { FC, Ref } from 'react';
import {
	Typography,
	ModalConfirmation as Modal,
	Select,
} from 'design_system/src';
import { ReactComponent as Reject } from 'design_system/src/static/icons/delete-folder.svg';
import {
	RejectedReason,
	RejectedReasonValues,
} from 'models/financial-form/enums/financial-form.enums';

interface RejectedProps {
	changeStatusRejected?: () => void;
	selected?: RejectedReason;
	setSelected: (status: RejectedReason) => void;
	open: boolean;
	setOpen: Function;
}

const Rejected: FC<RejectedProps> = ({
	changeStatusRejected,
	selected,
	setSelected,
	open,
	setOpen,
}) => (
	<Modal
		openConfirmation={open}
		handleClose={() => setOpen(false)}
		icon={<Reject className="text_primary_300" />}
		isConfirmationLoading={false}
		heightDesktop={80}
		actions={[
			{
				label: 'Cancelar',
				color: 'grey',
				action: () => setOpen(false),
			},
			{
				label: 'Rechazar',
				color: 'primary',
				isSubmit: true,
				action: changeStatusRejected,
				disabled: !selected,
			},
		]}
	>
		<>
			<Typography scale="large" weight="600">
				Rechazar Solcitud
			</Typography>
			<Typography scale="medium" weight="400" className="m_b_md">
				Seleccione el motivo de rechazo de la solicitud:
			</Typography>
			<Select
				value={selected}
				onChange={(e: any) => setSelected(e.target.value)}
				className="w_100_per"
				options={Object.entries(RejectedReasonValues).map(([key, value]) => ({
					value: key,
					label: value,
				}))}
			/>
		</>
	</Modal>
);

export default Rejected;
