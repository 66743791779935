/* eslint-disable no-unused-vars */

import React from 'react';
import _ from 'lodash';
import { ReactComponent as Checkbox } from '../../../static/icons/checkmark.svg';

interface CheckboxProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	label: string;
	value: any;
	checked: boolean;
	className?: string;
	variant?: '' | 'big';
}

const CheckboxInput: React.FC<CheckboxProps> = ({
	label,
	value,
	checked,
	className,
	variant = '',
	...props
}) => (
	// eslint-disable-next-line jsx-a11y/label-has-associated-control
	<label
		className={`dso_input_checkbox_cont text_small_600 ${className}`}
		key={`${props.name}-${label}`}
	>
		{label}
		<input
			type="checkbox"
			checked={checked}
			value={value}
			{...(props as React.DetailedHTMLProps<
				React.InputHTMLAttributes<HTMLInputElement>,
				HTMLInputElement
			>)}
		/>
		<span className={`dso_input_checkbox center_y br_xxxs ${variant}`}>
			<Checkbox className="dso_input_checkbox_icon center" />
		</span>
	</label>
);

export default CheckboxInput;
