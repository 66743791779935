/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { FC, Ref } from 'react';
import {
	Typography,
	ModalConfirmation as Modal,
	Select,
} from 'design_system/src';
import { ReactComponent as Devolt } from 'design_system/src/static/icons/delete-folder.svg';
import {
	DevolutionReason,
	DevolutionReasonValues,
} from 'models/financial-form/enums/financial-form.enums';

interface RejectedProps {
	changeStatusDevolution?: () => void;
	selected?: DevolutionReason;
	setSelected: (status: DevolutionReason) => void;
	open: boolean;
	setOpen: Function;
}

const Devolution: FC<RejectedProps> = ({
	changeStatusDevolution,
	selected,
	setSelected,
	open,
	setOpen,
}) => (
	<Modal
		openConfirmation={open}
		handleClose={() => setOpen(false)}
		icon={<Devolt className="text_primary_300" />}
		isConfirmationLoading={false}
		heightDesktop={80}
		actions={[
			{
				label: 'Cancelar',
				color: 'grey',
				action: () => setOpen(false),
			},
			{
				label: 'Devolver',
				color: 'primary',
				isSubmit: true,
				action: changeStatusDevolution,
				disabled: !selected,
			},
		]}
	>
		<>
			<Typography scale="large" weight="600">
				Devolver Solcitud
			</Typography>
			<Typography scale="medium" weight="400" className="m_b_md">
				Seleccione el motivo de devolución de la solicitud para el vendedor:
			</Typography>
			<Select
				value={selected}
				onChange={(e: any) => setSelected(e.target.value)}
				className="w_100_per"
				options={Object.keys(DevolutionReasonValues).map(
					(devolutionReason: string) => ({
						value: devolutionReason,
						label: DevolutionReasonValues[devolutionReason as DevolutionReason],
					}),
				)}
			/>
		</>
	</Modal>
);

export default Devolution;
