/* eslint-disable no-param-reassign */
import {
	get,
	put,
	post,
	deletes,
	axiosErrorHandler,
} from 'models/shared/helpers/axiosInstance';
import {
	DiscountCreateDto,
	EstimatePriceDto,
	Hub,
	SalePrices,
	VehiclePopulated,
	UpdateVehicleDto,
	CreateVehicleDto,
	VehicleFilterDto,
	imagesDto,
	QuotaVehicleDto,
} from 'models/inventory/interfaces/vehicle.interface';
import { buildPathWithQueryParams } from 'models/shared/helpers/utils';
import { PaginatedDocuments } from 'models/shared/types/pagination';
import { City } from 'models/shared/types/city';
import _ from 'lodash';

const baseVehiclePath = '/v1/vehicle';

export const getVehicles = async (dto: VehicleFilterDto) => {
	try {
		const res = await get<PaginatedDocuments<VehiclePopulated>>(
			buildPathWithQueryParams(baseVehiclePath, dto),
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const getVehicleByInternalId = async (internalId = '') => {
	try {
		const res = await get<VehiclePopulated>(`${baseVehiclePath}/${internalId}`);

		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

const formatData = (
	values: CreateVehicleDto | UpdateVehicleDto,
	images: imagesDto[],
): FormData => {
	const data = new FormData();

	images.forEach((img, i) => {
		if (img.name) {
			data.append(`images[${i}][name]`, img.name);
		} else {
			const name = `Foto${Date.now() + 100 * i}`;
			data.append(name, img.file);
			data.append(`images[${i}][name]`, name);
		}
	});

	Object.keys(values).forEach((k) => {
		const value = values[k as keyof (CreateVehicleDto | UpdateVehicleDto)];
		if (value !== undefined) {
			if (value instanceof Date) {
				data.append(k, (value as Date).toISOString());
			} else if (!(value instanceof Object)) {
				data.append(k, value as string);
			}
		}
	});

	if (values.location?.hub)
		data.append('location[hub]', values.location.hub.toString());

	Object.keys(values.extraCosts ?? {}).forEach((k) => {
		const value = _.get(values.extraCosts, k);
		if (value !== undefined) data.append(`extraCosts[${k}]`, value.toString());
	});

	if (values.salePlans)
		Object.keys(values.salePlans ?? {}).forEach((k) => {
			const value = _.get(values.salePlans, k);
			if (value !== undefined) {
				data.append(`salePlans[${k}][weeks]`, value.weeks.toString());
				data.append(
					`salePlans[${k}][percentageIncrease]`,
					value.percentageIncrease.toString(),
				);
			}
		});
	return data;
};

export const addVehicle = async (
	values: CreateVehicleDto,
	images: imagesDto[],
) => {
	try {
		const data = formatData(values, images);
		const res = await post<VehiclePopulated>(baseVehiclePath, data, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const editVehicle = async (
	values: UpdateVehicleDto,
	images: imagesDto[],
	internalId: string,
) => {
	try {
		const data = formatData(values, images);
		const res = await put<VehiclePopulated>(
			`${baseVehiclePath}/${internalId}`,
			data,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
			},
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const estimatePrice = async (dto: EstimatePriceDto) => {
	try {
		const res = await get<SalePrices>(
			buildPathWithQueryParams(`${baseVehiclePath}/estimatePrice`, dto),
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const getQuotaVehicle = async (
	internalId: string,
	dto: QuotaVehicleDto,
) => {
	try {
		const res = await get<SalePrices>(
			buildPathWithQueryParams(
				`${baseVehiclePath}/${internalId}/quota-vehicle`,
				dto,
			),
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const addDiscount = async (
	values: DiscountCreateDto,
	id: string,
	other?: string | null,
) => {
	try {
		if (other) {
			await put(`${baseVehiclePath}/${id}/discount`, {
				id: other,
				status: 'inactive',
			});
		}
		const res = await post<VehiclePopulated>(
			`${baseVehiclePath}/${id}/discount`,
			values,
		);

		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const deleteDiscount = async (discountId: any, internalId: string) => {
	try {
		const res = await put<VehiclePopulated>(
			`${baseVehiclePath}/${internalId}/discount`,
			{
				id: discountId,
				status: 'inactive',
			},
		);

		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const getHubs = async () => {
	try {
		const res = await get<Hub[]>('v1/hub');
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const getCities = async () => {
	try {
		const res = await get<City[]>('v1/city');
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const addFile = async (vehicle: string, file: File) => {
	try {
		const data = new FormData();
		data.append('name', file.name);
		data.append('file', file);

		const res = await put<VehiclePopulated>(
			`${baseVehiclePath}/${vehicle}/document`,
			data,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
			},
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const deleteFile = async (vehicle: string, file: string) => {
	try {
		const res = await deletes<VehiclePopulated>(
			`${baseVehiclePath}/${vehicle}/document`,
			{
				data: { name: file },
			},
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
