/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, MouseEventHandler } from 'react';
import './button.scss';

interface BtnProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	orientation?: 'left' | 'right';
	scale?: 'large' | 'small' | 'mini';
	className?: string;
	loading?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	color?:
		| 'primary'
		| 'secondary'
		| 'neutral'
		| 'green'
		| 'red'
		| 'gradient'
		| 'grey'
		| 'light_grey'
		| 'primary_dark'
		| 'white';
}
interface BtnClasicProps extends BtnProps {
	variant?: 'principal' | 'outline' | 'ghost' | 'link' | 'selector' | 'tint';
	icon?: React.ReactElement<any, any> | null;
	subvariant?: null;
	children: React.ReactNode;
	extend?: boolean;
}
interface BtnIconProps extends BtnProps {
	variant: 'icon';
	icon: React.ReactElement<any, any>;
	subvariant?: 'link' | 'edit' | 'fill';
	extend?: false;
}
const Button: FC<BtnClasicProps | BtnIconProps> = ({
	color = 'primary',
	orientation = 'left',
	variant = 'principal',
	scale = 'large',
	className = '',
	onClick,
	children,
	icon,
	subvariant = null,
	type = 'button',
	extend = false,
	loading = false,
	disabled = false,
	...props
}) => (
	<button
		type={type}
		disabled={loading || disabled}
		{...props}
		onClick={onClick}
		className={`dso_btn_${variant} dso_btn_color_${color} ${
			icon ? `dso_btn_img${orientation.substring(0, 1)}` : ''
		} dso_btn_${scale} ${subvariant ? `dso_btn_icon_${subvariant}` : ''} ${
			extend ? 'dso_btn_extend' : ''
		} ${className}`}
	>
		<div className="dso_btn_cont">
			<div className="dso_btn_img display_flex">{icon}</div>
			<div className="dso_btn_text">
				{loading ? 'Por favor espere...' : children}
			</div>
		</div>
	</button>
);

export default Button;
