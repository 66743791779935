import { get, axiosErrorHandler } from 'models/shared/helpers/axiosInstance';
import {
	HotspotsResponse,
	LocationResponse,
} from '../interfaces/gps.interface';

const baseGpsPath = '/v1/gps';

export const FetchVehiclesLocations = async (): Promise<LocationResponse> => {
	try {
		const res = await get<LocationResponse>(baseGpsPath);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const getHotspotsByInternalId = async (
	internalId = '',
): Promise<HotspotsResponse> => {
	try {
		const res = await get<HotspotsResponse>(
			`${baseGpsPath}/${internalId}/hotspots`,
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
