export const priceMXNSemanal = (price: number, weeks: number) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});
	const priceDivide = price / weeks;
	const priceFormat = formatter.format(priceDivide);
	return `${priceFormat}MXN`;
};
export const priceMXNTotal = (price: number, decimals?: number) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: decimals === null ? 2 : decimals,
	});
	const priceFormat = formatter.format(price);
	return `${priceFormat}MXN`;
};
export const priceFormatFinancial = (price: number, decimals?: number) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: decimals === null ? 2 : decimals,
	});
	const priceFormat = formatter.format(price);
	return `${priceFormat}`;
};

export const fixedFloat = (amount: number, decimals = 2): number | string =>
	amount.toFixed(decimals);
