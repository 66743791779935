import React from 'react';
import { Outlet } from 'react-router-dom';

// css
import './mobileLayout.scss';
import NavbarMobile from 'components/layouts/mobile/navbarMobile/NavbarMobile';

function MobileLayout() {
	return (
		<div className="rootL-mobile">
			<NavbarMobile />
			<div className="main">
				<div className="mobile-container">
					<Outlet />
				</div>
			</div>
		</div>
	);
}

export default MobileLayout;
