/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, {
	ComponentType,
	ElementType,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import './listTable.scss';
import { Typography } from '../Typography';
import { CheckboxInput } from '../Input';

interface PropT {
	children?: ReactNode;
	className?: string;
}
const BaseTypography: React.FC<PropT> = ({ children, className }) => (
	<Typography
		scale="medium"
		weight="400"
		textColor="neutral_700"
		className={`${className} text_center`}
	>
		{children}
	</Typography>
);

type ColBasic<T> = {
	label: string;
	attribute: keyof T;
	render?: undefined;
	hideMobile?: boolean;
	hideDesktop?: boolean;
	weight?: number;
};
type ColRender<T> = {
	label: string;
	attribute?: undefined;
	render: (
		item: T,
		index: number,
		BaseTypography: React.FC<PropT>,
	) => React.ReactElement;
	hideMobile?: boolean;
	hideDesktop?: boolean;
	weight?: number;
};

interface Props<T> {
	cols: (ColBasic<T> | ColRender<T>)[];
	data: T[];
	onRowClick?: (item: T, index: number) => void;
	to?: (item: T, index: number) => string;
	ContComponent?: ComponentType<any> | ElementType<any>;
	selecteds?: T[];
	setSelecteds?: (selecteds: T[]) => void;
	canSelectItem?: (item: T, index: number) => boolean;
	selectId?: string;
	hideHeader?: boolean;
	canSelect?: boolean;
	dividers?: boolean;
	rowClassName?: string;
	headerClassName?: string;
	className?: string;
	child?: (
		item: T,
		index: number,
		BaseTypography: React.FC<PropT>,
	) => React.ReactElement;
}

const ListTable = <T extends object & { _id: string }>({
	cols,
	child,
	onRowClick,
	to,
	ContComponent = 'div',
	data,
	selecteds,
	setSelecteds = () => {},
	canSelect = true,
	canSelectItem,
	selectId = '_id',
	hideHeader = false,
	dividers = false,
	rowClassName = '',
	headerClassName = '',
	className = '',
}: Props<T>) => {
	const [selectedAll, setSelectedAll] = useState(false);
	const [selectedsIds, setSelectedsIds] = useState<string[]>([]);

	const select = (row: T) => {
		if (selectedsIds.includes(row[selectId as keyof T] as any)) {
			setSelecteds(
				selecteds?.filter(
					(sel) =>
						(sel[selectId as keyof T] as any) !==
						(row[selectId as keyof T] as any),
				) ?? [],
			);
		} else {
			setSelecteds([...(selecteds ?? []), row]);
		}
	};
	const selectAll = () => {
		if (selectedAll) {
			const deselect = data.map((ele) => ele[selectId as keyof T] as any);

			setSelecteds(
				selecteds?.filter(
					(ele) => !deselect.includes(ele[selectId as keyof T] as any),
				) ?? [],
			);
		} else {
			setSelecteds([...(selecteds ?? []), ...data]);
		}
	};

	useEffect(() => {
		const ids = selecteds?.map((ele) => ele[selectId as keyof T] as any) ?? [];
		setSelectedsIds(ids);
		setSelectedAll(
			data.reduce(
				(acc, row) => acc && ids.includes(row[selectId as keyof T] as any),
				true,
			) as any,
		);
	}, [selecteds, data]);

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<div className={className}>
			{!hideHeader ? (
				<div
					className={`table_header p_x_lg p_y_xs display_flex flex_gap_xl bg_neutral_500 br_sm m_b_md ${headerClassName}`}
				>
					{canSelect && (
						<CheckboxInput
							className="m_b_none flex_grow_0"
							checked={selectedAll}
							value=""
							label=""
							onChange={() => selectAll()}
						/>
					)}
					{cols?.map((col) => (
						<Typography
							scale="medium"
							weight="600"
							textColor="neutral_1000"
							key={col.label}
							className={`${
								col.weight !== 0 ? 'flex_basis_0' : ''
							} overflow_ellipsis flex_grow_${col.weight ?? 1} text_center ${
								col.hideMobile ? 'display_none_mobile' : ''
							} ${col.hideDesktop ? 'display_none_desktop' : ''}`}
						>
							{col.label}
						</Typography>
					))}
				</div>
			) : canSelect && data?.length ? (
				<CheckboxInput
					className="m_b_none flex_grow_0 m_b_md m_l_lg"
					checked={selectedAll}
					value=""
					label="Seleccionar todo"
					onChange={() => selectAll()}
				/>
			) : null}
			<div className="display_flex flex_col flex_gap_xs cursor_pointer">
				{data.map((row, i) => (
					<div key={row[selectId as keyof T] as any}>
						<ContComponent
							to={to ? to(row, i) : null}
							className={`table_row p_x_lg p_y_xs display_flex flex_gap_${
								dividers ? 'md' : 'xl'
							} ${onRowClick ? 'cursor_pointer' : ''} flex_align_center br_sm ${
								selectedsIds.includes(row[selectId as keyof T] as any)
									? 'bg_primary_100'
									: 'bg_neutral_0'
							} ${rowClassName}`}
							onClick={() => onRowClick?.(row, i)}
							onKeyDown={() => onRowClick?.(row, i)}
							role="button"
							tabIndex={0}
						>
							{canSelect && (
								<div
									tabIndex={0}
									role="button"
									onClick={(e) => {
										e.stopPropagation();
									}}
									onKeyUp={(e) => {
										e.stopPropagation();
									}}
								>
									<CheckboxInput
										className={`m_b_none flex_grow_0 ${
											!canSelectItem || canSelectItem(row, i)
												? ''
												: 'hide_checkbox'
										}`}
										checked={selectedsIds.includes(
											row[selectId as keyof T] as any,
										)}
										value=""
										label=""
										variant="big"
										onChange={() => select(row)}
									/>
								</div>
							)}
							{cols?.map((col, j) => {
								let component;
								if (col.render) {
									component = (
										<div
											key={col.label + j}
											className={`${
												col.weight !== 0 ? 'flex_basis_0' : ''
											} overflow_hidden flex_grow_${col.weight ?? 1} ${
												col.hideMobile ? 'display_none_mobile' : ''
											} ${col.hideDesktop ? 'display_none_desktop' : ''}`}
										>
											{col.render(row, i, BaseTypography)}
										</div>
									);
								} else {
									component = (
										<BaseTypography
											key={col.label + j}
											className={`${
												col.weight !== 0 ? 'flex_basis_0' : ''
											} overflow_ellipsis flex_grow_${col.weight ?? 1} ${
												col.hideMobile ? 'display_none_mobile' : ''
											} ${col.hideDesktop ? 'display_none_desktop' : ''}`}
										>
											{row[col.attribute]}
										</BaseTypography>
									);
								}

								return (
									<>
										{component}
										{dividers && cols.length - 1 > j ? (
											<div className="table_divider h_xxxl" key={`div${j}`} />
										) : null}
									</>
								);
							})}
						</ContComponent>
						{child ? <div>{child(row, i, BaseTypography)}</div> : null}
					</div>
				))}
			</div>
		</div>
	);
};

export default ListTable;
