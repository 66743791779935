/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, MouseEventHandler } from 'react';

interface TypographyProps extends React.HTMLAttributes<HTMLHeadingElement> {
	children: React.ReactNode;
	component?: React.ElementType;
	onClick?: MouseEventHandler<HTMLHeadingElement>;
	scaleMobile?:
		| ''
		| 'display'
		| 'heading1'
		| 'heading2'
		| 'heading3'
		| 'heading4'
		| 'large'
		| 'medium'
		| 'small'
		| 'xsmall'
		| 'xxsmall';
	weightMobile?: '' | '600' | '400';
	textColor?:
		| null
		| 'primary_25'
		| 'primary_50'
		| 'primary_100'
		| 'primary_200'
		| 'primary_300'
		| 'primary_400'
		| 'primary_500'
		| 'primary_600'
		| 'primary_gradient'
		| 'secondary_100'
		| 'secondary_400'
		| 'secondary_500'
		| 'secondary_600'
		| 'secondary_700'
		| 'secondary_800'
		| 'neutral_0'
		| 'neutral_100'
		| 'neutral_200'
		| 'neutral_300'
		| 'neutral_400'
		| 'neutral_500'
		| 'neutral_600'
		| 'neutral_700'
		| 'neutral_800'
		| 'neutral_900'
		| 'neutral_1000'
		| 'green_100'
		| 'green_200'
		| 'green_300'
		| 'green_400'
		| 'green_500'
		| 'red_100'
		| 'red_200'
		| 'red_300'
		| 'red_400'
		| 'red_500'
		| 'transparent';
	weight: '600' | '400';
	scale:
		| 'display'
		| 'heading1'
		| 'heading2'
		| 'heading3'
		| 'heading4'
		| 'large'
		| 'medium'
		| 'small'
		| 'xsmall'
		| 'xxsmall';
}

const Typography: FC<TypographyProps> = ({
	weight,
	scale,
	scaleMobile = '',
	weightMobile = '',
	children,
	component: C = 'div',
	className,
	onClick = null,
	textColor = null,
	...props
}) => (
	<C
		{...props}
		onClick={onClick}
		className={`text_${scale}_${weight} ${className} ${
			scaleMobile !== ''
				? `text_${scaleMobile}_${
						weightMobile !== '' ? weightMobile : weight
				  }_mobile`
				: ''
		} ${textColor ? `text_${textColor}` : ''}`}
	>
		{children}
	</C>
);

export default Typography;
