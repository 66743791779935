/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';

import { Button, Typography } from 'design_system/src';
import * as yup from 'yup';

import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';

// -----------------STATIC IMPORTS------------------- //
import '../../../ozoner.scss';
import { ReactComponent as Fail } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as Success } from 'design_system/src/static/icons/tick-circle.svg';
import { ReactComponent as ArrowUp } from 'design_system/src/static/icons/up-md.svg';
import { ReactComponent as ArrowDown } from 'design_system/src/static/icons/down-md.svg';

import { updateFinancialForm } from 'models/financial-form/controllers/financial-form.controller';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { FormStatus } from 'models/financial-form/enums/financial-form.enums';
import {
	validateModel,
	validationSections,
	validationSectionsValues,
} from './constants';
import { ozonerContext } from '../../OzonerContext';
import { PartnershipSegmentation } from './Segmentations';

const validationItem = (
	valid: boolean,
	validLabel: string,
	invalidLabel: string,
) => (
	<>
		{valid ? (
			<Success className="dim_lg text_green_300" />
		) : (
			<Fail className="dim_lg text_red_300" />
		)}
		<Typography
			scale="xsmall"
			weight="400"
			textColor={valid ? 'green_300' : 'red_300'}
			className="text_no_break"
		>
			{valid ? validLabel : invalidLabel}
		</Typography>
	</>
);

function PendingSection() {
	const { financialForm, ozoner, updateFormData } = useContext(ozonerContext);

	const [infoOpen, setInfoOpen] = useState(false);
	const [referencesOpen, setReferencesOpen] = useState(false);
	const [personalOpen, setPersonalOpen] = useState(false);
	const [financialOpen, setFinancialOpen] = useState(false);

	const [validationStatus, setValidationStatus] = useState<any>({
		[validationSections.financial]: {
			valid: true,
			errors: [],
		},
		[validationSections.info]: {
			valid: true,
			errors: [],
		},
		[validationSections.personal]: {
			valid: true,
			errors: [],
		},
		[validationSections.references]: {
			valid: true,
			errors: [],
		},
		isValid: false,
	});

	const validationDropdowns = [
		infoOpen,
		referencesOpen,
		personalOpen,
		financialOpen,
	];
	useEffect(() => {
		validateModel(ozoner, financialForm?.form).then((result) =>
			setValidationStatus(result),
		);
	}, [financialForm, ozoner]);

	const toogleValidationDropdowns = (id: number) => {
		switch (id) {
			case 0:
				setInfoOpen(!infoOpen);
				break;
			case 1:
				setReferencesOpen(!referencesOpen);
				break;
			case 2:
				setPersonalOpen(!personalOpen);
				break;
			case 3:
				setFinancialOpen(!financialOpen);
				break;

			default:
				break;
		}
	};

	const sendToValidate = () => {
		updateFinancialForm(financialForm.form._id, {
			status: FormStatus.inValidation,
			loanApplication: {
				rejectedReason: null,
				devolutionReason: null,
			},
		})
			.then((newForm) => {
				toasterNotification({
					msg: 'Solicitud Exitosa',
					style: 'light',
					toasterType: toasterOptions.success,
				});
				updateFormData(newForm);
			})
			.catch((err) => {
				commonErrorHandler(err);
			});
		handleEventGoogle(
			'BTN_Pb_request_inValidation',
			'Panda Solicitudes',
			'click in validation request button',
		);
	};

	return (
		<div
			id="validate-soli-ozoner"
			className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
		>
			<div className="display_flex flex_col flex_gap_xs">
				{Object.keys(validationSectionsValues).map((key, idx) => (
					<React.Fragment key={key}>
						<div className="display_flex flex_gap_xs flex_align_center">
							{validationItem(
								validationStatus[key].valid,
								`${
									validationSectionsValues[key as validationSections].title
								} completos`,
								`${
									validationSectionsValues[key as validationSections].title
								} incompletos`,
							)}

							{validationStatus[key].valid ? null : validationDropdowns[idx] ? (
								<ArrowUp
									className="dim_lg text_red_300 cursor_pointer"
									onClick={() => toogleValidationDropdowns(idx)}
								/>
							) : (
								<ArrowDown
									className="dim_lg text_red_300 cursor_pointer"
									onClick={() => toogleValidationDropdowns(idx)}
								/>
							)}
						</div>
						{validationDropdowns[idx] &&
							validationStatus[key].errors.map((error: string) => (
								<Typography
									scale="xxsmall"
									weight="400"
									textColor="red_300"
									className="text_no_break p_l_md"
									key={error}
								>
									{error}
								</Typography>
							))}
					</React.Fragment>
				))}
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						!!financialForm?.form?.loanApplication?.partnership,
						'Alianza asignada',
						'Alianza sin asignar',
					)}
				</div>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						!!financialForm?.form?.loanApplication?.sellResponsible,
						'Vendedor asignado',
						'Vendedor sin asignar',
					)}
				</div>
			</div>
			<Button
				scale="small"
				color="primary"
				onClick={sendToValidate}
				disabled={
					!validationStatus.isValid ||
					!financialForm?.form?.loanApplication?.partnership ||
					!financialForm?.form?.loanApplication?.sellResponsible
				}
			>
				Mandar a validación de crédito
			</Button>
		</div>
	);
}
export default PendingSection;
