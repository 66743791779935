import { Theme, toast } from 'react-toastify';
import { toasterOptions } from '../enums/toaster.enum';

interface IToasterOptionsParams {
	msg: string;
	toasterType: toasterOptions;
	style?: Theme | undefined;
	onOpenCallback?: any;
	onCloseCallback?: any;
}

export const toasterNotification = (options: IToasterOptionsParams) => {
	const {
		msg,
		toasterType,
		style = 'colored',
		onOpenCallback = null,
		onCloseCallback = null,
	} = options;
	switch (toasterType) {
		case toasterType:
			return toast[toasterType](msg, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
				theme: style,
				onOpen: onOpenCallback,
				onClose: onCloseCallback,
			});
		default:
			return toast(msg, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
				theme: style,
				onOpen: onOpenCallback,
				onClose: onCloseCallback,
			});
	}
};
