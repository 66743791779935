/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from 'react';

import { Button, Input, Typography } from 'design_system/src';

import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';

import '../../../ozoner.scss';

import {
	ContactMethodsEnum,
	ContactMethodsValues,
	FormStatus,
} from 'models/financial-form/enums/financial-form.enums';
import { updateFinancialForm } from 'models/financial-form/controllers/financial-form.controller';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { validationItem } from 'components/hocs/messages/messages';
import { ozonerContext } from '../../OzonerContext';

export default function ReachSection() {
	const { financialForm, updateFormData } = useContext(ozonerContext);

	const [loading, setLoading] = useState(false);

	const markReachedMethod = (reachedMethod: ContactMethodsEnum) => {
		if (financialForm?.form) {
			setLoading(true);
			handleEventGoogle(
				'BTN_Pb_request_reached_method',
				'Panda Solicitudes',
				'Assign a reach method',
			);
			updateFinancialForm(financialForm.form._id, {
				loanApplication: {
					contactMethod: reachedMethod,
				},
			})
				.then((newForm) => {
					toasterNotification({
						msg: 'Solicitud Exitosa',
						style: 'light',
						toasterType: toasterOptions.success,
					});
					updateFormData(newForm);
					setLoading(false);
				})
				.catch((err) => {
					commonErrorHandler(err);
					setLoading(false);
				});
		}
	};

	const markReached = () => {
		if (financialForm?.form) {
			setLoading(true);
			handleEventGoogle(
				'BTN_Pb_request_reached_success',
				'Panda Solicitudes',
				'click in reach request button',
			);
			updateFinancialForm(financialForm.form._id, {
				status: FormStatus.contacted,
			})
				.then((newForm) => {
					toasterNotification({
						msg: 'Solicitud Exitosa',
						style: 'light',
						toasterType: toasterOptions.success,
					});
					updateFormData(newForm);
					setLoading(false);
				})
				.catch((err) => {
					commonErrorHandler(err);
					setLoading(false);
				});
		}
	};

	return (
		<div
			id="actions-soli-ozoner"
			className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_xs"
		>
			<Typography scale="medium" weight="400" className="">
				Metodo de contacto
			</Typography>
			<Input
				title=""
				disabled={loading}
				name="reachedMethod"
				placeholder="Metodo de contacto"
				value={financialForm.form.loanApplication?.contactMethod}
				onChange={(e: any) => markReachedMethod(e.target.value)}
				className="w_100_per"
				type="select"
				options={Object.entries(ContactMethodsValues).map(([key, value]) => ({
					label: value,
					value: key,
				}))}
				variant="default"
			/>
			<div className="display_flex flex_col flex_gap_xs">
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						!!financialForm.form.loanApplication?.contactMethod,
						'Metodo contacto asignado',
						'Metodo contacto sin asignar',
					)}
				</div>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						!!financialForm.form.loanApplication?.sellResponsible,
						'Responsable ventas asignado',
						'Responsable ventas sin asignar',
					)}
				</div>
			</div>
			<Button
				scale="small"
				color="primary"
				disabled={
					loading ||
					!financialForm.form.loanApplication?.sellResponsible ||
					!financialForm.form.loanApplication?.contactMethod
				}
				onClick={markReached}
			>
				Ozoner respondio
			</Button>
		</div>
	);
}
