/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as Check } from '../../static/icons/checkmark.svg';
import { ReactComponent as Down } from '../../static/icons/down-sm.svg';
import { ReactComponent as Up } from '../../static/icons/up-sm.svg';
import { ReactComponent as Close } from '../../static/icons/close-sm.svg';

import './dateSelect.scss';
import { Button, Calendar, Input, Popup, Typography } from '../index';

interface ValueDate {
	initial: Date;
	last: Date;
	option: number;
}
interface DateSelectProps {
	extended?: false;
	value: ValueDate | null;
	setValue: Function;
	className?: string;
	disabled?: boolean;
	hasValue?: boolean;
	setHasValue?: Function;
	placeholder?: string;
	popUpClassName?: string;
}

interface LocalValue {
	initial: Date;
	last: Date | null;
	option: number;
}

const months = [
	'enero',
	'febrero',
	'marzo',
	'abril',
	'mayo',
	'junio',
	'julio',
	'agosto',
	'septiembre',
	'octubre',
	'noviembre',
	'diciembre',
];
const options = [
	'Hoy',
	'Ayer',
	'Últimos 7 días',
	'Últimos 14 días',
	'Últimos 30 días',
	'Esta semana',
	'Este mes',
	'El mes pasado',
];
const formatDate = (date: Date | null) =>
	date ? `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}` : '';

const DateSelect: FC<DateSelectProps> = ({
	value,
	setValue,
	className = '',
	popUpClassName = '',
	extended = false,
	disabled,
	placeholder,
	hasValue = true,
	setHasValue,
}) => {
	const [active, setActive] = useState<boolean>(false);

	const [label, setLabel] = useState<string>('');
	const [open, setOpen] = useState<boolean>(false);
	const [initDate] = useState<Date>(
		new Date(new Date().setDate(new Date().getDate() - 7)),
	);
	const [localValue, setLocalValue] = useState<LocalValue | null>(null);
	const [month, setMonth] = useState<Date>(new Date());
	const [day, setDay] = useState<Date>();
	const [finalDay, setFinalDay] = useState<Date | null>(null);
	const [hover, setHover] = useState<Date | null>();
	const [ini, setIni] = useState<string>('');
	const [las, setLas] = useState<string>('');
	const [show, setShow] = useState<boolean>(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const handleOutsideClick = (event: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as any)
		) {
			setActive(false);
			setOpen(false);
		} else {
			setActive(true);
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);
	const saveFecha = () => {
		setValue(localValue);
		setOpen(false);
	};
	useEffect(() => {
		if (value) {
			setLocalValue(value);
			setIni(formatDate(value.initial));
			setLas(formatDate(value.last));
			setDay(value.initial);
			setFinalDay(value.last);
		} else {
			setValue({ initial: initDate, last: new Date(), option: 2 });
			setMonth(new Date());
			setIni(formatDate(initDate));
			setLas(formatDate(new Date()));
			setDay(initDate);
			setFinalDay(new Date());
		}
	}, []);

	useEffect(() => {
		if (!value) {
			setValue({ initial: initDate, last: new Date(), option: 2 });
			setMonth(new Date());
			setIni(formatDate(initDate));
			setLas(formatDate(new Date()));
		} else {
			setLocalValue(value);
			setIni(formatDate(value.initial));
			setLas(formatDate(value.last));
		}
	}, [value]);

	useEffect(() => {
		if (
			(localValue?.last &&
				localValue?.initial.getMonth() !== localValue?.last.getMonth()) ||
			localValue?.initial.getMonth() !== month.getMonth()
		) {
			setShow(true);
		} else {
			setShow(false);
		}
	}, [localValue, month]);

	useEffect(() => {
		if (localValue) {
			setMonth(localValue.initial);
			setLabel(
				`${localValue.option < 0 ? '' : `${options[localValue.option]}:`} ${
					localValue.initial
						? `${localValue.initial.getDate()} de ${
								months[localValue.initial.getMonth()]
						  } ${
								localValue.initial.getFullYear() === new Date().getFullYear()
									? ''
									: `de ${localValue.initial.getFullYear()}`
						  }`
						: ''
				} - ${
					localValue.last
						? `${localValue.last.getDate()} de ${
								months[localValue.last.getMonth()]
						  } ${
								localValue.last.getFullYear() === new Date().getFullYear()
									? ''
									: `de ${localValue.last.getFullYear()}`
						  }`
						: ''
				}`,
			);
		}
	}, [localValue]);

	const setOtherMonth = (mon: Date) => {
		const m = new Date(mon);
		m.setMonth(m.getMonth() - 1);
		setMonth(m);
	};
	const selectOption = (opt: number) => {
		const val: any = { option: opt };
		const hoy = new Date();
		switch (opt) {
			case 0: // Hoy
				val.initial = hoy;
				val.last = hoy;
				break;
			case 1: {
				// Ayer
				const ayer = new Date();
				ayer.setDate(hoy.getDate() - 1);
				val.initial = ayer;
				val.last = ayer;
				break;
			}
			case 2: {
				// 7 dias
				const ayer = new Date();
				ayer.setDate(hoy.getDate() - 6);
				val.initial = ayer;
				val.last = hoy;
				break;
			}
			case 3: {
				// 14 dias
				const ayer = new Date();
				ayer.setDate(hoy.getDate() - 13);
				val.initial = ayer;
				val.last = hoy;
				break;
			}
			case 4: {
				// 30 dias
				const ayer = new Date();
				ayer.setDate(hoy.getDate() - 29);
				val.initial = ayer;
				val.last = hoy;
				break;
			}
			case 5: {
				// semana
				const d = hoy.getDay() - 1;
				const ayer = new Date();
				ayer.setDate(hoy.getDate() - (d < 0 ? 6 : d));
				val.initial = ayer;
				val.last = hoy;
				break;
			}
			case 6: {
				// Mes
				const ayer = new Date();
				ayer.setDate(1);
				val.initial = ayer;
				val.last = hoy;
				break;
			}
			case 7: {
				// Mes Pasado
				const ayer = new Date();
				ayer.setMonth(ayer.getMonth() - 1, 1);
				const final = new Date();
				final.setDate(0);
				val.initial = ayer;
				val.last = final;
				break;
			}
			default:
				break;
		}
		setLocalValue(val);
		setIni(formatDate(val.initial));
		setLas(formatDate(val.last));
	};
	useEffect(() => {
		if (day) {
			const v = {
				initial: day,
				last: finalDay,
				option: -1,
			};
			setLocalValue(v);
			setIni(formatDate(v.initial || null));
			setLas(formatDate(v.last || null));
		}
	}, [day, finalDay]);

	const customDate = (text: string, initial: boolean) => {
		const separate = text.split('-').map((ele: string) => parseInt(ele, 10));
		const date = new Date(separate[2], separate[1] - 1, separate[0]);
		if (separate.length === 3) {
			if (initial) {
				setDay(date);
			} else {
				setFinalDay(date);
			}
		} else {
			console.log('malformated');
		}
	};
	return (
		<div
			ref={dropdownRef}
			className={`pos_relative dso_select_cont ${className} ${
				disabled ? 'disabled' : ''
			} ${active ? 'active' : ''}`}
		>
			<label className="dso_select">
				<Typography
					scale="small"
					weight="600"
					textColor="neutral_900"
					className="dso_select_placeholder"
				>
					{placeholder ?? 'Seleccione fechas'}
				</Typography>
				{hasValue ? (
					<div className="dso_option_tag" key={`tag_${label}`}>
						<Typography
							scale="xsmall"
							weight="600"
							textColor="primary_300"
							className="dso_option_tag_text"
						>
							{label}
						</Typography>
						{setHasValue ? (
							<Close
								className="dso_option_tag_close dim_sm text_primary_300 cursor_pointer"
								role="button"
								tabIndex={-1}
								onClick={(e) => {
									e.stopPropagation();
									setHasValue(false);
								}}
								onKeyDown={(e) => {
									e.stopPropagation();
									setHasValue(false);
								}}
							/>
						) : null}
					</div>
				) : null}
			</label>
			<div
				className="dso_select_arrow cursor_pointer display_flex"
				onClick={(e) => {
					setOpen(!open);
					e.stopPropagation();
				}}
				role="button"
				tabIndex={-1}
				onKeyDown={() => setOpen(!open)}
			>
				{open ? <Up className="dim_lg" /> : <Down className="dim_lg" />}
			</div>
			<Popup
				open={open}
				setOpen={setOpen}
				className={`br_xs overflow_hidden dim_fit bg_neutral_0 popup_date_select ${
					extended ? 'extended' : ''
				} ${popUpClassName}`}
			>
				<div className="display_flex">
					<div>
						<div className="display_flex">
							<Input
								title="Fecha de inicio"
								placeholder="Fecha inicio"
								name="fechaI"
								type="text"
								className="p_md flex_grow_1"
								value={ini}
								onChange={(e: React.FormEvent<HTMLInputElement>) =>
									setIni((e.target as HTMLInputElement).value)
								}
								onBlur={(e: React.FormEvent<HTMLInputElement>) =>
									customDate((e.target as HTMLInputElement).value, true)
								}
							/>
							<Input
								title="Fecha de final"
								placeholder="Fecha final"
								name="fechaF"
								type="text"
								className="p_md flex_grow_1"
								value={las}
								onChange={(e: React.FormEvent<HTMLInputElement>) =>
									setLas((e.target as HTMLInputElement).value)
								}
								onBlur={(e: React.FormEvent<HTMLInputElement>) =>
									customDate((e.target as HTMLInputElement).value, false)
								}
							/>
						</div>
						<div className="display_flex">
							<Calendar
								day={localValue ? localValue.initial : null}
								setDay={setDay}
								month={month}
								setMonth={(m: Date) =>
									!show && m.getMonth() > month.getMonth()
										? setShow(true)
										: setMonth(m)
								}
								finalDay={localValue?.last}
								setFinalDay={setFinalDay}
								hover={hover}
								setHover={setHover}
								range
							/>

							{show && (
								<Calendar
									day={localValue ? localValue.initial : null}
									setDay={setDay}
									month={(() => {
										const a = new Date(month);
										a.setMonth(month.getMonth() + 1);
										return a;
									})()}
									setMonth={setOtherMonth}
									finalDay={localValue?.last}
									setFinalDay={setFinalDay}
									hover={hover}
									setHover={setHover}
									range
								/>
							)}
						</div>
						<div className="p_md w_100_per">
							<Button
								scale="small"
								className="m_b_lg w_100_per"
								onClick={saveFecha}
							>
								Actualizar
							</Button>
						</div>
					</div>

					<div className="p_md bg_neutral_100">
						{options.map((opt: string, i) => (
							<Typography
								scale="medium"
								weight="400"
								key={opt}
								onClick={() => selectOption(i)}
								className={`p_xs m_b_xs option_date ${
									i === localValue?.option ? 'selected' : ''
								}`}
							>
								<Check className="check_icon text_primary_300 dim_lg m_xs" />
								{opt}
							</Typography>
						))}
						{localValue && localValue.option < 0 && (
							<Typography
								scale="medium"
								weight="400"
								className="p_xs m_b_xs option_date selected"
							>
								<Check className="check_icon text_primary_300 dim_lg m_xs" />
								Personalizado
							</Typography>
						)}
					</div>
				</div>
			</Popup>
		</div>
	);
};

export default DateSelect;
