/* eslint-disable no-unused-vars */
import * as React from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { ReactElement } from 'react';
import { ReactComponent as Close } from '../../static/icons/close-lg.svg';
import Button from '../Button/Button';
import './modal.scss';

type ModalAction = {
	label: string;
	action: any;
	isSubmit?: boolean;
	color:
		| 'primary'
		| 'secondary'
		| 'neutral'
		| 'green'
		| 'red'
		| 'gradient'
		| 'grey';
	disabled?: boolean;
	loading?: boolean;
};

type ModalContentChild = {
	title?: null;
	subtitle?: null;
	children: ReactElement<any, any>;
};
type ModalContentTitle = {
	title: ReactElement<any, any>;
	subtitle: ReactElement<any, any>;
	children?: null;
};
type ModalContent = ModalContentChild | ModalContentTitle;

type ModalOptionsDefault = {
	onConfirmationCallBack: any;
	copyConfirm?: string;
	copyReject?: string;
	actions?: null;
};
type ModalOptionsCustom = {
	onConfirmationCallBack?: null;
	copyConfirm?: null;
	copyReject?: null;
	actions: ModalAction[];
};
type ModalOptions = ModalOptionsDefault | ModalOptionsCustom;

type ModalConfirmationProps = {
	openConfirmation: boolean;
	handleClose: any;
	icon?: React.ReactElement<any, any> | null;
	isConfirmationLoading?: boolean;
	widthDesktop?: number;
	heightDesktop?: number;
};

type ModalProps = ModalConfirmationProps & ModalContent & ModalOptions;

const ModalConfirmation: React.FC<ModalProps> = ({
	openConfirmation,
	handleClose,
	isConfirmationLoading,
	title,
	subtitle,
	children,
	icon,
	onConfirmationCallBack,
	copyConfirm = 'Si',
	copyReject = 'No',
	widthDesktop = 25,
	heightDesktop,
	actions,
}) => (
	<Modal
		aria-labelledby="transition-modal-title"
		aria-describedby="transition-modal-description"
		open={openConfirmation}
		onClose={handleClose}
		closeAfterTransition
		className="container_modal"
	>
		<Fade in={openConfirmation}>
			<div
				className={`flex_content_col dso_modal center w_90_vp_mobile w_${widthDesktop}_vp_desktop h_${heightDesktop}_vp_desktop`}
			>
				<div className="flex_header p_t_lg p_x_md p_b_xs display_flex flex_justify_end">
					{icon && <div className="display_flex dso_modal_icon">{icon}</div>}

					<Close className="dim_md cursor_pointer" onClick={handleClose} />
				</div>
				<div className="flex_body">
					{children ?? (
						<>
							{title || <> </>}
							<br />
							{subtitle || <> </>}
						</>
					)}
				</div>
				<div className="shadow_hard_inv flex_footer display_flex flex_center_desktop flex_col_mobile flex_gap_lg">
					{actions ? (
						actions.map((action) => (
							<Button
								scale="small"
								variant="principal"
								color={action.color}
								className="flex_basis_0_desktop flex_grow_1_desktop w_100_per_mobile"
								onClick={action.action}
								type={action.isSubmit ? 'submit' : 'button'}
								disabled={action.disabled}
								loading={action.loading}
								key={`action-${action.label}`}
							>
								{action.label}
							</Button>
						))
					) : (
						<>
							<Button
								scale="small"
								variant="principal"
								className="flex_basis_0_desktop flex_grow_1_desktop w_100_per_mobile"
								onClick={handleClose}
								color="grey"
							>
								{copyReject}
							</Button>
							<Button
								scale="small"
								variant="principal"
								className="flex_basis_0_desktop flex_grow_1_desktop w_100_per_mobile"
								onClick={onConfirmationCallBack}
							>
								{copyConfirm}
							</Button>
						</>
					)}
				</div>
			</div>
		</Fade>
	</Modal>
);

export default ModalConfirmation;
