import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
	Routes,
	Route,
	BrowserRouter,
	Navigate,
	useNavigate,
} from 'react-router-dom';
import Auth from 'views/auth/Auth';
import Recover from 'views/recover/Recover';
import Layout from 'components/layouts/main/Layout';
import Inventory from 'views/inventory/Inventory';
import Dashboard from 'views/dashboard/Dashboard';
import DetailsInventory from 'views/inventory/details/DetailsInventory';
import Ozonio from 'views/ozonios/Ozonio';
import Ozocio from 'views/ozocios/Ozocio';
import Devices from 'views/devices/Devices';
import DetailsOzonio from 'views/ozonios/DetailsOzonio';
import { getAllAccess, getUser } from 'localStorage/authStorage';
import Solicitudes from 'views/solicitudes/Solicitudes';
import DetailsOzoner from 'views/ozoner/details/DetailsOzoner';
import { RouteDetail } from 'views/repossessions/routes/RouteDetail';
import { Repossessions } from 'views/repossessions/Repossessions';
import DetailsOzocio from 'views/ozocios/DetailsOzocio';
import MobileLayout from 'components/layouts/mobile/MobileLayout';
import { RoutesMobile } from 'views/routesMobile/RoutesMobile';
import { useDevice } from 'design_system/src';
import Notifications from 'views/notifications/Notifications';
import { RouteDetailMobile } from 'views/routesMobile/RouteDetailMobile';
import Loans from 'views/loan/Loan';
import { Roles } from 'models/ozonio/enums/ozonio.enum';
import { OzonerContextProvider } from 'views/ozoner/details/OzonerContext';

function RequireAuth({ children }: { children: JSX.Element }) {
	if (!getAllAccess()) {
		return <Navigate to="/auth" />;
	}
	return children;
}
function RequireDevice({
	mobile,
	children,
}: {
	mobile: boolean;
	children: JSX.Element;
}) {
	const { isMobile } = useDevice();
	const navigate = useNavigate();
	const [firstUpdate, setFirstUpdate] = useState(true);

	useEffect(() => {
		if (firstUpdate) {
			setFirstUpdate(false);
		} else if (!!mobile !== !!isMobile) {
			if (isMobile) navigate('/routes');
			else navigate('/');
		}
	}, [isMobile]);

	return children;
}
function RequireRole({
	children,
	authRoles,
}: {
	children: JSX.Element;
	authRoles: Roles[] | undefined;
}) {
	const user = getUser();
	if (authRoles && (!user || !authRoles.includes(user.role as Roles))) {
		return <Navigate to="/" />;
	}
	return children;
}

const layoutRoutes = [
	{
		path: '/',
		element: <Dashboard />,
	},
	{
		path: '/loans',
		element: <Loans />,

		roles: [
			Roles.ADMIN,
			Roles.CREDIT_ANALYST,
			Roles.DEVELOPER,
			Roles.COLLECTOR_AGENT,
		],
	},
	{
		path: '/solicitudes',
		element: <Solicitudes />,
		roles: [
			Roles.ADMIN,
			Roles.SELLER_CHIEF,
			Roles.SELLER,
			Roles.CREDIT_ANALYST,
			Roles.DEVELOPER,
			Roles.COLLECTOR_AGENT,
		],
	},
	{
		path: '/inventory',
		element: <Inventory />,
		roles: [
			Roles.ADMIN,
			Roles.RECUPERATOR,
			Roles.SELLER_CHIEF,
			Roles.SELLER,
			Roles.CREDIT_ANALYST,
			Roles.DEVELOPER,
			Roles.COLLECTOR_AGENT,
		],
	},
	{
		path: '/inventory/:internalid',
		element: <DetailsInventory />,
		roles: [
			Roles.ADMIN,
			Roles.RECUPERATOR,
			Roles.SELLER_CHIEF,
			Roles.SELLER,
			Roles.CREDIT_ANALYST,
			Roles.DEVELOPER,
			Roles.COLLECTOR_AGENT,
		],
	},
	{
		path: '/ozocio',
		element: <Ozocio />,
		roles: [
			Roles.ADMIN,
			Roles.RECUPERATOR,
			Roles.SELLER_CHIEF,
			Roles.SELLER,
			Roles.CREDIT_ANALYST,
		],
	},
	{
		path: '/ozocio/:_id',
		element: <DetailsOzocio />,
		roles: [
			Roles.ADMIN,
			Roles.RECUPERATOR,
			Roles.SELLER_CHIEF,
			Roles.SELLER,
			Roles.CREDIT_ANALYST,
		],
	},
	{
		path: '/ozoner/:_id',
		element: (
			<OzonerContextProvider>
				<DetailsOzoner />
			</OzonerContextProvider>
		),
		roles: [
			Roles.ADMIN,
			Roles.RECUPERATOR,
			Roles.SELLER_CHIEF,
			Roles.SELLER,
			Roles.CREDIT_ANALYST,
			Roles.DEVELOPER,
			Roles.COLLECTOR_AGENT,
		],
	},
	{
		path: '/ozonio',
		element: <Ozonio />,
		roles: [Roles.ADMIN, Roles.SELLER_CHIEF, Roles.DEVELOPER],
	},
	{
		path: '/ozonio/:_id',
		element: <DetailsOzonio />,
		roles: [Roles.ADMIN, Roles.SELLER_CHIEF, Roles.DEVELOPER],
	},
	{
		path: '/recovery',
		element: <Repossessions />,
		roles: [
			Roles.ADMIN,
			Roles.RECUPERATOR,
			Roles.DEVELOPER,
			Roles.COLLECTOR_AGENT,
		],
	},
	{
		path: '/route/:_id',
		element: <RouteDetail />,
		roles: [
			Roles.ADMIN,
			Roles.RECUPERATOR,
			Roles.DEVELOPER,
			Roles.COLLECTOR_AGENT,
		],
	},
	{
		path: '/notifications',
		element: <Notifications />,
	},
	{
		path: '/devices',
		element: <Devices />,
	},
	{
		path: '/routes',
		element: <RoutesMobile />,
		isMobile: true,
	},
	{
		path: '/routes/:_id',
		element: <RouteDetailMobile />,
		isMobile: true,
	},
];

function RootRouter() {
	const { isMobile } = useDevice();

	return (
		<BrowserRouter>
			<CssBaseline />

			<Routes>
				<Route path="auth" element={<Auth />} />
				<Route path="recover" element={<Recover />} />
				<Route
					element={
						<RequireAuth>
							<RequireDevice mobile={false}>
								<Layout />
							</RequireDevice>
						</RequireAuth>
					}
				>
					{layoutRoutes
						.filter((route) => !route.isMobile)
						.map((route) => (
							<Route
								key={route.path}
								path={route.path}
								element={
									<RequireRole authRoles={route.roles}>
										{route.element}
									</RequireRole>
								}
							/>
						))}
				</Route>
				<Route
					element={
						<RequireAuth>
							<RequireDevice mobile>
								<MobileLayout />
							</RequireDevice>
						</RequireAuth>
					}
				>
					{layoutRoutes
						.filter((route) => route.isMobile)
						.map((route) => (
							<Route
								key={route.path}
								path={route.path}
								element={
									<RequireRole authRoles={route.roles}>
										{route.element}
									</RequireRole>
								}
							/>
						))}
				</Route>
			</Routes>
		</BrowserRouter>
	);
}
export default RootRouter;
