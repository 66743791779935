import { axiosErrorHandler, get } from './shared/helpers/axiosInstance';

const formatDate = (d: Date) =>
	`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

export const fetchActiveUsers = async () => {
	try {
		const url = 'v1/dashboard/activeUsers';
		const res = await get(url);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const fetchFirstPaymentDefault = async () => {
	try {
		const url = 'v1/dashboard/firstPaymentDefault';
		const res = await get(url);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const fetchDashboardVehicles = async (status: string, dates: any) => {
	try {
		if (dates) {
			let url = 'v1/dashboard/getVehiclesByStatus';
			url = `${url}?status=${status}&startDate=${formatDate(
				dates.initial,
			)}&endDate=${formatDate(dates.last)}`;
			const res = await get(url);
			return res.data;
		}
		return null;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const fetchDashboardInventory = async () => {
	try {
		const url = 'v1/dashboard/getInventory';
		const res = await get(url);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const fetchDashboardCredits = async () => {
	try {
		const url = 'v1/dashboard/getCredits';
		const res = await get(url);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const fetchDashboardCreditsTime = async (status: string, dates: any) => {
	try {
		if (dates) {
			let url = 'v1/dashboard/getCreditsByStatus';
			url = `${url}?status=${status}&startDate=${formatDate(
				dates.initial,
			)}&endDate=${formatDate(dates.last)}`;
			const res = await get(url);
			return res.data;
		}
		return null;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const fetchInventoryMaintenance = async () => {
	try {
		const url = 'v1/dashboard/getInventoryMaintenance';
		const res = await get(url);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const fetchPaymentAnalysis = async (dates: any) => {
	try {
		if (dates) {
			let url = 'v1/dashboard/getPaymentsAnalysis';
			url = `${url}?startDate=${formatDate(dates.initial)}&endDate=${formatDate(
				dates.last,
			)}`;
			const res = await get(url);
			return res.data;
		}
		return null;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
