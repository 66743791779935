/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */

import _ from 'lodash';
import React, { FC } from 'react';
import DateInput from './DateInput/DateInput';
import './input.scss';
import Select from './Select/Select';
import CheckboxInput from './CheckboxInput/CheckboxInput';
import Counter from './Counter/Counter';
import ShortDateInput from './ShortDateInput/ShortDateInput';

interface BasicInputProps {
	className?: string;
	title: string;
	subtitle?: string;
	icon?: React.ReactElement<any, any> | null;
	error?: boolean;
	titleClassName?: string;
}
interface NormalInputProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	type:
		| 'color'
		| 'date'
		| 'short-date'
		| 'datetime-local'
		| 'email'
		| 'file'
		| 'month'
		| 'number'
		| 'password'
		| 'tel'
		| 'text'
		| 'time'
		| 'url'
		| 'textarea'
		| 'week';
	options?: null;
	labels?: false;
	name: string;
	inputClassName?: string;
}
interface CounterInputProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	type: 'counter';
	options?: null;
	labels?: false;
	name: string;
	inputClassName?: string;
	min?: number;
	max?: number;
}
interface SelectInputProps {
	type: 'select';
	name: string;
	value: any;
	onChange?: any;
	onBlur?: any;
	placeholder?: string;
	options: any[];
	variant?: 'default' | 'checkbox' | 'button';
	disabled?: boolean;
	error?: any;
	multiple?: boolean;
	setFilter?: any;
	labels?: false;
	inputClassName?: string;
}
interface OptionsInputProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	type: 'checkbox' | 'radio';
	options: any[];
	labels?: false;
	name: string;
	inputClassName?: string;
}
interface SliderInputProps {
	type: 'slider';
	options?: null;
	labels?: boolean;
	value: boolean;
	name: string;
	onChange: Function;
	inputClassName?: string;
}

const inputs: FC<
	| NormalInputProps
	| OptionsInputProps
	| SelectInputProps
	| SliderInputProps
	| CounterInputProps
> = ({
	type = 'text',
	options,
	value,
	labels = false,
	inputClassName = '',
	...props
}) => {
	if (type === 'date') {
		return (
			<DateInput
				className={` ${inputClassName}`}
				value={value}
				{...(props as React.DetailedHTMLProps<
					React.InputHTMLAttributes<HTMLInputElement>,
					HTMLInputElement
				>)}
			/>
		);
	}
	if (type === 'short-date') {
		return (
			<ShortDateInput
				className={` ${inputClassName}`}
				value={value}
				{...(props as React.DetailedHTMLProps<
					React.InputHTMLAttributes<HTMLInputElement>,
					HTMLInputElement
				>)}
			/>
		);
	}
	if (type === 'select' && options) {
		return (
			<div className="dso_input_select_cont">
				<Select
					className={`w_100_per bg_neutral_0 ${inputClassName}`}
					options={options}
					value={value}
					{...(props as any)}
				/>
			</div>
		);
	}
	if (type === 'counter') {
		return (
			<Counter
				className={`w_100_per ${inputClassName}`}
				value={value}
				{...(props as CounterInputProps)}
			/>
		);
	}
	if (type === 'checkbox' && options) {
		return (
			<div className="dso_input_checkbox_group">
				{options.map((ele: string | { value: string; label: string }) => {
					let label = '';
					let val = '';
					if (_.isObject(ele)) {
						label = ele.label;
						val = ele.value;
					} else {
						label = ele;
						val = ele;
					}
					return (
						//
						<CheckboxInput
							key={`${props.name}-${label}`}
							label={label}
							value={val}
							checked={(value as string[])?.includes(val)}
							{...(props as React.DetailedHTMLProps<
								React.InputHTMLAttributes<HTMLInputElement>,
								HTMLInputElement
							>)}
						/>
					);
				})}
			</div>
		);
	}
	if (type === 'radio' && options)
		return (
			<div className="dso_input_radio_group">
				{options.map((ele: string | { value: string; label: string }) => {
					let label = '';
					let val = '';
					if (_.isObject(ele)) {
						label = ele.label;
						val = ele.value;
					} else {
						label = ele;
						val = ele;
					}
					return (
						//
						<label
							className={`dso_input_radio_cont text_small_600 ${inputClassName}`}
							key={`${props.name}-${label}`}
						>
							{label}
							<input
								type="radio"
								checked={value === val}
								value={val}
								{...(props as React.DetailedHTMLProps<
									React.InputHTMLAttributes<HTMLInputElement>,
									HTMLInputElement
								>)}
							/>
							<span className="dso_input_radio center_y">
								<span className="dso_input_radio_icon center" />
							</span>
						</label>
					);
				})}
			</div>
		);
	if (type === 'slider') {
		const change = (props as SliderInputProps).onChange as Function;
		return (
			<label
				className={`dso_input_slider_cont text_small_600 ${
					labels ? 'labels' : ''
				} ${inputClassName}`}
			>
				<input
					type="radio"
					className="true"
					checked={value as boolean}
					onChange={() => change({ target: { name: props.name, value: true } })}
				/>
				<input
					type="radio"
					className="false"
					checked={!value as boolean}
					onChange={() =>
						change({ target: { name: props.name, value: false } })
					}
				/>
				<div className="dso_input_slider shadow_soft_inv">
					<div className="dso_input_slider_ball" />
					<div className="dso_input_slider_label center no">No</div>
					<div className="dso_input_slider_label center si">Si</div>
				</div>
			</label>
		);
	}
	if (type === 'textarea') {
		return (
			<textarea
				className={`dso_input ${inputClassName}`}
				value={value as string | number | readonly string[]}
				{...(props as React.DetailedHTMLProps<
					React.TextareaHTMLAttributes<HTMLTextAreaElement>,
					HTMLTextAreaElement
				>)}
			/>
		);
	}
	return (
		<input
			type={type}
			className={`dso_input ${inputClassName}`}
			value={value as string | number | readonly string[]}
			{...(props as React.DetailedHTMLProps<
				React.InputHTMLAttributes<HTMLInputElement>,
				HTMLInputElement
			>)}
		/>
	);
};

const Input: FC<
	BasicInputProps &
		(
			| NormalInputProps
			| OptionsInputProps
			| SelectInputProps
			| SliderInputProps
			| CounterInputProps
		)
> = ({
	className = '',
	icon = null,
	subtitle = '',
	error = '',
	title,
	titleClassName = '',
	...props
}) => (
	<div
		className={`dso_input_cont ${className} ${error ? 'dso_input_error' : ''}`}
	>
		<div className="dso_input_title">
			{icon ? <div className="dso_input_img">{icon}</div> : null}
			<div className={`${titleClassName} dso_input_text`}>{title}</div>
		</div>
		{inputs(props)}
		<div className="dso_input_subtitle">
			{subtitle && !error && subtitle !== '' ? '*' : ''}
			{subtitle}
		</div>
	</div>
);

export default Input;
