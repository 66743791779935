import {
	get,
	put,
	post,
	deletes,
	axiosErrorHandler,
} from 'models/shared/helpers/axiosInstance';
import { PaginatedDocuments } from 'models/shared/types/pagination';
import moment from 'moment';
import {
	InterestPointCreate,
	Repossession,
} from '../interfaces/repossession.interface';
import { Recovery, RecoveryPopulated } from '../interfaces/recovery.interface';
import { Route, RouteEdit } from '../interfaces/route.interface';

export const FindAllRepossessions = async (
	sort?: string,
	page?: number,
	limit?: number,
	query?: string,
	policy?: string,
): Promise<PaginatedDocuments<Repossession>> => {
	try {
		const res = await get<PaginatedDocuments<Repossession>>(
			`/v1/repossession?page=${page || 1}&limit=${limit || 30}${
				query ? `&query=${query}` : ''
			}${sort ? `&sort=${sort}` : ''}${policy ? `&policy=${policy}` : ''}`,
		);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const findRepossessionByLoan = async (
	id: string,
): Promise<Repossession[]> => {
	try {
		const res = await get<Repossession[]>(`/v1/repossession/loan/${id}`);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const FetchGeofences = async (): Promise<any> => {
	try {
		const res = await get<any>('/v1/geofence');
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const FetchRepossessionsGPS = async (
	sort?: string,
	page?: number,
	limit?: number,
	query?: string,
	policy?: string,
	status?: string,
): Promise<PaginatedDocuments<Repossession> & { totals: any }> => {
	try {
		const res = await get<PaginatedDocuments<Repossession> & { totals: any }>(
			`/v1/repossession/gpsList?page=${page || 1}&limit=${limit || 30}${
				query ? `&query=${query}` : ''
			}${sort ? `&sort=${sort}` : ''}${policy ? `&policy=${policy}` : ''}${
				status ? `&status=${status}` : ''
			}`,
		);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const FetchRepossession = async (id: string): Promise<Repossession> => {
	try {
		const res = await get<Repossession>(`/v1/repossession/${id}`);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const CreateCreditRepossession = async (id: string): Promise<any> => {
	try {
		const res = await post('/v1/repossession', {
			creditId: id,
		});
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const CreateCreditRepossessionDevolution = async (
	id: string,
	addressData: { cp: string; street: string; extNumber: string },
): Promise<any> => {
	try {
		const res = await post('/v1/repossession/devolution', {
			creditId: id,
			...addressData,
		});
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const EditRepossession = async (id: string, data: any): Promise<any> => {
	try {
		const res = await put(`/v1/repossession/${id}`, data);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const createInterestPoint = async (
	vehicleId: string,
	data: InterestPointCreate,
): Promise<any> => {
	try {
		const res = await post<any>(
			`/v1/repossession/interestPoint/${vehicleId}`,
			data,
		);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const FetchRoutes = async (
	dates?: { start: Date; end: Date },
	teamLeader?: string,
): Promise<any> => {
	try {
		const res = await get<any>(
			// eslint-disable-next-line quotes
			`/v1/route?${
				dates
					? `dateEstimateMin=${moment(dates.start).format(
							'YYYY-MM-DD',
					  )}&dateEstimateMax=${moment(dates.end).format('YYYY-MM-DD')}`
					: ''
			}${teamLeader && teamLeader !== '' ? `&teamLeader=${teamLeader}` : ''}`,
		);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const FetchRoute = async (id: string): Promise<any> => {
	try {
		const res = await get<any>(
			// eslint-disable-next-line quotes
			`/v1/route/${id}`,
		);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const FetchRecovery = async (id: string): Promise<Recovery> => {
	try {
		const res = await get<Recovery>(`/v1/recovery?${id}`);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const FetchRecoveries = async (
	idRoute: string,
): Promise<PaginatedDocuments<RecoveryPopulated>> => {
	try {
		const res = await get<PaginatedDocuments<RecoveryPopulated>>(
			`/v1/recovery?route=${idRoute}`,
		);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const editRecovery = async (id: string, data: any): Promise<any> => {
	try {
		const res = await put<any>(`/v1/recovery/${id}`, data);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const addRoute = async (data: RouteEdit): Promise<any> => {
	try {
		const res = await post<any>('/v1/route', data);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const editRoute = async (id: string, data: RouteEdit): Promise<any> => {
	try {
		const res = await put<any>(`/v1/route/${id}`, data);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const sendRouteSlack = async (ruta: Route): Promise<any> => {
	try {
		const res = await post<any>(`/v1/route/${ruta._id}/sendRoute`, {
			user: ruta.teamLeader._id,
		});
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
