import React, { useContext } from 'react';
import { LightTooltip } from 'components/hocs/tooltip/Tooltip';
import { Typography } from 'design_system/src';
import { FormStatusValues } from 'models/financial-form/enums/financial-form.enums';
import { LoanStatus, LoanStatusValues } from 'models/loan/enums/loan.enum';
import { AvailableForm, ozonerContext } from '../OzonerContext';

export const LoanOption = (data: AvailableForm, onClick: any = () => {}) => {
	const { handleDetailsVehicle } = useContext(ozonerContext);
	if (data) {
		const { vehicle, loan, form, vehicleAvailable } = data;
		return (
			<div
				className="p_md display_flex flex_gap_md border_solid border_1 border_neutral_400 flex_align_center cursor_pointer"
				role="button"
				tabIndex={0}
				onClick={onClick}
				onKeyDown={onClick}
				key={form._id}
			>
				{vehicle ? (
					<>
						<div className="br_xs overflow_hidden foto_moto">
							<img
								src={
									vehicle.images[0]
										? vehicle.images[0].url
										: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
								}
								alt="moto detail"
							/>
						</div>
						<div className="flex_grow_1 flex_center_col">
							<Typography
								scale="small"
								weight="600"
								className="inventory_link cursor_pointer"
								onClick={(e) => handleDetailsVehicle(e, vehicle.internalId)}
							>
								{vehicle.internalId}
							</Typography>

							<Typography scale="xxsmall" weight="400" className="m_b_xxs">
								{vehicle.brand && vehicle.model
									? `${vehicle.brand.name} ${vehicle.model.name} ${
											vehicle.suffix ?? ''
									  }`
									: 'no definido'}
							</Typography>
							{!vehicleAvailable && (
								<div className="br_xs p_x_md p_y_xxs  bg_neutral_600_nocontrast">
									<Typography
										scale="small"
										weight="600"
										textColor="neutral_0"
										className="text_center"
									>
										No disponible
									</Typography>
								</div>
							)}
						</div>
					</>
				) : (
					<>
						<div className="br_xs overflow_hidden foto_moto">
							<img
								src={`${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`}
								alt="moto detail"
							/>
						</div>
						<div className="flex_grow_1 flex_center_col">
							<Typography scale="small" weight="600">
								Sin Moto
							</Typography>

							<div className="br_xs p_x_md p_y_xxs  bg_neutral_600_nocontrast">
								<Typography
									scale="small"
									weight="600"
									textColor="neutral_0"
									className="text_center"
								>
									Por Asignar
								</Typography>
							</div>
						</div>
					</>
				)}
				<div className="h_xxxl bg_neutral_400 w_xxxs" />
				{loan ? (
					<div className="flex_grow_1">
						<Typography
							scale="medium"
							weight="600"
							textColor="neutral_900"
							className="m_b_xs text_no_break"
						>
							Crédito {loan.code}
						</Typography>
						<LightTooltip
							title={
								LoanStatusValues[loan?.status as keyof typeof LoanStatus]
									?.tooltip ?? loan?.status
							}
						>
							<div>
								<Typography
									scale="xsmall"
									weight="400"
									textColor={LoanStatusValues[loan.status]?.color as any}
									className="m_b_xs"
								>
									{LoanStatusValues[loan.status]?.title ?? loan.status}
								</Typography>
							</div>
						</LightTooltip>
					</div>
				) : (
					<div className="flex_grow_1">
						<Typography
							scale="medium"
							weight="600"
							textColor="neutral_900"
							className="m_b_xs text_no_break"
						>
							Solicitud de crédito
						</Typography>
						<LightTooltip
							title={
								FormStatusValues[form.status]?.tooltip ??
								FormStatusValues[form.status]
							}
						>
							<div>
								<Typography
									scale="xsmall"
									weight="400"
									textColor={FormStatusValues[form.status]?.color as any}
									className="m_b_xs"
								>
									{FormStatusValues[form.status]?.title}
								</Typography>
							</div>
						</LightTooltip>
					</div>
				)}
				<div className="dim_lg" />
			</div>
		);
	}
	return null;
};
