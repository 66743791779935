/* eslint-disable dot-notation */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Typography } from 'design_system/src';

import { ReactComponent as Back } from 'design_system/src/static/icons/back.svg';
import { ReactComponent as Edit } from 'design_system/src/static/icons/edit-text.svg';

import Swal from 'sweetalert2';
import { Grid } from '@mui/material';
import profileDefaultOzonio from 'static/images/ozonio_default.png';

import { toasterOptions } from 'models/shared/enums/toaster.enum';
import {
	findOzonioById,
	restorePassword,
	updateImageOzonio,
	updateOzonio,
} from 'models/ozonio/controllers/ozonios.controller';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import EditOzonio from './EditOzonio';

function DetailsOzonio() {
	const [ozonio, setOzonio] = useState<any | null>();
	const [loading, setloading] = useState<boolean>(false);
	const [open, setOpen] = useState(false);
	const [result, setResult] = useState<any>();
	const [loadingActive, setLoadingActive] = useState(false);
	const navigate = useNavigate();
	const { _id } = useParams();

	const fetchOzonio = () => {
		if (_id) {
			setloading(true);
			findOzonioById(_id)
				.then((vehi: any) => {
					setloading(false);
					setOzonio(vehi);
				})
				.catch(() => {
					setloading(false);
					Swal.fire({
						title: 'Error!',
						text: 'ozonio no valido',
						icon: 'error',
						confirmButtonText: 'Aceptar',
					}).then(() => {
						navigate('/ozonio');
					});
				});
		}
	};

	useEffect(() => {
		fetchOzonio();
	}, []);

	useEffect(() => {
		setOzonio(result);
	}, [result]);

	const formatName = (name: string) => name.replaceAll(' ', '_');

	const handleFile = (e: any) => {
		const file = e.target.files[0];
		if (file.size > 10000000) {
			Swal.fire({
				text: 'La imagen no debe ser superior a 10mb',
				icon: 'warning',
				confirmButtonText: 'Aceptar',
			});
		} else {
			const blob = file.slice(0, file.size, file.type);
			const newFile = new File([blob], formatName(file.name), {
				type: file.type,
			});
			updateImageOzonio(ozonio['_id'], newFile).then((newData: any) => {
				setOzonio(newData);
			});
		}
	};

	const toogleActiveStatus = () => {
		if (_id) {
			setLoadingActive(true);
			updateOzonio({ isActive: !ozonio.isActive }, _id)
				.then(() => {
					setLoadingActive(false);
					fetchOzonio();
					toasterNotification({
						// eslint-disable-next-line no-nested-ternary
						msg: 'Usuario modificado exitosamente',
						toasterType: toasterOptions.success,
						style: 'light',
					});
				})
				.catch((err) => {
					setLoadingActive(false);
					toasterNotification({
						// eslint-disable-next-line no-nested-ternary
						msg: err ? (Array.isArray(err) ? err[0] : err) : 'Ocurrio un error',
						toasterType: toasterOptions.error,
						style: 'light',
					});
				});
		}
	};

	return (
		<div>
			{!loading && ozonio ? (
				<div className="dim_100_per">
					<div className="display_flex flex_align_center m_b_lg">
						<Button
							variant="icon"
							icon={<Back />}
							subvariant="edit"
							scale="small"
							onClick={() => navigate(-1)}
						/>
						<Typography
							scale="heading1"
							weight="600"
							className="m_l_md text_neutral_900"
						>
							{ozonio.name}
						</Typography>
					</div>

					<Grid
						container
						direction="row"
						spacing={3}
						className="h_100_per p_t_md"
					>
						<Grid item xs={3} className="display_flex flex_col">
							<div className="flex_grow_1 dso_card bg_neutral_0 m_b_lg p_md flex_center_col_x">
								<Typography
									scale="heading4"
									weight="600"
									className="text_neutral_900 text_center m_b_xl"
								>
									{ozonio.name}
								</Typography>

								<div
									className="dso_card_img m_y_md w_fit center_x bg_neutral_200 display_flex overflow_hidden"
									onClick={() => 1}
									onKeyPress={() => 1}
									role="button"
									tabIndex={0}
								>
									{ozonio.image ? (
										<img
											src={ozonio.image.url}
											alt=""
											className="cards_photo big"
										/>
									) : (
										<img src={profileDefaultOzonio} alt="ozonio" />
									)}
									<input
										type="file"
										accept="image/png, image/jpeg"
										onChange={handleFile}
										className="input-file"
									/>
								</div>
								<Typography scale="medium" weight="600" className="m_b_xxs">
									Rol
								</Typography>
								<Typography scale="medium" weight="400" className="m_b_lg">
									{ozonio.role.description}
								</Typography>
								<Typography scale="medium" weight="600" className="m_b_xxs">
									Número telefónico
								</Typography>
								<Typography scale="medium" weight="400" className="m_b_lg">
									{ozonio.phone}
								</Typography>
								<Typography scale="medium" weight="600" className="m_b_xxs">
									Estado
								</Typography>
								<Typography scale="medium" weight="400" className="m_b_lg">
									{ozonio.isActive ? 'Activo' : 'Inactivo'}
								</Typography>
								<Typography scale="medium" weight="600" className="m_b_xxs">
									Correo electrónico
								</Typography>
								<Typography scale="medium" weight="400" className="m_b_lg">
									{ozonio.username}
								</Typography>
								{ozonio.moonflowId !== undefined && (
									<>
										<Typography scale="medium" weight="600" className="m_b_xxs">
											Id de Moonflow
										</Typography>
										<Typography scale="medium" weight="400" className="m_b_lg">
											{ozonio.moonflowId}
										</Typography>
									</>
								)}
								<Button
									variant="ghost"
									scale="small"
									className="w_100_per"
									onClick={() => restorePassword(ozonio.username)}
								>
									Restaurar Contraseña
								</Button>
								<Button
									variant="outline"
									scale="small"
									icon={<Edit />}
									className="w_100_per m_y_md"
									onClick={() => setOpen(true)}
								>
									Editar
								</Button>
								<Button
									variant="outline"
									scale="small"
									color={ozonio.isActive ? 'red' : 'green'}
									className="w_100_per m_y_md"
									onClick={() => toogleActiveStatus()}
									disabled={loadingActive}
								>
									{ozonio.isActive ? 'In' : 'Re'}activar usuario
								</Button>
							</div>
						</Grid>

						<Grid item xs={9} />
					</Grid>
				</div>
			) : (
				'cargando...'
			)}
			<EditOzonio
				open={open}
				setOpen={setOpen}
				values={ozonio}
				setResult={setResult}
			/>
		</div>
	);
}

export default DetailsOzonio;
