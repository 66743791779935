import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { toasterNotification } from './toasterNotification';

export const commonErrorHandler = (err: any) => {
	let msg = err;
	if (Array.isArray(msg)) [msg] = msg;
	if (msg.message) msg = msg.message;
	if (msg.error) msg = msg.error;
	if (msg.data) msg = msg.data;
	if (msg.msg) msg = msg.msg;
	if (typeof msg !== 'string') {
		msg = 'Ocurrio un error';
	}
	toasterNotification({
		msg,
		toasterType: toasterOptions.error,
		style: 'light',
	});
};
