/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './navbarMobile.scss';

import logo from 'static/logos/logo_prod.png';

import { Popup, Typography } from 'design_system/src';
import { logout } from 'store/actions/user';
import { getUser } from 'localStorage/authStorage';
import profileDefaultOzonio from 'static/images/ozonio_default.png';
import { findOzonioById } from 'models/ozonio/controllers/ozonios.controller';
import { Ozonio } from 'models/ozonio/interfaces/ozonio.interface';
import { ProfileResponse } from 'models/auth/interfaces/auth.interface';

const logos: any = {
	prod: logo,
	qa: logo,
	dev: logo,
};

function NavbarMobile() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showLogout, setshowLogout] = useState(false);
	const [user, setUser] = useState<ProfileResponse | null>(null);
	const [ozonio, setOzonio] = useState<Ozonio | null>();

	const handlelogout = () => {
		dispatch(logout());
		navigate('/auth');
	};
	useEffect(() => {
		const userL = getUser();
		setUser(userL);
		if (userL) {
			findOzonioById(userL.userId).then((data) => {
				setOzonio(data);
			});
		}
	}, []);

	return (
		<header className="navbar-mobile shadow_medium flex_center">
			<img
				src={
					process.env.REACT_APP_ENVIROMENT
						? logos[process.env.REACT_APP_ENVIROMENT]
						: logo
				}
				alt="icon"
				className="h_xl"
			/>
			<div className="pos_absolute profile_img">
				<div
					className="dim_xxl br_circle bg_neutral_200 overflow_hidden border_solid border_1 border_neutral_600"
					onClick={() => setshowLogout(!showLogout)}
					onKeyPress={() => setshowLogout(!showLogout)}
					tabIndex={0}
					role="button"
				>
					{ozonio?.image ? (
						<img
							src={ozonio.image.url}
							alt=""
							className="cards_photo dim_xxl"
						/>
					) : (
						<img
							src={profileDefaultOzonio}
							alt="ozonio"
							className="cards_photo dim_xxl"
						/>
					)}
					<Popup
						open={showLogout}
						setOpen={setshowLogout}
						placementX="right-inset"
						className="bg_neutral_100 br_xxs m_t_md "
					>
						<div className="flex_center_col p_md border_b_1 border_b_solid border_neutral_400">
							<div className="dim_xxxl br_circle bg_neutral_200 overflow_hidden border_solid border_1 border_neutral_600">
								{ozonio?.image ? (
									<img
										src={ozonio.image.url}
										alt=""
										className="cards_photo dim_xxxl"
									/>
								) : (
									<img
										src={profileDefaultOzonio}
										alt="ozonio"
										className="cards_photo dim_xxxl"
									/>
								)}
							</div>
							<Typography
								scale="medium"
								weight="400"
								className="text_neutral_900 text_no_break"
							>
								{user?.name}
							</Typography>
							<Typography
								scale="small"
								weight="400"
								className="text_neutral_700"
							>
								{user?.role}
							</Typography>
						</div>
						<Typography
							className="menu_selectable p_md"
							onClick={handlelogout}
							onKeyPress={handlelogout}
							role="button"
							tabIndex={0}
							scale="medium"
							weight="400"
						>
							Cerrar sesión
						</Typography>
					</Popup>
				</div>
			</div>
		</header>
	);
}

export default NavbarMobile;
