/* eslint-disable no-restricted-globals */
import { Dispatch } from 'redux';
import {
	LocationsActions,
	RepossessionsActions,
} from 'store/types/repossessions.types';

export const loadData =
	(repossessions: any, totals: any) => (dispatch: Dispatch) => {
		dispatch({
			type: RepossessionsActions.load_repossession,
			payload: { data: repossessions ?? [], totals },
		});
	};
export const addHistoric = (repossession: any) => (dispatch: Dispatch) => {
	dispatch({
		type: RepossessionsActions.add_historic,
		payload: repossession,
	});
};
export const loadAllLocations = (locations: any) => (dispatch: Dispatch) => {
	dispatch({
		type: LocationsActions.load_locations,
		payload: { data: locations },
	});
};
export const addAllLocationsHistoric =
	(locations: any) => (dispatch: Dispatch) => {
		dispatch({
			type: LocationsActions.add_historic,
			payload: { data: locations },
		});
	};
