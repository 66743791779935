import { AnyARecord } from 'dns';
import {
	axiosErrorHandler,
	deletes,
	get,
	post,
	put,
} from 'models/shared/helpers/axiosInstance';
import { PaginatedDocuments } from 'models/shared/types/pagination';
import { buildPathWithQueryParams } from 'models/shared/helpers/utils';
import {
	DeviceCreateDto,
	CurrentDeviceResponse,
	Device,
	DeviceFilterDto,
	DeviceUpdateDto,
	DeviceUpdateVehicleDto,
} from '../interfaces/device.interface';

const baseDevicePath = '/v1/device';

export const fetchDevices = async (dto: DeviceFilterDto) => {
	try {
		const res = await get<PaginatedDocuments<Device>>(
			buildPathWithQueryParams(baseDevicePath, dto),
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const fetchCurrentDevice = async (vehicleId: string) => {
	try {
		const url = `${baseDevicePath}/currentDevice/${vehicleId}`;
		const res = await get<CurrentDeviceResponse>(url);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const deleteDevice = async (id: string) => {
	try {
		const res = await deletes<boolean>(`${baseDevicePath}/${id}`);
		return res;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const createDevice = async (dto: DeviceCreateDto) => {
	try {
		const res = await post<Device>(baseDevicePath, dto);

		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const updateDevice = async (
	values: DeviceUpdateDto | DeviceUpdateVehicleDto,
	id: string,
) => {
	try {
		const res = await put<Device>(`${baseDevicePath}/${id}`, values);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
