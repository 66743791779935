import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import {
	SideModal,
	Typography,
	Input,
	Button,
	ChipPercentage,
} from 'design_system/src';
import * as yup from 'yup';
import _ from 'lodash';
import { ReactComponent as Bookmark } from 'design_system/src/static/icons/bookmark.svg';
import { ReactComponent as Document } from 'design_system/src/static/icons/document-ui.svg';
import { ReactComponent as Discount } from 'design_system/src/static/icons/discount.svg';
import { ReactComponent as Save } from 'design_system/src/static/icons/save.svg';
import { ReactComponent as Envelope } from 'design_system/src/static/icons/envelope.svg';
import { ReactComponent as Close } from 'design_system/src/static/icons/close-circle.svg';
import './AddEditDiscount.scss';
import { DiscountType } from 'models/inventory/enums/vehicle.enum';
import {
	VehiclePopulated,
	Discount as IDiscount,
	SalePrices,
	DiscountCreateDto,
} from 'models/inventory/interfaces/vehicle.interface';
import {
	addDiscount,
	deleteDiscount,
} from 'models/inventory/controllers/vehicle.controller';

interface AddEditDiscountForm extends Partial<IDiscount> {
	hasPromoCode: boolean;
}

const validationSchema = (
	vehicle: VehiclePopulated | null | undefined,
	loanTimes: SalePrices[],
) =>
	yup.object({
		hasPromoCode: yup.boolean().required('Campo requerido'),
		code: yup.string().when('hasPromoCode', {
			is: true,
			then: yup.string().required('Campo requerido'),
		}),
		type: yup.string().required('Campo requerido'),
		value: yup
			.number()
			.when('type', {
				is: DiscountType.VALUE.toString(),
				then: yup
					.number()
					.typeError('El valor debe ser numerico')
					.required('Campo requerido')
					.min(0, 'El valor no puede ser menor a 0')
					.max(
						loanTimes[loanTimes.length - 1].paymentWeek,
						`El valor no puede ser mayor a ${
							loanTimes[loanTimes.length - 1].paymentWeek
						}`,
					),
			})
			.when('type', {
				is: DiscountType.PERCENTAGE.toString(),
				then: yup
					.number()
					.typeError('El valor debe ser numerico')
					.required('Campo requerido')
					.min(0, 'El valor no puede ser menor a 0')
					.max(99, 'El valor no puede ser mayor a 100%'),
			}),
	});

const defaults: AddEditDiscountForm = {
	hasPromoCode: false,
	type: DiscountType.VALUE,
	value: 0,
	code: '',
};

interface DiscountProps {
	open: boolean;
	setOpen: Function;
	setResult: Function;
	vehicle?: VehiclePopulated | null;
	initials?: any;
	loanTimes: SalePrices[];
}

const AddEditDiscount: FC<DiscountProps> = ({
	open,
	setOpen,
	vehicle,
	setResult,
	initials = null,
	loanTimes,
}) => {
	const [initialValue, setInitialValue] = useState(defaults);
	const [activeId, setActiveId] = useState(null);
	const onSubmit = async (values: FormikValues) => {
		try {
			if (!vehicle) return;

			const discount: DiscountCreateDto = {
				type: values.type,
				value: values.value,
			};
			if (values.hasPromoCode) {
				discount.code = values.code;
			}
			if (values.type === DiscountType.PERCENTAGE) {
				discount.value = values.value / 100;
			}
			const result = await addDiscount(discount, vehicle.internalId, activeId);
			setOpen(false);
			setResult(result);
		} catch (e) {
			console.error(e);
			setOpen(false);
		}
	};
	const delDiscount = async () => {
		if (vehicle && initials) {
			const result = await deleteDiscount(initials._id, vehicle.internalId);
			setOpen(false);
			setResult(result);
		}
	};
	useEffect(() => {
		if (initials) {
			setActiveId(initials.id);
			setInitialValue({
				hasPromoCode: !!initials.code,
				type: initials.type,
				value:
					initials.type === DiscountType.PERCENTAGE
						? initials.value * 100
						: initials.value,
				code: initials.code ? initials.code : '',
			});
		} else {
			setActiveId(null);
			setInitialValue(defaults);
		}
	}, [initials]);
	return (
		<SideModal open={open} setOpen={setOpen} size="small">
			<Formik
				validateOnMount
				initialValues={initialValue}
				enableReinitialize
				onSubmit={onSubmit}
				validationSchema={validationSchema(vehicle, loanTimes)}
			>
				{(formik) => {
					return (
						<div className="discount-container">
							<Form>
								<div className="title-discount-container m_b_xs">
									<Typography
										scale="heading4"
										weight="600"
										className="m_t_xxxl m_b_xl text_center"
										textColor="neutral_900"
									>
										Nuevo descuento
									</Typography>
								</div>

								<Input
									icon={<Document />}
									title="¿Descuento por código promocional?"
									type="slider"
									name="hasPromoCode"
									labels
									className="m_b_sm"
									value={formik.values.hasPromoCode}
									onChange={formik.handleChange}
								/>
								{formik.values.hasPromoCode ? (
									<Input
										name="code"
										icon={<Envelope />}
										value={formik.values.code}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										title="Escribe código promocional"
										type="text"
										error={Boolean(formik.errors.code) && formik.touched.code}
										subtitle={
											formik.touched.code
												? (formik.errors.code as string)
												: undefined
										}
									/>
								) : null}
								<Input
									name="type"
									value={formik.values.type}
									onChange={(e: any) => {
										formik.setFieldValue(
											'value',
											e.target.value === DiscountType.VALUE ? 0 : 5,
										);
										formik.handleChange(e);
									}}
									icon={<Bookmark />}
									onBlur={formik.handleBlur}
									title="Tipo de descuento"
									type="select"
									options={[
										{ label: 'Descuento Neto', value: DiscountType.VALUE },
										{
											label: 'Descuento Procentual',
											value: DiscountType.PERCENTAGE,
										},
									]}
								/>
								<Input
									name="value"
									value={formik.values.value}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									icon={<Discount />}
									title={
										formik.values.type === DiscountType.VALUE
											? 'Valor de descuento'
											: 'Porcentaje de descuento'
									}
									type="number"
									error={
										!!formik.errors.value &&
										formik.getFieldMeta('value').touched
									}
									subtitle={
										formik.getFieldMeta('value').touched
											? (formik.errors.value as string)
											: undefined
									}
								/>

								<div className="flex_1" />
								<div className="footer-discount-container m_t_xxxl">
									<Typography weight="400" scale="small" className="m_b_xxs">
										<span className="text_neutral_900">Pagos semanales</span>
									</Typography>
									{formik.values.value &&
									formik.values.type &&
									Number(formik.values.value) > 0 ? (
										<div className="display_flex flex_1 flex_align_center">
											<Typography weight="600" scale="heading4">
												<span className=" text_red_300 m_t_xxs text_decoration_line-through">
													$
													{Number(
														loanTimes[0].paymentWeekBeforeDiscount ??
															loanTimes[0].paymentWeek,
													)}
												</span>
											</Typography>
											<div className="m_l_xs">
												{formik.values.type === DiscountType.PERCENTAGE && (
													<ChipPercentage
														total={loanTimes[0].paymentWeek}
														value={
															loanTimes[0].paymentWeekBeforeDiscount ??
															loanTimes[0].paymentWeek
														}
													/>
												)}
											</div>
										</div>
									) : null}
									<Typography weight="600" scale="heading3" className="m_y_xs">
										<span className="text_primary_300">
											${loanTimes[0].paymentWeek}
										</span>
									</Typography>
								</div>

								<Button
									icon={<Save />}
									disabled={!formik.isValid}
									scale="small"
									type="submit"
									orientation="right"
									className="m_x_xxl m_t_lg"
								>
									Guardar{' '}
								</Button>
								{initials && (
									<Button
										icon={<Close />}
										scale="small"
										variant="ghost"
										orientation="right"
										className="m_t_sm m_x_xxl m_b_xl text_red_300"
										onClick={delDiscount}
									>
										Borrar descuento
									</Button>
								)}
							</Form>
						</div>
					);
				}}
			</Formik>
		</SideModal>
	);
};

export default AddEditDiscount;
