/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';

import { Button, ModalConfirmation, Typography } from 'design_system/src';

import moment from 'moment';
import '../../../ozoner.scss';
import { ReactComponent as Check } from 'design_system/src/static/icons/checkmark.svg';

import { RequireRole } from 'components/layouts/main/sidebar/Sidebar';
import { EvidenceDocumentType } from 'models/shared/types/documents';
import { fetchCurrentDevice } from 'models/geozon/controllers/device.controller';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { getQuotaVehicle } from 'models/inventory/controllers/vehicle.controller';
import { SalePrices } from 'models/inventory/interfaces/vehicle.interface';
import { Roles } from 'models/ozonio/enums/ozonio.enum';
import { validationItem } from 'components/hocs/messages/messages';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { updateFinancialForm } from 'models/financial-form/controllers/financial-form.controller';
import { FormStatus } from 'models/financial-form/enums/financial-form.enums';
import { ScheduleAction, VerifyAction } from './SolicitudActions';
import { ozonerContext } from '../../OzonerContext';

function ScheduledSection() {
	const {
		financialForm,
		ozonios,
		vehicle,
		handleDetailsVehicle,
		initializeContext,
	} = useContext(ozonerContext);

	const [hasContract, setHasContract] = useState(false);
	const [hasPromissory, setHasPromissory] = useState(false);
	const [hasDelivery, setHasDelivery] = useState(false);
	const [hasDeposit, setHasDeposit] = useState(false);
	const [hasDevice, setHasDevice] = useState(false);
	const [deliverOzonio, setDeliverOzonio] = useState('');
	const [openActivate, setOpenActivate] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loanTotals, setLoanTotals] = useState<SalePrices>();

	useEffect(() => {
		setHasContract(false);
		setHasPromissory(false);
		setHasDelivery(false);
		setHasDeposit(false);
		financialForm?.form?.documents?.forEach((doc) => {
			if (doc.typeEvidence === EvidenceDocumentType.contract) {
				setHasContract(true);
			}
			if (doc.typeEvidence === EvidenceDocumentType.promissory_note) {
				setHasPromissory(true);
			}
			if (doc.typeEvidence === EvidenceDocumentType.delivery_photo) {
				setHasDelivery(true);
			}
			if (doc.typeEvidence === EvidenceDocumentType.deposit) {
				setHasDeposit(true);
			}
		});

		setDeliverOzonio(
			ozonios[
				financialForm?.form?.loanApplication?.appointment?.deliverOzonio ?? ''
			].name ?? '',
		);
		getLoanTotals();
	}, [financialForm]);

	useEffect(() => {
		if (vehicle?.internalId) {
			fetchCurrentDevice(vehicle.internalId).then((dev) => {
				if (dev?.hasDevice) {
					setHasDevice(true);
				}
			});
		}
		getLoanTotals();
	}, [vehicle]);

	const getLoanTotals = () => {
		if (vehicle && financialForm?.form?.loanApplication) {
			getQuotaVehicle(vehicle.internalId, {
				duration: financialForm.form.loanApplication.loanDuration ?? 0,
				advancedMoney: financialForm?.form?.loanApplication?.advancedMoney,
			}).then((totals) => {
				setLoanTotals(totals);
			});
		}
	};

	const activateContract = () => {
		setLoading(true);
		updateFinancialForm(financialForm.form._id, {
			status: FormStatus.completed,
		})
			.then(() => {
				toasterNotification({
					msg: 'Credito activado correctamente',
					style: 'light',
					toasterType: toasterOptions.success,
				});
				initializeContext();
				setOpenActivate(false);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				commonErrorHandler(err);
			});
	};
	return (
		<>
			<div
				id="validate-soli-ozoner"
				className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col"
			>
				<Typography scale="medium" weight="400" className="m_b_xs">
					Resumen de credito
				</Typography>
				<div className="display_flex">
					<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
						<Typography
							scale="xxsmall"
							weight="400"
							textColor="neutral_600"
							className="text_no_break text_center m_b_xxs"
						>
							Deposito
						</Typography>
						<Typography
							scale="small"
							weight="600"
							textColor="green_300"
							className="text_no_break text_center"
						>
							{priceFormatFinancial(
								financialForm?.form?.loanApplication?.deposit ?? 0,
								0,
							)}
						</Typography>
					</div>
					<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
						<Typography
							scale="xxsmall"
							weight="400"
							textColor="neutral_600"
							className="text_no_break text_center m_b_xxs"
						>
							Pago por adelantado
						</Typography>
						<Typography
							scale="small"
							weight="600"
							textColor="secondary_500"
							className="text_no_break text_center"
						>
							{priceFormatFinancial(
								financialForm?.form?.loanApplication?.advancedMoney ?? 0,
								0,
							)}
						</Typography>
					</div>
				</div>
				<div className="display_flex">
					<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
						<Typography
							scale="xxsmall"
							weight="400"
							textColor="neutral_600"
							className="text_no_break text_center m_b_xxs"
						>
							Tiempo
						</Typography>
						<Typography
							scale="small"
							weight="600"
							textColor="neutral_1000"
							className="text_no_break text_center"
						>
							{financialForm?.form?.loanApplication?.loanDuration} Semanas
						</Typography>
					</div>
					<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
						<Typography
							scale="xxsmall"
							weight="400"
							textColor="neutral_600"
							className="text_no_break text_center m_b_xxs"
						>
							Cuota
						</Typography>
						<Typography
							scale="small"
							weight="600"
							textColor="primary_300"
							className="text_no_break text_center"
						>
							{loanTotals?.paymentWeek
								? priceFormatFinancial(loanTotals.paymentWeek)
								: '--'}
						</Typography>
					</div>
				</div>
				<div className="display_flex">
					<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
						<Typography
							scale="xxsmall"
							weight="400"
							textColor="neutral_600"
							className="text_no_break text_center m_b_xxs"
						>
							Total credito sin deposito
						</Typography>
						<Typography
							scale="small"
							weight="600"
							textColor="green_300"
							className="text_no_break text_center"
						>
							{loanTotals?.loanAmount
								? priceFormatFinancial(loanTotals.loanAmount)
								: '--'}
						</Typography>
					</div>
				</div>
				<div className="display_flex ">
					<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
						<Typography
							scale="xxsmall"
							weight="400"
							textColor="neutral_600"
							className="text_no_break text_center m_b_xxs"
						>
							Alianza
						</Typography>
						<Typography
							scale="small"
							weight="600"
							textColor="neutral_900"
							className="text_no_break text_center"
						>
							{financialForm?.form?.loanApplication?.partnership ?? '--'}
						</Typography>
					</div>
					<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
						<Typography
							scale="xxsmall"
							weight="400"
							textColor="neutral_600"
							className="text_no_break text_center m_b_xxs"
						>
							Segmentacion Crédito
						</Typography>
						<Typography
							scale="small"
							weight="600"
							textColor="neutral_900"
							className="text_no_break text_center"
						>
							{financialForm?.form?.loanApplication?.loanSegmentation}
						</Typography>
					</div>
				</div>
				{vehicle ? (
					<div className="display_flex flex_gap_md flex_align_center border_solid border_1 border_neutral_400 p_xs">
						<div className="br_xs overflow_hidden foto_moto">
							<img
								src={
									vehicle.images[0]
										? vehicle.images[0].url
										: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
								}
								alt="moto detail"
							/>
						</div>
						<div className="flex_grow_1 flex_center_col">
							<Typography
								scale="small"
								weight="600"
								className="inventory_link cursor_pointer"
								onClick={(e) => handleDetailsVehicle(e, vehicle.internalId)}
							>
								{vehicle.internalId}
							</Typography>

							<Typography scale="xxsmall" weight="400" className="m_b_xxs">
								{vehicle.brand && vehicle.model
									? `${vehicle.brand.name} ${vehicle.model.name} ${
											vehicle.suffix ?? ''
									  }`
									: 'no definido'}
							</Typography>
						</div>
					</div>
				) : null}
				<div className="display_flex">
					<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
						<Typography
							scale="xxsmall"
							weight="400"
							textColor="neutral_600"
							className="text_no_break text_center m_b_xxs"
						>
							Cita de entrega
						</Typography>
						<Typography
							scale="small"
							weight="600"
							textColor="neutral_900"
							className="text_no_break text_center"
						>
							{moment
								.parseZone(
									financialForm?.form?.loanApplication?.appointment
										?.scheduledAt,
								)
								.format('YYYY-MM-DD hh:mm A')}
						</Typography>
					</div>
				</div>
				<div className="display_flex m_b_md">
					<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
						<Typography
							scale="xxsmall"
							weight="400"
							textColor="neutral_600"
							className="text_no_break text_center m_b_xxs"
						>
							Encargado de entrega
						</Typography>
						<Typography
							scale="small"
							weight="600"
							textColor="neutral_900"
							className="text_no_break text_center"
						>
							{deliverOzonio}
						</Typography>
					</div>
				</div>

				<ScheduleAction disable={false} />
			</div>

			<RequireRole authRoles={[Roles.ADMIN, Roles.COLLECTOR_AGENT]}>
				<div
					id="verify-soli-ozoner"
					className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
				>
					{financialForm?.form?.loanApplication?.isDataVerified ? (
						<div className="flex_center flex_gap_xs">
							<Typography
								scale="small"
								weight="600"
								textColor="green_300"
								className="text_no_break text_center"
							>
								Credito Verificado
							</Typography>
							<Check className="text_green_300 dim_md" />
						</div>
					) : (
						<>
							<div className="display_flex flex_gap_xs flex_align_center">
								{validationItem(
									!!financialForm?.form?.loanApplication?.collectionResponsible,
									'Responsable de cobranza asignado',
									'Responsable de cobranza sin asignar',
								)}
							</div>
							<VerifyAction
								disabled={
									!financialForm?.form?.loanApplication?.collectionResponsible
								}
							/>
						</>
					)}
				</div>
			</RequireRole>

			<div
				id="validate-soli-ozoner"
				className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
			>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						!!financialForm?.form?.loanApplication?.isDataVerified,
						'Verificacion de cobranza hecha',
						'Verificacion de cobranza pendiente',
					)}
				</div>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						hasDeposit,
						'Primera Cuota y deposito adjunto',
						'Primera Cuota y deposito sin subir',
					)}
				</div>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						hasContract,
						'Contrato adjunto',
						'Contrato sin subir',
					)}
				</div>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(hasPromissory, 'Pagare adjunto', 'Pagare sin subir')}
				</div>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						hasDelivery,
						'Foto entrega adjunto',
						'Foto entrega sin subir',
					)}
				</div>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						hasDevice,
						'Vehiculo con GPS',
						'Vehiculo sin GPS asignado',
					)}
				</div>
				<Button
					scale="small"
					color="primary"
					disabled={
						!hasDelivery ||
						!hasPromissory ||
						!hasDeposit ||
						!hasContract ||
						!hasDevice ||
						!financialForm?.form?.loanApplication?.isDataVerified
					}
					onClick={() => setOpenActivate(true)}
				>
					Entregar Moto
				</Button>
			</div>

			{openActivate && (
				<ModalConfirmation
					openConfirmation={openActivate}
					handleClose={() => setOpenActivate(false)}
					icon={<Check className="text_green_300" />}
					widthDesktop={40}
					isConfirmationLoading={false}
					actions={[
						{
							label: 'Volver',
							color: 'grey',
							action: () => setOpenActivate(false),
						},
						{
							label: 'Activar credito',
							color: 'primary',
							action: activateContract,
							disabled: loading,
						},
					]}
					title={
						<Typography scale="large" weight="400">
							¿Estás seguro de que{' '}
							<Typography scale="large" weight="600" component="span">
								deseas activar este credito?
							</Typography>
						</Typography>
					}
					subtitle={
						<Typography scale="medium" weight="400" textColor="neutral_700">
							Esta accion se debe realizar una vez se haya entregado la moto y
							se hayan firmado todos los documentos pertinentes
						</Typography>
					}
				/>
			)}
		</>
	);
}
export default ScheduledSection;
