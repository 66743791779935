import React, { FC } from 'react';
import './ProgressBar.scss';

interface Props {
	percentage: number;
	containerClassName?: string;
	fillClassName?: string;
}

const ProgressBar: FC<Props> = ({
	percentage,
	containerClassName,
	fillClassName,
}) => (
	<div className={`progress-bar w_100_per ${containerClassName || ''}`}>
		<div
			className={`fill ${fillClassName || ''} ${percentage && 'withValue'}`}
			style={{ width: `${Math.min(Math.max(percentage, 0), 100)}%` }}
		/>
	</div>
);

export default ProgressBar;
