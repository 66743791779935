import React from 'react';
import { LightTooltip } from 'components/hocs/tooltip/Tooltip';
import { Typography } from 'design_system/src';
import {
	MaintenanceColorValues,
	VehicleStatusValues,
} from 'models/inventory/enums/vehicle.enum';
import { VehiclePopulated } from 'models/inventory/interfaces/vehicle.interface';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { ReactComponent as Moto } from 'design_system/src/static/icons/Motocycle.svg';
import moment from 'moment';

export const vehicleListColumns = [
	{
		label: 'img',
		weight: 0,
		render: (row: VehiclePopulated, index: number) => (
			<div className="vehicle_list_img" key={index}>
				<img
					className="img dim_100_per"
					src={
						row.images[0]
							? row.images[0].url
							: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
					}
					alt="vehicle"
				/>
			</div>
		),
	},
	{
		label: 'name',
		weight: 4,
		render: (row: VehiclePopulated, index: number) => (
			<Typography scale="medium" weight="600">
				{`${row.brand?.name} ${row.model?.name} ${row.cylindersCapacity} ${
					row.suffix ? row.suffix : ''
				}`}
			</Typography>
		),
	},
	{
		label: 'options',
		weight: 3,
		render: (row: VehiclePopulated, index: number) => (
			<div className="display_flex flex_align_center flex_gap_xs">
				<LightTooltip
					title={
						row.maintenanceDays
							? `Mantenimiento de ${row.maintenanceDays} dias`
							: 'No dispone de informacion'
					}
				>
					<Moto
						className={`dim_lg text_${
							MaintenanceColorValues[row.maintenanceColor]?.color ??
							'primary_300'
						}`}
					/>
				</LightTooltip>
				<div className="flex_center_col">
					<Typography
						scale="medium"
						weight="400"
						textColor="neutral_900"
						className="text_center"
					>
						{row.internalId}
					</Typography>
					<Typography
						scale="xsmall"
						weight="400"
						textColor="neutral_600"
						className="text_center"
					>
						Creación:
					</Typography>
					<Typography
						scale="xsmall"
						weight="400"
						textColor="neutral_600"
						className="text_center"
					>
						{row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') : '--'}
					</Typography>
				</div>
			</div>
		),
	},
	{
		label: 'prices',
		weight: 7,
		render: (row: VehiclePopulated, index: number) =>
			row.salePrices ? (
				<div className="display_flex flex_align_center flex_gap_xl">
					{Object.values(row.salePrices).map((price) => (
						<div key={price.weeks} className="flex_center_col">
							{price.paymentWeekBeforeDiscount && (
								<Typography
									scale="xsmall"
									weight="600"
									className="text_center text_red_300 text_decoration_line-through"
								>
									{priceFormatFinancial(price.paymentWeekBeforeDiscount, 0)}
								</Typography>
							)}
							<Typography
								scale="large"
								weight="600"
								className="text_center text_neutral_900"
							>
								{priceFormatFinancial(price.paymentWeek ?? 0, 2)}
							</Typography>
							<Typography
								scale="xsmall"
								weight="400"
								className="text_center text_neutral_900"
							>
								{price.weeks} semanas
							</Typography>
						</div>
					))}
				</div>
			) : (
				<div />
			),
	},
	{
		label: 'estado',
		weight: 2,
		render: (row: VehiclePopulated, index: number) => (
			<div
				className={`
dso_chip_small flex_grow_1 
bg_${
					VehicleStatusValues[row.status]?.color
				}_nocontrast text_neutral_0 flex_basis_0 overflow_hidden overflow_ellipsis text_center
`}
			>
				{VehicleStatusValues[row.status]?.title ?? row.status}
			</div>
		),
	},
];
