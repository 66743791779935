/* eslint-disable no-shadow */
export enum FilterSections {
	ozonersList = 'ozonersList',
	vehiclesList = 'vehiclesList',
	creditsList = 'creditsList',
	formsList = 'formsList',
	ozociosList = 'ozociosList',
}

export enum Roles {
	ADMIN = 'ADMIN',
	RECUPERATOR = 'RECUPERATOR',
	CREDIT_ANALYST = 'CREDIT_ANALYST',
	COLLECTOR_AGENT = 'COLLECTOR_AGENT',
	DEVELOPER = 'DEVELOPER',
	SELLER = 'SELLER',
	SELLER_CHIEF = 'SELLER_CHIEF',
	SYSTEM = 'SYSTEM',
}
