/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import 'mapbox-gl/dist/mapbox-gl.css';

import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';

import { FetchRoutes } from 'models/repossession/controllers/repossession.controller';

import { ReactComponent as Moto } from 'design_system/src/static/icons/motorcycle.svg';
import { ReactComponent as Signal } from 'design_system/src/static/icons/radio-signal.svg';
import { ReactComponent as NoSignal } from 'design_system/src/static/icons/no-radio-signal.svg';
import { ReactComponent as Home } from 'design_system/src/static/icons/temple.svg';
import { ReactComponent as Hotspot } from 'design_system/src/static/icons/fire-camp.svg';

import { Button, ProgressBar, Typography } from 'design_system/src';
import './recovery.scss';
import { getUser } from 'localStorage/authStorage';
import { useNavigate } from 'react-router-dom';
import { PaginatedDocuments } from 'models/shared/types/pagination';
import { Route } from 'models/repossession/interfaces/route.interface';
import { InterestPointType } from 'models/repossession/enums/repossession.enums';
import { RecoveryStatus } from 'models/repossession/enums/recovery.enums';
import { RouteStatusValues } from 'models/repossession/enums/route.enums';

// eslint-disable-next-line no-shadow
enum datesOptions {
	'tomorrow' = 'tomorrow',
	'today' = 'today',
	'yesterday' = 'yesterday',
	'week' = 'week',
	'pastWeek' = 'pastWeek',
	'all' = 'all',
}
const datesOptionsLabels = {
	[datesOptions.tomorrow]: 'Mañana',
	[datesOptions.today]: 'Hoy',
	[datesOptions.yesterday]: 'Ayer',
	[datesOptions.week]: 'Esta Semana',
	[datesOptions.pastWeek]: 'Semana Pasada',
	[datesOptions.all]: 'Todas',
};

export const RoutesMobile = () => {
	const [routes, setRoutes] = useState<Route[]>([]);
	const [filter, setFilter] = useState(datesOptions.today);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const selectRuta = (ruta: Route) => {
		navigate(`/routes/${ruta._id}`);
	};
	const fetchRoutes = () => {
		let dates;
		switch (filter) {
			case datesOptions.tomorrow:
				dates = {
					start: moment().add(1, 'd').toDate(),
					end: moment().add(1, 'd').toDate(),
				};
				break;
			case datesOptions.today:
				dates = {
					start: new Date(),
					end: new Date(),
				};
				break;
			case datesOptions.yesterday:
				dates = {
					start: moment().subtract(1, 'd').toDate(),
					end: moment().subtract(1, 'd').toDate(),
				};
				break;
			case datesOptions.week:
				dates = {
					start: moment().startOf('week').toDate(),
					end: moment().endOf('week').toDate(),
				};
				break;
			case datesOptions.pastWeek:
				dates = {
					start: moment().subtract(1, 'w').startOf('week').toDate(),
					end: moment().subtract(1, 'w').endOf('week').toDate(),
				};
				break;
			default:
				dates = undefined;
				break;
		}
		setLoading(true);
		FetchRoutes(dates, getUser()?.userId).then(
			(resp: PaginatedDocuments<Route>) => {
				const newRoutes = resp.data;
				newRoutes.forEach((route) => {
					let routeSignal = 0;
					let routeNoSignal = 0;
					let address = 0;
					let hotspots = 0;
					let completed = 0;
					route.recoveries.forEach((recovery) => {
						if (recovery.interestPoint.type === InterestPointType.address) {
							address += 1;
						} else if (
							recovery.interestPoint.type === InterestPointType.hotspot
						) {
							hotspots += 1;
						} else if (
							recovery.interestPoint.type === InterestPointType.signalVehicle
						) {
							routeSignal += 1;
						} else {
							routeNoSignal += 1;
						}
						if (recovery.status === RecoveryStatus.recovered) {
							completed += 1;
						}
					});
					// eslint-disable-next-line no-param-reassign
					route.totals = {
						signal: routeSignal,
						noSignal: routeNoSignal,
						address,
						hotspots,
						completed,
					};
				});
				setRoutes(newRoutes);
				setLoading(false);
			},
		);
	};

	useEffect(() => {
		fetchRoutes();
	}, [filter]);

	return (
		<div>
			<div className="flex_center flex_justify_between m_b_md">
				<Typography scale="heading4" weight="600">
					Tus Rutas
				</Typography>
				<select
					disabled={loading}
					className="native_select text_large_600 shadow_hard"
					value={filter}
					onChange={(e) => setFilter(e.target.value as datesOptions)}
				>
					{Object.keys(datesOptionsLabels).map((key) => (
						<option value={key} key={key}>
							{datesOptionsLabels[key as datesOptions]}
						</option>
					))}
				</select>
			</div>

			{loading ? (
				<Typography
					className="text_center w_100_per m_t_xxl"
					scale="large"
					weight="400"
				>
					Cargando rutas...
				</Typography>
			) : routes.length ? (
				routes?.map((route, i) => (
					<div key={i} className="br_sm bg_neutral_0 m_b_md">
						<div className="display_flex flex_justify_between p_md p_b_xs border_b_solid border_neutral_300 border_1">
							<div>
								<Typography
									weight="600"
									scale="medium"
									textColor="neutral_1000"
									className=""
								>
									{moment
										.utc(route.dateEstimate)
										.locale('es')
										.format('dddd, D MMMM')}
								</Typography>
								<Typography
									weight="400"
									scale="small"
									textColor="neutral_600"
									className=""
								>
									{route.teamLeader.name}
								</Typography>
							</div>
						</div>
						<div className="p_md p_y_xs border_b_solid border_neutral_300 border_1">
							<div className="display_flex flex_gap_xs flex_align_center">
								<Moto className="dim_md" />
								<Typography
									weight="400"
									scale="medium"
									textColor="neutral_1000"
									className=""
								>
									{route.recoveries.length} motos a recuperar
								</Typography>
							</div>
							<div className="flex_center">
								<Typography
									weight="600"
									scale="small"
									textColor={RouteStatusValues[route.status].textColor as any}
									className={`bg_${
										RouteStatusValues[route.status].color
									}_nocontrast br_xl p_y_xxs p_x_sm m_y_xs`}
								>
									{RouteStatusValues[route.status].title}
								</Typography>
							</div>
							<div className="display_flex flex_justify_between">
								<div className="display_flex flex_gap_xs flex_align_center text_green_300">
									<Signal className="dim_md" />
									<Typography weight="600" scale="medium" className="">
										{route.totals?.signal ?? 0} con señal
									</Typography>
								</div>
								<div className="display_flex flex_gap_xs flex_align_center text_red_300">
									<NoSignal className="dim_md" />
									<Typography weight="600" scale="medium" className="">
										{route.totals?.noSignal ?? 0} sin señal
									</Typography>
								</div>
							</div>
							<div className="display_flex flex_justify_between m_t_xs">
								<div className="display_flex flex_gap_xs flex_align_center text_yellow_100">
									<Home className="dim_md" />
									<Typography weight="600" scale="medium" className="">
										{route.totals?.address ?? 0} Hogares
									</Typography>
								</div>
								<div className="display_flex flex_gap_xs flex_align_center text_primary_300">
									<Hotspot className="dim_md" />
									<Typography weight="600" scale="medium" className="">
										{route.totals?.hotspots ?? 0} Hotspots
									</Typography>
								</div>
							</div>
						</div>
						<div className="p_md">
							<Typography
								weight="400"
								scale="small"
								textColor="neutral_600"
								className=""
							>
								Recuperaciones completadas
							</Typography>
							<div className=" p_y_xs display_flex flex_align_center flex_gap_md m_b_xs">
								<Typography
									scale="small"
									weight="600"
									textColor="primary_300"
									className=""
								>
									{route.totals?.completed}/{route.recoveries?.length}{' '}
								</Typography>
								<ProgressBar
									containerClassName="bg_neutral_400 h_sm"
									fillClassName="bg_primary_300 h_sm"
									percentage={
										((route.totals?.completed ?? 0) * 100) /
										route.recoveries.length
									}
								/>
							</div>
							<Button
								scale="small"
								className="w_100_per"
								onClick={() => selectRuta(route)}
							>
								Ver ruta
							</Button>
						</div>
					</div>
				))
			) : (
				<Typography
					className="text_center w_100_per m_t_xxl"
					scale="large"
					weight="400"
				>
					No hay rutas en el periodo seleccionado asignadas a usted
				</Typography>
			)}
		</div>
	);
};
