/* eslint-disable react/no-unused-prop-types */
/* eslint-disable func-names */
import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import useWindowSize from '../../../hooks/useWindowSize';

export interface StepperStepProps {
	value: string;
	name: string;
	__TYPE?: string;
}

const Step: React.FC<StepperStepProps> = function ({ children }) {
	const ref = useRef<HTMLDivElement>(null);
	const [width, setWidth] = useState('100%');
	const { width: windowWidth, height: windowHeight } = useWindowSize();

	useEffect(() => {
		if (ref.current) {
			setWidth(`${ref.current.parentElement?.parentElement?.clientWidth}px`);
		}
	}, [windowWidth, windowHeight]);

	return (
		<div style={{ width }} ref={ref} className="stepper-step p_md">
			{children}
		</div>
	);
};

Step.defaultProps = {
	__TYPE: 'Step',
};

export default Step;
