import React, { FC, useEffect, useState } from 'react';
import { Typography } from 'design_system/src';
import { ReactComponent as Close } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as CircleAdd } from 'design_system/src/static/icons/add-circle.svg';
import { ReactComponent as Lock } from 'design_system/src/static/icons/lock-square.svg';
import { ReactComponent as Play } from 'design_system/src/static/icons/play-circle.svg';
// @ts-ignore
import generatePdfThumbnails from 'pdf-thumbnails-generator';

import { Grid } from '@mui/material';
import './loadDocuments.scss';
import {
	EvidenceDocument,
	EvidenceDocumentType,
	EvidenceDocumentTypeValues,
} from 'models/shared/types/documents';
import motoLoading from '../../../static/icons/loading_moto.gif';

const initialDocs: EvidenceDocumentType[] = [
	EvidenceDocumentType.ine_front,
	EvidenceDocumentType.ine_back,
	EvidenceDocumentType.selfie,
	EvidenceDocumentType.license_front,
	EvidenceDocumentType.license_back,
	EvidenceDocumentType.proof_of_address,
	EvidenceDocumentType.tax_situation,
	EvidenceDocumentType.promissory_note,
	EvidenceDocumentType.delivery_photo,
	EvidenceDocumentType.bank_certificate_1,
	EvidenceDocumentType.bank_certificate_2,
	EvidenceDocumentType.bank_certificate_3,
	EvidenceDocumentType.bank_certificate_4,
	EvidenceDocumentType.bank_certificate_5,
	EvidenceDocumentType.bank_certificate_6,
	EvidenceDocumentType.videoEvidenceAddress,
	EvidenceDocumentType.videoEvidenceWork,
	EvidenceDocumentType.truora,
	EvidenceDocumentType.advancedmoney,
	EvidenceDocumentType.videoEvidenceWork,
	EvidenceDocumentType.deposit,
	EvidenceDocumentType.contract,
	EvidenceDocumentType.layawayReceipt,
];

const formatName = (name: string) => name.replaceAll(' ', '_');

const mapCols: any = {
	'1': 12,
	'2': 6,
	'3': 4,
	'4': 3,
	'6': 2,
	'12': 1,
};

interface DocumentsProps {
	documents?: EvidenceDocument[];
	onDelete: Function;
	onAdd: Function;
	cols?: number;
	availableDocs?: EvidenceDocumentType[];
	canDelete?: boolean;
}

const LoadDocuments: FC<DocumentsProps> = ({
	documents = [],
	onDelete,
	onAdd,
	cols = 6,
	availableDocs = initialDocs,
	canDelete = true,
}) => {
	const [firstLoad, setFirstLoad] = useState(false);
	const [documentsView, setDocumentsView] = useState(
		<div className="flex_center_col w_100_per">
			<Typography
				weight="600"
				scale="medium"
				textColor="neutral_800"
				className="m_b_xs"
			>
				Cargando los Documentos...
			</Typography>
			<img src={motoLoading} alt="" className="h_xxxl" />
		</div>,
	);

	const genDocCard = async (file: any) => {
		let url;
		let error: any;
		if (file.mimeType.includes('image')) url = file.url;
		else if (file.mimeType.includes('pdf')) {
			await Promise.race([
				new Promise((res) =>
					// eslint-disable-next-line no-promise-executor-return
					setTimeout(() => {
						error = 'Timeout loading';
						res('timeout error');
					}, 5000),
				),
				generatePdfThumbnails(file.url, 200)
					.then((thumbnails: any) => {
						url = thumbnails[0].thumbnail;
					})
					.catch((e: any) => {
						error = e;
					}),
			]);
		} else if (file.mimeType.includes('video')) {
			return (
				<div
					id={file._id}
					className="w_100_per dso_card border_neutral_500 p_sm flex_center_col cursor_pointer flex_justify_between pos_relative document_card"
					onClick={() => window.open(file.url, '_blank')}
					onKeyPress={() => window.open(file.url, '_blank')}
					role="button"
					tabIndex={0}
				>
					{canDelete && (
						<Close
							className="dim_lg delete_file_solicitud"
							onClick={(e: any) => {
								e?.stopPropagation();
								onDelete(file);
							}}
						/>
					)}
					<Play className="dim_xl" />

					<Typography
						scale="small"
						weight="600"
						className="text_center"
						textColor="neutral_900"
					>
						Video
					</Typography>
				</div>
			);
		}
		if (error) {
			if (error.name === 'PasswordException')
				return (
					<div
						id={file._id}
						className="w_100_per dso_card border_neutral_500 p_sm flex_center_col cursor_pointer flex_justify_between pos_relative document_card"
						onClick={() => window.open(file.url, '_blank')}
						onKeyPress={() => window.open(file.url, '_blank')}
						role="button"
						tabIndex={0}
					>
						{canDelete && (
							<Close
								className="dim_lg delete_file_solicitud"
								onClick={(e: any) => {
									e?.stopPropagation();
									onDelete(file);
								}}
							/>
						)}

						<Lock className="dim_xl" />

						<Typography
							scale="small"
							weight="600"
							className="text_center"
							textColor="neutral_900"
						>
							Documento con contraseña
						</Typography>
					</div>
				);

			return (
				<div
					id={file._id}
					className="w_100_per dso_card border_neutral_500 p_sm flex_center_col cursor_pointer flex_justify_between pos_relative document_card"
					onClick={() => window.open(file.url, '_blank')}
					onKeyPress={() => window.open(file.url, '_blank')}
					role="button"
					tabIndex={0}
				>
					{canDelete && (
						<Close
							className="dim_lg delete_file_solicitud"
							onClick={(e: any) => {
								e?.stopPropagation();
								onDelete(file);
							}}
						/>
					)}

					<Close className="dim_xl" />

					<Typography
						scale="small"
						weight="600"
						className="text_center"
						textColor="neutral_900"
					>
						Fallo previsualización
					</Typography>
				</div>
			);
		}
		return (
			<div className="pos_relative" id={file._id}>
				<div
					className="w_100_per dso_card overflow_hidden cursor_pointer pos_relative document_card"
					onClick={() => window.open(file.url, '_blank')}
					onKeyPress={() => window.open(file.url, '_blank')}
					role="button"
					tabIndex={0}
				>
					<img src={url} alt="" className="image center_x" />
				</div>
				{canDelete && (
					<Close
						className="dim_lg delete_file_solicitud"
						onClick={(e: any) => {
							e?.stopPropagation();
							onDelete(file);
						}}
					/>
				)}
			</div>
		);
	};
	const getDoc = async (type: string, accept: string) => {
		const files = documents.filter((ele) => ele.typeEvidence === type);
		if (files.length === 0)
			return (
				<div
					id={type}
					className="w_100_per dso_card border_primary_200 border_dashed p_sm flex_center_col cursor_pointer flex_justify_between pos_relative document_card"
				>
					<CircleAdd className="dim_xl text_primary_300" />

					<Typography
						scale="small"
						weight="600"
						className="text_center"
						textColor="primary_300"
					>
						Añadir Documento
					</Typography>
					<input
						type="file"
						accept={accept}
						onChange={(e: any) => onAdd(e, type)}
						className="input-file"
					/>
				</div>
			);
		return genDocCard(files[0]);
	};
	const getDocs = async () => {
		const docs = await Promise.all(
			availableDocs.map((type: EvidenceDocumentType) =>
				getDoc(type, EvidenceDocumentTypeValues[type].accept).then((card) => (
					<Grid item xs={mapCols[cols]} className="p_md">
						<div>
							<div className="flex_center flex_gap_xxs">
								<Typography
									scale="medium"
									weight="600"
									textColor="neutral_900"
									className="m_b_sm overflow_ellipsis"
								>
									{EvidenceDocumentTypeValues[type].title}
								</Typography>
								<Typography
									scale="small"
									weight="400"
									textColor="neutral_900"
									className="m_b_sm"
								>
									{EvidenceDocumentTypeValues[type].subtitle}
								</Typography>
							</div>
							{card}
						</div>
					</Grid>
				)),
			),
		);
		const otherDocs = await Promise.all(
			documents
				.filter(
					(ele) =>
						!availableDocs.includes(ele.typeEvidence) &&
						ele.typeEvidence &&
						ele.mimeType,
				)
				.map((doc) =>
					genDocCard(doc).then((card) => (
						<Grid item xs={mapCols[cols]} className="p_md">
							<div>
								<div className="flex_center flex_gap_xxs">
									<Typography
										scale="medium"
										weight="600"
										textColor="neutral_900"
										className="m_b_sm overflow_ellipsis"
									>
										{formatName(doc.name)}
									</Typography>
								</div>
								{card}
							</div>
						</Grid>
					)),
				),
		);
		setDocumentsView(
			<>
				{docs}
				{otherDocs ?? ''}
				<Grid item xs={mapCols[cols]} className="p_md">
					<div>
						<div className="flex_center flex_gap_xxs">
							<Typography
								scale="medium"
								weight="600"
								textColor="neutral_900"
								className="m_b_sm overflow_ellipsis"
							>
								Otro
							</Typography>
						</div>
						<div
							id="add-other"
							className="w_100_per dso_card border_primary_200 border_dashed p_sm flex_center_col cursor_pointer flex_justify_between pos_relative document_card"
						>
							<CircleAdd className="dim_xl text_primary_300" />

							<Typography
								scale="small"
								weight="600"
								className="text_center"
								textColor="primary_300"
							>
								Añadir Documento
							</Typography>
							<input
								type="file"
								accept="image/png, image/jpeg, application/pdf, video/*"
								onChange={(e: any) => onAdd(e, 'other')}
								className="input-file"
							/>
						</div>
					</div>
				</Grid>
			</>,
		);
	};
	useEffect(() => {
		// if (documents?.length || !firstLoad) {
		setFirstLoad(true);
		getDocs();
		// }
	}, [documents]);
	return documentsView;
};

export default LoadDocuments;
