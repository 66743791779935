import {
	axiosErrorHandler,
	axiosInstance,
} from 'models/shared/helpers/axiosInstance';
import { getRefreshToken } from 'localStorage/authStorage';
import {
	LoginDto,
	LoginResponse,
	ProfileResponse,
} from 'models/auth/interfaces/auth.interface';
import { store } from 'store';
import { login, logout } from 'store/actions/user';

export const authLogin = async (user: LoginDto) => {
	const axios = axiosInstance;
	try {
		const res = await axios.post<LoginResponse>('v1/auth/login', user);
		store.dispatch(login(res.data));

		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const fetchProfile = async (token: string) => {
	const axios = axiosInstance;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const res = await axios.get<ProfileResponse>('v1/profile', config);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const fetchRefresh = async () => {
	const axios = axiosInstance;
	try {
		const res = await axios.post<LoginResponse>('v1/auth/refresh', {
			refresh_token: getRefreshToken(),
		});
		store.dispatch(login(res.data));
		return res.data;
	} catch (err: any) {
		store.dispatch(logout(true));
		throw axiosErrorHandler(err);
	}
};
