/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

// icons

import {
	Typography,
	Pager,
	Select,
	DateSelect,
	Button,
} from 'design_system/src';

// css
import moment from 'moment';
import 'moment/locale/es';

import { ReactComponent as Right } from 'design_system/src/static/icons/chevron-right.svg';
import './notifications.scss';

import {
	getNotificationsFilter,
	setNotificationsFilter,
} from 'localStorage/filterStorage';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'localStorage/authStorage';
import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';
import {
	findAllNotifications,
	markRead,
} from 'models/notification/controllers/notifications.controller';
import { NotificationClassificationValues } from 'models/notification/enums/notification.enum';
import { Notify } from 'models/notification/interfaces/notifications.interface';

let petition: number;

const readOptions = [
	{
		label: 'Leida',
		value: 'true',
	},
	{
		label: 'No leida',
		value: 'false',
	},
];

function Notifications() {
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const [datesFilter, setDatesFilter] = useState({
		initial: new Date(),
		last: new Date(),
		option: -1,
	});
	const [hasDatesFilter, setHasDatesFilter] = useState(false);
	const [readFilter, setReadFilter] = useState([]);

	const [notifications, setNotifications] = useState<Notify[]>();

	const [count, setCount] = useState(0);

	useEffect(() => {
		handleEventGoogle(
			'VIEW_Panda_notifications',
			'Panda',
			'page view to the notifications list view',
		);
		petition = 0;
		const information = getNotificationsFilter();
		if (information.currentPage) {
			setCurrentPage(information.currentPage);
		}

		if (information.readFilter) {
			setReadFilter(information.readFilter);
		}
		if (information?.datesFilter) {
			setDatesFilter({
				...information.datesFilter,
				initial: new Date(information.datesFilter.initial),
				last: new Date(information.datesFilter.last),
			});

			setHasDatesFilter(true);
		}
	}, []);

	const requestNotifications = () => {
		setLoading(true);
		const num = petition + 1;
		petition += 1;

		const filters = [];

		if (datesFilter && hasDatesFilter) {
			filters.push(
				`minCreatedAt=${moment
					.utc(datesFilter.initial)
					.format('YYYY-MM-DD')}&maxCreatedAt=${moment
					.utc(datesFilter.last)
					.format('YYYY-MM-DD')}`,
			);
		}

		let read = null;
		if (readFilter.length && readFilter.length !== readOptions.length)
			[read] = readFilter;

		const userL = getUser();
		if (userL)
			findAllNotifications({
				user: userL.userId,
				page: currentPage,
				limit: 30,
				read: read ?? undefined,
			}).then((info: any) => {
				setLoading(false);
				setNotifications(info.data);
				setTotalPages(info.pages);
				setCount(info.count);
			});
	};
	useEffect(() => {
		setCurrentPage(1);
	}, [readFilter, datesFilter, hasDatesFilter]);
	useEffect(() => {
		requestNotifications();
	}, [currentPage]);

	useEffect(() => {
		setNotificationsFilter({
			readFilter,
			currentPage,
			datesFilter: hasDatesFilter ? datesFilter : null,
		});
	}, [readFilter, datesFilter, hasDatesFilter, currentPage]);

	const triggerNotification = (notification: Notify) => {
		markRead(notification._id).then(() => {
			requestNotifications();
			if (
				NotificationClassificationValues[notification.classification].module
			) {
				navigate(
					`/${
						NotificationClassificationValues[notification.classification].module
					}/${
						notification.data[
							NotificationClassificationValues[notification.classification]
								.idField
						]
					}`,
				);
			}
		});
	};

	return (
		<div className="dim_100_per flex_content_col shadow_hard inner_container bg_neutral_400">
			<div className="flex_header">
				<div className="bg_neutral_0 shadow_hard p_y_md p_x_xxxl">
					<div className="display_flex flex_gap_sm flex_align_center">
						<Typography
							scale="heading3"
							weight="600"
							textColor="neutral_900"
							className="m_y_md"
						>
							Notificaciones
						</Typography>
					</div>
					<div className="display_flex flex_gap_xl">
						<Select
							fixedPlaceholder
							variant="checkbox"
							placeholder="Seleccione estado"
							options={readOptions}
							value={readFilter}
							onChange={(e: any) => {
								setReadFilter(e.target.value);
							}}
						/>
						<DateSelect
							value={datesFilter}
							hasValue={hasDatesFilter}
							setHasValue={setHasDatesFilter}
							setValue={(e: any) => {
								setDatesFilter(e);
								setHasDatesFilter(true);
							}}
						/>
					</div>
				</div>
			</div>
			<div className="flex_body p_x_xxxl overflow_x_hidden p_y_md">
				{loading ? (
					<div className="p_y_xl flex_center">Cargando notificaciones</div>
				) : notifications ? (
					notifications.map((notification) => (
						<div
							className="display_flex flex_gap_md p_y_sm p_x_md flex_align_center bg_neutral_0 m_b_xs br_xs"
							key={notification._id}
						>
							{!notification.read ? (
								<div className="br_circle bg_red_300 dim_sm" />
							) : null}
							<div className="flex_grow_1">
								<div className="display_flex flex_gap_md flex_align_center">
									<Typography
										scale="small"
										weight="600"
										textColor="neutral_900"
									>
										{notification.title}
									</Typography>
									<div className="br_circle bg_neutral_300 dim_xs" />
									<Typography
										scale="small"
										weight="600"
										textColor="neutral_700"
									>
										{NotificationClassificationValues[
											notification.classification
										]?.moduleName ?? ''}
									</Typography>
								</div>
								<Typography scale="small" weight="400" textColor="neutral_600">
									{notification.description}
								</Typography>
							</div>
							<Button
								icon={<Right />}
								orientation="right"
								scale="small"
								variant="ghost"
								color="grey"
								onClick={() => triggerNotification(notification)}
							>
								Ver Detalles
							</Button>
						</div>
					))
				) : (
					<div className="p_y_xl flex_center">Cargando notificaciones</div>
				)}
			</div>

			<div className="display_flex flex_align_center flex_justify_end flex_footer bg_neutral_0 p_x_xxxl p_y_md">
				<Pager
					totalPages={totalPages}
					setPage={setCurrentPage}
					page={currentPage}
					count={count}
					className="w_fit"
				/>
			</div>
		</div>
	);
}

export default Notifications;
