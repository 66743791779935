import { PaginatedDocuments } from 'models/shared/types/pagination';
import { EvidenceDocumentType } from 'models/shared/types/documents';
import { deletes, get, post, put } from 'models/shared/helpers/axiosInstance';
import { buildPathWithQueryParams } from 'models/shared/helpers/utils';
import {
	CreateFullPaymentFinancialFormDto,
	CreateLoanFinancialFormDto,
	FinancialFormFilter,
	GenericFinancialForm,
	LoanFinancialFormWithExtraFields,
	PopulatedFullPaymentFinancialForm,
	ScheduleAppointmentDto,
	UpdateFinancialFormDto,
} from '../interfaces/financial-form.interfaces';

const baseFinancialFormPath = '/v1/financial-form';

export const createLoanFinancialForm = async (
	dto: CreateLoanFinancialFormDto,
): Promise<LoanFinancialFormWithExtraFields> => {
	try {
		const res = await post<LoanFinancialFormWithExtraFields>(
			`${baseFinancialFormPath}/loan`,
			dto,
		);

		return res.data;
	} catch (err: any) {
		throw err.response.data;
	}
};

export const createFullPaymentFinancialForm = async (
	dto: CreateFullPaymentFinancialFormDto,
): Promise<PopulatedFullPaymentFinancialForm> => {
	try {
		const res = await post<PopulatedFullPaymentFinancialForm>(
			`${baseFinancialFormPath}/full-payment`,
			dto,
		);

		return res.data;
	} catch (err: any) {
		throw err.response.data;
	}
};

export const getFinancialForm = async (
	formId: string,
): Promise<GenericFinancialForm> => {
	try {
		const res = await get<GenericFinancialForm>(
			`${baseFinancialFormPath}/loan/${formId}`,
		);
		return res.data;
	} catch (err: any) {
		throw err.response.data.message;
	}
};

export const updateFinancialForm = async (
	formId: string,
	dto: UpdateFinancialFormDto,
): Promise<GenericFinancialForm> => {
	try {
		const res = await put<GenericFinancialForm>(
			`${baseFinancialFormPath}/${formId}`,
			dto,
		);
		return res.data;
	} catch (err: any) {
		throw err.response.data;
	}
};

export const findAllFinancialForms = async (
	dto: FinancialFormFilter,
): Promise<PaginatedDocuments<GenericFinancialForm>> => {
	try {
		const res = await get<PaginatedDocuments<GenericFinancialForm>>(
			buildPathWithQueryParams(baseFinancialFormPath, dto),
		);
		return res.data;
	} catch (err: any) {
		throw err.response.data;
	}
};

export const findAllFinancialFormsOzoner = async (
	ozonerId: string,
): Promise<GenericFinancialForm[]> => {
	try {
		const res = await get<GenericFinancialForm[]>(
			`${baseFinancialFormPath}/ozoner/${ozonerId}`,
		);
		return res.data;
	} catch (err: any) {
		throw err.response.data;
	}
};

export const uploadFileFinancialForm = async (
	formId: string,
	file: File,
	type: EvidenceDocumentType,
	alternativeName = '',
): Promise<LoanFinancialFormWithExtraFields> => {
	try {
		const data = new FormData();
		data.append('name', alternativeName !== '' ? alternativeName : file.name);
		data.append('file', file);

		const res = await put<LoanFinancialFormWithExtraFields>(
			`${baseFinancialFormPath}/${formId}/document/${type}`,
			data,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
			},
		);
		return res.data;
	} catch (err: any) {
		throw err.response.data.message;
	}
};

export const deleteFileFinancialForm = async (
	formId: string,
	docId: string,
): Promise<LoanFinancialFormWithExtraFields> => {
	try {
		const res = await deletes<LoanFinancialFormWithExtraFields>(
			`${baseFinancialFormPath}/${formId}/document/${docId}`,
		);

		return res.data;
	} catch (err: any) {
		throw err.response.data.message;
	}
};

export const scheduleAppointment = async (
	formId: string,
	dto: ScheduleAppointmentDto,
) => {
	try {
		const res = await post(
			`${baseFinancialFormPath}/schedule-appointment/${formId}`,
			dto,
		);
		return res.data;
	} catch (err: any) {
		throw err.response.data;
	}
};

export const cancelAppointment = async (formId: string) => {
	try {
		const res = await post(
			`${baseFinancialFormPath}/cancel-appointment/${formId}`,
		);
		return res.data;
	} catch (err: any) {
		throw err.response.data;
	}
};

export const createDocuments = async (formId: string) => {
	try {
		const res = await post<boolean>(
			`${baseFinancialFormPath}/${formId}/createDocuments`,
		);
		return res.data;
	} catch (err: any) {
		throw err.response.data;
	}
};

export const batchSellerAssign = async (
	financialForms: GenericFinancialForm[],
	seller: string,
): Promise<GenericFinancialForm[]> => {
	try {
		const res = await post('v1/sales/batch/assign-seller', {
			sellerId: seller,
			financialFormIds: financialForms.map((form) => form._id),
		});
		return res.data;
	} catch (err: any) {
		throw err.response.data.message;
	}
};
