/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './navbar.scss';

import logo from 'static/logos/logo_prod.png';
import divider from 'static/images/divider.svg';
import { ReactComponent as Down } from 'design_system/src/static/icons/down-md.svg';
import { ReactComponent as Notification } from 'design_system/src/static/icons/notification-bell.svg';

import { Popup, Typography } from 'design_system/src';
import { logout } from 'store/actions/user';
import { getUser } from 'localStorage/authStorage';
import profileDefaultOzonio from 'static/images/ozonio_default.png';
import moment from 'moment';
import { ProfileResponse } from 'models/auth/interfaces/auth.interface';
import { Ozonio } from 'models/ozonio/interfaces/ozonio.interface';
import { findOzonioById } from 'models/ozonio/controllers/ozonios.controller';
import {
	findAllNotifications,
	markRead,
} from 'models/notification/controllers/notifications.controller';
import {
	NotificationClassification,
	NotificationClassificationValues,
} from 'models/notification/enums/notification.enum';
import { Notify } from 'models/notification/interfaces/notifications.interface';

const logos: any = {
	prod: logo,
	qa: logo,
	dev: logo,
};

function Navbar() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showLogout, setshowLogout] = useState(false);
	const [user, setUser] = useState<ProfileResponse | null>(null);
	const [ozonio, setOzonio] = useState<Ozonio | null>();
	const [notifications, setNotifications] = useState<Notify[]>([]);
	const [showNotifications, setshowNotifications] = useState(false);

	const handlelogout = () => {
		dispatch(logout());
		navigate('/auth');
	};

	const requestNotifications = () => {
		const userL = getUser();
		if (userL) {
			findAllNotifications({ user: userL.userId, read: false }).then((info) => {
				setNotifications(info.data);
			});
		}
	};

	useEffect(() => {
		const userL = getUser();
		setUser(userL);
		if (userL) {
			findOzonioById(userL.userId).then((data) => {
				setOzonio(data);
			});
			requestNotifications();
		}
	}, []);

	const redirect = (notification: Notify) => {
		setshowNotifications(false);
		markRead(notification._id).then(() => {
			requestNotifications();
			const classification =
				NotificationClassificationValues[
					notification.classification as NotificationClassification
				];
			if (classification.module) {
				navigate(
					`/${classification.module}/${
						notification.data[classification.idField]
					}`,
				);
			}
		});
	};

	return (
		<header className="navbar shadow_medium ">
			<img
				src={
					process.env.REACT_APP_ENVIROMENT
						? logos[process.env.REACT_APP_ENVIROMENT]
						: logo
				}
				alt="icon"
				className="h_xxl"
			/>
			<div className="flex_center m_r_xxxl">
				<div
					className="display_flex pos_relative cursor_pointer m_r_md"
					onClick={() => setshowNotifications(!showNotifications)}
					onKeyPress={() => setshowNotifications(!showNotifications)}
					tabIndex={0}
					role="button"
				>
					<Notification className="dim_lg text_neutral_700" />
					{notifications.length ? (
						<div className="indicator_unread dim_lg br_circle bg_primary_300 pos_absolute">
							<Typography
								scale="xsmall"
								weight="600"
								className="pos_absolute center"
							>
								{notifications.length}
							</Typography>
						</div>
					) : null}
					<Popup
						open={showNotifications}
						setOpen={setshowNotifications}
						placementX="right-inset"
						className="bg_neutral_100 br_xxs m_t_md notification_popup"
					>
						{!notifications.length ?? (
							<div className="p_md border_b_solid border_b_1 border_neutral_400">
								<Typography scale="small" weight="400" className="text_center">
									No hay notificaciones sin leer
								</Typography>
							</div>
						)}
						{notifications.map((notification, i) => (
							<div
								className="p_md border_b_solid border_b_1 border_neutral_400 cursor_pointer"
								key={`${notification.title}${i}`}
								onClick={() => redirect(notification)}
								onKeyPress={() => redirect(notification)}
								tabIndex={0}
								role="button"
							>
								<div className="display_flex flex_justify_between">
									<Typography scale="medium" weight="600">
										[{notification.data?.internalId}] {notification.title}
									</Typography>
									<Typography
										scale="xsmall"
										weight="600"
										textColor="neutral_600"
									>
										{moment
											.parseZone(notification.createdAt)
											.format('DD/MM/YYYY HH:mm')}
									</Typography>
								</div>
								<Typography scale="small" weight="400">
									{notification.description}
								</Typography>
							</div>
						))}
					</Popup>
				</div>
				<img src={divider} alt="divider" />
				<div className="dso_card_img_small dim_xxl m_l_md bg_neutral_200 display_flex overflow_hidden">
					{ozonio?.image ? (
						<img
							src={ozonio.image.url}
							alt=""
							className="cards_photo dim_xxl"
						/>
					) : (
						<img src={profileDefaultOzonio} alt="ozonio" />
					)}
				</div>
				<div
					className="pos_relative cursor_pointer m_l_xs flex_center"
					onClick={() => setshowLogout(!showLogout)}
					onKeyPress={() => setshowLogout(!showLogout)}
					tabIndex={0}
					role="button"
				>
					<div>
						<Typography
							scale="medium"
							weight="400"
							className="text_neutral_900"
						>
							{user?.name}
						</Typography>
						<Typography scale="small" weight="400" className="text_neutral_700">
							{user?.role}
						</Typography>
					</div>
					<Down className="neutral_900 dim_md m_l_md " />
					<Popup
						open={showLogout}
						setOpen={setshowLogout}
						placementX="right-inset"
						className="bg_neutral_100 br_xxs m_t_md"
					>
						<Typography
							className="menu_selectable p_md"
							onClick={handlelogout}
							onKeyPress={handlelogout}
							role="button"
							tabIndex={0}
							scale="medium"
							weight="400"
						>
							Cerrar sesión
						</Typography>
					</Popup>
				</div>
			</div>
		</header>
	);
}

export default Navbar;
