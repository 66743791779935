/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import './Loan.scss';

import {
	Typography,
	Button,
	ModalConfirmation as Modal,
	Knob,
	Pager,
	ListTable,
	Select,
	DateSelect,
} from 'design_system/src';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getCreditsFilter, setCreditsFilter } from 'localStorage/filterStorage';
import profileDefaultOzoner from 'static/images/default_profile_ozoner.png';

import motoLoading from 'static/icons/loading_moto.gif';

import { LightTooltip } from 'components/hocs/tooltip/Tooltip';
import { ReactComponent as Pin } from 'design_system/src/static/icons/pin.svg';
import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';
import {
	Loan,
	LoanFilterDto,
	LoanWithExtraFields,
} from 'models/loan/interfaces/loan.interface';
import {
	LoanClassification,
	LoanStatus,
	LoanStatusFlags,
	LoanStatusFlagsValues,
	LoanStatusValues,
} from 'models/loan/enums/loan.enum';
import useFindAllParams from 'hooks/useFindAllParams';
import useDateFilters from 'hooks/useDatesFilters';
import PersonalFiltersSection from 'components/hocs/personalFiltersSection/PersonalFiltersSection';
import { UserFilter } from 'models/ozonio/interfaces/ozonio.interface';
import { formatValuesIfNotMaxLength } from 'models/shared/helpers/utils';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { FilterSections } from 'models/ozonio/enums/ozonio.enum';
import { OzonerStatus } from 'models/ozoner/enums/ozoner.enums';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import SearchBar from '../../components/hocs/searchBar/searchBar';
import { findAllLoans } from '../../models/loan/controllers/loan.controller';

let numFetch = 0;
const classOptions = ['AAA', 'AA', 'A', 'B', 'C'];
const classLevel: any = {
	[LoanClassification.AAA]: { value: 100, color: 'green_300' },
	[LoanClassification.AA]: { value: 80, color: 'green_300' },
	[LoanClassification.A]: { value: 60, color: 'green_300' },
	[LoanClassification.B]: { value: 40, color: 'yellow_100' },
	[LoanClassification.C]: { value: 20, color: 'red_300' },
};

const sortOptions = [
	{
		value: 'createdAt',
		label: 'Más antiguos',
	},
	{
		value: '-createdAt',
		label: 'Más recientes',
	},
];

const getStatusFilterName = (status: string) =>
	`statusPersonalFilter_${status}`;

const Loans = () => {
	const {
		findAllParams: {
			data,
			currentPage,
			pages,
			totals,
			loading,
			query,
			sortValue,
			count,
		},
		setData,
		setCurrentPage,
		setPages,
		setTotals,
		setLoading,
		setQuery,
		setSortValue,
		setCount,
	} = useFindAllParams<LoanWithExtraFields, Loan>();
	const navigate = useNavigate();

	const [openSaveFilter, setOpenSaveFilter] = useState<boolean>(false);
	const [selectedPersonalFilter, setSelectedPersonalFilter] =
		useState<string>('');

	const [statusFilter, setStatusFilter] = useState<LoanStatus[]>([]);
	const [classificationFilter, setClassificationFilter] = useState<
		LoanClassification[]
	>([]);
	const { datesFilter, hasDatesFilter, setDatesFilter, setHasDatesFilter } =
		useDateFilters();
	const [flagFilter, setFlagFilter] = useState<LoanStatusFlags[]>([]);

	const changePersonalFilter = (uFilter: UserFilter) => {
		if (uFilter._id !== selectedPersonalFilter) {
			setSelectedPersonalFilter(uFilter._id);
			setClassificationFilter(uFilter.values.scoreFilter ?? []);
			setFlagFilter(uFilter.values.flagFilter ?? []);
			setStatusFilter(uFilter.values.statusFilter ?? []);
			if (uFilter.values.datesFilter) {
				setDatesFilter({
					...uFilter.values.datesFilter,
					initial: new Date(uFilter.values.datesFilter.initial),
					last: new Date(uFilter.values.datesFilter.last),
				});
				setHasDatesFilter(true);
			} else {
				setHasDatesFilter(false);
			}
		}
	};

	const setStatusPersonalFilter = (value: LoanStatus) => {
		const name = getStatusFilterName(value);
		setSelectedPersonalFilter(name);
		setClassificationFilter([]);
		setFlagFilter([]);
		setHasDatesFilter(false);
		setStatusFilter([value]);
	};

	const getActualFilter = () => ({
		statusFilter,
		scoreFilter: classificationFilter,
		flagFilter,
		datesFilter: hasDatesFilter ? datesFilter : undefined,
	});

	const isFiltersModified = () =>
		!!selectedPersonalFilter ||
		(!classificationFilter.length &&
			!flagFilter.length &&
			!statusFilter.length &&
			!hasDatesFilter);

	const asyncFetchCredits = async (page: number) => {
		try {
			setLoading(true);
			const a = numFetch + 1;
			numFetch += 1;

			const params: LoanFilterDto = {
				classification: formatValuesIfNotMaxLength(
					classificationFilter,
					classOptions.length,
				),
				status: formatValuesIfNotMaxLength(
					statusFilter,
					Object.keys(LoanStatusValues).length,
				),
				sort: sortValue && sortValue !== '' ? sortValue : undefined,
				query,
				fieldsRequired:
					'ozoner,vehicle,code,status,createdAt,activatedAt,payments,financialFormId,loanClassification,isCorralon,isStolen,isFraud,isJudicialCollection',
				page,
			};
			if (flagFilter.length) {
				flagFilter.forEach((flag: LoanStatusFlags) => {
					params[flag] = true;
				});
			}
			if (datesFilter && hasDatesFilter) {
				params['min'] = moment.utc(datesFilter.initial).format('YYYY-MM-DD');
				params['max'] = moment.utc(datesFilter.last).format('YYYY-MM-DD');
			}

			const rows = await findAllLoans(params);

			if (a === numFetch) {
				setData(rows.data);
				setPages(rows.pages);
				setCount(rows.count);
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			setData([]);
			commonErrorHandler(e);
		}
	};

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		if (currentPage === 1) {
			asyncFetchCredits(currentPage);
		} else {
			handlePageChange(1);
		}
	}, [
		statusFilter,
		classificationFilter,
		flagFilter,
		datesFilter,
		hasDatesFilter,
		sortValue,
	]);
	useEffect(() => {
		asyncFetchCredits(currentPage);
	}, [currentPage]);

	useEffect(() => {
		handleEventGoogle(
			'VIEW_Panda_loans',
			'Panda',
			'page view to the loans list view',
		);
		const information = getCreditsFilter();
		if (information.query) {
			setQuery(information.query);
		}
		if (information.statusFilter) {
			setStatusFilter(information.statusFilter);
		}
		if (information.currentPage) {
			setCurrentPage(information.currentPage);
		}
		if (information.selectedPersonalFilter) {
			setSelectedPersonalFilter(information.selectedPersonalFilter);
		}
		if (information.classificationFilter) {
			setClassificationFilter(information.classificationFilter);
		}
		if (information.flagFilter) {
			setFlagFilter(information.flagFilter);
		}
		if (information?.datesFilter) {
			setDatesFilter({
				...information.datesFilter,
				initial: new Date(information.datesFilter.initial),
				last: new Date(information.datesFilter.last),
			});

			setHasDatesFilter(true);
		}
	}, []);

	useEffect(() => {
		setCreditsFilter({
			query,
			statusFilter,
			currentPage,
			scoreFilter: classificationFilter,
			flagFilter,
			datesFilter: hasDatesFilter ? datesFilter : null,
			selectedPersonalFilter,
		});
	}, [
		query,
		currentPage,
		statusFilter,
		classificationFilter,
		flagFilter,
		datesFilter,
		hasDatesFilter,
		selectedPersonalFilter,
	]);

	const goToCredit = (loan: LoanWithExtraFields) => {
		navigate(
			`/ozoner/${loan.financialForm.ozoner._id}?tab=4&formId=${loan.financialForm._id}`,
		);
	};

	const handleSearch = (event: any) => {
		event.preventDefault();
		asyncFetchCredits(currentPage);
	};

	return (
		<div className="dim_100_per flex_content_col shadow_hard inner_container bg_neutral_400">
			<div className="flex_header">
				<div className="bg_neutral_0 shadow_hard p_t_xs p_b_md p_x_lg">
					<div className="display_flex flex_gap_sm flex_align_center">
						<Typography scale="heading3" weight="600" textColor="neutral_900">
							Créditos
						</Typography>
						<SearchBar
							className="flex_grow_1"
							handleSearch={handleSearch}
							query={query}
							setQuery={setQuery}
							sortOptions={sortOptions}
							sortValue={sortValue}
							setsortValue={setSortValue}
						/>
					</div>
					<div className="display_flex flex_gap_xl m_b_xs">
						<div className="display_flex flex_gap_xl flex_grow_1 flex_align_start">
							<Select
								fixedPlaceholder
								variant="checkbox"
								placeholder="Estado"
								options={Object.keys(LoanStatusValues).map((key: any) => ({
									label: LoanStatusValues[key as LoanStatus].title,
									value: key,
								}))}
								value={statusFilter}
								onChange={(e: any) => {
									setStatusFilter(e.target.value);
									setSelectedPersonalFilter('');
								}}
							/>
							<Select
								fixedPlaceholder
								variant="checkbox"
								placeholder="Calificación"
								options={classOptions}
								value={classificationFilter}
								onChange={(e: any) => {
									setClassificationFilter(e.target.value);
									setSelectedPersonalFilter('');
								}}
							/>
							<Select
								fixedPlaceholder
								variant="checkbox"
								placeholder="Etiqueta"
								options={Object.keys(LoanStatusFlagsValues).map((key) => ({
									value: key,
									label:
										LoanStatusFlagsValues[key as LoanStatusFlags]?.title ?? key,
								}))}
								value={flagFilter}
								onChange={(e: any) => {
									setFlagFilter(e.target.value);
									setSelectedPersonalFilter('');
								}}
							/>
							<DateSelect
								value={datesFilter}
								hasValue={hasDatesFilter}
								setHasValue={setHasDatesFilter}
								placeholder="Fecha de creación"
								setValue={(e: any) => {
									setDatesFilter(e);
									setHasDatesFilter(true);
									setSelectedPersonalFilter('');
								}}
							/>
						</div>
						<div className="display_flex flex_gap_xs flex_grow_0 flex_align_center">
							<Button
								variant="ghost"
								scale="small"
								icon={<Pin />}
								onClick={() => setOpenSaveFilter(true)}
							>
								Fijar filtro
							</Button>
						</div>{' '}
					</div>
					<PersonalFiltersSection
						section={FilterSections.creditsList}
						selectedFilter={selectedPersonalFilter}
						setSelectedFilter={setSelectedPersonalFilter}
						changeFilter={changePersonalFilter}
						isModified={isFiltersModified}
						setStatusPersonalFilter={setStatusPersonalFilter}
						getActualFilter={getActualFilter}
						openSaveFilter={openSaveFilter}
						setOpenSaveFilter={setOpenSaveFilter}
					/>
				</div>
			</div>
			<div className="flex_body p_x_lg p_t_md overflow_x_hidden">
				{loading ? (
					<div className="flex_center_col dim_100_per">
						<Typography
							weight="600"
							scale="heading4"
							textColor="neutral_800"
							className="m_b_xs"
						>
							Cargando los créditos...
						</Typography>
						<img src={motoLoading} alt="" className="h_xxxl" />
					</div>
				) : data?.length ? (
					<ListTable
						hideHeader
						onRowClick={goToCredit}
						data={data}
						canSelect={false}
						cols={[
							{
								label: 'Foto',
								render: (row) => (
									<div className="display_flex flex_gap_md flex_align_center">
										<div className="flex_center">
											<div className=" border_solid border_4 w_fit br_sm bg_neutral_200 display_flex overflow_hidden border_primary_300">
												<img
													src={
														row.financialForm?.ozoner?.image?.url ||
														profileDefaultOzoner
													}
													alt=""
													className="dim_xxl"
												/>
											</div>
										</div>
										<div>
											<Typography scale="small" weight="600">
												{row.financialForm?.ozoner?.name ?? '-'}{' '}
												{row.financialForm?.ozoner?.lastName ?? '-'}
											</Typography>
											<div className="flex_center flex_gap_xs w_fit">
												{row.vehicle ? (
													<>
														<Typography
															scale="xsmall"
															weight="600"
															textColor="neutral_900"
														>
															{row.vehicle?.internalId}
														</Typography>
														<Typography
															scale="xsmall"
															weight="400"
															textColor="neutral_600"
														>
															{`${row.vehicle?.brand?.name ?? ''} ${
																row.vehicle?.model?.name ?? ''
															} ${row.vehicle?.cylindersCapacity ?? ''} ${
																row.vehicle?.suffix ?? ''
															}`}
														</Typography>
													</>
												) : (
													<Typography
														scale="small"
														weight="400"
														textColor="neutral_600"
													>
														sin datos de vehiculo
													</Typography>
												)}
											</div>
										</div>
										<div className="display_flex flex_align_center flex_wrap flex_gap_xs">
											{Object.keys(LoanStatusFlagsValues)
												.filter((key) => (row as any)[key])
												.map((key) => (
													<LightTooltip
														title={
															LoanStatusFlagsValues[key as LoanStatusFlags]
																?.tooltip ?? key
														}
													>
														<div>
															<Typography
																scale="xxsmall"
																weight="600"
																className="br_xxs p_x_xs p_y_xxxs text_center text_red_300 pos_relative bg_red_200_nocontrast text_no_break"
															>
																{LoanStatusFlagsValues[key as LoanStatusFlags]
																	?.title ?? key}
															</Typography>
														</div>
													</LightTooltip>
												))}
										</div>
										<div className="flex_grow_1 flex_justify_end display_flex">
											<div className="br_xxs bg_neutral_200 flex_gap_xl p_x_xl p_y_xs flex_center w_fit br_sm">
												<div>
													<Typography
														scale="small"
														weight="600"
														textColor="neutral_900"
														className="text_no_break text_center"
													>
														{row.createdAt
															? moment(row.createdAt)
																	.utcOffset(-360)
																	.locale('es')
																	.format('DD/MM/YYYY')
															: '--'}
													</Typography>
													<Typography
														scale="xsmall"
														weight="400"
														textColor="neutral_600"
														className="text_no_break text_center"
													>
														Creación
													</Typography>
												</div>
												<div className="table_divider h_xl" />
												<div>
													<Typography
														scale="small"
														weight="600"
														textColor="neutral_900"
														className="text_no_break text_center"
													>
														{row.createdAt
															? moment(row.createdAt)
																	.utcOffset(-360)
																	.locale('es')
																	.format('DD/MM/YYYY')
															: '--'}
													</Typography>
													<Typography
														scale="xsmall"
														weight="400"
														textColor="neutral_600"
														className="text_no_break text_center"
													>
														Activación
													</Typography>
												</div>
												<div className="table_divider h_xl" />
												<div>
													<Typography
														scale="small"
														weight="600"
														textColor="neutral_900"
														className="text_no_break text_center"
													>
														{row.code ?? 'N/D'}
													</Typography>
													<Typography
														scale="xsmall"
														weight="400"
														textColor="neutral_600"
														className="text_no_break text_center"
													>
														# contrato
													</Typography>
												</div>
												<div className="table_divider h_xl" />
												<div>
													<Typography
														scale="small"
														weight="600"
														textColor="green_300"
														className="text_no_break text_center"
													>
														{priceFormatFinancial(row.totalPaid, 0)}
													</Typography>
													<Typography
														scale="xsmall"
														weight="400"
														textColor="neutral_600"
														className="text_no_break text_center"
													>
														Pagado
													</Typography>
												</div>
											</div>
										</div>
										<div className="flex_center flex_gap_xs flex_shrink_0">
											<div className="flex_center_col">
												<Typography
													scale="xsmall"
													weight="400"
													textColor={
														classLevel[row.loanClassification]?.color ??
														'red_300'
													}
													className="text_no_break text_center"
												>
													Crédito: {row.loanClassification}
												</Typography>
												<Knob
													value={classLevel[row.loanClassification]?.value ?? 0}
													variant="gauge"
													symbol="%"
													color={
														classLevel[row.loanClassification]?.color ??
														'red_300'
													}
													className="dim_xxxl"
												/>
											</div>
											<LightTooltip
												title={
													LoanStatusValues[
														row?.status as keyof typeof LoanStatus
													]?.tooltip ?? row?.status
												}
											>
												<div
													className={`
                                            dso_chip_small 
                                            bg_${
																							LoanStatusValues[
																								row?.status as keyof typeof LoanStatus
																							]?.color
																						}_nocontrast text_neutral_0 overflow_hidden overflow_ellipsis text_center credit_table_status
                                            `}
												>
													{LoanStatusValues[
														row?.status as keyof typeof LoanStatus
													]?.title ?? row?.status}
												</div>
											</LightTooltip>
										</div>
									</div>
								),
							},
						]}
					/>
				) : (
					<div className="flex_center dim_100_per">
						<Typography
							scale="heading4"
							weight="600"
							className="text_neutral_900 m_xl text_center"
						>
							Tus filtros no generaron resultados
						</Typography>
					</div>
				)}
			</div>

			<div className="display_flex flex_align_center flex_justify_between flex_footer bg_neutral_0 p_x_xxxl p_y_md">
				<div className="display_flex flex_gap_md" />

				<Pager
					totalPages={pages ?? 0}
					setPage={handlePageChange}
					page={currentPage}
					className="w_fit"
					count={count}
				/>
			</div>
		</div>
	);
};

export default Loans;
