/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect, useState } from 'react';

import './knob.scss';

interface KnobProps {
	value: number;
	symbol?: string;
	className?: string;
	total?: number;
	color?: string;
	colorText?: string;
	variant?: 'classic' | 'fixed' | 'gauge';
}
const Knob: FC<KnobProps> = ({
	value,
	total = 100,
	className = '',
	symbol = '',
	color = 'primary_200',
	colorText = 'neutral_900',
	variant = 'classic',
}) => {
	const [x, setX] = useState(-0.5);
	const [y, setY] = useState(0);
	const [gauge1, setGauge1] = useState('0.025 -0.15');
	const [gauge2, setGauge2] = useState('-0.025 -0.15');
	const gaugeRadius = 0.1;
	const gaugeAngle = 1 / 6;

	// eslint-disable-next-line no-unused-vars
	const toDegrees = (rad: number) => (rad * 180) / Math.PI;

	useEffect(() => {
		let baseValue;
		switch (variant) {
			case 'fixed':
				baseValue = 0.8;
				break;
			case 'gauge':
				baseValue = Math.min(value, total) / (2 * total);
				break;

			default:
				baseValue = Math.min(value, total) / total;
				break;
		}
		baseValue = Math.min(1, baseValue);
		setX(0.5 * Math.cos(2 * Math.PI * baseValue));
		setY(
			0.5 * Math.sin(2 * Math.PI * baseValue) -
				(variant === 'gauge' ? 0.25 : 0),
		);

		setGauge1(
			`${gaugeRadius * Math.cos(Math.PI * (2 * baseValue - gaugeAngle))} ${
				0.05 * Math.sin(Math.PI * (2 * baseValue - gaugeAngle)) - 0.2
			}`,
		);
		setGauge2(
			`${gaugeRadius * Math.cos(Math.PI * (2 * baseValue + gaugeAngle))} ${
				0.05 * Math.sin(Math.PI * (2 * baseValue + gaugeAngle)) - 0.2
			}`,
		);
	}, [value]);
	return (
		<div
			className={`${className}`}
			style={{ transform: variant === 'fixed' ? 'rotate(35deg)' : '' }}
		>
			<svg viewBox="-0.6 -0.6 1.2 1.2">
				{variant !== 'gauge' && (
					<text
						textAnchor={variant === 'fixed' ? 'end' : 'middle'}
						pointerEvents="none"
						className={`text_${colorText}`}
						y="0.0875"
						fontSize="0.30"
						fontWeight="bold"
						style={{
							transform: variant === 'fixed' ? 'rotate(-35deg)' : '',
						}}
					>
						{value}
						{symbol}
					</text>
				)}
				<g
					className="arc"
					transform="rotate(-180)"
					strokeLinecap="round"
					strokeWidth={variant === 'gauge' ? '0.2' : '0.12'}
					fill="none"
				>
					{variant === 'gauge' && (
						<path
							className="border_neutral_500"
							d="M -0.5 -0.25 A 0.5 0.5 0 0 0 0.5 -0.25"
						/>
					)}
					<path
						className={`border_${color}`}
						d={`M ${x} ${y} A 0.5 0.5 0 ${
							(variant === 'fixed' ? 80 : value) / total > 0.5 &&
							variant !== 'gauge'
								? 1
								: 0
						} 0 0.5 ${variant === 'gauge' ? -0.25000001 : 0.0000001}`}
					/>
					{variant === 'gauge' && (
						<path
							fill="#000"
							d={`M ${gauge2} A ${gaugeRadius} ${gaugeRadius} 0 1 1 ${gauge1} L ${x} ${y}`}
						/>
					)}
				</g>
			</svg>
		</div>
	);
};

export default Knob;

// eslint-disable-next-line no-lone-blocks
{
	/* <svg width="27" height="11" viewBox="0 0 27 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.8653 3.40873C23.0848 2.97657 24.3042 2.54299 25.5237 2.11368C25.6791 2.05948 25.8389 2.01955 25.9958 1.96963C26.3381 1.85981 26.5035 1.62875 26.4265 1.26933C26.3466 0.901356 26.0742 0.787254 25.7319 0.814353C24.6679 0.898503 23.6595 1.26791 22.6141 1.44762C22.1962 1.52036 21.7811 1.61021 21.3647 1.69151C17.2642 2.58578 13.1636 3.47862 9.06313 4.37146C8.83208 4.42138 8.59532 4.45561 8.36854 4.52265C7.5356 4.76939 6.84385 4.79934 6.04515 4.16323C4.92125 3.26753 3.15553 3.47719 1.98742 4.36433C0.87065 5.21296 0.312987 6.84889 0.685243 8.191C1.08317 9.62725 2.25984 10.6499 3.72176 10.8296C5.50317 11.0478 7.01644 10.1336 7.63544 8.45343C7.73385 8.1853 7.7966 7.95282 8.13463 7.84442C12.7158 6.37537 17.2913 4.8892 21.8681 3.40731L21.8653 3.40873Z" fill="#343434" />
</svg> */
}
