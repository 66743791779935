/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import ReactGA from 'react-ga4';
import React, { useEffect, useState } from 'react';

// css
import './inventory.scss';

import { ReactComponent as AddCircle } from 'design_system/src/static/icons/add-circle.svg';
import { ReactComponent as Pin } from 'design_system/src/static/icons/pin.svg';
import { LightTooltip } from 'components/hocs/tooltip/Tooltip';

import {
	MaintenanceColorValues,
	VehicleStatusValues,
	VehicleSortType,
	VehicleStatus,
} from 'models/inventory/enums/vehicle.enum';
import {
	Vehicle,
	VehicleFilterDto,
	VehiclePopulated,
} from 'models/inventory/interfaces/vehicle.interface';
import { getVehicles } from 'models/inventory/controllers/vehicle.controller';
import { getBrandsWithModels } from 'models/inventory/controllers/brand.controller';
import {
	Typography,
	Button,
	Pager,
	DateSelect,
	Select,
	ListTable,
} from 'design_system/src';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	getInventoryFilter,
	setInventoryFilter,
} from 'localStorage/filterStorage';
import moment from 'moment';
import motoLoading from 'static/icons/loading_moto.gif';
import { UserFilter } from 'models/ozonio/interfaces/ozonio.interface';
import useFindAllParams from 'hooks/useFindAllParams';
import useDateFilters from 'hooks/useDatesFilters';
import { formatValuesIfNotMaxLength } from 'models/shared/helpers/utils';
import PersonalFiltersSection from 'components/hocs/personalFiltersSection/PersonalFiltersSection';
import { FilterSections } from 'models/ozonio/enums/ozonio.enum';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import SearchBar from '../../components/hocs/searchBar/searchBar';
import EditVehicle from './edit/EditVehicle';
import { vehicleListColumns } from './constants';
import CardInventory from './details/cardInventory/CardInventory';

interface AvailableFilters {
	available: boolean;
	delivered: boolean;
	aside: boolean;
	lost: boolean;
	inRecovery: boolean;
	recovered: boolean;
	left: boolean;
	underMaintenance: boolean;
	draft: boolean;
}
const defaultFilters = {
	available: true,
	delivered: true,
	aside: true,
	lost: true,
	inRecovery: true,
	recovered: true,
	left: true,
	underMaintenance: true,
	draft: true,
};
interface InventoryProps {
	onClick?: Function | null;
	initialFilter?: VehicleStatus[];
	availableFilters?: AvailableFilters;
	maxQuota?: number;
}

const weeksOptions = [
	{ value: '1', label: 'De contado' },
	{ value: '52', label: '52 Cuotas' },
	{ value: '78', label: '78 Cuotas' },
	{ value: '104', label: '104 Cuotas' },
	{ value: '156', label: '156 Cuotas' },
];

const filterOptions = [
	{
		_id: 'available_hide_vehicles',
		name: 'Vehiculos disponibles',
		values: {
			statusFilter: [VehicleStatus.AVAILABLE],
			weeksFilter: [],
		},
		default: true,
	},
	{
		_id: 'no_available_vehicles',
		name: 'Vehiculos no disponibles ',
		values: {
			statusFilter: [VehicleStatus.DRAFT],
			weeksFilter: [],
		},
		default: true,
	},
];

const sortOptions = [
	{ value: VehicleSortType.higherPrice, label: 'Precio más alto' },
	{ value: VehicleSortType.lowerPrice, label: 'Precio más bajo' },
	{ value: VehicleSortType.higherCC, label: 'Mayor CC' },
	{ value: VehicleSortType.lowerCC, label: 'Menor CC' },
	{ value: VehicleSortType.promotion, label: 'En promoción' },
	{ value: VehicleSortType.lowerKM, label: 'Menos KM' },
	{ value: VehicleSortType.higherKM, label: 'Más KM' },
];

const getStatusFilterName = (status: string) =>
	`statusPersonalFilter_${status}`;

// eslint-disable-next-line quotes
function Inventory({
	onClick = null,
	initialFilter = [],
	availableFilters = defaultFilters,
	maxQuota,
}: InventoryProps) {
	const navigate = useNavigate();
	const location = useLocation();

	const {
		findAllParams: {
			data,
			currentPage,
			pages,
			totals,
			loading,
			query,
			sortValue,
			count,
		},
		setData,
		setCurrentPage,
		setPages,
		setTotals,
		setLoading,
		setQuery,
		setSortValue,
		setCount,
	} = useFindAllParams<VehiclePopulated, Vehicle>({
		defaultSort: VehicleSortType.higherPrice,
	});
	const [isMounted, setIsMounted] = useState(false);

	// -----------------PERSONAL FILTERS STATES------------------- //
	const [openSaveFilter, setOpenSaveFilter] = useState<boolean>(false);
	const [selectedPersonalFilter, setSelectedPersonalFilter] =
		useState<string>('');

	// -----------------FILTERS STATES------------------- //
	const [statusFilter, setStatusFilter] = useState<VehicleStatus[]>([]);
	const [weeksFilter, setWeeksFilter] = useState([]);
	const [brandFilter, setBrandFilter] = useState('');
	const { datesFilter, hasDatesFilter, setDatesFilter, setHasDatesFilter } =
		useDateFilters();

	// -----------------PERSONAL FILTERS LOGIC------------------- //

	const changePersonalFilter = (uFilter: UserFilter) => {
		if (uFilter._id !== selectedPersonalFilter) {
			setSelectedPersonalFilter(uFilter._id);
			setStatusFilter(uFilter.values.statusFilter ?? []);
			setWeeksFilter(uFilter.values.weekFilter ?? []);
			setBrandFilter(uFilter.values.brandFilter ?? []);
			if (uFilter.values.datesFilter) {
				setDatesFilter({
					...uFilter.values.datesFilter,
					initial: new Date(uFilter.values.datesFilter.initial),
					last: new Date(uFilter.values.datesFilter.last),
				});
				setHasDatesFilter(true);
			} else {
				setHasDatesFilter(false);
			}
		}
	};

	const setStatusPersonalFilter = (value: VehicleStatus) => {
		const name = getStatusFilterName(value);
		setSelectedPersonalFilter(name);
		setWeeksFilter([]);
		setBrandFilter('');
		setHasDatesFilter(false);
		setStatusFilter([value]);
	};

	const getActualFilter = () => ({
		statusFilter,
		weekFilter: weeksFilter,
		brandFilter,
		datesFilter: hasDatesFilter ? datesFilter : undefined,
	});

	const isFiltersModified = () =>
		!!selectedPersonalFilter ||
		(!weeksFilter.length &&
			!brandFilter.length &&
			!statusFilter.length &&
			!hasDatesFilter);

	// -----------------MODULE LOGIC ------------------ //
	const requestVehicles = () => {
		setLoading(true);

		const params: VehicleFilterDto = {
			sort: sortValue && sortValue !== '' ? sortValue : undefined,
			query,
			page: currentPage,
			weeks: formatValuesIfNotMaxLength(weeksFilter, weeksOptions.length),
			brand: brandFilter && brandFilter !== '' ? brandFilter : undefined,
			status: formatValuesIfNotMaxLength(
				statusFilter,
				Object.keys(VehicleStatusValues).length,
			),
		};

		if (datesFilter && hasDatesFilter) {
			params['minCreatedAt'] = moment
				.utc(datesFilter.initial)
				.format('YYYY-MM-DD');
			params['maxCreatedAt'] = moment
				.utc(datesFilter.last)
				.format('YYYY-MM-DD');
		}

		setCount(0);
		getVehicles(params)
			.then((rows) => {
				if (rows) {
					setData(rows.data);
					setPages(rows.pages);
					setCount(rows.count);
					setLoading(false);
				}
			})
			.catch(() => {
				setLoading(false);
				setData([]);
			});
	};
	const [result, setResult] = useState();

	useEffect(() => {
		if (isMounted) {
			if (currentPage === 1) {
				requestVehicles();
			} else {
				setCurrentPage(1);
			}
		}
	}, [
		isMounted,
		statusFilter,
		result,
		query,
		weeksFilter,
		brandFilter,
		datesFilter,
		hasDatesFilter,
		sortValue,
	]);

	useEffect(() => {
		if (isMounted) {
			requestVehicles();
		}
	}, [currentPage]);

	useEffect(() => {
		ReactGA.event('VIEW_Panda_inventory', {
			category: 'Panda',
			label: 'page view to the vehicles list view',
		});
		const queryParams = new URLSearchParams(location.search);
		const queryStatusFilter = queryParams.get('statusFilter');
		const information = getInventoryFilter();

		if (information.query && !onClick) {
			setQuery(information.query);
		}
		if (information?.datesFilter) {
			setDatesFilter({
				...information.datesFilter,
				initial: new Date(information.datesFilter.initial),
				last: new Date(information.datesFilter.last),
			});

			setHasDatesFilter(true);
		}
		if (information.selectedPersonalFilter) {
			setSelectedPersonalFilter(information.selectedPersonalFilter);
		}
		if (information.weeksFilter) {
			setWeeksFilter(information.weeksFilter);
		}
		if (information.brandFilter) {
			setBrandFilter(information.brandFilter);
		}
		if (information.currentPage) {
			setCurrentPage(information.currentPage);
		}
		if (initialFilter.length) {
			setStatusFilter(initialFilter);
		} else if (information.statusFilter?.length) {
			setStatusFilter(information.statusFilter);
		} else if (queryStatusFilter) {
			setStatusFilter([queryStatusFilter as VehicleStatus]);
		} else {
			setStatusFilter([]);
		}
		if (onClick) {
			setSubview(true);
		} else {
			setSubview(false);
		}

		getBrandsWithModels().then((res: any) => {
			if (res) {
				const maped = res
					.map((ele: any) => ele.name)
					.sort((a: any, b: any) => {
						if (a < b) {
							return -1;
						}
						if (a > b) {
							return 1;
						}
						return 0;
					});
				setBrands(maped);
			}
		});

		if (!isMounted) {
			setIsMounted(true);
		}
	}, []);

	useEffect(() => {
		if (!onClick)
			setInventoryFilter({
				statusFilter,
				query,
				currentPage,
				weeksFilter,
				brandFilter,
				datesFilter: hasDatesFilter ? datesFilter : null,
				selectedPersonalFilter,
			});
	}, [
		statusFilter,
		query,
		currentPage,
		weeksFilter,
		brandFilter,
		datesFilter,
		hasDatesFilter,
		selectedPersonalFilter,
	]);

	const [brands, setBrands] = useState<any[]>([]);
	const [openDetails, setOpenDetails] = useState<boolean>(false);
	const [subview, setSubview] = useState<boolean>(false);

	const handleDetailsVeh = (id: string) => {
		navigate(`/inventory/${id}`);
	};

	const handleSearch = (event: any) => {
		event.preventDefault();
		requestVehicles();
	};

	const renderContent = () => {
		return (
			<div
				className={`dim_100_per flex_content_col ${
					subview ? '' : 'shadow_hard inner_container bg_neutral_400'
				}`}
			>
				<div className="flex_header">
					<div
						className={
							subview
								? 'p_t_xs'
								: 'bg_neutral_0 shadow_hard p_t_xs p_b_md p_x_lg'
						}
					>
						<div className="display_flex flex_gap_sm flex_align_center">
							{subview ? null : (
								<Typography
									scale="heading3"
									weight="600"
									textColor="neutral_900"
								>
									Inventario
								</Typography>
							)}
							<SearchBar
								sortOptions={sortOptions}
								sortValue={sortValue}
								setsortValue={setSortValue}
								handleSearch={handleSearch}
								query={query}
								setQuery={setQuery}
								miniSearchButton={subview}
								subview={subview}
								className="flex_grow_1"
							/>
						</div>
						<div className="display_flex flex_gap_xl m_b_xs">
							<div
								className={`display_flex flex_grow_1 ${
									subview ? 'flex_col flex_gap_md' : 'flex_gap_xs'
								}`}
							>
								<Select
									fixedPlaceholder
									variant="checkbox"
									placeholder="Estado"
									options={Object.keys(VehicleStatusValues)
										.filter((key) => (availableFilters as any)[key])
										.map((key: any) => ({
											label: (VehicleStatusValues as any)[key].title,
											value: key,
										}))}
									value={statusFilter}
									onChange={(e: any) => {
										setStatusFilter(e.target.value);
										setSelectedPersonalFilter('');
									}}
								/>
								<Select
									fixedPlaceholder
									variant="checkbox"
									placeholder="Semanas"
									options={weeksOptions}
									value={weeksFilter}
									onChange={(e: any) => {
										setWeeksFilter(e.target.value);
										setSelectedPersonalFilter('');
									}}
								/>
								<Select
									fixedPlaceholder
									variant="default"
									placeholder="Marca"
									options={[...brands, { value: '', label: 'Marca' }]}
									value={brandFilter}
									onChange={(e: any) => {
										setBrandFilter(e.target.value);
										setSelectedPersonalFilter('');
									}}
								/>
								{!subview && (
									<DateSelect
										value={datesFilter}
										hasValue={hasDatesFilter}
										placeholder="Fecha de creación"
										setHasValue={setHasDatesFilter}
										setValue={(e: any) => {
											setDatesFilter(e);
											setHasDatesFilter(true);
											setSelectedPersonalFilter('');
										}}
									/>
								)}
							</div>
							{!subview && (
								<div className="display_flex flex_gap_xs flex_grow_0 flex_align_center">
									<Button
										variant="ghost"
										scale="small"
										icon={<Pin />}
										onClick={() => setOpenSaveFilter(true)}
									>
										Fijar filtro
									</Button>
								</div>
							)}
						</div>
						<div className="display_flex flex_gap_xl overflow_x_auto p_b_md">
							{!subview ? (
								<PersonalFiltersSection
									section={FilterSections.vehiclesList}
									selectedFilter={selectedPersonalFilter}
									setSelectedFilter={setSelectedPersonalFilter}
									changeFilter={changePersonalFilter}
									isModified={isFiltersModified}
									setStatusPersonalFilter={setStatusPersonalFilter}
									getActualFilter={getActualFilter}
									openSaveFilter={openSaveFilter}
									setOpenSaveFilter={setOpenSaveFilter}
									defaultFilters={filterOptions}
								/>
							) : null}
						</div>
					</div>
				</div>

				<div
					className={`flex_body overflow_x_hidden ${subview ? '' : 'p_x_lg'}`}
				>
					{loading ? (
						<div className="flex_center_col dim_100_per">
							<Typography
								weight="600"
								scale="heading4"
								textColor="neutral_800"
								className="m_b_xs"
							>
								Cargando los vehiculos...
							</Typography>
							<img src={motoLoading} alt="" className="h_xxxl" />
						</div>
					) : data?.length ? (
						subview ? (
							<Grid
								container
								direction="row"
								spacing={3}
								className="h_100_per p_t_md spaced"
							>
								{data.map((i, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<Grid item md={subview ? 6 : 2} key={index}>
										<CardInventory
											vehicle={i}
											onClick={onClick}
											setResult={setResult}
											filterSalePrice={weeksFilter as any}
										/>
									</Grid>
								))}
							</Grid>
						) : (
							<ListTable
								data={data}
								onRowClick={(item) => handleDetailsVeh(item.internalId)}
								canSelect={false}
								rowClassName="br_none"
								hideHeader
								className="m_t_md"
								cols={vehicleListColumns}
							/>
						)
					) : (
						<div className="flex_center dim_100_per">
							<Typography
								scale="heading4"
								weight="600"
								className="text_neutral_900 m_xl text_center"
							>
								Tus filtros no generaron resultados
							</Typography>
						</div>
					)}
				</div>

				<div
					className={`display_flex flex_align_center flex_justify_between flex_footer ${
						subview ? '' : 'bg_neutral_0 p_x_xxxl p_y_md'
					}`}
				>
					{!subview && (
						<Button
							scale="small"
							icon={<AddCircle />}
							className="w_30_per"
							onClick={() => setOpenDetails(true)}
						>
							Añadir vehículo
						</Button>
					)}
					<Pager
						totalPages={pages ?? 0}
						page={currentPage}
						setPage={setCurrentPage}
						count={count}
						className="w_fit"
					/>
				</div>
				<EditVehicle
					open={openDetails}
					setOpen={setOpenDetails}
					setResult={setResult}
				/>
			</div>
		);
	};

	return renderContent();
}

export default Inventory;
