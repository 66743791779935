/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './Solicitudes.scss';
import {
	Button,
	Pager,
	Typography,
	Select,
	DateSelect,
	Knob,
	ModalConfirmation,
	Input,
	ListTable,
} from 'design_system/src';
import { Link } from 'react-router-dom';
import {
	getSolicitudesFilter,
	setSolicitudesFilter,
} from 'localStorage/filterStorage';
import { ReactComponent as Pin } from 'design_system/src/static/icons/pin.svg';
import { ReactComponent as Check } from 'design_system/src/static/icons/checkmark.svg';
import { ReactComponent as AddCircle } from 'design_system/src/static/icons/add-circle.svg';
import { ReactComponent as Monitor } from 'design_system/src/static/icons/monitor.svg';
import { ReactComponent as Panda } from 'design_system/src/static/icons/panda.svg';
import { ReactComponent as Fail } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as Trash } from 'design_system/src/static/icons/trash.svg';
import { ReactComponent as Success } from 'design_system/src/static/icons/tick-circle.svg';
import { ReactComponent as Clip } from 'design_system/src/static/icons/document.svg';
import { ReactComponent as Person } from 'design_system/src/static/icons/user-2.svg';
import { ReactComponent as Profile } from 'design_system/src/static/icons/add-profile.svg';
import { ReactComponent as Chat } from 'design_system/src/static/icons/chat-circle.svg';
import { ReactComponent as Helmet } from 'design_system/src/static/icons/helmet.svg';

import motoLoading from 'static/icons/loading_moto.gif';

import { LightTooltip } from 'components/hocs/tooltip/Tooltip';

import EditOzoner from 'views/ozoner/edits/EditOzoner';
import moment from 'moment';
import profileDefaultOzonio from 'static/images/ozonio_default.png';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';

import {
	findAllOzonios,
	findOzonioById,
} from 'models/ozonio/controllers/ozonios.controller';
import useFindAllParams from 'hooks/useFindAllParams';
import {
	FinancialFormFilter,
	GenericFinancialForm,
} from 'models/financial-form/interfaces/financial-form.interfaces';
import useDateFilters from 'hooks/useDatesFilters';
import { Ozonio, UserFilter } from 'models/ozonio/interfaces/ozonio.interface';
import { formatValuesIfNotMaxLength } from 'models/shared/helpers/utils';
import {
	ContactMethodsEnum,
	ContactMethodsValues,
	FinancialFormTypeEnum,
	FormStatus,
	FormStatusValues,
	SalesRankEnum,
	salesRankValues,
} from 'models/financial-form/enums/financial-form.enums';
import {
	EvidenceDocumentType,
	EvidenceDocumentTypeValues,
} from 'models/shared/types/documents';
import {
	batchSellerAssign,
	findAllFinancialForms,
	updateFinancialForm,
} from 'models/financial-form/controllers/financial-form.controller';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import PersonalFiltersSection from 'components/hocs/personalFiltersSection/PersonalFiltersSection';
import { FilterSections } from 'models/ozonio/enums/ozonio.enum';
import SearchBar from '../../components/hocs/searchBar/searchBar';

interface FormProgress {
	[EvidenceDocumentType.ine_front]: boolean;
	[EvidenceDocumentType.ine_back]: boolean;
	[EvidenceDocumentType.selfie]: boolean;
	[EvidenceDocumentType.bank_certificate_1]: boolean;
	[EvidenceDocumentType.bank_certificate_2]: boolean;
	[EvidenceDocumentType.bank_certificate_3]: boolean;
	[EvidenceDocumentType.proof_of_address]: boolean;
	references: boolean;
	total: number;
}

const createdOnOptions = [
	{
		label: 'Pandaboard',
		color: 'primary_200',
		value: 'pandaboard',
	},
	{
		label: 'Webpage',
		color: 'yellow_100',
		value: 'webpage',
	},
];
const digitalPlatformsOptions = [
	{
		label: 'Repartidor',
		value: true,
	},
	{
		label: 'No repartidor',
		value: false,
	},
];

const filterOptions: UserFilter[] = [
	{
		_id: 'validation_solicitudes',
		name: 'Solicitudes por validar',
		values: {
			statusFilter: [FormStatus.inValidation],
		},
		default: true,
	},
	{
		_id: 'approved_solicitudes',
		name: 'Solicitudes aprobadas',
		values: {
			statusFilter: [FormStatus.approved],
		},
		default: true,
	},
];

const requiredDocuments = [
	EvidenceDocumentType.ine_front,
	EvidenceDocumentType.ine_back,
	EvidenceDocumentType.selfie,
	EvidenceDocumentType.bank_certificate_1,
	EvidenceDocumentType.bank_certificate_2,
	EvidenceDocumentType.bank_certificate_3,
	EvidenceDocumentType.proof_of_address,
];

let numFetch = 0;

const sortOptions = [
	{
		value: 'name',
		label: 'A-Z',
	},
	{
		value: '-name',
		label: 'Z-A',
	},
	{
		value: '-updatedAt',
		label: 'Actualizado recientemente',
	},
	{
		value: 'createdAt',
		label: 'Más antiguos',
	},
	{
		value: '-createdAt',
		label: 'Más recientes',
	},
	{
		value: 'salesRank',
		label: 'Mejor segmentacion',
	},
	{
		value: '-salesRank',
		label: 'Peor segmentacion',
	},
];

const Solicitudes = () => {
	const {
		findAllParams: {
			data,
			currentPage,
			pages,
			totals,
			loading,
			query,
			sortValue,
			count,
		},
		setData,
		setCurrentPage,
		setPages,
		setTotals,
		setLoading,
		setQuery,
		setSortValue,
		setCount,
	} = useFindAllParams<GenericFinancialForm, GenericFinancialForm>();
	const [formsProgress, setFormsProgress] = useState<{
		[kay: string]: FormProgress;
	}>({});
	const [result, setResult] = useState();

	const [openDetails, setOpenDetails] = useState<boolean>(false);

	// -----------------PERSONAL FILTERS STATES------------------- //
	const [selectedPersonalFilter, setSelectedPersonalFilter] =
		useState<string>('');
	const [openSaveFilter, setOpenSaveFilter] = useState<boolean>(false);

	// -----------------FILTERS STATES------------------- //
	const [statusFilter, setStatusFilter] = useState<string[]>([]);
	const [createdOnFilter, setCreatedOnFilter] = useState([]);
	const [digitalPlatformsFilter, setDigitalPlatformsFilter] = useState([]);
	const [salesRankFilter, setSalesRankFilter] = useState([]);
	const [sellResponsibleFilter, setSellResponsibleFilter] = useState('');
	const [contactFilter, setContactFilter] = useState('');
	const { datesFilter, hasDatesFilter, setDatesFilter, setHasDatesFilter } =
		useDateFilters();

	const [ozonios, setOzonios] = useState<{ value: string; label: string }[]>(
		[],
	);
	const [ozoniosMap, setOzoniosMap] = useState<{
		[key: string]: Ozonio;
	}>({});
	const [ozoniosPhoto, setOzoniosPhoto] = useState<any>({});

	const [selecteds, setSelecteds] = useState<GenericFinancialForm[]>([]);

	const [openSeller, setOpenSeller] = useState(false);
	const [selectedSeller, setSelectedSeller] = useState('');
	const [loadingSeller, setLoadingSeller] = useState(false);

	const [openContact, setOpenContact] = useState(false);
	const [selectedContact, setSelectedContact] = useState('');
	const [loadingContact, setLoadingContact] = useState(false);

	const [openReachSuccess, setOpenReachSuccess] =
		useState<GenericFinancialForm>();

	const changePersonalFilter = (uFilter: UserFilter) => {
		if (uFilter._id !== selectedPersonalFilter) {
			setSelectedPersonalFilter(uFilter._id);
			setStatusFilter(uFilter.values.statusFilter ?? []);
			setCreatedOnFilter(uFilter.values.createdOnFilter ?? []);
			setDigitalPlatformsFilter(uFilter.values.digitalPlatformsFilter ?? []);
			setSalesRankFilter(uFilter.values.salesRankFilter ?? []);
			setSellResponsibleFilter(uFilter.values.sellResponsibleFilter ?? '');
			setContactFilter(uFilter.values.contactFilter ?? '');
			if (uFilter.values.datesFilter) {
				setDatesFilter({
					...uFilter.values.datesFilter,
					initial: new Date(uFilter.values.datesFilter.initial),
					last: new Date(uFilter.values.datesFilter.last),
				});
				setHasDatesFilter(true);
			} else {
				setHasDatesFilter(false);
			}
		}
	};

	const getActualFilter = () => ({
		createdOnFilter,
		digitalPlatformsFilter,
		sellResponsibleFilter,
		contactFilter,
		salesRankFilter,
		datesFilter: hasDatesFilter ? datesFilter : undefined,
		statusFilter,
	});

	const isFiltersModified = () =>
		!!selectedPersonalFilter ||
		(!createdOnFilter.length &&
			!digitalPlatformsFilter.length &&
			sellResponsibleFilter === '' &&
			contactFilter === '' &&
			!statusFilter.length &&
			!salesRankFilter.length &&
			!hasDatesFilter);
	const cleanFilter = () => {
		setCreatedOnFilter([]);
		setDigitalPlatformsFilter([]);
		setSalesRankFilter([]);
		setSellResponsibleFilter('');
		setContactFilter('');
		setHasDatesFilter(false);
		setStatusFilter([]);
		setSelectedPersonalFilter('');
	};

	const FetchSolicitud = async (page: number) => {
		try {
			setLoading(true);
			const a = numFetch + 1;
			numFetch += 1;
			const params: FinancialFormFilter = {
				createdOn: formatValuesIfNotMaxLength(
					createdOnFilter,
					createdOnOptions.length,
				),
				workInDigitalPlatforms: formatValuesIfNotMaxLength(
					digitalPlatformsFilter,
					digitalPlatformsOptions.length,
				) as any,
				salesRank: formatValuesIfNotMaxLength(
					salesRankFilter,
					Object.keys(salesRankValues).length,
				),
				status: formatValuesIfNotMaxLength(
					statusFilter,
					Object.keys(FormStatusValues).length,
				),
				sort: sortValue && sortValue !== '' ? sortValue : undefined,
				query,
				page,
			};

			if (sellResponsibleFilter !== '') {
				if (sellResponsibleFilter === 'noResponsible') {
					params.withSeller = false;
				} else if (sellResponsibleFilter === 'assignedResponsible') {
					params.withSeller = true;
				} else {
					params.sellResponsible = sellResponsibleFilter;
				}
			}
			if (datesFilter && hasDatesFilter) {
				params['min'] = moment.utc(datesFilter.initial).format('YYYY-MM-DD');
				params['max'] = moment.utc(datesFilter.last).format('YYYY-MM-DD');
			}

			const rows = await findAllFinancialForms(params);
			if (a === numFetch) {
				setData(rows.data);
				setPages(rows.pages);
				setCount(rows.count);

				if (currentPage > rows.pages) {
					setCurrentPage(1);
				}
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			setData([]);
			commonErrorHandler(e);
		}
	};

	useEffect(() => {
		const progress: {
			[key: string]: FormProgress;
		} = {};
		data?.forEach((row) => {
			const itemWeight = 1 / (requiredDocuments.length + 1);
			progress[row._id] = {
				total: 0,
				[EvidenceDocumentType.ine_front]: false,
				[EvidenceDocumentType.ine_back]: false,
				[EvidenceDocumentType.selfie]: false,
				[EvidenceDocumentType.bank_certificate_1]: false,
				[EvidenceDocumentType.bank_certificate_2]: false,
				[EvidenceDocumentType.bank_certificate_3]: false,
				[EvidenceDocumentType.proof_of_address]: false,
				references: false,
			};
			if (row.type === FinancialFormTypeEnum.loan) {
				row.documents?.forEach((doc) => {
					if (requiredDocuments.includes(doc.typeEvidence)) {
						progress[row._id].total += itemWeight;
						(progress[row._id] as any)[doc.typeEvidence] = true;
					}
				});
				row.ozoner?.documents?.forEach((doc) => {
					if (requiredDocuments.includes(doc.typeEvidence)) {
						progress[row._id].total += itemWeight;
						(progress[row._id] as any)[doc.typeEvidence] = true;
					}
				});
				if (row.personalInformation.personalReferences) {
					progress[row._id].total += itemWeight;
					progress[row._id].references = true;
				}
			} else {
				progress[row._id].total = 1;
			}
		});
		setFormsProgress(progress);
	}, [data]);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		handleEventGoogle(
			'VIEW_Panda_requests',
			'Panda',
			'page view to the loan requests list view',
		);
		const information = getSolicitudesFilter();
		if (information.searchQuery) {
			setQuery(information.searchQuery);
		}
		if (information.statusFilter) {
			setStatusFilter(information.statusFilter);
		}
		if (information.selectedPersonalFilter) {
			setSelectedPersonalFilter(information.selectedPersonalFilter);
		}
		if (information.createdOnFilter) {
			setCreatedOnFilter(information.createdOnFilter);
		}
		if (information.digitalPlatformsFilter) {
			setDigitalPlatformsFilter(information.digitalPlatformsFilter);
		}
		if (information.salesRankFilter) {
			setSalesRankFilter(information.salesRankFilter);
		}
		if (information.sellResponsibleFilter) {
			setSellResponsibleFilter(information.sellResponsibleFilter);
		}
		if (information.contactFilter) {
			setContactFilter(information.contactFilter);
		}
		if (information.datesFilter) {
			setDatesFilter({
				...information.datesFilter,
				initial: new Date(information.datesFilter.initial),
				last: new Date(information.datesFilter.last),
			});
			setHasDatesFilter(true);
		}
		setTimeout(() => {
			if (information.currentPage) {
				setCurrentPage(information.currentPage);
			} else {
				setCurrentPage(1);
			}
		}, 10);

		findAllOzonios('').then((ozons) => {
			if (ozons) {
				const ozoniosObj: { [key: string]: Ozonio } = {};
				setOzonios(
					ozons.data
						.map((ozonio) => {
							ozoniosObj[ozonio._id] = ozonio;
							return {
								value: ozonio._id,
								label: ozonio.name,
							};
						})
						.sort((a, b) => {
							if (a.label < b.label) {
								return -1;
							}
							if (a.label > b.label) {
								return 1;
							}
							return 0;
						}),
				);
				setOzoniosMap(ozoniosObj);

				setOzoniosPhoto(
					ozons.data.reduce((acc, ozo) => {
						return { ...acc, [ozo._id]: ozo?.image?.url };
					}, {}),
				);
			}
		});
	}, []);

	useEffect(() => {
		setSolicitudesFilter({
			statusFilter,
			currentPage,
			createdOnFilter,
			digitalPlatformsFilter,
			salesRankFilter,
			sellResponsibleFilter,
			contactFilter,
			datesFilter: hasDatesFilter ? datesFilter : null,
			selectedPersonalFilter,
		});
		if (currentPage === 1) {
			FetchSolicitud(currentPage);
		} else if (currentPage) {
			handlePageChange(1);
		}
	}, [
		statusFilter,
		createdOnFilter,
		digitalPlatformsFilter,
		salesRankFilter,
		sellResponsibleFilter,
		contactFilter,
		datesFilter,
		hasDatesFilter,
		selectedPersonalFilter,
		sortValue,
		result,
	]);
	useEffect(() => {
		setSolicitudesFilter({
			statusFilter,
			currentPage,
			createdOnFilter,
			digitalPlatformsFilter,
			salesRankFilter,
			sellResponsibleFilter,
			contactFilter,
			datesFilter: hasDatesFilter ? datesFilter : null,
			selectedPersonalFilter,
		});
		if (currentPage) {
			FetchSolicitud(currentPage);
		}
	}, [currentPage]);

	const handleRowClick = (row: GenericFinancialForm) => {
		// eslint-disable-next-line no-underscore-dangle
		return `/ozoner/${row.ozoner._id}?formId=${row._id}&tab=2`;
	};

	const handleSearch = (event: any) => {
		event.preventDefault();
		FetchSolicitud(currentPage);
	};

	const getFormLoanChip = (solicitud: GenericFinancialForm) => {
		// TODO: Implement this logic again
		// const finishedStatus = [
		//     FormStatus.completed,
		//     FormStatus.expired,
		//     FormStatus.canceled,
		//     FormStatus.rejected,
		// ];
		// const statuses = solicitud?.creditStatus.filter((status:string) => status !== FormStatus.pending) ?? [];
		// if (
		//     statuses.length &&
		//     !finishedStatus.includes(solicitud?.status)
		// ) {
		//     let msg;

		//     if (statuses.includes(LoanStatus.writeOff)) {
		//         msg = "Crédito en writeOff";
		//     } else if (
		//         statuses.includes(LoanStatus.repossession)
		//     ) {
		//         msg = "Moto recuperada";
		//     } else if (
		//         statuses.includes(LoanStatusFlags.isFraud)
		//     ) {
		//         msg = "Crédito con fraude";
		//     } else if (
		//         statuses.includes(LoanStatus.current)
		//     ) {
		//         msg = "Crédito en mora";
		//     } else if (
		//         statuses.includes(LoanStatus.devolution)
		//     ) {
		//         msg = "Moto devuelta";
		//     } else {
		//         return (
		//             <Typography
		//                 scale="xxsmall"
		//                 weight="600"
		//                 className="br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative bg_green_300_nocontrast"
		//             >
		//                 Segunda Moto
		//             </Typography>
		//         );
		//     }
		//     return (
		//         <Typography
		//             scale="xxsmall"
		//             weight="600"
		//             className="br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative bg_red_300_nocontrast"
		//         >
		//             {msg}
		//         </Typography>
		//     );
		// }
		return null;
	};

	const goWA = (number: string) =>
		window.open(`https://api.whatsapp.com/send?phone=${number}`, '_blank');

	const copyNumbers = () => {
		const contentToCopy = selecteds
			.map((solicitud) => `+${solicitud.personalInformation?.phone}`)
			.join(',');
		navigator.clipboard
			.writeText(contentToCopy)
			.then(() => {
				toasterNotification({
					toasterType: toasterOptions.success,
					msg: 'Copiado al portapapeles',
					style: 'light',
				});
			})
			.catch((error) => {
				toasterNotification({
					toasterType: toasterOptions.error,
					msg: `Error al copiar el contenido: ${error}`,
					style: 'light',
				});
			});
	};

	const closeSeller = () => {
		setOpenSeller(false);
		setSelectedSeller('');
		setSelecteds([]);
	};
	const assignSeller = () => {
		if (selectedSeller && selectedSeller !== '') {
			setLoadingSeller(true);
			batchSellerAssign(selecteds, selectedSeller)
				.then(() => {
					closeSeller();
					setLoadingSeller(false);
					FetchSolicitud(currentPage);
					toasterNotification({
						msg: 'Asignacion Exitosa',
						style: 'light',
						toasterType: toasterOptions.success,
					});
				})
				.catch((err) => {
					commonErrorHandler(err);
					setLoadingSeller(false);
				});
		}
	};

	const closeReachSuccess = () => {
		setOpenReachSuccess(undefined);
	};
	const reachSuccess = () => {
		if (openReachSuccess?._id) {
			setLoading(true);
			handleEventGoogle(
				'BTN_Pb_request_reached_success',
				'Panda Solicitudes',
				'click in reach request button',
			);
			updateFinancialForm(openReachSuccess?._id, {
				status: FormStatus.contacted,
			})
				.then(() => {
					toasterNotification({
						msg: 'Solicitud Exitosa',
						style: 'light',
						toasterType: toasterOptions.success,
					});
					closeReachSuccess();
					FetchSolicitud(currentPage);
				})
				.catch((err) => {
					commonErrorHandler(err);
				});
		}
	};

	const contact = () => {
		setLoadingContact(true);
		const p = selecteds.map((s) => {
			return updateFinancialForm(s._id, {
				status: FormStatus.contacted,
				loanApplication: {
					contactMethod: selectedContact as ContactMethodsEnum,
				},
			});
		});

		Promise.all(p)
			.then(() => {
				toasterNotification({
					msg: 'Solicitud Exitosa',
					style: 'light',
					toasterType: toasterOptions.success,
				});
				setSelectedContact('');
				setOpenContact(false);
				FetchSolicitud(currentPage);
				setLoadingContact(false);
			})
			.catch((err) => {
				commonErrorHandler(err);
				setLoadingContact(false);
			});
	};

	return (
		<div className="dim_100_per flex_content_col shadow_hard inner_container bg_neutral_400">
			<div className="flex_header">
				<div className="bg_neutral_0 shadow_hard p_t_xs p_b_md p_x_lg">
					<div className="display_flex flex_gap_sm flex_align_center">
						<Typography scale="heading3" weight="600" textColor="neutral_900">
							Solicitudes
						</Typography>
						<SearchBar
							className="flex_grow_1"
							handleSearch={handleSearch}
							query={query}
							setQuery={setQuery}
							sortOptions={sortOptions}
							sortValue={sortValue}
							setsortValue={setSortValue}
						/>
					</div>
					<div className="display_flex flex_gap_xl m_b_xs">
						<div
							className={`display_flex flex_gap_md flex_grow_1 flex_align_start ${
								statusFilter.length ? 'flex_col' : ''
							}`}
						>
							<div className="display_flex flex_gap_xl flex_align_start">
								<Select
									fixedPlaceholder
									variant="checkbox"
									placeholder="Estado"
									options={Object.entries(FormStatusValues).map(
										([key, value]) => ({
											label: value.title,
											value: key,
										}),
									)}
									value={statusFilter}
									onChange={(e: any) => {
										setStatusFilter(e.target.value);
										setSelectedPersonalFilter('');
									}}
									className="flex_grow_1"
								/>
								<Select
									fixedPlaceholder
									variant="checkbox"
									placeholder="Origen"
									options={createdOnOptions}
									value={createdOnFilter}
									onChange={(e: any) => {
										setCreatedOnFilter(e.target.value);
										setSelectedPersonalFilter('');
									}}
								/>
							</div>
							<div className="display_flex flex_gap_xl flex_align_start">
								<DateSelect
									value={datesFilter}
									hasValue={hasDatesFilter}
									setHasValue={setHasDatesFilter}
									placeholder="Fecha de creación"
									setValue={(e: any) => {
										setDatesFilter(e);
										setHasDatesFilter(true);
										setSelectedPersonalFilter('');
									}}
								/>
								<Select
									fixedPlaceholder
									variant="checkbox"
									placeholder="Segmentacíón"
									options={Object.entries(salesRankValues).map(
										([key, value]) => ({
											label: value.title,
											value: key,
										}),
									)}
									value={salesRankFilter}
									onChange={(e: any) => {
										setSalesRankFilter(e.target.value);
										setSelectedPersonalFilter('');
									}}
								/>
								<Select
									fixedPlaceholder
									variant="default"
									placeholder="Responsable"
									options={[
										{
											value: 'noResponsible',
											label: 'Sin Responsable',
										},
										{
											value: 'assignedResponsible',
											label: 'Responsable Asignado',
										},
										...ozonios,
										{ value: '', label: 'Responsable' },
									]}
									value={sellResponsibleFilter}
									onChange={(e: any) => {
										setSellResponsibleFilter(e.target.value);
										setSelectedPersonalFilter('');
									}}
								/>
								<Select
									variant="checkbox"
									placeholder="Repartidor"
									options={digitalPlatformsOptions}
									value={digitalPlatformsFilter}
									onChange={(e: any) => {
										setDigitalPlatformsFilter(e.target.value);
										setSelectedPersonalFilter('');
									}}
								/>
							</div>
						</div>
						<div
							className={`display_flex flex_gap_xs flex_grow_0 flex_justify_between ${
								statusFilter.length
									? 'flex_col flex_align_end'
									: 'flex_align_center'
							}`}
						>
							<Button
								variant="ghost"
								scale="small"
								icon={<Pin />}
								onClick={() => setOpenSaveFilter(true)}
							>
								Fijar filtro
							</Button>
							<Button
								variant="ghost"
								scale="small"
								icon={<Trash />}
								onClick={() => cleanFilter()}
								color="grey"
							>
								Limpiar Filtro
							</Button>
						</div>
					</div>
					<PersonalFiltersSection
						section={FilterSections.formsList}
						selectedFilter={selectedPersonalFilter}
						setSelectedFilter={setSelectedPersonalFilter}
						changeFilter={changePersonalFilter}
						isModified={isFiltersModified}
						setStatusPersonalFilter={() => {}}
						getActualFilter={getActualFilter}
						openSaveFilter={openSaveFilter}
						setOpenSaveFilter={setOpenSaveFilter}
						defaultFilters={filterOptions}
					/>
				</div>
			</div>
			<div className="flex_body p_x_lg overflow_x_hidden">
				{loading ? (
					<div className="flex_center_col dim_100_per">
						<Typography
							weight="600"
							scale="heading4"
							textColor="neutral_800"
							className="m_b_xs"
						>
							Cargando las solicitudes...
						</Typography>
						<img src={motoLoading} alt="" className="h_xxxl" />
					</div>
				) : data?.length ? (
					<ListTable
						data={data}
						canSelect
						rowClassName="br_none text_decoration_none"
						hideHeader
						className="m_t_md"
						to={handleRowClick}
						ContComponent={Link}
						selecteds={selecteds}
						setSelecteds={setSelecteds}
						selectId="idForm"
						cols={[
							{
								label: 'Nombre',
								weight: 4,
								render: (row: GenericFinancialForm) => (
									// eslint-disable-next-line no-nested-ternary
									<div className="display_flex flex_align_center flex_gap_md w_100_per">
										{row.ozoner?.createdOn === 'pandaboard' ? (
											<Panda className="dim_md text_primary_300" />
										) : (
											<Monitor className="dim_md text_primary_300" />
										)}

										<div className="flex_grow_1">
											<Typography
												scale="medium"
												weight="400"
												textColor="neutral_700"
												className=""
											>
												{row.ozoner?.name || '-'} {row.ozoner?.lastName || '-'}
											</Typography>
											<div className="display_flex flex_align_center flex_gap_xs">
												<Typography
													scale="xsmall"
													weight="400"
													textColor="neutral_700"
													className=""
												>
													{row.ozoner?.curp || '-'}
												</Typography>
												{getFormLoanChip(row)}
											</div>
										</div>
									</div>
								),
							},
							{
								label: 'Progreso',
								render: (row: GenericFinancialForm) =>
									row.type === FinancialFormTypeEnum.loan ? (
										<LightTooltip
											title={
												<div className="display_flex flex_col flex_gap_xs">
													<div className="display_flex flex_gap_sm">
														{formsProgress[row._id]?.references ? (
															<Success className="dim_sm text_green_300" />
														) : (
															<Fail className="dim_sm text_red_300" />
														)}
														<Typography
															scale="xsmall"
															weight="400"
															textColor="neutral_700"
															className="text_center flex_1 "
														>
															Referencias personales
														</Typography>
													</div>
													{requiredDocuments.map((type) => (
														<div
															className="display_flex flex_gap_sm"
															key={type}
														>
															{(formsProgress[row._id] as any)?.[type] ? (
																<Success className="dim_sm text_green_300" />
															) : (
																<Fail className="dim_sm text_red_300" />
															)}
															<Typography
																scale="xsmall"
																weight="400"
																textColor="neutral_700"
																className=""
															>
																{EvidenceDocumentTypeValues[type].title}{' '}
																{EvidenceDocumentTypeValues[type].subtitle}
															</Typography>
														</div>
													))}
												</div>
											}
										>
											<div className="flex_center_col">
												<Knob
													value={Math.round(
														(formsProgress[row._id]?.total ?? 0) * 100,
													)}
													symbol="%"
													className="dim_xxl border_transparent display_flex"
													color="green_300"
												/>
												<Typography
													scale="xsmall"
													weight="400"
													textColor="neutral_700"
													className="text_center flex_1"
												>
													Validaciones
												</Typography>
											</div>
										</LightTooltip>
									) : (
										<div />
									),
							},
							{
								label: 'Segmentacion',
								weight: 2,
								render: (row: GenericFinancialForm) =>
									row.type === FinancialFormTypeEnum.loan ? (
										<div className="flex_center flex_gap_xs w_100_per">
											<LightTooltip
												title={
													salesRankValues[
														row.loanApplication?.salesRank ?? SalesRankEnum.wood
													].tooltip ?? ''
												}
											>
												<div className="">
													<Typography
														scale="xsmall"
														weight="400"
														className="text_center flex_1"
													>
														Segmentación
													</Typography>
													<Typography
														scale="medium"
														weight="600"
														className={`text_center flex_1 text_${
															salesRankValues[
																row.loanApplication?.salesRank ??
																	SalesRankEnum.wood
															].color
														}`}
													>
														{
															salesRankValues[
																row.loanApplication?.salesRank ??
																	SalesRankEnum.wood
															].title
														}
													</Typography>
												</div>
											</LightTooltip>
											{row.financialInformation?.workInDigitalPlatforms ? (
												<LightTooltip title="Trabajador de plataformas digitales">
													<Helmet className="dim_lg text_primary_300" />
												</LightTooltip>
											) : null}
										</div>
									) : (
										<div />
									),
							},
							{
								label: 'Contacto',
								weight: 2,
								render: (row: GenericFinancialForm) =>
									row.type === FinancialFormTypeEnum.loan ? (
										<div>
											<Typography
												scale="medium"
												weight="400"
												className="text_center flex_1 text_link"
												onClick={(e) => {
													goWA(row.personalInformation?.phone ?? '');
													e.stopPropagation();
													e.preventDefault();
												}}
											>
												{row.personalInformation?.phone || '-'}
											</Typography>
											<Typography
												scale="xsmall"
												weight="400"
												textColor="neutral_700"
												className="text_center flex_1"
											>
												{FormStatusValues[row.status].order >= 2 ? (
													<div className="flex_center flex_gap_xs">
														<Typography
															scale="xsmall"
															weight="400"
															textColor="green_300"
															className="text_no_break text_center"
														>
															Contacto exitoso
														</Typography>
														<Check className="text_green_300 dim_md" />
													</div>
												) : row.loanApplication?.contactMethod ? (
													<Button
														scale="small"
														variant="outline"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setOpenReachSuccess(row);
														}}
													>
														Ozoner respondió
													</Button>
												) : (
													<Typography
														scale="xsmall"
														weight="400"
														textColor="red_300"
														className="text_no_break text_center"
													>
														Lead sin contactar
													</Typography>
												)}
											</Typography>
										</div>
									) : (
										<Typography
											scale="medium"
											weight="400"
											textColor="primary_300"
											className="text_no_break text_center"
										>
											Venta de Contado
										</Typography>
									),
							},
							{
								label: 'Moto',
								render: (row: GenericFinancialForm) => (
									<div className="flex_center">
										{row?.vehicle ? (
											<div className="flex_center_col">
												<Typography
													scale="medium"
													weight="600"
													textColor="neutral_700"
													className="text_center flex_1"
												>
													{row.vehicle?.internalId || '-'}
												</Typography>
												<Typography
													scale="xsmall"
													weight="400"
													textColor="neutral_700"
													className="text_center flex_1"
												>
													{row.vehicle?.brand || '-'}{' '}
													{row.vehicle?.model || '-'}{' '}
													{row.vehicle?.cylindersCapacity || '-'}
												</Typography>
											</div>
										) : (
											<Typography
												scale="medium"
												weight="400"
												textColor="neutral_700"
												className="text_center flex_1"
											>
												Sin vehiculo
											</Typography>
										)}
									</div>
								),
							},
							{
								label: 'Estado',
								weight: 2,
								render: (row: GenericFinancialForm) => (
									<div className="flex_center flex_gap_xs">
										{row.type === FinancialFormTypeEnum.loan ? (
											<LightTooltip
												title={
													ozoniosMap[row.loanApplication?.sellResponsible ?? '']
														?.name ?? 'Sin asignado'
												}
											>
												<div
													className="dim_xxl border_solid border_1 border_neutral_500 br_circle overflow_hidden flex_center"
													tabIndex={0}
													role="button"
													onClick={(e) => {
														e.stopPropagation();
														e.preventDefault();
														setSelecteds([row]);
														setOpenSeller(true);
														setSelectedSeller(
															row.loanApplication?.sellResponsible ?? '',
														);
													}}
													onKeyDown={(e) => {
														e.stopPropagation();
														e.preventDefault();
														setSelecteds([row]);
														setOpenSeller(true);
														setSelectedSeller(
															row.loanApplication?.sellResponsible ?? '',
														);
													}}
												>
													{row.loanApplication?.sellResponsible ? (
														<img
															src={
																ozoniosPhoto[
																	row.loanApplication?.sellResponsible
																] ?? profileDefaultOzonio
															}
															className="dim_100_per"
															alt=""
														/>
													) : (
														<Profile className="dim_lg text_neutral_700 border_none" />
													)}
												</div>
											</LightTooltip>
										) : null}
										<div className="flex_center_col pos_relative flex_gap_xxs">
											<LightTooltip
												title={
													FormStatusValues[row.status]?.tooltip ??
													'Sin informacion'
												}
											>
												<div>
													<Typography
														scale="medium"
														weight="600"
														className={`br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative
                                        bg_${
																					FormStatusValues[row.status]?.color
																				}_nocontrast`}
													>
														{FormStatusValues[row.status]?.title}
													</Typography>
												</div>
											</LightTooltip>
											{row.statusHistoric?.length ? (
												<Typography
													scale="xsmall"
													weight="400"
													textColor="neutral_700"
													className="text_center flex_1"
												>
													Fecha:{' '}
													{moment
														.parseZone(
															row.statusHistoric[row.statusHistoric.length - 1]
																.updatedAt,
														)
														.format('DD/MM/YY')}{' '}
												</Typography>
											) : null}
										</div>
									</div>
								),
							},
						]}
					/>
				) : (
					<div className="flex_center dim_100_per">
						<Typography
							scale="heading4"
							weight="600"
							className="text_neutral_900 m_xl text_center"
						>
							Tus filtros no generaron resultados
						</Typography>
					</div>
				)}
			</div>

			<div className="display_flex flex_align_center flex_justify_between flex_footer bg_neutral_0 p_x_xxxl p_y_md">
				{selecteds.length > 0 ? (
					<div className="display_flex flex_gap_md">
						<Button scale="small" icon={<Clip />} onClick={copyNumbers}>
							Copiar numeros de telefono
						</Button>
						<Button
							scale="small"
							icon={<Person />}
							onClick={() => setOpenSeller(true)}
							disabled={
								!selecteds.reduce(
									(acc, form) =>
										acc && form.type === FinancialFormTypeEnum.loan,
									true,
								)
							}
						>
							Asignar vendedor
						</Button>
						<Button
							scale="small"
							icon={<Chat />}
							onClick={() => setOpenContact(true)}
							disabled={
								!selecteds.reduce(
									(acc, form) =>
										acc &&
										form.type === FinancialFormTypeEnum.loan &&
										form.status === FormStatus.pending,
									true,
								)
							}
						>
							Registrar contacto
						</Button>
					</div>
				) : (
					<Button
						scale="small"
						icon={<AddCircle />}
						className="w_30_per"
						onClick={() => setOpenDetails(true)}
					>
						Crear nueva Solicitud
					</Button>
				)}
				<Pager
					page={currentPage}
					totalPages={pages || 1}
					setPage={handlePageChange}
					count={count}
					className="pager_solicitudes "
				/>
			</div>
			<EditOzoner
				open={openDetails}
				setOpen={setOpenDetails}
				setResult={setResult}
			/>

			{openSeller && (
				<ModalConfirmation
					openConfirmation={openSeller}
					handleClose={closeSeller}
					icon={<Person className="text_primary_300" />}
					widthDesktop={40}
					isConfirmationLoading={false}
					heightDesktop={80}
					actions={[
						{
							label: 'Volver',
							color: 'grey',
							action: closeSeller,
						},
						{
							label: 'Asignar vendedor',
							color: 'primary',
							action: () => assignSeller(),
							disabled:
								!selectedSeller || selectedSeller === '' || loadingSeller,
						},
					]}
				>
					<>
						<Typography scale="large" weight="600" className="m_b_xs">
							Asignar vendedor
						</Typography>
						<Typography
							scale="medium"
							weight="400"
							className="m_b_md"
							textColor="neutral_700"
						>
							Selecciona el vendedor al que se le{' '}
							{selecteds.length === 1
								? 'va a asignar la solicitud'
								: `van a asignar ${selecteds.length} solicitudes`}
						</Typography>
						<Input
							title=""
							name="Responsable de la entrega"
							placeholder="Ingresa el Ozonio"
							value={selectedSeller}
							onChange={(e: any) => setSelectedSeller(e.target.value)}
							className="w_100_per"
							type="select"
							options={ozonios}
							variant="default"
						/>
					</>
				</ModalConfirmation>
			)}
			{openContact && (
				<ModalConfirmation
					openConfirmation={openContact}
					handleClose={() => {
						setSelectedContact('');
						setOpenContact(false);
					}}
					icon={<Chat className="text_primary_300" />}
					widthDesktop={40}
					isConfirmationLoading={false}
					heightDesktop={80}
					actions={[
						{
							label: 'Volver',
							color: 'grey',
							action: () => {
								setSelectedContact('');
								setOpenContact(false);
							},
						},
						{
							label: 'Reportar contacto',
							color: 'primary',
							action: () => contact(),
							disabled:
								!selectedContact || selectedContact === '' || loadingContact,
						},
					]}
				>
					<>
						<Typography scale="large" weight="600" className="m_b_xs">
							Reportar contacto
						</Typography>
						<Typography
							scale="medium"
							weight="400"
							className="m_b_md"
							textColor="neutral_700"
						>
							Selecciona el metodo de contacto que se hizo
						</Typography>

						<Input
							title=""
							name="reachedMethod"
							placeholder="Metodo de contacto"
							value={selectedContact}
							onChange={(e: any) => setSelectedContact(e.target.value)}
							className="w_100_per"
							type="select"
							options={Object.entries(ContactMethodsValues).map(
								([key, value]) => ({
									label: value,
									value: key,
								}),
							)}
							variant="default"
						/>
					</>
				</ModalConfirmation>
			)}
			{!!openReachSuccess && (
				<ModalConfirmation
					openConfirmation={!!openReachSuccess}
					handleClose={closeReachSuccess}
					icon={<Chat className="text_primary_300" />}
					widthDesktop={40}
					isConfirmationLoading={false}
					actions={[
						{
							label: 'Volver',
							color: 'grey',
							action: closeReachSuccess,
						},
						{
							label: 'Marcar respuesta',
							color: 'primary',
							action: () => reachSuccess(),
						},
					]}
					title={
						<Typography
							scale="medium"
							weight="600"
							className="m_b_md"
							textColor="neutral_700"
						>
							¿Esta seguro que desea marcar que se contesto esta solicitud?
						</Typography>
					}
					subtitle={
						<Typography
							scale="medium"
							weight="400"
							className="m_b_md"
							textColor="neutral_700"
						>
							Una vez se marque como contacto exitoso se puede continuar con el
							proceso para llegar a validacion
						</Typography>
					}
				/>
			)}
		</div>
	);
};

export default Solicitudes;
