/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';

import { Button, Typography, DataTable } from 'design_system/src';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/es';

import '../../ozoner.scss';
import { ReactComponent as Info } from 'design_system/src/static/icons/info.svg';

import {
	EconomicActivityEnum,
	FinancialFormTypeEnum,
} from 'models/financial-form/enums/financial-form.enums';
import { OzonerStatusValues } from 'models/ozoner/enums/ozoner.enums';
import { updateOzoner } from 'models/ozoner/controllers/ozoner.controller';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { ozonerContext } from '../OzonerContext';
import { EditTypesOzoner } from '../DetailsOzoner';

interface DataType {
	value: string | React.ReactNode;
	label?: string | React.ReactNode;
	valueColor?: string;
	onClick?: any;
}
interface DataProps {
	openEditOzoner: (label: string, editType: number) => void;
}
function DataOzoner({ openEditOzoner }: DataProps) {
	const { ozoner, updateComment, financialForm, validCurp, canValidateCurp } =
		useContext(ozonerContext);

	const [age, setAge] = useState<string>('no definido');
	const [gender, setGender] = useState<string>('no definido');
	const [address, setAddress] = useState<string>('no definido');
	const [comment, setComment] = useState<string>('');

	useEffect(() => {
		if (ozoner?.curp) {
			moment.parseTwoDigitYear = (yearString) => {
				const year = parseInt(yearString, 10);
				return year + (year > moment().year() % 100 ? 1900 : 2000);
			};
			const actualYear = moment(ozoner.curp.substring(4, 10), 'YYMMDD');
			setAge(`${moment().diff(actualYear, 'years', false)}`);
			setGender(
				ozoner.curp.toUpperCase().substring(10, 11) === 'H'
					? 'Masculino'
					: 'Femenino',
			);
		}
		setComment(ozoner?.comment ?? '');
	}, [ozoner]);

	useEffect(() => {
		const fullAddress = financialForm?.form?.personalInformation?.fullAddress;
		if (fullAddress) {
			setAddress(
				`${fullAddress.street} ${fullAddress.extNumber} ${
					fullAddress.intNumber ?? ''
				}, ${fullAddress.neighborhood}, ${fullAddress.zipCode}, ${
					fullAddress.delegation
				}, ${fullAddress.state}`,
			);
		} else {
			setAddress('no definido');
		}
	}, [financialForm]);

	const getOzonerDetails = () => {
		const fullAddress = financialForm?.form?.personalInformation?.fullAddress;
		const phone = financialForm.form.personalInformation?.phone;
		const data: DataType[] = [
			{
				label: 'Curp',
				value: ozoner?.curp,
				valueColor: canValidateCurp
					? validCurp
						? 'green_300'
						: 'red_300'
					: 'neutral_900',
			},
			{
				label: 'Email',
				value: ozoner?.email,
				onClick: () => {
					window.location.href = `mailto:${ozoner?.email}`;
				},
			},
			{ label: 'Edad', value: `${age}` },
			{ label: 'Genero', value: `${gender}` },
		];
		if (financialForm.form.type === FinancialFormTypeEnum.loan) {
			data.push({
				label: 'Telefono',
				value: phone,
				onClick: () => {
					window.open(`https://api.whatsapp.com/send?phone=${phone}`, '_blank');
				},
			});
			data.push({
				label: 'Direccion',
				value: `${address}`,
				onClick:
					fullAddress?.long && fullAddress?.lat
						? () => {
								window.open(
									`https://www.google.com/maps/search/?api=1&query=${fullAddress.lat},${fullAddress.long}`,
									'_blank',
								);
						  }
						: undefined,
			});
		}
		return data;
	};

	const getFormDetails = () => {
		const form = financialForm?.form;
		return [
			{
				label: 'Ingresos',
				value: form?.financialInformation?.monthlyIncome
					? priceFormatFinancial(form.financialInformation.monthlyIncome)
					: undefined,
				valueColor: 'green_300',
			},
			{
				label: 'Egresos',
				value: form?.financialInformation?.monthlyOutcome
					? priceFormatFinancial(form.financialInformation.monthlyOutcome)
					: undefined,
				valueColor: 'red_300',
			},
			{
				label: 'Nivel de Estudios',
				value: form?.personalInformation?.educationalLevel,
			},
			{
				label: 'Estado Civil',
				value: form?.personalInformation?.civilStatus,
			},
			{
				label: 'Vive con',
				value: `${form?.personalInformation?.livesWith
					?.join(', ')
					.slice(0, -1)}`,
			},
			{
				label: 'Dependientes Económicos',
				value: `${form?.personalInformation?.dependantsCount}`,
			},
			{
				label: 'Activos',
				value: `${form?.financialInformation?.assets?.join(', ')}`,
			},
			{
				label: 'Empresa',
				value: `${form?.financialInformation?.companyName}`,
			},
			{
				label: 'Ocupación',
				value: `${
					form?.financialInformation?.economicActivity?.value ===
					EconomicActivityEnum.otro
						? form.financialInformation.economicActivity.description
						: form?.financialInformation?.economicActivity?.value
				}`,
			},
		];
	};
	const getPersonalReferenceDetails = (num: number) => {
		const personalReference =
			financialForm?.form?.personalInformation?.personalReferences?.[
				num ? 'personalReference1' : 'personalReference2'
			];
		return [
			{
				label: 'Nombre',
				value: personalReference?.name,
			},
			{
				label: 'Teléfono',
				value: `${personalReference?.phone}`,
				onClick: () => {
					window.open(
						`https://api.whatsapp.com/send?phone=${personalReference?.phone}`,
						'_blank',
					);
				},
			},
			{
				label: 'Parentesco',
				value: personalReference?.relationship,
			},
		];
	};

	return (
		<Grid container direction="row" spacing={3} className="h_100_per p_t_md">
			<Grid item xs={4}>
				<div className="bg_neutral_0 border_neutral_400 border_solid border_1 br_sm overflow_hidden">
					{canValidateCurp && !validCurp && (
						<div
							id="info-soli-ozoner"
							className="p_xs flex_center_col bg_red_200 flex_gap_xs"
						>
							<div className="flex_center flex_gap_xs">
								<Info className="dim_lg text_red_300" />
								<Typography scale="small" weight="600" textColor="red_300">
									El CURP del ozoner esta mal
								</Typography>
							</div>
							<Typography
								scale="xsmall"
								textColor="red_300"
								weight="400"
								className="text_center"
							>
								El CURP que registra truora es diferente al de panda
							</Typography>
						</div>
					)}
					{ozoner?.status ? (
						<div id="estado-ozoner" className="p_md">
							<div className="display_flex flex_justify_between flex_align_center">
								<Typography
									scale="medium"
									weight="400"
									textColor="neutral_800"
									className="text_no_break"
								>
									Estado Ozoner:
								</Typography>
								<Typography
									scale="medium"
									weight="600"
									className={`br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative
                                        bg_${
																					OzonerStatusValues[ozoner.status]
																						?.color
																				}_nocontrast`}
								>
									{OzonerStatusValues[ozoner.status]?.title}
								</Typography>
							</div>
						</div>
					) : null}
					<div className="p_xs">
						<DataTable
							data={getOzonerDetails()}
							weightLabels={1}
							weightValues={2}
						/>
						<Button
							scale="small"
							className="w_100_per m_t_md"
							onClick={() =>
								openEditOzoner(
									'Editar Datos Basicos',
									financialForm.form.type === FinancialFormTypeEnum.loan
										? EditTypesOzoner.OZONER_DATA
										: EditTypesOzoner.BASIC_DATA,
								)
							}
						>
							{' '}
							Editar Info Basica
						</Button>
					</div>
				</div>
			</Grid>
			{financialForm.form.type === FinancialFormTypeEnum.loan ? (
				<Grid item xs={8}>
					<Grid container direction="row" spacing={3} className="">
						<Grid item xs={7} className="">
							<div className="br_xxs bg_neutral_0 p_xs border_1 border_solid border_neutral_400 h_100_per pos_relative">
								<DataTable
									data={getFormDetails()}
									weightLabels={3}
									weightValues={2}
								/>
								<Button
									scale="small"
									className="w_100_per m_t_md"
									onClick={() =>
										openEditOzoner(
											'Editar Datos Personales',
											EditTypesOzoner.FINANCIAL_DATA,
										)
									}
								>
									{' '}
									Editar Info Personal
								</Button>
							</div>
						</Grid>
						<Grid
							item
							xs={5}
							className="display_flex flex_col flex_gap_lg pos_relative"
						>
							{[0, 1].map((num) => (
								<div>
									<Typography
										scale="medium"
										weight="600"
										className="cursor_pointer text_center p_x_lg p_y_md reference_tab w_fit"
										key={num}
									>
										Referencia {num + 1}
									</Typography>
									<div className="br_xxs bg_neutral_0 p_xs border_1 border_solid border_neutral_400 pos_relative">
										<DataTable
											weightLabels={3}
											weightValues={2}
											data={getPersonalReferenceDetails(num)}
										/>
									</div>
								</div>
							))}
							<Button
								scale="small"
								className="w_100_per"
								onClick={() =>
									openEditOzoner(
										'Editar Referencias Personales',
										EditTypesOzoner.PERSONAL_REFERENCES,
									)
								}
							>
								{' '}
								Editar Referencias
							</Button>
						</Grid>
						<Grid item xs={12} className="">
							<Typography
								scale="large"
								weight="600"
								textColor="neutral_900"
								className="m_y_md"
							>
								Observaciones del ozoner :
							</Typography>
							<textarea
								className="observaciones w_100_per p_md border_1 border_solid border_neutral_400"
								placeholder="Agregue todas las observaciones del ozoner que se detecten"
								value={comment}
								onChange={(e) => setComment(e.target.value)}
							/>
							{comment !== ozoner?.comment && (
								<div className="display_flex flex_justify_end">
									<Button
										scale="small"
										variant="ghost"
										onClick={() => updateComment(comment)}
									>
										Guardar Cambios
									</Button>
									<Button
										scale="small"
										variant="ghost"
										onClick={() => setComment(ozoner?.comment ?? '')}
									>
										Cancelar
									</Button>
								</div>
							)}
						</Grid>
					</Grid>
				</Grid>
			) : null}
		</Grid>
	);
}
export default DataOzoner;
