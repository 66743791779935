import { getUser } from 'localStorage/authStorage';
import { PaginatedDocuments } from 'models/shared/types/pagination';
import { buildPathWithQueryParams } from 'models/shared/helpers/utils';
import {
	axiosErrorHandler,
	get,
	post,
	put,
} from '../../shared/helpers/axiosInstance';
import { Notify, NotifyFilterDto } from '../interfaces/notifications.interface';

export const findAllNotifications = async (dto: NotifyFilterDto) => {
	try {
		const res = await get<PaginatedDocuments<Notify>>(
			buildPathWithQueryParams('v1/notify', dto),
		);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const markRead = async (id: string) => {
	try {
		const url = `v1/notify/${id}`;
		const userL = getUser();
		if (!userL) throw new Error('User not found');
		const res = await put<Notify>(url, { read: true, user: userL.userId });
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
