import { ProfileResponse } from 'models/auth/interfaces/auth.interface';

const getUser: () => ProfileResponse | null = () => {
	const user = localStorage.getItem('user');
	if (user) {
		return JSON.parse(user);
	}
	return null;
};

const setUser = (user: ProfileResponse) => {
	localStorage.setItem('user', JSON.stringify(user));
};

const setToken = (accessToken: string) => {
	localStorage.setItem('access_token', JSON.stringify(accessToken));
};

const getAccessToken: () => string = () => {
	const accessToken = localStorage.getItem('access_token');
	if (accessToken) {
		return JSON.parse(accessToken);
	}
	return null;
};

const clearLogout = () => {
	localStorage.removeItem('access_token');
	localStorage.removeItem('refresh_token');
	localStorage.removeItem('user');
};

const setRefreshToken = (RefreshToken: string) => {
	localStorage.setItem('refresh_token', JSON.stringify(RefreshToken));
};

const getRefreshToken: () => string = () => {
	const refreshToken = localStorage.getItem('refresh_token');
	if (refreshToken) {
		return JSON.parse(refreshToken);
	}
	return null;
};

const setLastUrl = (lastUrl: string) => {
	localStorage.setItem('last_url', lastUrl);
};

const getLastUrl = () => {
	return localStorage.getItem('last_url');
};
const clearLastUrl = () => {
	localStorage.removeItem('last_url');
};

const getAllAccess = () => {
	const accessToken = getAccessToken();
	const refreshToken = getRefreshToken();
	if (accessToken || refreshToken) {
		return {
			accessToken,
			refreshToken,
		};
	}
	return null;
};

export {
	getUser,
	getAccessToken as getToken,
	setUser,
	setToken,
	setRefreshToken,
	getRefreshToken,
	setLastUrl,
	getLastUrl,
	clearLastUrl,
	clearLogout,
	getAllAccess,
};
