/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useCallback } from 'react';

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import {
	Button,
	Input,
	Knob,
	ModalConfirmation as Modal,
	ModalConfirmation,
	SideModal,
	Typography,
} from 'design_system/src';
import { Grid } from '@mui/material';
import * as yup from 'yup';

// -----------------DATA IMPORTS------------------- //
import moment from 'moment';

import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';

// -----------------STATIC IMPORTS------------------- //
import '../../../ozoner.scss';
import { ReactComponent as Calendar } from 'design_system/src/static/icons/date.svg';
import { ReactComponent as Edit } from 'design_system/src/static/icons/edit-text.svg';
import { ReactComponent as Reload } from 'design_system/src/static/icons/reload.svg';
import { ReactComponent as Close } from 'design_system/src/static/icons/close-lg.svg';
import { ReactComponent as Up } from 'design_system/src/static/icons/success-plan.svg';
import { ReactComponent as Down } from 'design_system/src/static/icons/failed-plan.svg';
import { ReactComponent as Fail } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as Success } from 'design_system/src/static/icons/tick-circle.svg';
import { ReactComponent as Info } from 'design_system/src/static/icons/info.svg';
import { ReactComponent as ArrowUp } from 'design_system/src/static/icons/up-md.svg';
import { ReactComponent as ArrowDown } from 'design_system/src/static/icons/down-md.svg';

// -----------------COMPONENTS IMPORTS------------------- //
import Dropzone from 'react-dropzone';
import Swal from 'sweetalert2';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { LightTooltip } from 'components/hocs/tooltip/Tooltip';
import {
	DevolutionReasonValues,
	FinancialFormTypeEnum,
	FormStatus,
	FormStatusValues,
	RejectedReasonValues,
	SalesRankEnum,
	salesRankValues,
} from 'models/financial-form/enums/financial-form.enums';
import { updateFinancialForm } from 'models/financial-form/controllers/financial-form.controller';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { informativeMessage } from 'components/hocs/messages/messages';
import { ozonerContext } from '../../OzonerContext';
import { EditTypesOzoner, displayQuota } from '../../DetailsOzoner';
import { CancelAction } from './SolicitudActions';
import ReachSection from './1.ReachSection';
import PendingSection from './2.PendingSection';
import ValidationSection from './3.ValidationSection';
import {
	CollectionResponsible,
	CollectionSegmentation,
	LoanSegmentation,
	PartnershipSegmentation,
} from './Segmentations';
import RejectedSection from './RejectedSection';
import ApprovedSection from './4.ApprovedSection';
import ScheduledSection from './5.ScheduledSection';
import ExpiredSection from './ExpiredSection';
import FullPaymentSection from './FullPaymentSection';

const noCancelStatuses = [
	FormStatus.scheduled,
	FormStatus.completed,
	FormStatus.canceled,
	FormStatus.rejected,
	FormStatus.expired,
];

function SolicitudOzoner() {
	const {
		ozoner,
		updateFormData,
		updateComment,
		financialForm,
		validCurp,
		canValidateCurp,
		ozoniosList,
	} = useContext(ozonerContext);

	// -----------------DATA STATES------------------- //
	const [comment, setComment] = useState<string>('');

	const [loadingResponsible, setLoadingResponsible] = useState(false);

	useEffect(() => {
		setComment(ozoner?.comment ?? '');
	}, [ozoner]);

	const handleChangeResponsible = (e: any) => {
		if (financialForm?.form?._id && e.target.value) {
			setLoadingResponsible(true);
			updateFinancialForm(financialForm?.form?._id, {
				loanApplication: {
					sellResponsible: e.target.value,
				},
			})
				.then((newForm) => {
					updateFormData(newForm);
					setLoadingResponsible(false);
					toasterNotification({
						msg: 'Solicitud Exitosa',
						style: 'light',
						toasterType: toasterOptions.success,
					});
				})
				.catch((err) => {
					setLoadingResponsible(false);
					commonErrorHandler(err);
				});
		}
	};

	// const activateContado = () => {
	// 	setModalOption('approve_contado');
	// 	handleEventGoogle(
	// 		'BTN_Pb_request_create_contract_contado',
	// 		'Panda Solicitudes',
	// 		'click in create contract to request button',
	// 	);
	// };

	const renderFinancialFormActions = useCallback(() => {
		if (!financialForm?.form) return;
		switch (financialForm.form.status) {
			case FormStatus.pending:
				if (financialForm.form.type === FinancialFormTypeEnum.fullPayment)
					return <FullPaymentSection />;
				return <ReachSection />;
			case FormStatus.contacted:
				return <PendingSection />;
			case FormStatus.inValidation:
				return <ValidationSection />;
			case FormStatus.approved:
				return <ApprovedSection />;

			case FormStatus.scheduled:
				return <ScheduledSection />;
			case FormStatus.rejected:
				return <RejectedSection />;
			case FormStatus.canceled:
				return informativeMessage(
					'Solicitud cancelada por:',
					financialForm.form.loanApplication?.canceledReason ?? '--',
					'neutral_1000',
					'neutral_400',
				);
			case FormStatus.expired:
				return <ExpiredSection />;

			default:
				return null;
		}
	}, [ozoner, financialForm]);

	const getWebpageLink = () => {
		let prefix = '';
		if (process.env.REACT_APP_API_BASE_URL?.includes('dev')) {
			prefix = 'dev.';
		}
		if (process.env.REACT_APP_API_BASE_URL?.includes('qa')) {
			prefix = 'qa.';
		}
		navigator.clipboard
			.writeText(
				`https://${prefix}ozon.mobi/documentos-en-proceso?email=${encodeURIComponent(
					ozoner?.email ?? '',
				)}`,
			)
			.then(() => {
				toasterNotification({
					toasterType: toasterOptions.success,
					msg: 'Copiado al portapapeles',
					style: 'light',
				});
			})
			.catch((error) => {
				toasterNotification({
					toasterType: toasterOptions.error,
					msg: `Error al copiar el contenido: ${error}`,
					style: 'light',
				});
			});
	};

	return financialForm?.form ? (
		<Grid container direction="row" spacing={3} className="h_100_per p_t_md">
			<Grid item xs={3}>
				<div className="bg_neutral_0 border_neutral_400 border_solid border_1 br_sm">
					{renderFinancialFormActions()}

					{financialForm?.form?.status !== FormStatus.pending &&
					!noCancelStatuses.includes(financialForm?.form?.status) ? (
						<CancelAction />
					) : null}
				</div>
			</Grid>

			<Grid item xs={9}>
				{canValidateCurp && !validCurp
					? informativeMessage(
							'El CURP del ozoner esta mal',
							'El CURP que registra truora es diferente al de panda',
							'red_300',
							'red_200',
					  )
					: null}
				{financialForm && !financialForm.vehicleAvailable
					? informativeMessage(
							'Vehiculo no disponible',
							'El vehiculo a sido apartado o entregado a otro ozoner, por favor seleccione un nuevo vehiculo para esta solicitud',
							'neutral_800',
							'yellow_100',
					  )
					: null}

				{financialForm?.form?.loanApplication?.devolutionReason
					? informativeMessage(
							'Solicitud devuelta por:',
							DevolutionReasonValues[
								financialForm.form.loanApplication.devolutionReason
							] ?? '--',
							'neutral_0',
							'secondary_400',
					  )
					: null}

				{financialForm?.form?.loanApplication?.rejectedReason
					? informativeMessage(
							'Solicitud rechazada por:',
							RejectedReasonValues[
								financialForm.form.loanApplication.rejectedReason
							] ?? '--',
							'red_300',
							'red_200',
					  )
					: null}

				<Grid container direction="row" spacing={1} className="">
					<Grid item xs={4}>
						<div className="br_xxs bg_neutral_0 flex_align_center p_x_md p_y_xs dim_100_per display_flex flex_gap_md border_1 border_neutral_400 border_solid ">
							<Calendar className="dim_xxl text_primary_100 border_transparent" />
							<div className="flex_grow_3">
								<Typography scale="medium" weight="600">
									{moment
										.parseZone(financialForm?.form?.createdAt)
										.format('DD/MM/yyyy')}
								</Typography>
								<Typography scale="xsmall" weight="400">
									Fecha de creacion
								</Typography>
							</div>
						</div>
					</Grid>
					<Grid item xs={4}>
						<div className="br_xxs bg_neutral_0 flex_align_center p_x_md p_y_xs dim_100_per display_flex flex_gap_md border_1 border_neutral_400 border_solid ">
							<div
								className={`br_circle dim_xxl bg_${
									FormStatusValues[financialForm.form.status].color
								}`}
							/>
							<div className="flex_grow_3">
								<Typography scale="medium" weight="600">
									{FormStatusValues[financialForm.form.status].title}
								</Typography>
								<Typography scale="xsmall" weight="400">
									Estado de solicitud{' '}
									{financialForm?.form?.statusHistoric?.length
										? `desde:
										${moment
											.parseZone(
												financialForm?.form?.statusHistoric[
													financialForm.form.statusHistoric.length - 1
												].updatedAt,
											)
											.format('DD/MM/yyyy')}`
										: ''}
								</Typography>
							</div>
						</div>
					</Grid>
					{financialForm?.form.type === FinancialFormTypeEnum.loan && (
						<Grid item xs={4}>
							<div className="br_xxs bg_neutral_0 flex_align_center p_x_md p_y_xs dim_100_per display_flex flex_gap_md border_1 border_neutral_400 border_solid ">
								<div
									className={`br_circle dim_xxl bg_${
										salesRankValues[
											financialForm?.form?.loanApplication?.salesRank ??
												SalesRankEnum.wood
										].color
									}`}
								/>
								<div className="flex_grow_3">
									<Typography scale="medium" weight="600">
										{
											salesRankValues[
												financialForm?.form?.loanApplication?.salesRank ??
													SalesRankEnum.wood
											].title
										}
									</Typography>
									<Typography scale="xsmall" weight="400">
										Segmentación
									</Typography>
								</div>
							</div>
						</Grid>
					)}
					{FormStatusValues[financialForm.form.status].order >= 3 &&
						financialForm?.form.type === FinancialFormTypeEnum.loan && (
							<Grid item xs={4}>
								<div className="br_xxs bg_neutral_0 dim_100_per flex_center p_x_md p_y_xs flex_gap_md border_1 border_neutral_400 border_solid flex_justify_between ">
									<div>
										<Typography scale="medium" weight="600" className="">
											Max. Cuota:
										</Typography>
										<Typography scale="xsmall" weight="400" className="">
											Pago semanal maximo acordado
										</Typography>
									</div>

									<Typography
										scale="large"
										weight="600"
										textColor="green_300"
										className=""
									>
										{financialForm?.form?.loanApplication?.approvedWeeklyPayment
											? priceFormatFinancial(
													financialForm.form.loanApplication
														.approvedWeeklyPayment,
													0,
											  )
											: '--'}
									</Typography>
								</div>
							</Grid>
						)}
				</Grid>
				{financialForm?.form.type === FinancialFormTypeEnum.loan && (
					<Grid container direction="row" spacing={3} className="p_t_md">
						<Grid item xs={6} className="p_md">
							<div className="br_xxs border_1 border_neutral_400 border_solid ">
								<div className="bg_primary_25 p_x_md p_y_xs border_b_solid border_1 border_neutral_400">
									<Typography scale="medium" weight="600">
										Acciones ventas
									</Typography>
								</div>
								<div className="p_x_md p_y_sm border_b_solid border_1 border_neutral_400 ">
									<div className="display_flex flex_justify_between flex_align_center flex_gap_md">
										<Typography scale="medium" weight="400">
											Responsable Ventas:
										</Typography>
										<Input
											title=""
											disabled={loadingResponsible}
											name="Responsable"
											placeholder="Ingresa el Ozonio"
											value={
												financialForm?.form?.loanApplication?.sellResponsible
											}
											onChange={handleChangeResponsible}
											className="w_100_per"
											type="select"
											options={ozoniosList}
											variant="default"
										/>
									</div>
								</div>
								<div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 ">
									<div className="display_flex flex_justify_between flex_align_center flex_gap_md">
										<Typography scale="medium" weight="400">
											Link para cargar documentos:
										</Typography>
										<Button
											scale="small"
											color="primary"
											variant="outline"
											onClick={getWebpageLink}
											className="w_100_per"
										>
											Copiar Link Carga Docs.
										</Button>
									</div>
								</div>
								{FormStatusValues[financialForm.form.status].order >= 2 && (
									<div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 ">
										<div className="display_flex flex_justify_between flex_align_center flex_gap_md">
											<Typography scale="medium" weight="600">
												Metodo de contacto:
											</Typography>
											<Typography
												scale="medium"
												weight="400"
												className="text_capitalize"
											>
												{financialForm.form.loanApplication?.contactMethod}
											</Typography>
										</div>
									</div>
								)}
							</div>
						</Grid>
						{FormStatusValues[financialForm.form.status].order >= 2 && (
							<Grid item xs={6} className="p_md">
								<div className="br_xxs border_1 border_neutral_400 border_solid h_100_per">
									<div className="bg_primary_25 p_x_md p_y_xs border_b_solid border_1 border_neutral_400">
										<Typography scale="medium" weight="600">
											Segmentacion
										</Typography>
									</div>
									<div className="p_x_md p_y_sm border_b_solid border_1 border_neutral_400 ">
										<PartnershipSegmentation />
									</div>
									{FormStatusValues[financialForm.form.status].order >= 3 && (
										<div className="p_x_md p_y_sm border_b_solid border_1 border_neutral_400 ">
											<LoanSegmentation />
										</div>
									)}
									{FormStatusValues[financialForm.form.status].order >= 5 && (
										<>
											<div className="p_x_md p_y_sm border_b_solid border_1 border_neutral_400 ">
												<CollectionResponsible />
											</div>
											<div className="p_x_md p_y_sm border_b_solid border_1 border_neutral_400 ">
												<CollectionSegmentation />
											</div>
										</>
									)}
								</div>
							</Grid>
						)}
					</Grid>
				)}
				<Grid container direction="row" spacing={1} className="">
					<Grid item xs={12} className="">
						<Typography
							scale="large"
							weight="600"
							textColor="neutral_900"
							className="m_y_md"
						>
							Observaciones del ozoner :
						</Typography>
						<textarea
							className="observaciones w_100_per p_md border_1 border_solid border_neutral_400"
							placeholder="Agregue todas las observaciones del ozoner que se detecten"
							value={comment}
							onChange={(e: any) => setComment(e.target.value)}
						/>
						{comment !== ozoner?.comment && (
							<div className="display_flex flex_justify_end">
								<Button
									scale="small"
									variant="ghost"
									onClick={() => updateComment(comment)}
								>
									Guardar Cambios
								</Button>
								<Button
									scale="small"
									variant="ghost"
									onClick={() => setComment(ozoner?.comment ?? '')}
								>
									Cancelar
								</Button>
							</div>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	) : null;
}
export default SolicitudOzoner;
