import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';

// logos
import logo from 'static/logos/logo_pandaboard.svg';

// icons
import { ReactComponent as Boy } from 'design_system/src/static/icons/boy.svg';

import { ReactComponent as Lock } from 'design_system/src/static/icons/lock-square.svg';
import { Button, Input, Typography } from 'design_system/src';

import { clearLastUrl, getLastUrl, setUser } from 'localStorage/authStorage';

// images
import img1 from 'static/images/login1.svg';
import img2 from 'static/images/login2.svg';

// css
import './auth.scss';
import {
	authLogin,
	fetchProfile,
} from 'models/auth/controllers/auth.controller';
import { LoginResponse } from 'models/auth/interfaces/auth.interface';

const validationSchema = yup.object({
	username: yup.string().required('Campo requerido'),
	password: yup.string().required('Campo requerido'),
});

function Auth() {
	useEffect(() => {
		handleEventGoogle('VIEW_Panda_login', 'Panda', 'page view to login view');
	}, []);
	const navigate = useNavigate();
	const [loading, setloading] = useState<boolean>(false);

	const login = (value: { username: string; password: string }) => {
		setloading(true);
		authLogin(value)
			.then((res: LoginResponse) => {
				setloading(false);
				return fetchProfile(res.access_token);
			})
			.then((prof) => {
				setUser(prof);
				const lastUrl = getLastUrl();
				if (lastUrl) {
					clearLastUrl();
					// eslint-disable-next-line no-restricted-globals
					location.href = lastUrl;
				} else {
					navigate('/');
				}
			})
			.catch(() => {
				setloading(false);
				Swal.fire({
					title: 'Error!',
					text: 'Usuario o contraseña incorrecta',
					icon: 'error',
					confirmButtonText: 'Aceptar',
				});
			});
	};

	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validationSchema,
		onSubmit: login,
	});

	return (
		<div className="root flex_center">
			<img alt="circulos" src={img1} className="img1 display_none_mobile" />
			<img alt="orange" src={img2} className="img2  display_none_mobile" />
			<div
				className="
        dso_card 
        login_card 
        bg_neutral_0 
        p_xxl 
        shadow_hard"
			>
				<img alt="Logo" src={logo} className="logo center_x m_b_xxl" />
				<form onSubmit={formik.handleSubmit}>
					<Typography
						scale="medium"
						weight="600"
						className=" m_b_md text_center"
					>
						LOGIN
					</Typography>

					<Input
						title="Usuario"
						type="text"
						name="username"
						placeholder="Escriba su usuario aquí"
						value={formik.values.username}
						onChange={formik.handleChange}
						icon={<Boy />}
						className="m_b_md dso_width_100_per"
					/>
					<Input
						title="Contraseña"
						type="password"
						name="password"
						placeholder="Escriba su contraseña aquí"
						value={formik.values.password}
						onChange={formik.handleChange}
						icon={<Lock />}
						className="m_b_xxl dso_width_100_per"
					/>
					<Button
						type="submit"
						disabled={loading || !formik.isValid}
						scale="small"
						className="w_100_per m_b_md m_t_xxs"
						icon={<Boy />}
					>
						{loading ? <CircularProgress className="loading" /> : 'Ingresar'}
					</Button>
				</form>
			</div>
		</div>
	);
}

export default Auth;
