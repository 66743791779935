/* eslint-disable no-shadow */
export enum RepossessionPolicy {
	overdue = 'overdue',
	writeOff = 'writeOff',
	priority = 'priority',
	devolution = 'devolution',
}

export const RepossessionPolicyValues: {
	[key in RepossessionPolicy]: { title: string; color: string };
} = {
	[RepossessionPolicy.priority]: {
		title: 'Prioritario',
		color: 'primary_300',
	},
	[RepossessionPolicy.overdue]: {
		title: 'Mora',
		color: 'red_300',
	},
	[RepossessionPolicy.devolution]: {
		title: 'Devolución',
		color: 'neutral_600',
	},
	[RepossessionPolicy.writeOff]: {
		title: 'WriteOff',
		color: 'neutral_900',
	},
};

export enum RepossessionStatus {
	pending = 'pending',
	completed = 'completed',
	failed = 'failed',
	canceled = 'canceled',
}

export enum InterestPointType {
	hotspot = 'hotspot',
	signalVehicle = 'signalVehicle',
	noSignalVehicle = 'noSignalVehicle',
	address = 'address',
}

export const InterestPointTypeValues = {
	[InterestPointType.hotspot]: {
		title: 'Hotspot',
		score: 0,
	},
	[InterestPointType.signalVehicle]: {
		title: 'Ubicacion actual',
		score: 3,
	},
	[InterestPointType.noSignalVehicle]: {
		title: 'Ultima ubicacíon',
		score: 2,
	},
	[InterestPointType.address]: {
		title: 'Hogar',
		score: 1,
	},
};

export enum InterestPointStatus {
	active = 'active',
	expired = 'expired',
}

export enum LocationType {
	signalVehicle = 'signalVehicle',
	noSignalVehicle = 'noSignalVehicle',
	gpsTraker = 'gpsTraker',
}
