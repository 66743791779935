/* eslint-disable no-shadow */
export enum ModelType {
	ozoner = 'ozoner',
	agreement = 'agreement',
	new = 'new',
	vehicle = 'vehicle',
	credit = 'credit',
	payment = 'payment',
	financialForm = 'financialForm',
}

export const financialFormStatuses: any = {
	approved: 'Aprobada',
	rejected: 'Rechazada',
	rejectedByMax: 'Rechazada por maximo',
	new: 'Pendiente de aprobación',
};

export const rejectedReason: any = {
	creditRisk: 'Riesgo de crédito',
	reported: 'Reportado',
	maxWeekly: 'Límite semanal máximo',
	capacity: 'Capacidad',
};

export const typePayments: any = {
	creditPayment: 'cuota',
	note: 'intereses',
};

export const paymentStatus: any = {
	paid: 'pagado',
};

export enum modelComplementEnum {
	payment = 'payment',
	agreement = 'agreement',
}
