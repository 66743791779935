import {
	CreatedOnType,
	TOzocio,
	TOzocioOneOffer,
} from 'models/ozocio.interface';
import {
	axiosErrorHandler,
	deletes,
	get,
	post,
	put,
} from './shared/helpers/axiosInstance';
import { toasterOptions } from './shared/enums/toaster.enum';
import { toasterNotification } from './shared/helpers/toasterNotification';

export interface FetchOzocioResponse {
	count: number;
	pages: number;
	page: number;
	totals: any;
	data: TOzocioOneOffer[];
}

export const exportarOzocios = async (
	pages?: string,
	params?: string,
	limit?: number,
): Promise<any> => {
	try {
		let path = 'v1/ozocio/export?';
		if (pages) {
			path = `${path}&pages=${pages}`;
		}
		if (limit) {
			path = `${path}&limit=${limit}`;
		}
		if (params) {
			path = `${path}&${params}`;
		}

		const res = await get(path);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const FetchOzocios = async (
	page: number,
	query?: string,
	status?: string,
	createdOn?: CreatedOnType,
	order?: string,
	aditionalQuery?: string,
): Promise<FetchOzocioResponse> => {
	try {
		const res = await get<FetchOzocioResponse>(
			/* eslint no-underscore-dangle: 0 */
			`/v1/ozocio?page=${page}${status ? `&formStatus=${status}` : ''}${
				query ? `&query=${query}` : ''
			}${createdOn ? `&createdOn=${createdOn}` : ''}${
				order ? `&sort=${order}` : ''
			}&${aditionalQuery ?? ''}`,
		);
		return res.data;
	} catch (err: any) {
		toasterNotification({
			msg: 'Ocurrio un error al cargar los datos',
			style: 'light',
			toasterType: toasterOptions.error,
		});
		throw axiosErrorHandler(err);
	}
};

export const getOzocioById = async (id: string): Promise<any> => {
	try {
		const res = await get<TOzocio>(`/v1/ozocio/${id}`);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const getBrandsOzocios = async (): Promise<any> => {
	try {
		const res = await get<any>('/v1/simulator/brands');
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const getModelsOzocios = async (brand: string): Promise<any> => {
	try {
		const res = await get<any>(`/v1/simulator/models/${brand}`);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const editOzocio = async (id: string, data: any): Promise<any> => {
	try {
		const res = await put<any>(`/v1/ozocio/${id}`, data);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const editOffer = async (
	id: string,
	offerId: string,
	data: any,
): Promise<any> => {
	try {
		const res = await put<any>(`/v1/ozocio/${id}/vehicle/${offerId}`, data);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const createOzocio = async (data: any): Promise<any> => {
	try {
		const res = await post<any>('/v1/ozocio/PB', data);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
export const createOffer = async (
	ozocioId: string,
	data: any,
): Promise<any> => {
	try {
		const res = await post<any>(`/v1/ozocio/PB/${ozocioId}/vehicle`, data);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const deleteFile = async (
	idSolicitud: string,
	urlFile: string,
): Promise<any> => {
	try {
		const res = await deletes(`v1/ozocio/${idSolicitud}/document/${urlFile}`);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const addFileForm = async (
	form: string,
	file: File,
	type = 'other',
): Promise<any> => {
	try {
		const data = new FormData();
		data.append('name', file.name);
		data.append('file', file);

		const res = await put(`v1/ozocio/${form}/document/${type}`, data, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const getFiles = async (id: string): Promise<any> => {
	try {
		const res = await get(`v1/ozocio/${id}/download-documents`, {
			responseType: 'arraybuffer',
			responseEncoding: 'binary',
		});
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const updateAdvancedMoney = async (
	financialFormId: string,
	advancedMoneyAmount: number,
): Promise<any> => {
	try {
		const res = await put(`v1/ozocio/${financialFormId}/advancedmoney`, {
			advancedMoney: advancedMoneyAmount,
		});
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const getExport = async (
	pages?: string,
	params?: string,
	limit?: number,
): Promise<any> => {
	try {
		let path = 'v1/ozocio/export?';
		const fields = [];
		if (pages) {
			fields.push(`pages=${pages}`);
		}
		if (limit) {
			fields.push(`limit=${limit}`);
		}
		if (params) {
			fields.push(`${params}`);
		}
		path = `${path}${fields.join('&')}`;

		const res = await get(path);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
