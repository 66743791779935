/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Grid } from '@mui/material';
import {
	FormikValues,
	FormikHelpers,
	FormikProps,
	FormikProvider,
	useFormik,
} from 'formik';
import Swal from 'sweetalert2';
import Divider from '@mui/material/Divider';
import _ from 'lodash';

// icons
import { ReactComponent as Bookmark } from 'design_system/src/static/icons/bookmark.svg';
import { ReactComponent as Color } from 'design_system/src/static/icons/color.svg';
import { ReactComponent as Moto } from 'design_system/src/static/icons/motorcycle.svg';
import { ReactComponent as Eye } from 'design_system/src/static/icons/eye.svg';
import { ReactComponent as Docs } from 'design_system/src/static/icons/file.svg';
import { ReactComponent as Calendar } from 'design_system/src/static/icons/date.svg';
import { ReactComponent as Coin } from 'design_system/src/static/icons/coin.svg';
import { ReactComponent as Money } from 'design_system/src/static/icons/money.svg';
import { ReactComponent as Archive } from 'design_system/src/static/icons/archive.svg';
import { ReactComponent as Speedometer } from 'design_system/src/static/icons/speedometer.svg';
import { ReactComponent as CD } from 'design_system/src/static/icons/cd.svg';
import { ReactComponent as List } from 'design_system/src/static/icons/numbered-list.svg';
import { ReactComponent as Card } from 'design_system/src/static/icons/credit-card-2.svg';
import { ReactComponent as Wheel } from 'design_system/src/static/icons/car-wheel-2.svg';
import { ReactComponent as Image } from 'design_system/src/static/icons/image.svg';
import { ReactComponent as Save } from 'design_system/src/static/icons/save.svg';
import { ReactComponent as Close } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as Store } from 'design_system/src/static/icons/store.svg';
import { ReactComponent as Helmet } from 'design_system/src/static/icons/helmet-race.svg';
import { ReactComponent as Copy } from 'design_system/src/static/icons/copy.svg';
import { ReactComponent as Wrench } from 'design_system/src/static/icons/thermometer-hot.svg';
import { ReactComponent as Book } from 'design_system/src/static/icons/book.svg';

import {
	Button,
	Input,
	Typography,
	SideModal,
	CheckboxInput,
} from 'design_system/src';

// css
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import {
	VehiclePopulated,
	UpdateVehicleDto,
	CreateVehicleDto,
	SalePlan,
	imagesDto,
} from 'models/inventory/interfaces/vehicle.interface';
import {
	addVehicle,
	editVehicle,
	estimatePrice,
	getHubs,
} from 'models/inventory/controllers/vehicle.controller';
import {
	DocumentalStatusValues,
	SalePriceWeek,
	VisualStatusValues,
} from 'models/inventory/enums/vehicle.enum';
import { getBrandsWithModels } from 'models/inventory/controllers/brand.controller';
import { Model } from 'models/inventory/interfaces/brand.interface';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import moment from 'moment';
import { filterObjectByKeys } from 'models/shared/helpers/utils';
import {
	colorsOptions,
	vehicleDefaults,
	vehicleTypes,
	vehicleValidationSchema,
	brakeTypeOptions,
} from './constants';

interface EditProps {
	open: boolean;
	setOpen: Function;
	values?: Partial<VehiclePopulated> | null;
	setResult: Function;
}

function EdiVehiclePopulated({
	open,
	setOpen,
	values = null,
	setResult,
}: EditProps) {
	const [img, setimg] = useState<imagesDto[]>([]);
	const [initial, setInitial] = useState<UpdateVehicleDto | CreateVehicleDto>(
		vehicleDefaults,
	);
	const [isContado, setIsContado] = useState<boolean>(false);
	const [brands, setBrands] = useState<
		{ label: string; value: string | number; models?: Model[] }[]
	>([]);
	const [models, setModels] = useState<
		| {
				label: string;
				value: string | number;
				cylinderCapacity: string[];
		  }[]
		| undefined
	>([]);
	const [cylinders, setCylinders] = useState<
		{ label: string; value: string | number }[]
	>([]);
	const [hubs, setHubs] = useState<{ label: string; value: string | number }[]>(
		[],
	);
	const [modified, setModified] = useState<boolean>(false);
	const [descriptionTab, setDetailTab] = useState<number>(0);
	const [useDefault, setUseDefault] = useState<boolean>(false);
	const [defaultType, setDefaultType] = useState<{
		label: string;
		shortDescription: string;
		description: string;
	}>(vehicleTypes[0]);
	const [loanTimes, setLoanTimes] = useState<{
		[key: string]: {
			selected: boolean;
			percentageIncrease: number;
		};
	}>({
		78: { selected: false, percentageIncrease: 80 },
		104: { selected: false, percentageIncrease: 106 },
		156: { selected: false, percentageIncrease: 135 },
	});
	const [prices, setPrices] = useState<any>({});
	const [pricingValues, setPricingValues] = useState<any>({
		blue: 0,
		total: 0,
		debtsCost: 0,
		repairsCost: 0,
	});
	const [showErrors, setShowErrors] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const findModels = (brand: string) => {
		setModels(
			brands
				.filter((e) => e.value === brand)[0]
				?.models?.map((ele: Model) => ({
					label: ele.name,
					value: ele['_id'],
					cylinderCapacity: ele.cylinderCapacity,
				})) ?? [],
		);
	};

	const findCylinders = (model: string) => {
		if (model && models) {
			setCylinders(
				models
					.filter((e) => e.value === model)[0]
					?.cylinderCapacity.map((ele: string) => ({
						label: ele,
						value: ele,
					})),
			);
		}
	};

	const setDescription = (
		form: FormikProps<any>,
		type: any,
		isDefault: boolean,
	) => {
		setDefaultType(type);
		setUseDefault(isDefault);
		if (isDefault) {
			form.setFieldValue('shortDescription', type.shortDescription);
			form.setFieldValue('description', type.description);
		}
	};

	const updatePrice = async () => {
		const newPrices: any = {};
		const { blue, total, debtsCost, repairsCost } = pricingValues;
		const generalAvailable =
			blue !== undefined &&
			typeof blue === 'number' &&
			total !== undefined &&
			typeof total === 'number';
		const availableForUpfront =
			generalAvailable &&
			debtsCost !== undefined &&
			typeof debtsCost === 'number' &&
			repairsCost !== undefined &&
			typeof repairsCost === 'number';

		let promises: any[] = [];
		if (generalAvailable) {
			promises = [
				...promises,
				...Object.keys(loanTimes).map((weeks: string) => {
					return estimatePrice({
						marketPrice: blue,
						purchaseCost: total,
						percentageIncrease: loanTimes[weeks].percentageIncrease,
						weeks: parseInt(weeks, 10),
					}).then((price) => {
						newPrices[weeks] = {
							price: price.paymentWeek,
							loanAmount: price.loanAmount,
						};
					});
				}),
			];
		}
		if (availableForUpfront) {
			promises.push(
				estimatePrice({
					marketPrice: blue,
					purchaseCost: total,
					weeks: 1,
					percentageIncrease: 0,
					repairsCost,
					debtsCost,
				}).then((price) => {
					newPrices[1] = {
						price: price.paymentWeek,
						loanAmount: price.loanAmount,
					};
				}),
			);
		}
		await Promise.all(promises);

		setPrices(newPrices);
	};

	useEffect(() => {
		getBrandsWithModels().then((res) => {
			if (res) {
				const maped = res
					.map((ele) => ({
						label: ele.name,
						value: ele['_id'],
						models: ele.models,
					}))
					.sort((a: any, b: any) => {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					});
				setBrands(maped);
			}
		});
		getHubs().then((res) => {
			if (res) {
				setHubs(
					res.map((ele: any) => ({
						label: ele.name,
						value: ele['_id'],
					})),
				);
			}
		});
	}, []);

	useEffect(() => {
		if (initial.brand) findModels(initial.brand);
	}, [initial, brands]);

	useEffect(() => {
		updatePrice();
	}, [loanTimes, pricingValues]);

	useEffect(() => {
		let initialValue = vehicleDefaults;
		if (values?._id) {
			const imgs = values.images!.map((ele: any) => ({
				view: ele.url,
				name: ele.name,
			}));
			setimg(imgs);

			initialValue = filterObjectByKeys(
				values,
				Object.keys(vehicleDefaults) as any,
			);
			initialValue.brand = values.brand?._id;
			initialValue.model = values.model?._id;
			initialValue.location = {
				hub: values.location?.hub?._id,
			};
			initialValue.purchaseDate = new Date(values.purchaseDate!);
		}

		if (values?.salePrices) {
			const loans = { ...loanTimes };
			Object.entries(values.salePrices).forEach(([, value]) => {
				if (value.weeks === 1) {
					setIsContado(true);
					return;
				}
				if (value && loans[value.weeks]) {
					loans[value.weeks] = {
						selected: true,
						percentageIncrease: value.percentageIncrease,
					};
				}
			});
			setLoanTimes(loans);
		}

		setPricingValues({
			blue: initialValue.marketPrice,
			total: initialValue.purchaseCost,
			debtsCost: initialValue.extraCosts?.debtsCost,
			repairsCost: initialValue.extraCosts?.repairsCost,
		});

		setInitial(initialValue);
	}, [values]);

	const deleteImage = (i: number) => {
		const images = JSON.parse(JSON.stringify(img));
		images.splice(i, 1);
		setimg(images);
		setModified(true);
	};

	const handleFiles = (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		if (files) {
			let changes = false;
			const temp = [...img];
			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < files.length; i++) {
				const file = files.item(i);
				if (file) {
					if (file.size > 10000000) {
						Swal.fire({
							text: 'La imagen no debe ser superior a 10mb',
							icon: 'warning',
							confirmButtonText: 'Aceptar',
						});
					} else {
						temp.push({
							file,
							view: URL.createObjectURL(file),
						});
						changes = true;
					}
				}
			}
			if (changes) {
				setimg(temp);
				setModified(true);
			}
		}
	};

	const displayFormErrors = (form: any) => {
		if (Object.keys(form.errors)?.length)
			toasterNotification({
				msg: `Hay errores en los campos: ${Object.keys(form.errors).reduce(
					(acc, ele) => `${acc ? `${acc}, ` : ''}'${ele}'`,
					'',
				)}`,
				style: 'light',
				toasterType: toasterOptions.error,
			});
		setShowErrors(true);
	};

	const displayRequestErrors = (err: any) => {
		if (err.message === 'Vehicle already exists') {
			// eslint-disable-next-line no-param-reassign
			err =
				'Este internalId ya existe, si es una moto recuperada marque la anterior como tal para registrar la nueva moto';
		}
		toasterNotification({
			msg:
				typeof err === 'string'
					? err
					: typeof err.message === 'string'
					? err.message
					: 'Ocurrio un error',
			toasterType: toasterOptions.error,
			style: 'light',
		});
		setLoading(false);
	};

	const selectCredits = (weeks: string) => {
		const credits = { ...loanTimes };
		credits[weeks].selected = !credits[weeks].selected;
		if (Object.values(credits).filter((value) => value.selected).length > 0) {
			setLoanTimes(credits);
			setModified(true);
		}
	};

	const changeInterestCredits = (weeks: string, value: string) => {
		if (parseInt(value, 10) >= 0) {
			const loans = { ...loanTimes };
			loans[weeks].percentageIncrease = parseInt(value, 10);
			setLoanTimes(loans);
			setModified(true);
		}
	};

	const handleCopy = (type: number) => {
		if (values) {
			let text;
			switch (type) {
				case 0:
					text = `Hazme una descripción corta sobre ${values.brand?.name} ${
						values.model?.name
					} ${values.suffix ? values.suffix : ''} ${
						values.cylindersCapacity ?? ''
					}, No más de 25 palabras`;
					break;
				case 1:
					text = `Hazme una descripción larga sobre ${values.brand?.name} ${
						values.model?.name
					} ${values.suffix ? values.suffix : ''} ${
						values.cylindersCapacity ?? ''
					} , con puntos claves`;
					break;

				default:
					text = '';
					break;
			}
			navigator.clipboard.writeText(text);
		}
	};

	const onSubmit = (
		vals: UpdateVehicleDto | CreateVehicleDto,
		form: FormikHelpers<UpdateVehicleDto | CreateVehicleDto>,
	) => {
		const val = {
			...vals,
			salePlans:
				Object.entries(loanTimes)
					.filter(([, value]) => value.selected)
					.reduce(
						(obj: { [key: string]: SalePlan }, [key, value]) => ({
							...obj,
							[key]: {
								weeks: parseInt(key, 10) as SalePriceWeek,
								percentageIncrease: value.percentageIncrease,
							},
						}),
						{},
					) ?? {},
		};
		if (isContado)
			val.salePlans['1'] = {
				weeks: SalePriceWeek.CASH,
				percentageIncrease: 0,
			};
		setLoading(true);

		if (values?._id) {
			editVehicle(val as UpdateVehicleDto, img, values.internalId ?? '')
				.then((ele) => {
					setLoading(false);
					setOpen(false);
					setResult(ele);
				})
				.catch((err) => {
					displayRequestErrors(err);
				});
		} else {
			addVehicle(val as CreateVehicleDto, img)
				.then((ele) => {
					setimg([]);
					setLoading(false);
					setOpen(false);
					setResult(ele);
					setInitial(vehicleDefaults);
					form.resetForm();
				})
				.catch((err) => {
					displayRequestErrors(err);
				});
		}
	};

	const form = useFormik<UpdateVehicleDto | CreateVehicleDto>({
		initialValues: initial,
		validationSchema: vehicleValidationSchema,
		onSubmit,
		enableReinitialize: true,
		validateOnMount: true,
	});

	return (
		initial && (
			<SideModal open={open} setOpen={setOpen} size="normal">
				<FormikProvider value={form}>
					<div className="flex_header">
						<Typography
							scale="heading4"
							weight="600"
							textColor="neutral_900"
							className="m_b_lg m_t_md"
						>
							{values?._id ? 'Editar Ozoneta' : 'Crear nueva Ozoneta'}
						</Typography>
						<Divider />
					</div>
					<div className="flex_body p_y_lg p_x_sm overflow_x_hidden">
						<Grid container direction="row" spacing={3}>
							<Grid item xs={12}>
								<Typography
									scale="large"
									weight="600"
									textColor="neutral_700"
									className="m_b_lg"
								>
									Información del vehículo
								</Typography>
							</Grid>
							<Grid item xs={12} className="display_flex">
								<div className="dso_card bg_neutral_0 flex_center_col m_b_xl m_r_md p_md border_primary_200 border_dotted pos_relative ">
									<Typography
										scale="xsmall"
										weight="600"
										textColor="primary_300"
										className="text_center m_b_md"
									>
										AÑADIR FOTO
									</Typography>
									<div
										className="dso_card_img m_b_md bg_neutral_200 w_fit display_flex overflow_hidden border_primary_300"
										onClick={() => 1}
										onKeyPress={() => 1}
										role="button"
										tabIndex={0}
									>
										<Image className="dim_xl m_lg text_primary_300 border_transparent" />
										<input
											type="file"
											multiple
											accept="image/png, image/jpeg"
											onChange={handleFiles}
											className="input-file"
										/>
									</div>
								</div>
								<div className="flex_1 display_flex overflow_y_auto flex_gap_md p_r_md">
									{img.map((ele, i) => (
										<div
											className="dso_card bg_neutral_0 flex_center_col m_b_xl p_md pos_relative"
											key={`photo${i}`}
										>
											<Close
												className="text_red_300 dim_lg delete_photo"
												onClick={() => {
													deleteImage(i);
												}}
											/>
											<Typography
												scale="xsmall"
												weight="600"
												textColor="neutral_900"
												className="text_center m_b_md"
											>
												FOTO
											</Typography>
											<div
												className="dso_card_img m_b_md bg_neutral_200 w_fit display_flex overflow_hidden"
												onClick={() => 1}
												onKeyPress={() => 1}
												role="button"
												tabIndex={0}
											>
												<img src={ele.view} alt="" className="cards_photo " />
											</div>
										</div>
									))}
								</div>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.purchaseDate &&
										(form.getFieldMeta('purchaseDate').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('purchaseDate').touched || showErrors
											? (form.errors.purchaseDate as string | undefined)
											: undefined
									}
									title="Fecha de compra"
									name="purchaseDate"
									type="date"
									className="m_b_xs w_100_per"
									value={
										form.values.purchaseDate
											? moment
													.parseZone(form.values.purchaseDate)
													.format('YYYY-MM-DD')
											: ''
									}
									onChange={(e: any) => {
										form.handleChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.internalId &&
										(form.getFieldMeta('internalId').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('internalId').touched || showErrors
											? form.errors.internalId
											: undefined
									}
									title="ID Interno"
									placeholder="Identificacion vehículo"
									name="internalId"
									type="text"
									icon={<Archive />}
									className="m_b_xs w_100_per"
									value={form.values.internalId}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.brand &&
										(form.getFieldMeta('brand').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('brand').touched || showErrors
											? form.errors.brand
											: undefined
									}
									title="Marca"
									placeholder="Selecciona marca"
									name="brand"
									type="select"
									options={brands}
									icon={<Bookmark />}
									className="m_b_xs w_100_per"
									value={form.values.brand}
									onChange={(e: any) => {
										findModels(e.target.value);
										form.handleChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.model &&
										(form.getFieldMeta('model').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('model').touched || showErrors
											? form.errors.model
											: undefined
									}
									title="Modelo"
									placeholder="Modelo del vehículo"
									name="model"
									type="select"
									options={models as any[]}
									icon={<Moto />}
									className="m_b_xs w_100_per"
									value={form.values.model}
									onChange={(e: any) => {
										findCylinders(e.target.value);
										form.handleChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.year &&
										(form.getFieldMeta('year').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('year').touched || showErrors
											? form.errors.year
											: undefined
									}
									title="Año"
									placeholder="Año del vehículo"
									name="year"
									type="number"
									min={0}
									icon={<Calendar />}
									className="m_b_xs w_100_per"
									value={form.values.year}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.cylindersCapacity &&
										(form.getFieldMeta('cylindersCapacity').touched ||
											showErrors)
									}
									subtitle={
										form.getFieldMeta('cylindersCapacity').touched || showErrors
											? form.errors.cylindersCapacity
											: undefined
									}
									title="Cilindraje"
									name="cylindersCapacity"
									type="select"
									options={cylinders}
									icon={<Speedometer />}
									className="m_b_xs w_100_per flex_1"
									value={form.values.cylindersCapacity}
									onChange={form.handleChange}
									placeholder="Cilindraje"
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.color &&
										(form.getFieldMeta('color').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('color').touched || showErrors
											? form.errors.color
											: undefined
									}
									title="Color Principal"
									placeholder="Selecciona un color"
									name="color"
									type="select"
									options={colorsOptions}
									icon={<Color />}
									className="m_b_xs w_100_per"
									value={form.values.color}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.secondaryColor &&
										(form.getFieldMeta('secondaryColor').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('secondaryColor').touched || showErrors
											? form.errors.secondaryColor
											: undefined
									}
									title="Color Secundario"
									name="secondaryColor"
									type="select"
									placeholder="Selecciona un color"
									options={colorsOptions}
									icon={<Color />}
									className="m_b_xs w_100_per"
									value={form.values.secondaryColor}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.brakeType &&
										(form.getFieldMeta('brakeType').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('brakeType').touched || showErrors
											? form.errors.brakeType
											: undefined
									}
									title="Tipo de Frenos"
									name="brakeType"
									type="select"
									placeholder="Selecciona frenos"
									options={brakeTypeOptions}
									icon={<CD />}
									className="m_b_xs w_100_per"
									value={form.values.brakeType}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.engineSN &&
										(form.getFieldMeta('engineSN').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('engineSN').touched || showErrors
											? form.errors.engineSN
											: undefined
									}
									title="Numero de motor (Opcional)"
									placeholder="Numero del motor"
									name="engineSN"
									type="text"
									icon={<List />}
									className="m_b_xs w_100_per"
									value={form.values.engineSN}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.plate &&
										(form.getFieldMeta('plate').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('plate').touched || showErrors
											? form.errors.plate
											: undefined
									}
									title="Placa(Opcional)"
									name="plate"
									type="text"
									icon={<Speedometer />}
									className="m_b_xs w_100_per"
									value={form.values.plate}
									onChange={form.handleChange}
									placeholder="Placa"
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.vehicleSN &&
										(form.getFieldMeta('vehicleSN').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('vehicleSN').touched || showErrors
											? form.errors.vehicleSN
											: undefined
									}
									title="Serie vehicular"
									placeholder="Numero de seríe"
									name="vehicleSN"
									type="text"
									icon={<Wheel />}
									className="m_b_xs w_100_per"
									value={form.values.vehicleSN}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.suffix &&
										(form.getFieldMeta('suffix').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('suffix').touched || showErrors
											? form.errors.suffix
											: undefined
									}
									title="Sufijo (Opcional)"
									placeholder="Sufijo del vehículo"
									name="suffix"
									type="text"
									icon={<Helmet />}
									className="m_b_xs w_100_per"
									value={form.values.suffix}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.registrationCard &&
										(form.getFieldMeta('registrationCard').touched ||
											showErrors)
									}
									subtitle={
										form.getFieldMeta('registrationCard').touched || showErrors
											? form.errors.registrationCard
											: undefined
									}
									title="T. de circulacíon (Opcional)"
									placeholder="Numero de tarjeta"
									name="registrationCard"
									type="text"
									icon={<Card />}
									className="m_b_xs w_100_per"
									value={form.values.registrationCard}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.maintenanceDays &&
										(form.getFieldMeta('maintenanceDays').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('maintenanceDays').touched || showErrors
											? form.errors.maintenanceDays
											: undefined
									}
									title="Dias de Mantenimiento"
									name="maintenanceDays"
									type="number"
									min={0}
									placeholder="Selecciona dias"
									icon={<Wrench />}
									className="m_b_xs w_100_per"
									value={form.values.maintenanceDays}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.visualStatus &&
										(form.getFieldMeta('visualStatus').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('visualStatus').touched || showErrors
											? (form.errors.visualStatus as string)
											: undefined
									}
									title="Estado Visual"
									name="visualStatus"
									type="select"
									placeholder="Selecciona estado"
									options={Object.keys(VisualStatusValues).map((key) => ({
										value: key,
										label: (VisualStatusValues as any)[key].label,
									}))}
									icon={<Eye />}
									className="m_b_xs w_100_per"
									value={form.values.visualStatus}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.documentalStatus &&
										(form.getFieldMeta('documentalStatus').touched ||
											showErrors)
									}
									subtitle={
										form.getFieldMeta('documentalStatus').touched || showErrors
											? (form.errors.documentalStatus as string)
											: undefined
									}
									title="Estado documental"
									name="documentalStatus"
									type="select"
									placeholder="Selecciona estado"
									options={Object.keys(DocumentalStatusValues).map((key) => ({
										value: key,
										label: (DocumentalStatusValues as any)[key].label,
									}))}
									icon={<Docs />}
									className="m_b_xs w_100_per"
									value={form.values.documentalStatus}
									onChange={form.handleChange}
								/>
							</Grid>

							<Grid item xs={12}>
								<Typography
									scale="large"
									weight="600"
									textColor="neutral_700"
									className="m_b_lg"
								>
									Etiquetas
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<div className="flex_align_center flex_gap_xs display_flex">
									<CheckboxInput
										className="m_b_lg text_no_break"
										label="Marcar como vehículo en Pre-venta"
										name="isPresale"
										value
										checked={form.values.isPresale || false}
										onChange={form.handleChange}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className="flex_align_center flex_gap_xs display_flex">
									<CheckboxInput
										className="m_b_lg text_no_break"
										label="Tiene un GPS Tile instalado"
										name="hasTileDevice"
										value
										checked={form.values.hasTileDevice || false}
										onChange={form.handleChange}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<Typography
									scale="large"
									weight="600"
									textColor="neutral_700"
									className="m_b_lg"
								>
									Costo del vehículo
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Input
									title="Moto para venta de contado"
									type="slider"
									labels
									subtitle="¿Es venta de contado?"
									name="isContado"
									className="m_b_xs"
									value={isContado}
									onChange={(e: any) => setIsContado(e.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.mileage &&
										(form.getFieldMeta('mileage').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('mileage').touched || showErrors
											? form.errors.mileage
											: undefined
									}
									title="Kilometraje"
									name="mileage"
									type="number"
									min={0}
									icon={<Speedometer />}
									className="m_b_xs w_100_per"
									value={form.values.mileage}
									onChange={form.handleChange}
									placeholder="Kilometraje"
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={(e: any) => {
										setPricingValues({
											blue: form.values.marketPrice,
											total: form.values.purchaseCost,
											debtsCost: form.values.extraCosts?.debtsCost,
											repairsCost: form.values.extraCosts?.repairsCost,
										});
										form.handleBlur(e);
									}}
									error={
										!!form.errors.purchaseCost &&
										(form.getFieldMeta('purchaseCost').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('purchaseCost').touched || showErrors
											? (form.errors.purchaseCost as string)
											: undefined
									}
									title="Costo de compra"
									placeholder="Costo del vehículo"
									name="purchaseCost"
									type="number"
									min={0}
									icon={<Coin />}
									className="m_b_xs w_100_per"
									value={form.values.purchaseCost}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									error={
										!!form.errors.extraCosts &&
										(form.getFieldMeta('extraCosts.basicExtraCost').touched ||
											showErrors)
									}
									subtitle={
										form.getFieldMeta('extraCosts.basicExtraCost').touched ||
										showErrors
											? _.get(form.errors, 'extraCosts.basicExtraCost', '')
											: undefined
									}
									title="Gastos extras "
									placeholder="Ejemplo: GPS, Sirenas, Tracker etc."
									name="extraCosts.basicExtraCost"
									type="number"
									min={0}
									icon={<Coin />}
									className="m_b_xs w_100_per"
									value={form.values.extraCosts?.basicExtraCost}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.extraCosts &&
										(form.getFieldMeta('extraCosts.gpsHardwareCost').touched ||
											showErrors)
									}
									subtitle={
										form.getFieldMeta('extraCosts.gpsHardwareCost').touched ||
										showErrors
											? _.get(form.errors, 'extraCosts.gpsHardwareCost', '')
											: undefined
									}
									title="Costo Hardware GPS"
									placeholder="Costo del hardware"
									name="extraCosts.gpsHardwareCost"
									type="number"
									min={0}
									icon={<Coin />}
									className="m_b_xs w_100_per"
									value={form.values.extraCosts?.gpsHardwareCost}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Input
									error={
										!!form.errors.extraCosts &&
										(form.getFieldMeta('extraCosts.gpsMonthlySoftwareCost')
											.touched ||
											showErrors)
									}
									subtitle={
										form.getFieldMeta('extraCosts.gpsMonthlySoftwareCost')
											.touched || showErrors
											? _.get(
													form.errors,
													'extraCosts.gpsMonthlySoftwareCost',
													'',
											  )
											: undefined
									}
									title="Costo Mensual GPS"
									placeholder="Plataforma GPS"
									name="extraCosts.gpsMonthlySoftwareCost"
									type="number"
									min={0}
									icon={<Coin />}
									className="m_b_xs w_100_per"
									value={form.values.extraCosts?.gpsMonthlySoftwareCost}
									onChange={form.handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									onBlur={(e: any) => {
										setPricingValues({
											blue: form.values.marketPrice,
											total: form.values.purchaseCost,
											debtsCost: form.values.extraCosts?.debtsCost,
											repairsCost: form.values.extraCosts?.repairsCost,
										});
										form.handleBlur(e);
									}}
									error={
										!!form.errors.marketPrice &&
										(form.getFieldMeta('marketPrice').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('marketPrice').touched || showErrors
											? (form.errors.marketPrice as string)
											: undefined
									}
									title={
										isContado
											? 'Precio de venta total de la moto'
											: 'Precio de mercado'
									}
									placeholder={
										isContado
											? 'Ingrese el precio de venta'
											: 'Ingrese el precio de mercado'
									}
									name="marketPrice"
									type="number"
									min={0}
									icon={<Book />}
									className="m_b_xs w_100_per"
									value={form.values.marketPrice}
									onChange={form.handleChange}
								/>
							</Grid>
							{isContado && (
								<>
									<Grid item xs={6}>
										<Input
											onBlur={(e: any) => {
												setPricingValues({
													blue: form.values.marketPrice,
													total: form.values.purchaseCost,
													debtsCost: form.values.extraCosts?.debtsCost,
													repairsCost: form.values.extraCosts?.repairsCost,
												});
												form.handleBlur(e);
											}}
											error={
												!!form.errors.extraCosts &&
												(form.getFieldMeta('extraCosts.repairsCost').touched ||
													showErrors)
											}
											subtitle={
												form.getFieldMeta('extraCosts.repairsCost').touched ||
												showErrors
													? _.get(form.errors, 'extraCosts.repairsCost', '')
													: undefined
											}
											title="Precio de reparacion"
											placeholder="Ingrese el valor estimado de reparacion"
											name="extraCosts.repairsCost"
											type="number"
											min={0}
											icon={<Coin />}
											className="m_b_xs w_100_per"
											value={form.values.extraCosts?.repairsCost}
											onChange={form.handleChange}
										/>
									</Grid>
									<Grid item xs={6}>
										<Input
											onBlur={(e: any) => {
												setPricingValues({
													blue: form.values.marketPrice,
													total: form.values.purchaseCost,
													debtsCost: form.values.extraCosts?.debtsCost,
													repairsCost: form.values.extraCosts?.repairsCost,
												});
												form.handleBlur(e);
											}}
											error={
												!!form.errors.extraCosts &&
												(form.getFieldMeta('extraCosts.debtsCost').touched ||
													showErrors)
											}
											subtitle={
												form.getFieldMeta('extraCosts.debtsCost').touched ||
												showErrors
													? _.get(form.errors, 'extraCosts.debtsCost', '')
													: undefined
											}
											title="Adeudos"
											placeholder="Ingrese el valor del adeudo"
											name="extraCosts.debtsCost"
											type="number"
											min={0}
											icon={<Coin />}
											className="m_b_xs w_100_per"
											value={form.values.extraCosts?.debtsCost}
											onChange={form.handleChange}
										/>
									</Grid>
								</>
							)}
							<Grid item xs={12}>
								<Divider />
								<div className="display_flex flex_justify_end flex_gap_lg m_y_md flex_align_center">
									<Typography
										scale="small"
										weight="600"
										textColor="neutral_900"
										className=""
									>
										Costo total de la moto:
									</Typography>
									<Typography
										scale="heading3"
										weight="600"
										textColor="primary_300"
										className=""
									>
										{priceFormatFinancial(
											(form.values.extraCosts?.basicExtraCost || 0) +
												(form.values.purchaseCost || 0),
											0,
										)}
									</Typography>
								</div>
							</Grid>
							{isContado ? (
								<Grid item xs={12}>
									<Divider />
									<div className="display_flex flex_justify_end flex_gap_lg m_y_md flex_align_center">
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_900"
											className=""
										>
											Precio final de la moto de contado:
										</Typography>
										<Typography
											scale="heading3"
											weight="600"
											textColor="primary_300"
											className=""
										>
											{priceFormatFinancial(prices[1]?.price ?? 0, 0)}
										</Typography>
									</div>
								</Grid>
							) : null}
							<Grid item xs={12}>
								<Typography scale="large" weight="600" textColor="neutral_700">
									Opciones de crédito y Hub de venta
								</Typography>
								<Typography
									scale="medium"
									weight="400"
									textColor="neutral_700"
									className="m_b_md"
								>
									Selecciona las semanas que están habilitadas para esta ozoneta
								</Typography>
								<div className="display_flex flex_col flex_gap_xl w_100_per p_b_md overflow_y_auto">
									{isContado ? (
										<div className="dso_card bg_neutral_0 p_md display_flex flex_gap_md flex_align_center">
											<Input
												title=""
												type="checkbox"
												options={[' ']}
												name="1"
												checked
												disabled
											/>
											<div className="flex_center_col flex_gap_md flex_grow_1">
												<Typography
													scale="large"
													weight="600"
													textColor="primary_300"
												>
													Venta de contado
												</Typography>
												<div className="display_flex flex_justify_between w_100_per">
													<div className="flex_center_col flex_justify_between">
														<Typography
															scale="small"
															weight="600"
															textColor="neutral_900"
															className="text_center"
														>
															Tasa interés
														</Typography>
														<Typography
															scale="large"
															weight="600"
															textColor="green_300"
															className="text_center"
														>
															0%
														</Typography>
													</div>
													<div className="flex_center_col flex_justify_between">
														<Typography
															scale="small"
															weight="600"
															textColor="neutral_900"
															className="text_center m_b_xxs"
														>
															Pago
														</Typography>
														<Typography
															scale="large"
															weight="600"
															textColor="green_300"
															className="text_center"
														>
															{prices[1]
																? priceFormatFinancial(prices[1].price, 0)
																: '$--'}
														</Typography>
													</div>
												</div>
											</div>
										</div>
									) : null}
									{Object.entries(loanTimes).map(([key, price]) => (
										<div
											className={`dso_card ${
												price.selected ? 'bg_neutral_0' : 'bg_neutral_300'
											} p_md p_b_none display_flex flex_gap_md flex_align_center`}
											key={key}
										>
											<Input
												title=""
												type="checkbox"
												options={[' ']}
												name={key}
												checked={price.selected}
												onChange={() => selectCredits(key)}
											/>
											<div className="flex_center_col flex_gap_md flex_grow_1">
												<Typography
													scale="large"
													weight="600"
													textColor={
														price.selected ? 'primary_300' : 'neutral_700'
													}
												>
													{`${key} semanas`}
												</Typography>
												<div className="display_flex flex_gap_md">
													<div className="flex_center_col flex_justify_between p_b_md">
														<Typography
															scale="small"
															weight="600"
															textColor={
																price.selected ? 'neutral_900' : 'neutral_800'
															}
															className="text_center m_b_xxs"
														>
															Pago
														</Typography>
														<Typography
															scale="large"
															weight="600"
															textColor={
																price.selected ? 'green_300' : 'neutral_600'
															}
															className="text_center"
														>
															{prices[key]?.price
																? priceFormatFinancial(prices[key].price, 0)
																: '$--'}
														</Typography>
													</div>
													<div className="flex_center_col flex_justify_between">
														<Typography
															scale="small"
															weight="600"
															textColor={
																price.selected ? 'neutral_900' : 'neutral_800'
															}
															className="text_center"
														>
															Tasa interés
														</Typography>
														<div className="pos_relative w_50_per">
															<Input
																type="number"
																title=""
																inputMode="numeric"
																min={0}
																disabled={!price.selected}
																name="interest"
																value={price.percentageIncrease}
																onChange={(e: any) =>
																	changeInterestCredits(key, e.target.value)
																}
																className=""
																inputClassName="text_center input-with-percentage"
																titleClassName="m_b_none"
															/>
															<Typography
																scale="small"
																weight="600"
																className="percentage-symbol"
															>
																%
															</Typography>
														</div>
													</div>
													<div className="flex_center_col flex_justify_between p_b_md">
														<Typography
															scale="small"
															weight="600"
															textColor={
																price.selected ? 'neutral_1000' : 'neutral_700'
															}
															className="text_center"
														>
															Total
														</Typography>
														<Typography
															scale="large"
															weight="600"
															textColor={
																price.selected ? 'green_300' : 'neutral_700'
															}
															className="text_center"
														>
															{prices[key]?.loanAmount
																? priceFormatFinancial(
																		prices[key].loanAmount,
																		0,
																  )
																: '$--'}
														</Typography>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</Grid>
							<Grid item xs={12}>
								<Input
									onBlur={form.handleBlur}
									error={
										!!form.errors.location &&
										(form.getFieldMeta('location.hub').touched || showErrors)
									}
									subtitle={
										form.getFieldMeta('location.hub').touched || showErrors
											? _.get(form.errors, 'location.hub', '')
											: undefined
									}
									title="Hub de venta"
									name="location.hub"
									placeholder="Selecciona un Hub"
									type="select"
									options={hubs}
									icon={<Store />}
									className="m_b_xs w_100_per"
									value={form.values.location?.hub}
									onChange={form.handleChange}
								/>
							</Grid>
							<Divider />
							<Grid item xs={12}>
								<Typography
									scale="large"
									weight="600"
									textColor="neutral_700"
									className="m_b_sm"
								>
									Descripción y Detalles del vehículo
								</Typography>
								<Typography
									scale="medium"
									weight="400"
									textColor="neutral_800"
									className="m_b_sm"
								>
									Utiliza{' '}
									<a
										href="https://chat.openai.com"
										target="_blank"
										rel="noreferrer"
									>
										ChatGPT
									</a>{' '}
									con este parámetro: “
									{descriptionTab === 0
										? 'Hazme una descripción corta sobre (Nombre de la moto), No más de 25 palabras'
										: 'Hazme una descripción larga sobre (Nombre de la moto) , con puntos claves'}
									”.
									<div
										className="m_t_xxs bg_neutral_0 br_xs border_neutral_400 p_y_xxs p_x_xs display_flex flex_gap_sm border_solid border_1 w_fit cursor_pointer"
										onClick={() => handleCopy(descriptionTab)}
										role="button"
										onKeyPress={() => handleCopy(descriptionTab)}
										tabIndex={0}
									>
										<Copy className="text_primary_300 dim_md border_primary_300" />
										<Typography
											scale="xsmall"
											weight="400"
											textColor="neutral_1000"
										>
											Copiar parámetro
										</Typography>
									</div>
								</Typography>
								<div className="display_flex w_90_per z_index_1 pos_relative">
									<div
										className={`flex_basis_0 flex_grow_1 cursor_pointer p_x_lg p_y_sm bg_${
											descriptionTab === 0 ? 'primary_300' : 'neutral_300'
										}`}
										onClick={() => setDetailTab(0)}
										role="button"
										onKeyPress={() => setDetailTab(0)}
										tabIndex={0}
									>
										<Typography
											scale="medium"
											weight="600"
											textColor={
												descriptionTab === 0 ? 'neutral_0' : 'neutral_700'
											}
											className="overflow_ellipsis"
										>
											Descripción corta
										</Typography>
									</div>
									<div
										className={`flex_basis_0 flex_grow_1 cursor_pointer p_x_lg p_y_sm bg_${
											descriptionTab === 1 ? 'primary_300' : 'neutral_300'
										}`}
										onClick={() => setDetailTab(1)}
										role="button"
										onKeyPress={() => setDetailTab(1)}
										tabIndex={0}
									>
										<Typography
											scale="medium"
											weight="600"
											textColor={
												descriptionTab === 1 ? 'neutral_0' : 'neutral_700'
											}
										>
											Detalles
										</Typography>
									</div>
								</div>
								<div className="detalles_edit">
									{descriptionTab === 0 ? (
										<Input
											onBlur={form.handleBlur}
											error={
												!!form.errors.shortDescription &&
												(form.getFieldMeta('shortDescription').touched ||
													showErrors)
											}
											subtitle={
												form.getFieldMeta('shortDescription').touched ||
												showErrors
													? (form.errors.shortDescription as string)
													: undefined
											}
											title=""
											name="shortDescription"
											placeholder="Ingresa aquí la descripción larga, generada en chatGPT"
											type="textarea"
											className="m_b_xs w_100_per"
											value={form.values.shortDescription}
											onChange={form.handleChange}
											inputClassName="h_100_px"
										/>
									) : (
										<Input
											onBlur={form.handleBlur}
											error={
												!!form.errors.description &&
												(form.getFieldMeta('description').touched || showErrors)
											}
											subtitle={
												form.getFieldMeta('description').touched || showErrors
													? (form.errors.description as string)
													: undefined
											}
											title=""
											name="description"
											placeholder="Ingresa aquí la descripción larga con puntos claves, generada en chatGPT"
											type="textarea"
											className="m_b_xs w_100_per"
											value={form.values.description}
											onChange={form.handleChange}
											inputClassName="h_100_px"
										/>
									)}
								</div>
								<CheckboxInput
									className="m_b_lg"
									label="Utilizar mensaje predeterminado (No recomendado)"
									value
									checked={useDefault}
									onChange={(e: any) =>
										setDescription(form, defaultType, e.target.checked)
									}
								/>
								{useDefault && (
									<>
										<Typography
											scale="medium"
											weight="600"
											textColor="neutral_1000"
											className="m_b_lg"
										>
											¿Que tipo de motocicleta, se esta subiendo?
										</Typography>
										<div className="display_flex flex_gap_md">
											{vehicleTypes.map((typeV: any) => (
												<div
													key={typeV.label}
													className={`flex_grow_1 flex_basis_0 overflow_hidden cursor_pointer br_xxs border_neutral_400 border_solid border_1 bg_${
														typeV.label === defaultType?.label
															? 'primary_300'
															: 'neutral_0'
													} p_xs`}
													onClick={() =>
														setDescription(form, typeV, useDefault)
													}
													role="button"
													onKeyPress={() =>
														setDescription(form, typeV, useDefault)
													}
													tabIndex={0}
												>
													<Typography
														scale="xsmall"
														weight="400"
														className="text_center w_100_per"
													>
														{typeV.label}
													</Typography>
												</div>
											))}
										</div>
									</>
								)}
							</Grid>
						</Grid>
					</div>
					<div className="flex_footer">
						<Button
							scale="small"
							orientation="right"
							icon={<Save />}
							className="m_b_xs w_100_per"
							type="submit"
							onClick={() => {
								displayFormErrors(form);
								form.handleSubmit();
							}}
							disabled={loading || (!form.dirty && !modified)}
						>
							Guardar
						</Button>
					</div>
				</FormikProvider>
			</SideModal>
		)
	);
}

export default EdiVehiclePopulated;
