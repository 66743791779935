/* eslint-disable no-shadow */

export enum RepossessionsActions {
	load_repossession = '[LOAD] repossessions',
	add_historic = '[ADD] historic',
}

export enum LocationsActions {
	load_locations = '[LOAD] locations',
	add_historic = '[ADD] historic',
}
