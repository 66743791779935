import { InterestPointTypeValues } from 'models/repossession/enums/repossession.enums';
import {
	RepossessionTotals,
	Repossession,
	InterestPoint,
} from 'models/repossession/interfaces/repossession.interface';
import {
	LocationsActions,
	RepossessionsActions,
} from 'store/types/repossessions.types';
import { featureProperties } from 'views/repossessions/Repossessions';

interface ReducerType {
	lastUpdate: number;
	data:
		| {
				[key: string]: {
					repossession: Repossession;
					interestPoint: InterestPoint;
					id?: string;
					primary: boolean;
					isOnRoute?: boolean;
					isSelected?: boolean;
				};
		  }
		| undefined;
	recoveryTotals: RepossessionTotals | null;
	historicRecoveries: { [key: string]: Repossession };
}

const ini = {
	lastUpdate: 0,
	data: undefined,
	recoveryTotals: null,
	historicRecoveries: {},
};

export const repossessionReducer = (
	// eslint-disable-next-line default-param-last
	state: ReducerType = ini,
	action: any,
): ReducerType => {
	switch (action.type) {
		case RepossessionsActions.load_repossession:
			return {
				...state,
				lastUpdate: Date.now(),
				data: action.payload.data?.reduce(
					(
						acc: { [id: string]: featureProperties },
						repossession: Repossession,
					) => {
						if (!repossession.interestPoint?.length) return acc;
						let currentScore = 0;
						let idPrimary = '';

						const interestPoints: { [id: string]: featureProperties } = {};
						repossession.interestPoint
							.filter(
								(interestPoint) =>
									interestPoint.location.coordinates.length === 2 &&
									interestPoint.location.coordinates[0] &&
									interestPoint.location.coordinates[1],
							)
							.forEach((interestPoint) => {
								const id = `${interestPoint._id ?? `tmp${interestPoint.type}`}${
									repossession._id
								}`;
								const ipScore =
									InterestPointTypeValues[interestPoint.type].score;
								if (currentScore < ipScore) {
									currentScore = ipScore;
									idPrimary = id;
								}
								interestPoints[id] = {
									repossession,
									interestPoint,
									id,
									primary: false,
								};
							});
						interestPoints[idPrimary].primary = true;
						return { ...acc, ...interestPoints };
					},
					{},
				),
				recoveryTotals: action.payload.totals,
			};
		case RepossessionsActions.add_historic:
			return {
				...state,
				historicRecoveries: {
					...state.historicRecoveries,
					[action.payload._id]: action.payload,
				},
			};

		default:
			return state;
	}
};
interface ReducerLocationsType {
	lastUpdateLocations: number;
	allLocations: any[] | undefined;
	historicLocations: { [key: string]: any };
}

const iniLoc = {
	lastUpdateLocations: 0,
	allLocations: undefined,
	historicLocations: {},
};

export const locationsReducer = (
	// eslint-disable-next-line default-param-last
	state: ReducerLocationsType = iniLoc,
	action: any,
): ReducerLocationsType => {
	switch (action.type) {
		case LocationsActions.load_locations:
			return {
				...state,
				lastUpdateLocations: Date.now(),
				allLocations: action.payload.data,
			};
		case RepossessionsActions.add_historic:
			return {
				...state,
				historicLocations: {
					...state.historicLocations,
					[action.payload?.credit?._id]: action.payload,
				},
			};

		default:
			return state;
	}
};
