/* eslint-disable no-restricted-globals */
import {
	setToken,
	setRefreshToken,
	clearLogout,
	setLastUrl,
} from 'localStorage/authStorage';
import { LoginResponse } from 'models/auth/interfaces/auth.interface';
import { AuthActions } from 'store/types/auth.types';

export const login = (authData: LoginResponse) => {
	setToken(authData.access_token);
	setRefreshToken(authData.refresh_token);

	return {
		type: AuthActions.login,
		payload: authData,
	};
};

export const logout = (save = false) => {
	if (save) setLastUrl(location.href);
	clearLogout();
	location.reload();
	return {
		type: AuthActions.logout,
	};
};
