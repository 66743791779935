/* eslint-disable no-shadow */
export enum RouteStatus {
	planning = 'planning',
	inProgress = 'inProgress',
	completed = 'completed',
	canceled = 'canceled',
}

export const RouteStatusValues: {
	[key in RouteStatus]: { title: string; color: string; textColor: string };
} = {
	[RouteStatus.planning]: {
		title: 'Planeada',
		color: 'neutral_300',
		textColor: 'neutral_800',
	},
	[RouteStatus.inProgress]: {
		title: 'En Progreso',
		color: 'neutral_300',
		textColor: 'secondary_500',
	},
	[RouteStatus.completed]: {
		title: 'Finalizada',
		color: 'green_100',
		textColor: 'green_300',
	},
	[RouteStatus.canceled]: {
		title: 'Cancelada',
		color: 'red_100',
		textColor: 'red_300',
	},
};
