/* eslint-disable no-shadow */

export enum OzonerStatus {
	lead = 'lead',
	inactive = 'inactive',
	active = 'active',
}

export const OzonerStatusValues = {
	lead: {
		title: 'Lead',
		color: 'yellow_100',
	},
	active: {
		title: 'Activo',
		color: 'green_300',
	},
	inactive: {
		title: 'Inactivo',
		color: 'neutral_700',
	},
};

export const ozonerFields = {
	name: 'Nombre',
	phone: 'Teléfono',
	email: 'Correo electrónico',
	address: 'Dirección',
	password: 'Contraseña',
	roles: 'Roles',
	financialForm: 'Formulario financiero',
	geolocations: 'Geolocalizaciones',
	city: 'Ciudad',
	currentHashedRefreshToken: 'Token de actualización',
	curp: 'CURP',
	palencaVerification: 'Verificación de Palenca',
	truoraVerification: 'Verificación de Truora',
	paymentDay: 'Día de pago',
	paymentMethod: 'Método de pago',
	status: 'Estado',
	subStatus: 'Subestado',
	vehicle: 'Vehículo',
	image: 'Imagen',
	porcobrarId: 'ID por cobrar',
	createdAt: 'Fecha de creación',
	statusHistoric: 'Histórico de estado',
	documents: 'Documentos',
	comment: 'Comentario',
	createdOn: 'Creado en',
	truoraId: 'ID de Truora',
	palencaId: 'ID de Palenca',
	truoraData: 'Datos de Truora',
	palencaData: 'Datos de Palenca',
	internalId: 'ID interno',
	social_network: 'Red social',
	lastChangeBy: 'Último cambio realizado por',
};
