/* eslint-disable no-shadow */

export enum EvidenceDocumentType {
	bank_certificate_1 = 'bank_certificate_1',
	bank_certificate_2 = 'bank_certificate_2',
	bank_certificate_3 = 'bank_certificate_3',
	bank_certificate_4 = 'bank_certificate_4',
	bank_certificate_5 = 'bank_certificate_5',
	bank_certificate_6 = 'bank_certificate_6',

	ine_front = 'ine_front',
	ine_back = 'ine_back',
	license_front = 'license_front',
	license_back = 'license_back',
	truora = 'truora',
	proof_of_address = 'proof_of_address',
	selfie = 'selfie',
	videoEvidenceAddress = 'videoEvidenceAddress',
	videoEvidenceWork = 'videoEvidenceWork',
	layawayReceipt = 'layawayReceipt',
	advancedmoney = 'advancedmoney',
	other = 'other',
	deposit = 'deposit',
	contract = 'contract',
	tax_situation = 'tax_situation',
	promissory_note = 'promissory_note',
	delivery_photo = 'delivery_photo',
}

export const EvidenceDocumentTypeValues: {
	[key in EvidenceDocumentType]: {
		title: string;
		subtitle: string;
		accept: string;
	};
} = {
	[EvidenceDocumentType.ine_front]: {
		title: 'INE ·',
		subtitle: 'Frontal',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.ine_back]: {
		title: 'INE ·',
		subtitle: 'Posterior',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.selfie]: {
		title: 'Selfie',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.license_front]: {
		title: 'Licencia · ',
		subtitle: 'Frontal',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.license_back]: {
		title: 'Licencia · ',
		subtitle: 'Posterior',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.proof_of_address]: {
		title: 'Domicilio',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.tax_situation]: {
		title: 'Situación Fiscal',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.bank_certificate_1]: {
		title: '1er Estado de Cuenta',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.bank_certificate_2]: {
		title: '2do Estado de Cuenta',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.bank_certificate_3]: {
		title: '3er Estado de cuenta',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.bank_certificate_4]: {
		title: '4to Estado de Cuenta',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.bank_certificate_5]: {
		title: '5to Estado de Cuenta',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.bank_certificate_6]: {
		title: '6to Estado de cuenta',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},

	[EvidenceDocumentType.videoEvidenceAddress]: {
		title: 'Video-Domicilio',
		subtitle: '',
		accept: 'video/*',
	},
	[EvidenceDocumentType.videoEvidenceWork]: {
		title: 'Video-Trabajo',
		subtitle: '',
		accept: 'video/*',
	},
	[EvidenceDocumentType.truora]: {
		title: 'Truora',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.advancedmoney]: {
		title: 'Pago Adelantado',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.deposit]: {
		title: '1er Pago',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.contract]: {
		title: 'Contrato',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.layawayReceipt]: {
		title: 'Recibo apartado',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.promissory_note]: {
		title: 'Pagare',
		subtitle: '',
		accept: 'image/png, image/jpeg, application/pdf',
	},
	[EvidenceDocumentType.delivery_photo]: {
		title: 'Foto de entrega',
		subtitle: '',
		accept: 'image/png, image/jpeg',
	},
	[EvidenceDocumentType.other]: {
		title: 'Otro',
		subtitle: '',
		accept: '*',
	},
};

export enum DocumentMovementType {
	add = 'add',
	remove = 'remove',
}

export interface EvidenceDocument {
	_id: string;
	name: string;
	url: string;
	size: number;
	path: string;
	hash: string;
	ext: string;
	mimeType: string;
	typeEvidence: EvidenceDocumentType;
}
