import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { index } from 'theme';
import { store } from 'store';
import RootRouter from 'routers/index.router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Ozon() {
	return (
		<Provider store={store}>
			<ThemeProvider theme={index}>
				<RootRouter />
				<ToastContainer
					position="top-center"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="colored"
				/>
			</ThemeProvider>
		</Provider>
	);
}

export default Ozon;
