import React, { FC, useEffect, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { Typography } from 'design_system/src';

// css
import './sidebar.scss';

// imgs - icons
import { ReactComponent as Menu } from 'design_system/src/static/icons/menu.svg';
import semicircle from 'static/images/semicircle.png';
import { ReactComponent as Dashboard } from 'design_system/src/static/icons/bar.svg';
import { ReactComponent as CreditCard } from 'design_system/src/static/icons/credit-card.svg';
import { ReactComponent as Repossession } from 'design_system/src/static/icons/compare.svg';
import { ReactComponent as Soli } from 'design_system/src/static/icons/bulleted-list-2.svg';
import { ReactComponent as Bell } from 'design_system/src/static/icons/notification-bell.svg';
import { ReactComponent as GPS } from 'design_system/src/static/icons/gps.svg';
import { ReactComponent as Inventario } from 'design_system/src/static/icons/motorcycle.svg';
import { ReactComponent as Credits } from 'design_system/src/static/icons/money.svg';
import { ReactComponent as Ozoners } from 'design_system/src/static/icons/helmet.svg';
import { ReactComponent as Ozocio } from 'design_system/src/static/icons/idea.svg';
import { ReactComponent as Invest } from 'design_system/src/static/icons/invest.svg';
import { ReactComponent as Panda } from 'design_system/src/static/icons/panda.svg';
import { ReactComponent as Left } from 'design_system/src/static/icons/left-lg.svg';
import { ReactComponent as Right } from 'design_system/src/static/icons/right-lg.svg';
import { ReactComponent as Cartera } from 'design_system/src/static/icons/working-bag-3.svg';
import profileDefaultOzonio from 'static/images/ozonio_default.png';
import { getUser } from 'localStorage/authStorage';
import {
	clearInventoryFilter,
	clearOzonerFilter,
	clearOzonioFilter,
	clearSolicitudesFilter,
	clearCreditsFilter,
	clearRepossessionsFilter,
	clearNotificationsFilter,
	clearDevicesFilter,
	clearOzocioFilter,
	clearWriteOffFilter,
} from 'localStorage/filterStorage';
import { Roles } from 'models/ozonio/enums/ozonio.enum';

type Props = {
	to: string;
	name: string;
	Img: React.FunctionComponent<
		React.SVGProps<SVGSVGElement> & {
			title?: string | undefined;
		}
	>;
};

export function RequireRole({
	children,
	authRoles,
}: {
	children: JSX.Element;
	authRoles: Roles[] | undefined;
}) {
	const user = getUser();
	if (authRoles && (!user || !authRoles.includes(user.role as Roles))) {
		return null;
	}
	return children;
}

const nav = [
	{
		to: '/',
		img: Menu,
		name: 'Dashboard',
	},
	{
		to: '/loans',
		img: CreditCard,
		name: 'Créditos',
		auth: [
			Roles.ADMIN,
			Roles.CREDIT_ANALYST,
			Roles.DEVELOPER,
			Roles.COLLECTOR_AGENT,
		],
	},
	{
		to: '/inventory',
		img: Inventario,
		name: 'Inventario',
		auth: [
			Roles.ADMIN,
			Roles.RECUPERATOR,
			Roles.SELLER_CHIEF,
			Roles.SELLER,
			Roles.CREDIT_ANALYST,
			Roles.COLLECTOR_AGENT,
			Roles.DEVELOPER,
		],
	},
	{
		to: '/devices',
		img: GPS,
		name: 'Dispositivos',
		auth: [Roles.ADMIN, Roles.RECUPERATOR, Roles.DEVELOPER],
	},
	{
		to: '/solicitudes',
		img: Soli,
		name: 'Solicitudes',
		auth: [
			Roles.SELLER_CHIEF,
			Roles.SELLER,
			Roles.ADMIN,
			Roles.CREDIT_ANALYST,
			Roles.COLLECTOR_AGENT,
			Roles.DEVELOPER,
		],
	},
	{
		to: '/ozocio',
		img: Ozocio,
		name: 'Ozocios',
		auth: [
			Roles.ADMIN,
			Roles.SELLER_CHIEF,
			Roles.SELLER,
			Roles.RECUPERATOR,
			Roles.CREDIT_ANALYST,
		],
	},
	{
		to: '/recovery',
		img: Repossession,
		name: 'Recuperaciones',

		auth: [
			Roles.ADMIN,
			Roles.RECUPERATOR,
			Roles.DEVELOPER,
			Roles.COLLECTOR_AGENT,
		],
	},
	{
		to: '/notifications',
		img: Bell,
		name: 'Notificaciones',
		auth: [
			Roles.ADMIN,
			Roles.RECUPERATOR,
			Roles.DEVELOPER,
			Roles.COLLECTOR_AGENT,
		],
	},
	{
		to: '/ozonio',
		img: Panda,
		name: 'Ozonios',
		auth: [Roles.SELLER_CHIEF, Roles.ADMIN, Roles.DEVELOPER],
	},
];

const CustomLink: FC<Props> = ({ to, name, Img }) => {
	const resolved = useResolvedPath(to);
	const match = useMatch({ path: `${resolved.pathname}/*`, end: true });

	const cleanUp = () => {
		if (!match) {
			clearInventoryFilter();
			clearOzonerFilter();
			clearOzocioFilter();
			clearOzonioFilter();
			clearSolicitudesFilter();
			clearCreditsFilter();
			clearRepossessionsFilter();
			clearNotificationsFilter();
			clearDevicesFilter();
			clearWriteOffFilter();
		}
	};
	return (
		<div className="m_b_md">
			<Link to={to} className="text_decoration_none" onClick={cleanUp}>
				<div className={`containerSelect ${match && 'active '}`}>
					<Img className="link_icon dim_lg text_neutral_900 " />
					<Typography
						className="link_text text_neutral_900"
						scale="small"
						weight="600"
						component="span"
					>
						{name}
					</Typography>
				</div>
			</Link>
		</div>
	);
};

function Sidebar() {
	const [user, setUser] = useState<any>(null);
	const [close, setclose] = useState(true);

	useEffect(() => {
		setUser(getUser());
	}, []);

	const handleSidebar = () => {
		setclose(!close);
	};
	return (
		<div
			className={`sidebar bg_neutral_0 shadow_hard p_y_xl p_x_sm ${
				close ? 'close' : ''
			}`}
		>
			{nav
				.filter((ele) => !ele.auth || ele.auth.includes(user?.role))
				.map((ele) => (
					<CustomLink
						to={ele.to}
						name={ele.name}
						Img={ele.img}
						key={ele.name}
					/>
				))}
			<div
				className="circle bg_primary_300"
				onClick={handleSidebar}
				onKeyPress={handleSidebar}
				role="button"
				tabIndex={0}
			>
				{close ? <Right className="dim_md" /> : <Left className="dim_md" />}
			</div>
		</div>
	);
}

export default Sidebar;
