import { getBrandsModels, setBrands } from 'localStorage/dataStorage';
import { Brand } from 'models/inventory/interfaces/brand.interface';
import { axiosErrorHandler, get } from 'models/shared/helpers/axiosInstance';

export const getBrandsWithModels = async () => {
	try {
		let info = getBrandsModels();
		if (!info) {
			const res = await get<Brand[]>('v1/brand');
			info = res.data;
			setBrands(info);
		}
		return info;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
