import React from 'react';

import { ReactComponent as Signal } from 'design_system/src/static/icons/radio-signal.svg';
import { ReactComponent as NoSignal } from 'design_system/src/static/icons/no-radio-signal.svg';
import { ReactComponent as Home } from 'design_system/src/static/icons/temple.svg';
import { ReactComponent as Hotspot } from 'design_system/src/static/icons/fire-camp.svg';
import {
	InterestPointType,
	LocationType,
	RepossessionPolicy,
} from 'models/repossession/enums/repossession.enums';

export const baseFilterOptions = [
	{
		value: RepossessionPolicy.devolution,
		label: 'Devolución',
	},
	{
		value: RepossessionPolicy.priority,
		label: 'Prioritario',
	},
	{
		value: RepossessionPolicy.overdue,
		label: 'Mora 30',
	},
	{
		value: RepossessionPolicy.writeOff,
		label: 'WriteOff',
	},
];
export const tabs = ['Mapa', 'Por recuperar', 'Rutas'];

export const typesOptions = [
	{
		value: InterestPointType.signalVehicle,
		title: 'Motos con señal',
		icon: <Signal className="dim_lg" />,
	},
	{
		value: InterestPointType.noSignalVehicle,
		title: 'Motos sin señal',
		icon: <NoSignal className="dim_lg" />,
	},
	{
		value: InterestPointType.address,
		title: 'Hogares',
		icon: <Home className="dim_lg" />,
	},
	{
		value: InterestPointType.hotspot,
		title: 'Hotspots',
		icon: <Hotspot className="dim_lg" />,
	},
];
export const locationTypesOptions = [
	{
		value: LocationType.signalVehicle,
		label: 'Motos con señal',
	},
	{
		value: LocationType.noSignalVehicle,
		label: 'Motos sin señal',
	},
	{
		value: LocationType.gpsTraker,
		label: 'Ultima ubicacion GPSTracker',
	},
];

export const allVehiclesSourceBase = {
	type: 'geojson',
	data: {
		type: 'FeatureCollection',
		features: [],
	},
	cluster: true,
	clusterRadius: 90,
	clusterProperties: {
		gpsTracker: ['+', ['case', ['==', ['get', 'isTrackGPS'], true], 1, 0]],
		active: [
			'+',
			[
				'case',
				[
					'all',
					['==', ['get', 'type'], InterestPointType.signalVehicle],
					['==', ['get', 'isTrackGPS'], false],
				],
				1,
				0,
			],
		],
		inactive: [
			'+',
			[
				'case',
				[
					'all',
					['==', ['get', 'type'], InterestPointType.noSignalVehicle],
					['==', ['get', 'isTrackGPS'], false],
				],
				1,
				0,
			],
		],
	},
};

export const vehiclesSourceBase = {
	type: 'geojson',
	data: {
		type: 'FeatureCollection',
		features: [],
	},
	cluster: true,
	clusterRadius: 90,
	clusterProperties: {
		// keep separate counts for each magnitude category in a cluster
		[RepossessionPolicy.priority]: [
			'+',
			['case', ['==', ['get', 'policy'], RepossessionPolicy.priority], 1, 0],
		],
		[RepossessionPolicy.overdue]: [
			'+',
			['case', ['==', ['get', 'policy'], RepossessionPolicy.overdue], 1, 0],
		],
		[RepossessionPolicy.writeOff]: [
			'+',
			['case', ['==', ['get', 'policy'], RepossessionPolicy.writeOff], 1, 0],
		],
		active: [
			'+',
			['case', ['==', ['get', 'type'], InterestPointType.signalVehicle], 1, 0],
		],
		inactive: [
			'+',
			[
				'case',
				['==', ['get', 'type'], InterestPointType.noSignalVehicle],
				1,
				0,
			],
		],
		hotspot: [
			'+',
			['case', ['==', ['get', 'type'], InterestPointType.hotspot], 1, 0],
		],
		address: [
			'+',
			['case', ['==', ['get', 'type'], InterestPointType.address], 1, 0],
		],
	},
};

export const hotspotHeatLayerDefinition = {
	id: 'interest_points_heat',
	type: 'heatmap',
	source: 'vehicle-hotspots',
	maxzoom: 18,
	paint: {
		// increase weight as diameter breast height increases
		'heatmap-weight': {
			property: 'dbh',
			type: 'exponential',
			stops: [
				[1, 0],
				[62, 1],
			],
		},
		// increase intensity as zoom level increases
		'heatmap-intensity': {
			stops: [
				[11, 1],
				[15, 3],
			],
		},
		// assign color values be applied to points depending on their density
		'heatmap-color': [
			'interpolate',
			['linear'],
			['heatmap-density'],
			0,
			'rgba(236,222,239,0)',
			0.2,
			'rgb(208,209,230)',
			0.4,
			'rgb(166,189,219)',
			0.6,
			'rgb(103,169,207)',
			0.8,
			'rgb(255,144,153)',
		],
		// increase radius as zoom increases
		'heatmap-radius': {
			stops: [
				[11, 15],
				[15, 20],
			],
		},
		// decrease opacity to transition into the circle layer
		'heatmap-opacity': {
			default: 1,
			stops: [
				[14, 1],
				[15, 0],
			],
		},
	},
};

export const hotspotLayerDefinition = {
	id: 'interest_points_circles',
	type: 'circle',
	source: 'vehicle-hotspots',
	minzoom: 14,
	paint: {
		// increase the radius of the circle as the zoom level and dbh value increases
		'circle-radius': {
			property: 'dbh',
			type: 'exponential',
			stops: [
				[{ zoom: 15, value: 1 }, 5],
				[{ zoom: 15, value: 62 }, 10],
				[{ zoom: 22, value: 1 }, 20],
				[{ zoom: 22, value: 62 }, 50],
			],
		},
		'circle-color': {
			property: 'dbh',
			type: 'exponential',
			stops: [
				[0, 'rgba(236,222,239,0)'],
				[10, 'rgb(236,222,239)'],
				[20, 'rgb(208,209,230)'],
				[30, 'rgb(166,189,219)'],
				[40, 'rgb(103,169,207)'],
				[50, 'rgb(28,144,153)'],
				[60, 'rgb(1,108,89)'],
			],
		},
		'circle-stroke-color': 'white',
		'circle-stroke-width': 1,
		'circle-opacity': {
			stops: [
				[14, 0],
				[15, 1],
			],
		},
	},
};

function getRandomCoordinate(center: number[], radius: number) {
	const y0 = center[1];
	const x0 = center[0];
	const rd = radius / 111300; // about 111300 meters in one degree

	const u = Math.random();
	const v = Math.random();

	const w = rd * Math.sqrt(u);
	const t = 2 * Math.PI * v;
	const x = w * Math.cos(t);
	const y = w * Math.sin(t);

	const newX = x + x0;
	const newY = y + y0;

	return [newX, newY];
}

export const generateMockPoints = (
	center: number[],
	n: number,
	radius: number,
) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			const points = [];

			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < n; i++) {
				const coord = getRandomCoordinate(center, radius);
				points.push({
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: coord,
					},
					properties: {},
				});
			}

			resolve(points);
		}, 2000);
	});
};
