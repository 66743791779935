/* eslint-disable no-underscore-dangle */
/* eslint-disable dot-notation */
import {
	get,
	put,
	post,
	deletes,
	axiosErrorHandler,
} from 'models/shared/helpers/axiosInstance';
import FormData from 'form-data';
import { getRoles, setRoles } from 'localStorage/dataStorage';
import * as qs from 'qs';
import { PaginatedDocuments } from 'models/shared/types/pagination';
import {
	Ozonio,
	CreateOzonioDto,
	UpdateOzonioDto,
	Role,
	CreateUserFilterDto,
	PasswordRecover,
} from '../interfaces/ozonio.interface';
import { FilterSections } from '../enums/ozonio.enum';

export const findAllOzonios = async (
	query: string,
	letter?: string,
): Promise<PaginatedDocuments<Ozonio>> => {
	try {
		let path = 'v1/user?limit=-1';
		if (query) {
			path = `${path}&query=${query}`;
		}
		if (letter) {
			path = `${path}&letter=${letter}`;
		}
		const res = await get<PaginatedDocuments<Ozonio>>(path);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const findOzonioById = async (id = ''): Promise<Ozonio> => {
	try {
		const res = await get<Ozonio>(`v1/user/${id}`);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const createOzonio = async (
	values: CreateOzonioDto,
	image: any,
): Promise<Ozonio> => {
	try {
		const data = new FormData();
		Object.entries(values).forEach(([key, value]) => {
			if (value !== undefined && value !== '') data.append(key, value);
		});
		if (image && image.file) data.append('imageFile', image.file);

		const res = await post<Ozonio>('v1/user', data, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const updateOzonio = async (
	values: UpdateOzonioDto,
	id: string,
): Promise<Ozonio> => {
	try {
		const data = new FormData();
		Object.keys(values).forEach((k) => {
			data.append(k, values[k as keyof UpdateOzonioDto]);
		});

		const res = await put<Ozonio>(`v1/user/${id}`, data, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const updateImageOzonio = async (
	user: string,
	file: File,
): Promise<Ozonio> => {
	try {
		const data = new FormData();
		data.append('imageFile', file);

		const res = await put<Ozonio>(`v1/user/${user}`, data, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const findAllRoles = async (): Promise<Role[]> => {
	try {
		let info = getRoles();
		if (!info) {
			const res = await get<PaginatedDocuments<Role>>('v1/user/role');
			info = res.data.data;
			setRoles(info);
		}
		return info;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const restorePassword = async (
	email: string,
): Promise<{
	success: boolean;
}> => {
	try {
		const data = qs.stringify({
			email,
		});

		const res = await post<{
			success: boolean;
		}>('v1/user/recover-password', data, {
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		});
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const createUserFilter = async (
	id: string,
	name: string,
	section: FilterSections,
	values: any,
): Promise<Ozonio> => {
	try {
		const data = { name, values, section };

		const res = await post<Ozonio>(`v1/user/${id}/personalFilter`, data);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const deleteUserFilter = async (
	id: string,
	fId: string,
): Promise<Ozonio> => {
	try {
		const res = await deletes<Ozonio>(`v1/user/${id}/personalFilter/${fId}`);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};

export const fetchRecover = async (recover: PasswordRecover) => {
	try {
		const res = await put('v1/user/password', recover);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};
