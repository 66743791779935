import { getToken, getRefreshToken } from 'localStorage/authStorage';
import {
	AuthActions,
	AuthActionTypes,
	AuthState,
} from 'store/types/auth.types';

const accessToken = getToken();
const refreshToken = getRefreshToken();

const initialState: AuthState =
	accessToken && refreshToken
		? {
				isLoggedIn: true,
				access: {
					accessToken,
					refreshToken,
				},
		  }
		: { isLoggedIn: false, access: null };

export const authReducer = (
	// eslint-disable-next-line default-param-last
	state: AuthState = initialState,
	action: AuthActionTypes,
) => {
	switch (action.type) {
		case AuthActions.login:
			return {
				...state,
				isLoggedIn: true,
				access: {
					accessToken: action.payload.access_token,
					refreshToken: action.payload.refresh_token,
				},
			};
		case AuthActions.logout:
			return {
				isLoggedIn: false,
				access: null,
			};

		default:
			return state;
	}
};
