import React, { FC, useEffect, useState } from 'react';
import { Popup, Typography } from 'design_system/src';
import { ReactComponent as Action } from 'design_system/src/static/icons/more-v.svg';
import { getUser } from 'localStorage/authStorage';
import { UserFilter } from 'models/ozonio/interfaces/ozonio.interface';
import {
	createUserFilter,
	deleteUserFilter,
	findOzonioById,
} from 'models/ozonio/controllers/ozonios.controller';
import { FilterSections } from 'models/ozonio/enums/ozonio.enum';
import SaveFilter from './saveFilter/SaveFilter';

interface PersonalFiltersSectionProps {
	section: FilterSections;
	selectedFilter: string;
	setSelectedFilter: (filter: string) => void;
	changeFilter: (filter: UserFilter) => void;
	isModified: () => boolean;
	setStatusPersonalFilter: (status: any) => void;
	defaultFilters?: UserFilter[];
	getActualFilter: () => any;
	openSaveFilter: boolean;
	setOpenSaveFilter: (open: boolean) => void;
}

const PersonalFiltersSection: FC<PersonalFiltersSectionProps> = ({
	section,
	selectedFilter,
	setSelectedFilter,
	changeFilter,
	isModified,
	setStatusPersonalFilter,
	getActualFilter,
	defaultFilters = [],
	openSaveFilter,
	setOpenSaveFilter,
}) => {
	const [openOptions, setOpenOptions] = useState('');
	const [personalFilters, setPersonalFilters] = useState<UserFilter[]>([]);
	const [ozonioFilters, setOzonioFilters] = useState<UserFilter[]>([]);
	const [callbackConfirm, setCallbackConfirm] = useState<any>();
	const [openConfirmFilter, setOpenConfirmFilter] = useState(false);

	const requestFilters = () => {
		const userL = getUser();
		if (userL)
			findOzonioById(userL.userId).then((ozonio) => {
				setOzonioFilters([
					...(ozonio?.personalFilters?.filter(
						(fil: UserFilter) => fil.section === FilterSections.formsList,
					) ?? []),
				]);
			});
	};

	useEffect(() => {
		requestFilters();
	}, []);

	useEffect(() => {
		setPersonalFilters([...defaultFilters, ...ozonioFilters]);
	}, [ozonioFilters, defaultFilters]);

	const confirmChangeFilter = () => {
		const { type, value } = callbackConfirm;
		if (type) {
			setStatusPersonalFilter(value);
		} else {
			changeFilter(value);
		}
	};

	const changingFilter = (type: boolean, value: any) => {
		if (isModified()) {
			if (type) {
				setStatusPersonalFilter(value);
			} else {
				changeFilter(value);
			}
		} else {
			setCallbackConfirm({ type, value });
			setOpenConfirmFilter(true);
		}
	};
	const deletePersonalFilter = (uFilter: UserFilter) => {
		const userL = getUser();
		if (userL)
			deleteUserFilter(userL.userId, uFilter._id).then(() => {
				setSelectedFilter('');
				requestFilters();
			});
	};

	const saveFilter = (name: string) => {
		const userL = getUser();
		if (userL)
			return createUserFilter(
				userL.userId,
				name,
				section,
				getActualFilter(),
			).then((e: any) => {
				requestFilters();
				setSelectedFilter(e.personalFilters[e.personalFilters.length - 1]._id);
				return e;
			});
		return Promise.resolve();
	};

	return (
		<>
			<div className="display_flex flex_gap_xl overflow_x_auto p_b_md">
				{personalFilters.map((uFilter, index) => (
					<div
						key={index}
						className={`display_flex flex_gap_sm p_x_md p_y_xs status_tab pos_relative ${
							selectedFilter === uFilter._id ? 'active' : ''
						}`}
					>
						<Typography
							scale="small"
							weight={selectedFilter === uFilter._id ? '600' : '400'}
							onClick={() => changingFilter(false, uFilter)}
							key={`status-filter-${uFilter._id}`}
							className="cursor_pointer "
						>
							{uFilter.name}
						</Typography>
						{!uFilter.default && (
							<>
								<Action
									className="dim_md cursor_pointer text_neutral_600"
									onClick={() => setOpenOptions(uFilter._id)}
								/>
								<Popup
									open={openOptions === uFilter._id}
									setOpen={() => setOpenOptions('')}
									placementX="right-inset"
									className="bg_neutral_100 br_xxs m_r_md"
								>
									<Typography
										scale="xsmall"
										weight="400"
										className="p_xs border_b_solid border_b_1 border_neutral_400 cursor_pointer menu_selectable"
										onClick={() => deletePersonalFilter(uFilter)}
										onKeyPress={() => deletePersonalFilter(uFilter)}
										tabIndex={0}
										role="button"
									>
										Eliminar filtro
									</Typography>
								</Popup>
							</>
						)}
					</div>
				))}
			</div>
			{(openSaveFilter || openConfirmFilter) && (
				<SaveFilter
					open={openSaveFilter}
					setOpen={setOpenSaveFilter}
					callback={saveFilter}
					openConfirm={openConfirmFilter}
					setOpenConfirm={setOpenConfirmFilter}
					callbackConfirm={confirmChangeFilter}
				/>
			)}
		</>
	);
};

export default PersonalFiltersSection;
