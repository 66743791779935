/* eslint-disable no-shadow */
export enum RecoveryStatus {
	pending = 'pending',
	onWay = 'onWay',
	inSite = 'inSite',
	recovered = 'recovered',
	failed = 'failed',
	canceled = 'canceled',
}

export const RecoveryStatusValues: {
	[key in RecoveryStatus]: { title: string; color: string };
} = {
	[RecoveryStatus.pending]: {
		title: 'Por recuperar',
		color: 'yellow_100',
	},
	[RecoveryStatus.onWay]: {
		title: 'En proceso',
		color: 'primary_300',
	},
	[RecoveryStatus.inSite]: {
		title: 'En sitio',
		color: 'primary_200',
	},
	[RecoveryStatus.failed]: {
		title: 'No recuperada',
		color: 'red_300',
	},
	[RecoveryStatus.recovered]: {
		title: 'Recuperada',
		color: 'green_300',
	},
	[RecoveryStatus.canceled]: {
		title: 'Cancelada',
		color: 'neutral_1000',
	},
};
