import {
	DocumentalStatusValues,
	VisualStatusValues,
} from 'models/inventory/enums/vehicle.enum';
import { VehiclePopulated } from 'models/inventory/interfaces/vehicle.interface';

export const detailsData: {
	title: string;
	name: keyof VehiclePopulated;
	map?: any;
}[][] = [
	[
		{
			title: 'Color principal',
			name: 'color',
			map: {
				yellow: 'Amarillo',
				pink: 'Rosa',
				purple: 'Morado',
				blue: 'Azul',
				orange: 'Naranja',
				green: 'Verde',
				white: 'Blanco',
				gray: 'Gris',
				red: 'Rojo',
				black: 'Negro',
			},
		},
		{
			title: 'Color secundario',
			name: 'secondaryColor',
			map: {
				yellow: 'Amarillo',
				pink: 'Rosa',
				purple: 'Morado',
				blue: 'Azul',
				orange: 'Naranja',
				green: 'Verde',
				white: 'Blanco',
				gray: 'Gris',
				red: 'Rojo',
				black: 'Negro',
			},
		},
		{
			title: 'Serie vehicular',
			name: 'vehicleSN',
		},

		{
			title: 'Tarjeta de Circulación',
			name: 'registrationCard',
		},
		{
			title: 'Motor',
			name: 'engineSN',
		},
		{
			title: 'Estado documental',
			name: 'documentalStatus',
			map: Object.keys(DocumentalStatusValues).reduce(
				(acc, key) => ({
					...acc,
					[key]: (DocumentalStatusValues as any)[key].label,
				}),
				{},
			),
		},
		{
			title: 'Estado Visual',
			name: 'visualStatus',
			map: Object.keys(VisualStatusValues).reduce(
				(acc, key) => ({
					...acc,
					[key]: (VisualStatusValues as any)[key].label,
				}),
				{},
			),
		},
	],
	[
		{
			title: 'Año',
			name: 'year',
		},
		{
			title: 'Frenos',
			name: 'brakeType',
			map: { drum: 'Tambor', disc: 'Discos' },
		},
		{
			title: 'Placa',
			name: 'plate',
		},
	],
];

export const hasVehicleOptions = [
	{
		label: 'Dispositivo asignado',
		value: 'true',
	},
	{
		label: 'Dispositivo sin asignar',
		value: 'false',
	},
];
