import { useState } from 'react';

interface FindAllParams<Model, Totals> {
	data: Model[];
	currentPage: number;
	pages?: number;
	totals?: Totals;
	sortValue: string;
	loading: boolean;
	query: string;
	count: number;
}

interface useFindAllParamsReturn<Model, Totals> {
	findAllParams: FindAllParams<Model, Totals>;
	setData: (data: Model[]) => void;
	setCurrentPage: (page: number) => void;
	setPages: (pages: number) => void;
	setTotals: (totals: Totals) => void;
	setSortValue: (sortValue: string) => void;
	setLoading: (loading: boolean) => void;
	setQuery: (query: string) => void;
	setCount: (count: number) => void;
}

interface useFindAllParamsConfig {
	defaultSort?: string;
}

const useFindAllParams = <Model, Totals>(
	config?: useFindAllParamsConfig,
): useFindAllParamsReturn<Model, Totals> => {
	const [data, setData] = useState<Model[]>([]);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pages, setPages] = useState<number>();
	const [totals, setTotals] = useState<Totals>();
	const [sortValue, setSortValue] = useState<string>(
		config?.defaultSort || '-createdAt',
	);
	const [loading, setLoading] = useState(false);
	const [query, setQuery] = useState<string>('');
	const [count, setCount] = useState(0);

	return {
		findAllParams: {
			data,
			currentPage,
			pages,
			totals,
			sortValue,
			loading,
			query,
			count,
		},
		setData,
		setCurrentPage,
		setPages,
		setTotals,
		setSortValue,
		setLoading,
		setQuery,
		setCount,
	};
};

export default useFindAllParams;
